import { Component, EventEmitter, Input, NO_ERRORS_SCHEMA, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoggerService } from '../../shared/logger/logger.service';
import { FormModes } from '../../common/constants.lib';
import { User, Role } from '../../models/models.lib';
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { LabelModule } from "@progress/kendo-angular-label";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { ButtonsModule } from "@progress/kendo-angular-buttons";

@Component({
  selector: 'casi-edit-user-modal',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    FontAwesomeModule,
    RouterLink, RouterLinkActive,
    DropDownsModule,
    LabelModule, InputsModule,
    ButtonsModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
  templateUrl: './edit-user-modal.component.html',
  styleUrl: './edit-user-modal.component.scss'
})
export class EditUserModalComponent implements OnInit, OnDestroy {

  //#region Properties

  private _user: User | undefined;

  @Input({ required: true })
  set user(value: User) {
    this._user = value;
    this.form.patchValue(this._user);
  }
  get user(): User | undefined {
    return this._user
  };
  // @Input({ required: true }) user: User = new User();
  @Input({ required: true }) roles: Role[] = [];
  @Input({ required: true }) selectedRoleIds: Role[] = [];
  @Input({ required: true }) mode: FormModes = FormModes.NOTSET;

  @Output() submitForm: EventEmitter<FormGroup> = new EventEmitter();
  @Output() closeForm: EventEmitter<void> = new EventEmitter();

  form: FormGroup;

  public value: number[] = [];

  private _subs: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private logger: LoggerService,
  ) {
    this.form = this.fb.group(
      {
        id: [null, []],
        email: ['', [Validators.required, Validators.email]],
        first_name: [null, [Validators.required]],
        last_name: [null, [Validators.required]],
        userRoles: [null, [Validators.required]]
      }
    );
  }

  //#region Form

  get f() {
    return this.form.controls;
  }
  get email() {
    return this.form.get('email');
  }
  get first_name() {
    return this.form.get('first_name');
  }
  get last_name() {
    return this.form.get('last_name');
  }
  get userRoles() {
    return this.form.get('userRoles');
  }

  //#endregion

  //#endregion

  //#region Ng Events

  ngOnInit(): void {
    this.logger.debug('ngOnInit...', this.roles, this.selectedRoleIds, this.user);
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnInit...');
    this._subs.forEach(s => s.unsubscribe());
  }

  //#endregion

  onSubmit(): void {
    this.logger.debug(`onSubmit...`, this.form.value, this.mode);
    this.submitForm.emit(this.form);
  }

  closeWindow(): void {
    this.logger.debug(`closeWindow...`);
    this.closeForm.emit();
  }
}
