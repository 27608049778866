import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { LoggerService } from '../../shared/logger/logger.service';
import { CommonModule } from '@angular/common';
import { ApiService } from '../../api/api.service';
import { AuthTokenService } from '../../auth/auth-token.service';
import { AuthService } from '../../auth/auth.service';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faCoffee, faPoo, faLock, faEnvelope, faSpinner, } from '@fortawesome/free-solid-svg-icons';
import { EMAIL_REGEX, MessageStates, PHONE_REGEX } from '../../common/constants.lib';
import { invalidMobileValidator, invalidPhoneValidator } from '../../directives/casi.validators';
import { UserEnquiry } from '../../common/obj.lib';
import { EnquiresService } from '../enquires.service';
import { BehaviorSubject } from 'rxjs';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'app-contact-us',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    FontAwesomeModule,
    RouterLink, RouterLinkActive
  ],
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss'
})
export class ContactUsComponent implements OnInit {

  contactForm: FormGroup;
  MessageStates = MessageStates;

  private _messageState = new BehaviorSubject<MessageStates>(MessageStates.Before);
  get messageState() {
    return this._messageState.getValue();
  }
  set messageState(val: MessageStates) {
    this._messageState.next(val);
  }

  constructor(private logger: LoggerService,
    public authTokenService: AuthTokenService,
    private fb: FormBuilder,
    public faIcons: FaIconLibrary,
    private dataService: EnquiresService
  ) {
    faIcons.addIcons(faEnvelope);
    this.contactForm = this.fb.group({
      id: [null, []],
      firstName: [null, []],
      lastName: [null, []],
      phone: [null, [invalidPhoneValidator]],
      email: [null, [Validators.required, Validators.email]],
      message: [null, [Validators.required]]
    });
  }

  get f() {
    return this.contactForm.controls;
  }
  get email() {
    return this.contactForm.get('email');
  }
  get firstName() {
    return this.contactForm.get('firstName');
  }
  get lastName() {
    return this.contactForm.get('lastName');
  }
  get phone() {
    return this.contactForm.get('phone');
  }
  get message() {
    return this.contactForm.get('message');
  }

  ngOnInit(): void {
    this.setForm();
  }
  emailRegx: string = EMAIL_REGEX;
  phonePattern: string = PHONE_REGEX

  setForm() {
    this.messageState = MessageStates.Before; // 'before';

    if (this.authTokenService.loggedIn) {
      let userInfo = this.authTokenService.getClaims();
      this.logger.debug('userInfo', userInfo);
      this.contactForm.patchValue({ ...userInfo });
    } else {
      this.contactForm.patchValue({});
    }
  }

  submitEnquiry(evnt: any) {
    this.logger.debug('submitEnquiry:...', evnt);
    // let form = evnt.target.form;
    // if (form.checkValidity()) {
    //   evnt.preventDefault();
    //   evnt.stopPropagation();
    //   submitEnquiry();
    //   return;
    // }
    // form.classList.add('was-validated');
  };

  _submitEnquiry() {
    this.logger.debug('_submitEnquiry...');
    this.messageState = MessageStates.InProgress; // "inprogress";

    // enquiriesdataservice.createEnquiry(vm.enquiries)
    //   .then((data) => {
    //     enquiriesdataservice.sendEmails(vm.enquiries)
    //       .then((data) => {
    //         // notifications.notify('New Enquiry Created');
    //         vm.messageState = 'after';
    //       })
    //       .catch((err) => {
    //         common.logger.error("Error: sending emails: ", err);
    //         vm.messageState = "error";
    //       });
    //   })
    //   .catch((err) => {
    //     common.logger.error("Error: saving enquiry: ", err);
    //     vm.messageState = "error";
    //   });
  }

  cancelEnquiry() {
    this.logger.debug('cancelEnquiry...');
    // $state.go('base');
  }

  resetForm() {
    this.logger.debug('resetForm...');
    this.contactForm.reset();
    this.setForm();
  }

  onSubmit() {
    this.messageState = MessageStates.InProgress;
    this.logger.debug('onSubmit...', this.contactForm.value);
    let toSubmit = UserEnquiry.create(this.contactForm.value);
    this.logger.debug('onSubmit...', toSubmit.forSubmit());
    this.dataService.createEnquiry(toSubmit.forSubmit())
      .then(data => {
        this.messageState = MessageStates.After;
      })
      .catch(err => {
        this.logger.error('onSubmit:err: ', err.message);
        this.messageState = MessageStates.Error; // 'error'
        this.logger.debug('onSubmit:messageState:', this.messageState);
      })

  }
}
