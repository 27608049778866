<link rel="stylesheet" href="https://unpkg.com/@progress/kendo-font-icons/dist/index.css" />
<!--
<ng-template #windowTitleBar let-win>
  <span class="k-window-title">OPPORTUNITY DETAILS</span>
  <button kendoWindowCloseAction [window]="win"></button>
</ng-template>

<div class="k-overlay" *ngIf="printOpportunityOpen"></div> -->

<div class="container-fluid gx-5">
  <div class="card" #appendTo>
    <div class="card-body no-border">
      <div class="card-title">
      </div>

      <!--      {{this.test}}-->

      <form class="k-form-inline" style="padding: 0; margin-bottom: 40px">
        <fieldset>

          <hr style="width: 50px; border-top: 5px solid;">
          <!-- small screen version -->
          <div class="row row-nomargin d-block d-md-none">
            <div class="col-12">
              <h2>Explore Opportunities</h2>
            </div>
          </div>
          <div class="row row-nomargin d-block d-md-none" style="margin-bottom: 10px;">
            <div class="col-12 d-flex">
              <input type="text" id="search" class="form-control" [(ngModel)]="params.search" name="search"
                placeholder="Refine your search..." style="width: 80%">
              <button class="btn-transparent" (click)="loadData()" #searchLogger="casiclicklogger"
                casiClickLogger="false">
                <span style="cursor: pointer"><fa-icon icon="magnifying-glass" style="margin-left: 5px; font-size: 20px"></fa-icon></span>
              </button>

            </div>
            <label for="search" class="form-label" style="text-align: left; padding-left: 10px;">Search for phrase or term</label>
          </div>

          <div style="display: flex">

            <div class="d-none d-md-block col-md-6">
              <h2>Explore Opportunities</h2>
            </div>
            <div class="d-none d-md-block col-md-6">
              <div class="col-12 d-flex" style="margin-top: -40px;">
                <input type="text" id="search" class="form-control" [(ngModel)]="params.search" name="search" placeholder="Refine your search..." style="width: 80%">
                <button class="btn-transparent" (click)="loadData()" #searchLogger="casiclicklogger" casiClickLogger="false">
                  <span style="cursor: pointer"><fa-icon icon="magnifying-glass" style="margin-left: 5px; font-size: 20px"></fa-icon></span>
                </button>
              </div>
              <label for="search" class="form-label" style="text-align: left; padding-left: 10px;">Search for phrase or term</label>
            </div>
          </div>

          <div class="accordion" id="main-accordion">
            <div class="accordion-item">
              <h4 class="accordion-header ps-4 pt-2" id="headingOne" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  I want to filter the opportunities by: <casi-help-popover style="display: inline-block;" [infoId]="25"></casi-help-popover>
              </h4>
              <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#main-accordion">
                <div class="accordion-content">
                  <opportunity-options-accordion [items]="options" [output]="params" [filterBy]="filterBy" [ids]="ids" (onChange)="onChange($event)"></opportunity-options-accordion>
                </div>
              </div>
            </div>
          </div>



        </fieldset>
      </form>

      <h4>Browse {{gridData.length}} listed Opportunites based on selection:</h4>

      <button kendoButton class="btn btn-primary" (click)="excelexport.save()" *ngIf="gridData.length > 0"><fa-icon icon="download"></fa-icon> Export Data</button>

      <kendo-excelexport [data]="allData" fileName="Discover Opportunities.xlsx" #excelexport>
        <kendo-excelexport-column field="title" title="Title" [width]="200">
        </kendo-excelexport-column>
        <kendo-excelexport-column field="organisation_name" title="Organisation" [width]="200">
        </kendo-excelexport-column>
        <kendo-excelexport-column field="short_description" title="Summary" [width]="200">
        </kendo-excelexport-column>
        <kendo-excelexport-column field="eligibilityText" title="Eligibility" [width]="200">
        </kendo-excelexport-column>
        <kendo-excelexport-column field="industryList" title="Industries" [width]="200">
        </kendo-excelexport-column>
        <kendo-excelexport-column field="classification_description" title="Market Opportunites" [width]="200">
        </kendo-excelexport-column>
        <kendo-excelexport-column field="goalList" title="Project Goals" [width]="200">
        </kendo-excelexport-column>
        <kendo-excelexport-column field="coBenefitList" title="Project Outcomes" [width]="200">
        </kendo-excelexport-column>
        <kendo-excelexport-column field="stateList" title="Location" [width]="200">
        </kendo-excelexport-column>
        <kendo-excelexport-column field="resourceList" title="Resources" [width]="200">
        </kendo-excelexport-column>
      </kendo-excelexport>

      <div class="add-footer-margin" style="margin-top: 10px;">


      <div *ngFor="let dataItem of gridData" style="margin-bottom: 30px; border: 1px solid lightgrey; padding: 20px; font-size: 14px;">
        <div class="k-form-field row">

        <label class="form-label col-12 col-md-3">Title<casi-help-popover [infoId]="15" style="display: inline-block; margin-left: 10px;"></casi-help-popover></label>

          <div class="col-12 col-md-8" style="padding-top: 10px;">
            <a [href]="dataItem.organisation_url" style="cursor: pointer; text-decoration: underline; font-weight: bold; font-size: 15px;" target=”_blank” *ngIf="dataItem.organisation_url">{{dataItem.title}}</a>
            <div *ngIf="!dataItem.organisation_url" style="font-weight: bold; font-size: 15px; margin-bottom: 10px;">{{dataItem.title}}</div>
            <div style="margin-top: 10px;">
            <button (click)="onViewClick(dataItem)" kendoButton class="btn btn-stn-grid" casiClickLogger="button[discoverOpportunities:ViewOpportunity:{{dataItem.id}}]">
              <fa-icon [icon]="['fat', 'memo-circle-info']"></fa-icon><span style="margin-left: 10px;">View Summary</span></button>
            </div>
          </div>
        </div>

        <div class="k-form-field row">
          <label class="form-label col-12 col-md-3">Organisation</label>
          <div class="col-12 col-md-8">
            <div *ngIf="dataItem.organisation_name" style="font-weight: bold; font-size: 15px;">{{dataItem.organisation_name}}</div>
          </div>
        </div>

        <div class="k-form-field row">
          <label class="form-label col-12 col-md-3">Summary<casi-help-popover [infoId]="16" style="display: inline-block; margin-left: 10px;"></casi-help-popover></label>
          <div class="col-12 col-md-8" >
            <div [innerHTML]="dataItem.shortDescription"></div>
          </div>
        </div>
        <div class="k-form-field row">
          <label class="form-label col-12 col-md-3">Eligibility<casi-help-popover [infoId]="24" style="display: inline-block; margin-left: 10px;"></casi-help-popover></label>

          <div class="col-12 col-md-8" >
            <div [innerHTML]="dataItem.elegibility | safeHtml" *ngIf="dataItem.elegibility_text"></div>
          </div>
        </div>
        <div class="k-form-field row">
          <label class="form-label col-12 col-md-3">Industries<casi-help-popover [infoId]="17" style="display: inline-block; margin-left: 10px;"></casi-help-popover></label>
          <div class="col-12 col-md-8" >
            <div style="display: flex" class="row">
              <div *ngFor="let industry of dataItem.industries" style="text-align: center; margin-right: 15px; max-width: 130px;">
                <kendo-avatar [size]="iconSize" [rounded]="iconRoundness"
                  [imageSrc]="buildImageLink(industry.icon_name)" *ngIf="industry.icon_name">˝</kendo-avatar>
                <div>{{industry.description}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="k-form-field row">
          <label class="form-label col-12 col-md-3">Market Opportunites<casi-help-popover [infoId]="18" [iconStyle]="'color: #7ba89e;'" style="display: inline-block; margin-left: 10px;"></casi-help-popover></label>
          <div class="col-12 col-md-8" >
            <div style="display: flex" class="row">
            <div style="text-align: center; margin-right: 15px; max-width: 130px;">
            <kendo-avatar [size]="iconSize" [rounded]="iconRoundness" *ngIf="dataItem.classification_icon_name"
              [imageSrc]="buildImageLink(dataItem.classification_icon_name)">˝</kendo-avatar>
            <div>{{dataItem.classification_description}}</div>
            </div>
            </div>
          </div>
        </div>
        <div class="k-form-field row">
          <label class="form-label col-12 col-md-3">Project Goals<casi-help-popover [infoId]="19" [iconStyle]="'color: #5d654d;'" style="display: inline-block; margin-left: 10px;"></casi-help-popover></label>
          <div class="col-12 col-md-8" >
            <div style="display: flex" class="row">
              <div *ngFor="let item of dataItem.opportunity_goals" style="text-align: center; margin-right: 15px; max-width: 130px;">
                <kendo-avatar [size]="iconSize" [rounded]="iconRoundness" [imageSrc]="buildImageLink(item.icon_name)"
                  *ngIf="item.icon_name">˝</kendo-avatar>
                <div>{{item.goal}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="k-form-field row">
          <label class="form-label col-12 col-md-3">Project Outcomes<casi-help-popover [infoId]="20" [iconStyle]="'color: #dd7f45;'" style="display: inline-block; margin-left: 10px;"></casi-help-popover></label>
          <div class="col-12 col-md-8" >
            <div style="display: flex" class="row">
              <div *ngFor="let item of dataItem.co_benefits" style="text-align: center; margin-right: 15px; max-width: 130px;">
                <kendo-avatar [size]="iconSize" [rounded]="iconRoundness" [imageSrc]="buildImageLink(item.icon_name)"
                  *ngIf="item.icon_name">˝</kendo-avatar>
                <div>{{item.co_benefit}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="k-form-field row">
          <label class="form-label col-12 col-md-3">Location</label>

          <div class="col-12 col-md-8" >
              <div>{{dataItem.stateList}}</div>
          </div>
        </div>
        <div class="k-form-field row">
          <label class="form-label col-12 col-md-3">Resources<casi-help-popover [infoId]="21" style="display: inline-block; margin-left: 10px;"></casi-help-popover></label>
          <div class="col-12 col-md-8" >

              <div *ngFor="let item of dataItem.opportunity_resources" style="margin-bottom: 20px">
                <a [href]="item.url" target="_blank"
                  style="cursor: pointer; text-decoration: underline;">{{item.description}}</a>
              </div>

          </div>
        </div>
      </div>

        <!-- <kendo-grid [kendoGridBinding]="gridData" [loading]="dataService.loading" [groupable]="false" [resizable]="true"
          [filter]="filter" filterable="menu" (filterChange)="filterChange($event)" [pageSize]="pageSize"
          [pageable]="true" [style.maxHeight.px]="2000" [width]="500">

          <ng-template kendoGridToolbarTemplate position="top">
            <button kendoGridExcelCommand class="btn btn-primary">
              <fa-icon icon="download"></fa-icon> Export Data</button>
            <button kendoGridExcelCommand class="btn btn-primary">
              <fa-icon icon="download"></fa-icon> Export Data</button>
          </ng-template>

          <kendo-grid-column title="View Opportunity" [width]="150"
            [headerStyle]="{ 'vertical-align': 'top', 'font-weight': 'bold'}" [sticky]="stickyCols">
            <ng-template kendoGridHeaderTemplate><span style="margin-right: 10px">View Information</span>
              <casi-help-popover [infoId]="14"></casi-help-popover>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <div>
                <button (click)="onViewClick(dataItem)" kendoButton class="btn btn-stn-grid"
                  casiClickLogger="button[discoverOpportunities:ViewOpportunity:{{dataItem.id}}]">
                  <fa-icon [icon]="['fat', 'memo-circle-info']"></fa-icon><span
                    style="margin-left: 10px;">View</span></button>
              </div>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="title" title="Organisation & Title" [width]="220"
            [headerStyle]="{ 'vertical-align': 'top', 'font-weight': 'bold'}" [sticky]="stickyCols">
            <ng-template kendoGridHeaderTemplate><span style="margin-right: 10px">Title & Organisation</span>
              <casi-help-popover [infoId]="15" (click)="disableStickyCols()" [winStyle]="'position: fixed;'"
                (onClose)="enableStickyCols($event)"></casi-help-popover>
            </ng-template>

            <ng-template kendoGridCellTemplate let-dataItem>
              <div style="margin-bottom: 15px">
                <a [href]="dataItem.organisation_url" style="cursor: pointer; text-decoration: underline"
                  target=”_blank” *ngIf="dataItem.organisation_url">{{dataItem.title}}</a>
                <span *ngIf="!dataItem.organisation_url">{{dataItem.title}}</span>
              </div>
              <div *ngIf="dataItem.organisation_name">{{dataItem.organisation_name}}</div>


            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="short_description" title="Summary" [width]="270"
            [headerStyle]="{ 'vertical-align': 'top', 'font-weight': 'bold'}" [sticky]="stickyCols">
            <ng-template kendoGridHeaderTemplate><span style="margin-right: 10px">Summary</span>
              <casi-help-popover [infoId]="16"></casi-help-popover>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <div [innerHTML]="dataItem.short_description"></div>
            </ng-template>

          </kendo-grid-column>

          <kendo-grid-column field="eligibilityText" title="Eligibility" [width]="300"
            [headerStyle]="{ 'vertical-align': 'top', 'font-weight': 'bold'}">
            <ng-template kendoGridHeaderTemplate><span style="margin-right: 10px">Eligibility</span>
              <casi-help-popover [infoId]="24" [winStyle]="'position: fixed;'"></casi-help-popover></ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <div [innerHTML]="dataItem.elegibility_text | safeHtml" *ngIf="dataItem.elegibility_text"></div>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="industryList" title="Industries" [width]="150"
            [headerStyle]="{ 'vertical-align': 'top', 'font-weight': 'bold'}" [filterable]="false">
            <ng-template kendoGridHeaderTemplate><span style="margin-right: 10px">Industries</span>
              <casi-help-popover [infoId]="17" [iconStyle]="'color: #d9b35b;'"></casi-help-popover>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <div [ngStyle]="{'text-align':'center'}">
                <div *ngFor="let industry of dataItem.industries">
                  <kendo-avatar [size]="iconSize" [rounded]="iconRoundness"
                    [imageSrc]="buildImageLink(industry.icon_name)" *ngIf="industry.icon_name">˝</kendo-avatar>
                  <div>{{industry.description}}</div>
                </div>
              </div>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="classification_description" title="Market Opportunities" [width]="150"
            [headerStyle]="{ 'vertical-align': 'top', 'font-weight': 'bold'}" [filterable]="false">
            <ng-template kendoGridHeaderTemplate><span style="margin-right: 10px">Market opportunities</span>
              <casi-help-popover [infoId]="18" [iconStyle]="'color: #7ba89e;'"
                [winStyle]="'position: fixed;'"></casi-help-popover>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <div [ngStyle]="{'text-align':'center'}">
                <kendo-avatar [size]="iconSize" [rounded]="iconRoundness" *ngIf="dataItem.classification_icon_name"
                  [imageSrc]="buildImageLink(dataItem.classification_icon_name)">˝</kendo-avatar>
                <div>{{dataItem.classification_description}}</div>
              </div>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="goalList" title="Project Goals" [width]="150"
            [headerStyle]="{ 'vertical-align': 'top', 'font-weight': 'bold'}" [filterable]="false">
            <ng-template kendoGridHeaderTemplate><span style="margin-right: 10px">Project Goals</span>
              <casi-help-popover [infoId]="19" [iconStyle]="'color: #5d654d;'">
              </casi-help-popover></ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <div [ngStyle]="{'text-align':'center'}">
                <div *ngFor="let item of dataItem.opportunity_goals">
                  <kendo-avatar [size]="iconSize" [rounded]="iconRoundness" [imageSrc]="buildImageLink(item.icon_name)"
                    *ngIf="item.icon_name">˝</kendo-avatar>
                  <div>{{item.goal}}</div>
                </div>
              </div>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="coBenefitList" title="Project Outcomes" [width]="150"
            [headerStyle]="{ 'vertical-align': 'top', 'font-weight': 'bold'}" [filterable]="false">
            <ng-template kendoGridHeaderTemplate><span style="margin-right: 10px">Project Outcomes</span>
              <casi-help-popover [infoId]="20" [iconStyle]="'color: #dd7f45;'"></casi-help-popover>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <div [ngStyle]="{'text-align':'center'}">
                <div *ngFor="let item of dataItem.co_benefits">
                  <kendo-avatar [size]="iconSize" [rounded]="iconRoundness" [imageSrc]="buildImageLink(item.icon_name)"
                    *ngIf="item.icon_name">˝</kendo-avatar>
                  <div>{{item.co_benefit}}</div>
                </div>
              </div>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="stateList" title="Location" [width]="150"
            [headerStyle]="{ 'vertical-align': 'top', 'font-weight': 'bold'}">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div>
                <div>{{dataItem.stateList}}</div>
              </div>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="resourceList" title="Resources" [width]="200"
            [headerStyle]="{ 'vertical-align': 'top', 'font-weight': 'bold'}">
            <ng-template kendoGridHeaderTemplate><span style="margin-right: 10px">Resources</span>
              <casi-help-popover [infoId]="21"></casi-help-popover>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <div>
                <div *ngFor="let item of dataItem.opportunity_resources" style="margin-bottom: 20px">
                  <a [href]="item.url" target="_blank"
                    style="cursor: pointer; text-decoration: underline;">{{item.description}}</a>
                </div>
              </div>
            </ng-template>
          </kendo-grid-column>

          <ng-template kendoPagerTemplate>
            <kendo-pager-info *ngIf="pageSize > 0"></kendo-pager-info>
          </ng-template>

          <kendo-grid-excel fileName="Discover Opportunities.xlsx" [fetchData]="allData"></kendo-grid-excel>
        </kendo-grid> -->

      </div>

    </div>
  </div>
</div>


<kendo-window *ngIf="viewOpportunityOpen" (close)="closeViewOpportunity()" [width]="1350"
  [style]="{'max-width': '100vw'}" autoFocusedElement="#title">
  <kendo-window-titlebar>
    <span class="k-window-title">OPPORTUNITY DETAILS</span>
    <button kendoWindowCloseAction></button>
  </kendo-window-titlebar>
  <casi-view-opportunity [opportunity]="currentItem" (onClose)="closeViewOpportunity()" (onPrint)="printPDF($event)"
    (onPrintClick)="closeViewer()"></casi-view-opportunity>
</kendo-window>


<kendo-window *ngIf="printOpportunityOpen" (close)="closePrintOpportunity()" [width]="1350" autoFocusedElement="#title">
  <kendo-window-titlebar>
    <span class="k-window-title">OPPORTUNITY DETAILS</span>
    <button kendoWindowCloseAction></button>
  </kendo-window-titlebar>

  <kendo-pdfviewer style="height: 600px;" [saveFileName]="downloadFilename" [url]="url"> </kendo-pdfviewer>
  <div kendoDialogContainer></div>
</kendo-window>
