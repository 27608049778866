import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoggerService } from '../../../shared/logger/logger.service';
import { HelpInfo } from '../../../models/models.lib';
import { FormModes } from '../../../common/constants.lib';
import { EditorModule } from '@progress/kendo-angular-editor';

@Component({
  selector: 'casi-edit-help',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    FontAwesomeModule,
    RouterLink, RouterLinkActive,
    EditorModule
  ],
  templateUrl: './edit-help.component.html',
  styleUrl: './edit-help.component.scss'
})
export class EditHelpComponent implements OnInit, OnDestroy {

  @Input({ required: true }) helpInfo: HelpInfo = new HelpInfo();
  @Input({ required: true }) mode: FormModes = FormModes.NOTSET;

  @Output() onCancel = new EventEmitter<void>();
  @Output() onSave = new EventEmitter<any>();

  public value = '';

  form: FormGroup;

  private _subs: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private logger: LoggerService,
  ) {
    this.form = this.fb.group(
      {
        id: [null, []],
        site_section: [null, [Validators.required]],
        element_name: [null, [Validators.required]],
        heading: [null, [Validators.required]],
        html: [null, [Validators.required]]
      }
    );
  }

  get f() {
    return this.form.controls;
  }
  get site_section() {
    return this.form.get('site_section');
  }
  get element_name() {
    return this.form.get('element_name');
  }
  get heading() {
    return this.form.get('heading');
  }
  get html() {
    return this.form.get('html');
  }

  ngOnInit(): void {
    this.logger.debug('ngOnInit...');
    this.form.patchValue(this.helpInfo);
    this.value = (this.helpInfo.html as string);
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnInit...');
    this._subs.forEach(s => s.unsubscribe());
  }

  onSubmit(): void {
    this.logger.debug(`onSubmit...`, this.form.value, this.mode);
    const { name } = this.form.value;
    this.onSave.emit({
      action: "save",
      data: this.form.value,
      mode: this.mode
    });
  }

  closeWindow(): void {
    this.logger.debug(`closeWindow...`);
    this.onCancel.emit();
  }

  public valueChange(value: string): void {
    this.logger.debug(`valueChange...`, value);
    this.form.patchValue({ html: value });
  }
}
