import { Component } from '@angular/core';
import { LoggerService } from '../../../shared/logger/logger.service';
import { Router } from '@angular/router';
import { HelpButtonComponent } from '../../../admin/help-info/help-button/help-button.component';
import { DiscoverOpportunitiesService } from "../../../discoverOpportunities/discover-opportunities.service";
import { ClickLoggerDirective } from '../../../directives/click-logger.directive';
import { HelpPopoverComponent } from '../../../admin/help-info/help-popover/help-popover.component';
import {AuthTokenService} from "../../../auth/auth-token.service";

@Component({
  selector: 'casi-discover-opportunity',
  standalone: true,
  imports: [
    HelpButtonComponent,
    ClickLoggerDirective,
    HelpPopoverComponent
  ],
  templateUrl: './discover-opportunity.component.html',
  styleUrl: './discover-opportunity.component.scss'
})
export class DiscoverOpportunityComponent {

  private isLoggedIn: boolean = this.authTokenService.loggedIn;

  constructor(
    public authTokenService: AuthTokenService,
    private logger: LoggerService,
    private router: Router,
    private discoverService: DiscoverOpportunitiesService
  ) { }

  public discoverOpportunity(item: string, id: any): void {
    this.logger.debug('discoverOpportunity: ', item);

    if(!this.isLoggedIn)
      return;

    this.discoverService.setIds(id ? [id] : []);
    this.discoverService.setFilterBy(item || "");
    this.router.navigate(['/discover-opportunities']);
  }

}
