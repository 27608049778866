<!--Load the font icons package:-->
<link rel="stylesheet" href="https://unpkg.com/@progress/kendo-font-icons/dist/index.css" />

<kendo-dialog-titlebar>
  <div style="font-size: 18px; line-height: 1.3em;">
    <span class="k-icon k-font-icon k-icon-xl k-i-form-element"></span> {{title}}
  </div>
</kendo-dialog-titlebar>

<form [formGroup]="form">
  <fieldset>

    <div class="row mb-3">
      <label for="co_benefit" class="col-3 col-form-label">Co-benefit</label>
      <div class="col-6">
        <input type="text" id="co_benefit" formControlName="co_benefit" class="form-control">
        <div *ngIf="co_benefit?.invalid" class="alert alert-danger">
          <div *ngIf="co_benefit?.errors?.['required']">
            Co-benefit is required.
          </div>
        </div>
      </div>

    </div>

    <div class="row mb-3">
      <label for="co_benefit_group" class="col-3 col-form-label">Co-benefit Group</label>
      <div class="col-6">
        <input type="text" id="co_benefit_group" formControlName="co_benefit_group" class="form-control">
        <div *ngIf="co_benefit_group?.invalid" class="alert alert-danger">
          <div *ngIf="co_benefit_group?.errors?.['required']">
            Co-benefit Group is required.
          </div>
        </div>
      </div>

    </div>

    <div class="row mb-3">
      <label for="sub_co_benefit" class="col-3 col-form-label">Sub Co-benefit</label>
      <div class="col-6">
        <input type="text" id="sub_co_benefit" formControlName="sub_co_benefit" class="form-control">
        <div *ngIf="sub_co_benefit?.invalid" class="alert alert-danger">
          <div *ngIf="sub_co_benefit?.errors?.['required']">
            Sub Co-benefit is required.
          </div>
        </div>
      </div>

    </div>

    <div class="row mb-2">
      <kendo-label for="active" text="Active" class="col-3 col-form-label"></kendo-label>
      <div class="col-8" style="padding-top: 10px;">
        <input type="checkbox" size="large" kendoCheckBox class="form-check-input" formControlName="active"
          id="active" />

      </div>
    </div>

    <!-- <div class="d-grid d-md-flex justify-content-md-end">
      <button type="button" class="btn btn-primary me-md-2" type="submit" [disabled]="form.invalid"> Save</button>
      <button type="button" class="btn btn-secondary" (click)="closeWindow()"> Cancel</button>
    </div> -->

  </fieldset>
</form>

<kendo-dialog-actions>
  <button kendoButton (click)="onSaveAction()" class="btn btn-primary" [disabled]="form.invalid">
    Save
  </button>
  <button kendoButton (click)="onCancelAction()" class="btn btn-secondary">Cancel</button>
</kendo-dialog-actions>
