<div class="k-overlay" *ngIf="isOpen"></div>

@if(!showText) {
<!-- <a href="" style="text-decoration: none;" tabindex="-1" (click)="displayInfo($event)">

  <i font-size="26px"><fa-icon icon="info-circle"></fa-icon></i>
</a> -->
<button mat-icon-button aria-label="Information icon" (click)="displayInfo($event)" style="height: unset; width: unset;">
  <mat-icon [style]="iconStyle">info_outlined_i</mat-icon>
</button>
}
@else {
<button class="btn btn-default" (click)="displayInfo($event)" style="text-decoration: underline">{{text}}</button>
}

<!-- <kendo-window *ngIf="isOpen" (close)="close()" [width]="900" [(top)]="windowTop" [(left)]="windowLeft"> -->
<kendo-window *ngIf="isOpen" (close)="close()" [width]="900" [style]="winStyle">
  <kendo-window-titlebar>
    <span class="k-window-title">{{title}}</span>
    <button kendoWindowCloseAction></button>
  </kendo-window-titlebar>
  <p class="e2e-inner-html-bound" [innerHTML]="helpContent"></p>
  <!-- <h3>Binding innerHTML</h3>
  <p>Bound value:</p>
  <p class="e2e-inner-html-interpolated">{{ helpContent }}</p>
  <p>Result of binding to innerHTML:</p> -->

  <div class="d-grid d-md-flex justify-content-md-end">
    <button mat-button type="button" color="primary" (click)="close()"> Close</button>
  </div>

</kendo-window>
