import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DialogRef, DialogContentBase, DialogModule } from "@progress/kendo-angular-dialog";
import { EditorComponent, EditorModule } from '@progress/kendo-angular-editor';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { LabelModule } from '@progress/kendo-angular-label';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { CasiCloseOptions, FormModes } from '../../../common/constants.lib';
import { LoggerService } from '../../../shared/logger/logger.service';
import { Role } from '../../../models/models.lib';

@Component({
  selector: 'casi-edit-role-modal',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    FontAwesomeModule,
    RouterLink, RouterLinkActive,
    DialogModule,
    EditorModule,
    InputsModule,
    ButtonsModule,
    NotificationModule,
    LabelModule,
    DropDownsModule,
    DateInputsModule,
    LayoutModule
  ],
  templateUrl: './edit-role-modal.component.html',
  styleUrl: './edit-role-modal.component.scss'
})
export class EditRoleModalComponent extends DialogContentBase implements OnInit {

  private _role: Role | undefined;

  @Input() title: string = 'Title Not Set';

  @Input({ required: true }) set role(val: Role) {
    this._role = val;
    this.form.patchValue(this._role);
  }
  get role(): Role | undefined {
    return this._role;
  };

  private _mode: FormModes = FormModes.NOTSET;
  @Input({ required: true }) set mode(val: FormModes) {
    this._mode = val;
  }
  get mode(): FormModes {
    return this._mode;
  };

  form: FormGroup;

  constructor(
    public dialog: DialogRef,
    private fb: FormBuilder,
    private logger: LoggerService,
  ) {
    super(dialog);
    this.form = this.fb.group(
      {
        id: [null, []],
        name: [null, [Validators.required]],
        permission_cnt: [null, []]
      }
    );
  }

  //#region Form

  get f() {
    return this.form.controls;
  }
  get name() {
    return this.form.get('name');
  }

  //#endregion

  ngOnInit(): void {
    this.logger.debug('ngOnInit...');
  }

  public onCancelAction(): void {
    this.logger.debug(`onCancelAction...`);
    this.dialog.close({ text: CasiCloseOptions.Cancelled });
  }

  public onSaveAction(): void {
    this.logger.debug(`onSaveAction...`, this.form.value);

    this.dialog.close({ text: CasiCloseOptions.Saved });
  }
}

