import { ApplicationConfig, CSP_NONCE } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';

import { routes } from './app.routes';
import { HTTP_INTERCEPTORS, HttpXsrfTokenExtractor, provideHttpClient, withInterceptors, withXsrfConfiguration } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service'
import { provideAnimations } from '@angular/platform-browser/animations';
import { bearerInterceptor } from './interceptors/bearer.interceptor';
import { cookieInterceptor } from './interceptors/cookie.interceptor';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { casiXsrfInterceptor } from './interceptors/casi-xsrf.interceptor';
import { provideToastr } from 'ngx-toastr';
import { ToastrModule, OverlayContainer } from 'ngx-toastr';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding()),
    provideHttpClient(
      withXsrfConfiguration({
        cookieName: 'X-CSRF-TOKEN',
        headerName: 'X-CSRF-TOKEN'
      }),
      // { provide: HTTP_INTERCEPTORS, useClass: casiXsrfInterceptor, multi: true },
      // { provide: HttpXsrfTokenExtractor, useClass: HttpXsrfTokenExtractor },
      withInterceptors([casiXsrfInterceptor, bearerInterceptor, cookieInterceptor])
    ),
    provideAnimations(),
    provideToastr({
      timeOut: 2000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true
    }),
    CookieService, provideAnimationsAsync()],

};
