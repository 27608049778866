import { Component, NO_ERRORS_SCHEMA, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { LoggerService } from '../../shared/logger/logger.service';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { UserDataService } from '../../users/user-data.service';
import { MessageStates } from '../../common/constants.lib';
import { BehaviorSubject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { User } from '../me.lib';
import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { confirmedPasswordValidator } from '../../directives/casi.validators';
import { AuthTokenService } from '../auth-token.service';
import { DialogsModule, WindowModule } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'casi-reset-password-view',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    FontAwesomeModule,
    RouterLink, RouterLinkActive,
    DialogsModule,
    WindowModule,
    LabelModule, InputsModule,
    ButtonsModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
  templateUrl: './reset-password-view.component.html',
  styleUrl: './reset-password-view.component.scss'
})
export class ResetPasswordViewComponent implements OnInit {

  form: FormGroup;
  MessageStates = MessageStates;
  public user: any = {};

  email: string | null = null;
  resetToken: string | null = null;
  isNewUser: boolean | null = false;

  hasUser: boolean = false;
  fetchError: boolean = false;

  public updateSuccess: boolean = false;

  private _messageState = new BehaviorSubject<MessageStates>(MessageStates.Before);
  get messageState() {
    return this._messageState.getValue();
  }
  set messageState(val: MessageStates) {
    this._messageState.next(val);
  }

  constructor(
    private logger: LoggerService,
    private fb: FormBuilder,
    public faIcons: FaIconLibrary,
    private router: Router,
    private dataService: UserDataService,
    private activatedRoute: ActivatedRoute,
    private authTokenService: AuthTokenService
  ) {
    this.form = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmedPassword: ['', [Validators.required]]
    },
      {
        validators: confirmedPasswordValidator
      }
    );
  }

  get f() {
    return this.form.controls;
  }

  get password() {
    return this.form.get('password');
  }

  get confirmedPassword() {
    return this.form.get('confirmedPassword');
  }

  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe(params => {
      this.logger.debug('ngOnInit:query params: ', params);
      this.email = params.get('email');
      this.resetToken = params.get('resettoken');
      if (this.email && this.resetToken) {
        this.checkPassword(this.email, this.resetToken);
      }
    })
    this.setForm();
  }

  setForm() {
    this.messageState = MessageStates.Before; // 'before';
    this.form.patchValue({ email: null });
  }

  onSubmit(): void {
    this.logger.debug('onSubmit...', this.form.value);
    const { password } = this.form.value;
    this.dataService
      .resetPassword(this.user.email, password)
      .then(resData => {
        this.logger.debug('onSubmit:resData: ', resData);
        this.authTokenService.setToken(resData.token);
        this.updateSuccess = true;
      })
      .catch(err => {
        this.logger.error('onSubmit: error: ', err);
      });
  }

  checkPassword(email: string, resetToken: string) {
    this.logger.debug('checkPassword...', { email, resetToken });
    return this.dataService.checkPassword(email, resetToken)
      .then(user => {
        this.logger.debug('checkPassword:user: ', user);
        this.user = user;
      })
      .catch(err => {
        this.logger.error('checkPassword:error: ', err);
        this.user = null;
        this.fetchError = true;
      })
      .finally(() => {
        this.hasUser = !this.user;
      });
  }

  fetchTerms() {
    this.logger.debug('checkPassword...', this.form.value);

  }

  cancelPassword() {
    this.logger.debug(`cancelPassword...`);
    this.router.navigate(['/home']);
  }

  reloadUser(): void {
    this.logger.debug("reloadUser...");
    this.updateSuccess = false;
    this.dataService.checkTerms()
      .then((agreed) => {
        if (agreed) {
          this.router.navigate(['/logged-in']);
        } else {
          this.router.navigate(['/terms-check']);
        }
      })
      .catch(err => {
        this.logger.error('reloadUser:error: ', err.message);
      })
  }

}
