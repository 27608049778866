<form class="k-form"  [formGroup]="form">
  <fieldset>
    <p style="margin-bottom: 35px">Confirm email address that will do review</p>
    <div class="row mb-3" >
      <label  class="col-4 form-label k-label" style="padding-top: 0">Send</label>
      <div class=" col-8">{{opportunity.title}}</div>
    </div>
    <div class="row">
      <label  class="col-4 form-label k-label">To be Reviewed By</label>
      <div class=" col-8">
        <input type="email" id="email" formControlName="email" class="form-control" style="max-width: 250px">
        <div *ngIf="email?.invalid" class="alert alert-danger">
          <div *ngIf="email?.errors?.['required']">Email address is required.</div>
          <div *ngIf="email?.errors && email?.errors?.['email']">Please, enter valid email address.</div>
        </div>
<!--        {{opportunity.organisation_contact_email}}</div>-->
    </div>
    </div>
    <div class="d-grid d-md-flex justify-content-md-end mt-4">
      <button type="button" class="btn btn-primary me-md-2" (click)="onSubmit()" [disabled]="form.invalid || disableSave">Save</button>
      <button type="button" class="btn btn-secondary" (click)="closeWindow()"> Cancel</button>
    </div>

  </fieldset>
</form>
