import { Component, EventEmitter, Input, NO_ERRORS_SCHEMA, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoggerService } from '../../shared/logger/logger.service';
import { ReferenceLink } from '../../models/models.lib';
import { AppEnities, FormModes } from '../../common/constants.lib';
import { CheckboxListComponent } from '../../common-components/checkbox-list/checkbox-list.component';
import { InputsModule } from "@progress/kendo-angular-inputs";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { NotificationModule, NotificationService } from '@progress/kendo-angular-notification';
import { LabelModule } from "@progress/kendo-angular-label";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { CompositeFilterDescriptor, SortDescriptor, filterBy, distinct, orderBy } from "@progress/kendo-data-query";
import { GridModule, PageChangeEvent, PagerModule, PagerPosition, PagerType } from '@progress/kendo-angular-grid';
import {
  faPlusCircle, faAddressCard,
  faEdit, faTrash, faBan
} from '@fortawesome/free-solid-svg-icons';
import { findNearestNumber, setTitle } from '../../common/utils.lib';
import { MulticheckFilterComponent } from '../../filters/multicheck-filter/multicheck-filter.component';
// import { EditCoBenefitComponent } from './edit-co-benefit/edit-co-benefit.component';
import { DialogsModule, WindowModule } from '@progress/kendo-angular-dialog';
import { ReferenceLinkComponent } from './reference-link/reference-link.component';
import { ReferenceLinksService } from '../reference-links.service';
import { MeService } from '../../auth/me.service';

const { OPPORTUNITY_REFERENCE_LINK } = AppEnities;

@Component({
  selector: 'casi-reference-links',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    FontAwesomeModule,
    RouterLink, RouterLinkActive,
    DialogsModule,
    WindowModule,
    NotificationModule,
    PagerModule,
    GridModule,
    MulticheckFilterComponent,
    CheckboxListComponent,
    InputsModule,
    ButtonsModule,
    LabelModule,
    DropDownsModule,
    DateInputsModule,
    LayoutModule,
    ReferenceLinkComponent
  ],
  schemas: [NO_ERRORS_SCHEMA],
  templateUrl: './reference-links.component.html',
  styleUrl: './reference-links.component.scss'
})
export class ReferenceLinksComponent implements OnInit, OnDestroy {

  @Input({ required: true }) opportunityId: number = 0;

  @Output() onClose = new EventEmitter<void>();
  @Output() onAddEdit = new EventEmitter<any>();

  public view: Observable<ReferenceLink[]> = new Observable<ReferenceLink[]>();
  public rawData: ReferenceLink[] = [];
  public filter: CompositeFilterDescriptor = { logic: "and", filters: [] };
  public gridData: ReferenceLink[] = filterBy(this.rawData, this.filter);

  public currentItem: ReferenceLink = new ReferenceLink();
  public formMode: FormModes = FormModes.NOTSET;

  public pagerTypes = ["numeric", "input"];
  public type: PagerType = "numeric";
  public info = true;
  public pageSizes = true;
  public previousNext = true;
  public position: PagerPosition = "bottom";

  public pageSize = 10;

  public editItemOpen: boolean = false;
  public errorMsgOpen: boolean = false;

  public confirmDeleteItem: boolean = false;

  public windowTop = 100;
  public windowLeft = 50;

  public canCreate: boolean = false;
  public canUpdate: boolean = false;
  public canDestroy: boolean = false;

  @ViewChild("appendTo", { read: ViewContainerRef }) appendTo: ViewContainerRef | undefined;

  private _showTitle = new BehaviorSubject<string>('');
  get showTitle() {
    return this._showTitle.getValue();
  }
  set showTitle(val: string) {
    this._showTitle.next(val);
  }

  private _subs: Subscription[] = [];

  constructor(
    private logger: LoggerService,
    public dataService: ReferenceLinksService,
    public faIcons: FaIconLibrary,
    private me: MeService,
    private notificationService: NotificationService
  ) {
    faIcons.addIcons(
      faPlusCircle, faAddressCard, faEdit,
      faTrash,
      faBan
    );
  }

  public sort: SortDescriptor[] = [
    {
      field: "sort_order",
      dir: "asc",
    },
  ];

  //#region Ng Handlers

  ngOnInit(): void {
    this.logger.debug('ngOnInit...',);
    const { can_create, can_update, can_destroy } = this.me.allICanDo(OPPORTUNITY_REFERENCE_LINK);

    this.logger.debug('ngOnInit:permissions: ', can_create, can_update, can_destroy);
    this.canCreate = can_create;
    this.canUpdate = can_update;
    this.canDestroy = can_destroy;

    this.dataService.query(this.opportunityId);
    this._subs.push(this.dataService.subscribe(e => {
      this.logger.debug('ngOnInit:v: ', e);
      this.rawData = e;
      this.loadData();
    }))
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnInit...');
    this._subs.forEach(s => s.unsubscribe());
  }

  //#endregion

  //#region Grid handlers

  onClearFilters(gridName: string) {
    this.logger.debug("onClearFilters: ", gridName);
    this.filter = { logic: "and", filters: [] };
    this.loadData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.logger.debug("sortChange: ", sort);
    this.sort = sort;
    this.loadData();
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    // this.logger.debug('filterChange: filter: ', filter);
    this.filter = filter;
    this.loadData();
  }

  public distinctPrimitive(fieldName: string): any[] {
    // this.logger.debug('distinctPrimitive: fieldName: ', fieldName);
    return distinct(this.gridData, fieldName).map((item: any) => item[fieldName]);
  }


  //#endregion

  //#region Window handlers

  onEditClick(dataItem: any): void {
    this.logger.debug("onEditClick: ", dataItem);
    this.currentItem = ReferenceLink.create(dataItem);
    this.formMode = FormModes.EDIT;
    this.logger.debug("onEditClick:currentItem ", this.currentItem);
    this.showTitle = setTitle(this.formMode, 'REFERENCE LINK');
    this.editItemOpen = true;
  }

  public onNew(): void {
    this.logger.debug("onNew...");
    this.currentItem = ReferenceLink.createWithOppId(this.opportunityId);
    this.formMode = FormModes.NEW;
    this.logger.debug("onNew:currentItem ", this.currentItem);
    this.showTitle = setTitle(this.formMode, 'REFERENCE LINK');
    this.editItemOpen = true;
  }

  public onItemSave(args: any): void {
    this.logger.debug('onItemSave...', args);
    this.handleSaveAndClose(args);
  }

  onConfirmDelete(dataItem: any): void {
    this.logger.debug("onConfirmDelete: ", dataItem);
    this.currentItem = dataItem;
    this.confirmDeleteItem = true;
  }

  onConfirmedClose(status: string): void {
    this.logger.debug("onConfirmedClose: ", status, this.currentItem);
    this.confirmDeleteItem = false;
    if (status === 'delete') {
      this.handleRemove(this.currentItem);
    }
  }

  public onErrorDialogClose(): void {
    this.logger.debug('onErrorDialogClose...');
    this.errorMsgOpen = false;
  }

  closeWindow(): void {
    this.logger.debug(`closeWindow...`);
    this.onClose.emit();
  }

  public close(args: any): void {
    this.logger.debug('close...', args);
    this.editItemOpen = false;
  }

  public closeItem(): void {
    this.logger.debug('closeItem...');
    this.editItemOpen = false;
  }

  //#endregion

  //#region Private functions

  private loadData(): void {
    // this.logger.debug('loadData...');
    this.gridData = orderBy(filterBy(this.rawData, this.filter), this.sort);
    this.pageSize = this.gridData.length;;
  }

  private handleRemove(args: any): void {
    if (args && !args.id) {
      this.logger.error('handlRemove: No id given');
    }

    this.dataService.destroy(args.id)
      .then(resp => {
        this.logger.debug('handleRemove:resp: ', resp);
        this.notify("Reference Link Deleted");
        this.dataService.query(this.opportunityId);
      })
      .catch(err => {
        this.logger.error('handleRemove:err: ', err);
        this.notify("Error Deleting Reference Link", true);
        this.errorMsgOpen = true;
      });
  }

  private handleSaveAndClose(args: any): void {
    this.logger.debug('handleSaveAndClose...', args);
    const { action, data, mode } = args;
    if (data && data.linkType === 'URL' && data.document_file_name) {
      data.document_file_name = null;
      this.dataService.deleteFile(data.id, "reference_link")
        .then(() => {
          this.logger.debug('deleteFile:done...');
          this.handleSave(data, mode);
        })
        .catch(err => this.logger.error('handleSaveAndClose:error: ', err))
    } else {
      this.handleSave(data, mode);
    }
  }

  private handleSave(data: ReferenceLink, mode: FormModes): void {
    this.dataService.addEdit(data, mode)
      .then(resp => {
        this.notify(mode === FormModes.NEW ? 'Reference Link Added' : 'Reference Link Updated')
        this.dataService.query(this.opportunityId);
      })
      .catch(err => {
        this.logger.error('handleSaveAndClose:error: ', err);
        this.notify("Error Adding/Updating Reference Link", true);
      })
      .finally(() => this.editItemOpen = false);
  }

  private notify(msg: string, error: boolean = false): void {
    this.logger.debug("notify", msg);
    this.notificationService.show({
      appendTo: this.appendTo,
      content: msg,
      animation: { type: "fade", duration: 300 },
      type: { style: !error ? "success" : "error", icon: true },
      position: {
        horizontal: "right",
        vertical: "top"
      },
      height: 50,
      // width: 150,
      hideAfter: 2000
    });
  }

  //#endregion

}
