import { HttpInterceptorFn } from '@angular/common/http';
import { AuthTokenService } from '../auth/auth-token.service';
import { inject } from '@angular/core';
import { LoggerService } from '../shared/logger/logger.service';
import { jwtDecode } from 'jwt-decode';
import { Router } from '@angular/router';

export const bearerInterceptor: HttpInterceptorFn = (req, next) => {
  const logger = inject(LoggerService);
  let router = inject(Router);
  let authService = inject(AuthTokenService);
  const bearerToken = authService.getToken();
  if (bearerToken && bearerToken !== 'undefined') {
    if (authService.hasExpired(bearerToken)) {
      authService.removeToken();
      router.navigateByUrl('/login');
      return next(req);
    } else {
      const newReq = req.clone({
        headers: req.headers.append('Authorization', `Bearer ${bearerToken}`),
      });
      // logger.debug('bearerInterceptor...', `Bearer ${bearerToken}`, newReq.headers);
      return next(newReq);
    }
  } else {
    logger.debug('bearerInterceptor:no token: ');
    return next(req);
  }
};
