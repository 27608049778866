<link rel="stylesheet" href="https://unpkg.com/@progress/kendo-font-icons/dist/index.css" />
<div class="k-overlay" *ngIf="addressPopupOpen"></div>
<div class="container" my-5>
  <div class="card" #appendTo>
    <div class="card-body no-border">
      <div class="card-title">
        <h3 style="text-align: left;" class="page-header">Manage Enquiries</h3>
      </div>
      <div style="text-align: right;">
        <button *ngIf="editRecipients" kendoButton (click)="addressPopup()" class="btn btn-primary">
          <i class="fa fa-pencil" aria-hidden="true"></i> Edit Enquiry Recipients</button>
      </div>
      <kendo-grid [kendoGridBinding]="gridData" [loading]="dataService.loading" [sort]="sort" [sortable]="true"
        [groupable]="true" [filter]="filter" filterable="menu" (filterChange)="filterChange($event)"
        (sortChange)="sortChange($event)" [resizable]="true" [pageable]="true" [pageSize]="pageSize"
        style="height: calc(100vh - 320px); min-height: 250px;">

        <ng-template kendoGridToolbarTemplate position="top">
          <button (click)="onClearFilters('enquiriesGrid')" kendoButton class="btn btn-primary">
            <fa-icon icon="ban"></fa-icon> Clear Filters
          </button>
        </ng-template>

        <!-- <kendo-grid-column field="id" title="Id"></kendo-grid-column> -->
        <kendo-grid-column field="createdAt" title="Date Created" [width]="140">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.createdAt | date: 'dd/MM/yyyy'}}
          </ng-template>
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
            let-filterService="filterService">
            <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
              [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="first_name" title="First Name" [width]="180">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
            let-filterService="filterService">
            <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
              [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="last_name" title="Last Name" [width]="180">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
            let-filterService="filterService">
            <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
              [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="email" title="Email" [width]="200">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
            let-filterService="filterService">
            <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
              [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="phone" title="Phone" [width]="100">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
            let-filterService="filterService">
            <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
              [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="message" title="Message" [width]="420">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
            let-filterService="filterService">
            <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
              [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Completed" field="completed" [width]="130">
          <ng-template kendoGridCellTemplate let-dataItem>
            <input type="checkbox" kendoCheckBox size="large" [checked]="dataItem.completed"
              (click)="confirmComplete(dataItem)" />
          </ng-template>
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
            let-filterService="filterService">
            <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
              [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
          </ng-template>
        </kendo-grid-column>

        <ng-template kendoPagerTemplate>
          <kendo-pager-info *ngIf="pageSize > 0"></kendo-pager-info>
        </ng-template>

      </kendo-grid>

    </div>
  </div>

</div>

<kendo-window *ngIf="addressPopupOpen" (close)="close($event)" [width]="1000">
  <kendo-window-titlebar>
    <span class="k-window-title">Edit Enquiry Recipient Email Addresses</span>
  </kendo-window-titlebar>
  <casi-enquiry-recipients (onClose)="close($event)"></casi-enquiry-recipients>
</kendo-window>
