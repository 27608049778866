import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { LoggerService } from '../shared/logger/logger.service';
import { IResponseToken } from './me.lib';
import { AuthTokenService } from './auth-token.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private apiService: ApiService,
    private logger: LoggerService,
    private authTokenService: AuthTokenService
  ) { }

  login(email: string, password: string): Promise<IResponseToken> {
    return new Promise((resolve, reject) => {
      return this.apiService
        .postHttpCallAsPromise('login', { email, password }, 'login')
        .then(resData => {
          this.logger.debug('login:resData: ', resData);
          return resolve(resData.token);
        })
        .catch(err => {
          this.logger.error('login: error: ', err);
          return reject(err);
        });
    });
  }

  logout(): void {
    this.authTokenService.removeToken();
  }
}
