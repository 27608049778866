import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {BehaviorSubject, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {map, tap} from "rxjs/operators";
import {OpportunityReview, ReviewOpportunity} from "../models/models.lib";
import {ApiService} from "../api/api.service";

const { apiUrl } = environment;

export abstract class EnvironerReviewOpportunityService extends BehaviorSubject<Array<any>>{
  public loading: boolean = false;

  constructor(
    private http: HttpClient,
  ) {
    super([]);
  }

  public query(): void {
    this.fetch().subscribe((x) => {
      return super.next(x);
    });
  }

  protected fetch(): Observable<ReviewOpportunity[]> {
    this.loading = true;

    return this.http.get(encodeURI(`${apiUrl}/opportunity/review`))
      .pipe(
        map((response: any, i) => {
          return <ReviewOpportunity[]>response;
        }),
        map(r => r.map(x => ReviewOpportunity.create(x))),
        tap(() => (this.loading = false))
      )
  }
}

@Injectable({
  providedIn: 'root'
})
export class ReviewOpportunityService extends EnvironerReviewOpportunityService{

  constructor(
    http: HttpClient,
    private apiService: ApiService
  ) {
    super(http);
  }

  get() {
    return this.apiService.getHttpCall(`opportunity/review`, 'get');
  }

  setComplete(item: any): Promise<any> {
    return this.apiService.putHttpCallAsPromise(`opportunity/review/complete/${item.opportunity_review_id}`,  item,'complete');
  }

  update(p: any): Promise<any> {
    return this.apiService.putHttpCallAsPromise(`opportunity/review/${p.opportunity_review_id}`, p, 'update');
  }

}
