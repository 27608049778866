import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { AuthTokenService } from './auth-token.service';
import { UploadEvent } from '@progress/kendo-angular-upload';
import { LoggerService } from '../shared/logger/logger.service';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  constructor(
    private apiService: ApiService,
    private tokenService: AuthTokenService,
    private logger: LoggerService
  ) { }

  secureUploads(e: UploadEvent, description: string = 'File uploading') {
    this.logger.debug('secureUploads...');
    e.data = {
      description: description
    };
    const bearerToken = this.tokenService.getToken();
    e.headers = e.headers.append('Authorization', `Bearer ${bearerToken}`);
    const csrfToken = this.apiService.csrfToken;
    e.headers = e.headers.append('X-CSRF-TOKEN', csrfToken);
    this.logger.debug('secureUploads: tokens: ', csrfToken, bearerToken);
  };

  requestCsrfToken() {
    return this.apiService.getCsrfAsPromise();
  }
}
