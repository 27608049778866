import { Component, NO_ERRORS_SCHEMA, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DialogCloseResult, DialogService, DialogsModule } from "@progress/kendo-angular-dialog";
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterLink, RouterLinkActive } from '@angular/router';
import {
  faTriangleExclamation,
  faPlusCircle, faAddressCard,
  faEdit, faTrash, faBan, faDownload
} from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { WindowModule } from "@progress/kendo-angular-dialog";
import { NotificationService, NotificationModule } from "@progress/kendo-angular-notification";
import { LoggerService } from '../../shared/logger/logger.service';
import { AuthTokenService } from '../../auth/auth-token.service';
import { CoBenefitTypesService } from '../co-benefit-types.service';
import { AppEnities, CasiCloseOptions, FormModes } from '../../common/constants.lib';
import { MeService } from '../../auth/me.service';
import { CoBenefitType } from '../../models/models.lib';
import { CompositeFilterDescriptor, SortDescriptor, filterBy, distinct, orderBy } from "@progress/kendo-data-query";
import { ExcelModule, GridModule, PageChangeEvent, PagerModule, PagerPosition, PagerType } from '@progress/kendo-angular-grid';
import { MulticheckFilterComponent } from '../../filters/multicheck-filter/multicheck-filter.component';
import { findNearestNumber, setTitle } from '../../common/utils.lib';
import { EditCoBenefitComponent } from '../edit-co-benefit/edit-co-benefit.component';
import { ExcelExportData } from "@progress/kendo-angular-excel-export";
import { process } from "@progress/kendo-data-query";
import { EditCoBenefitModalComponent } from '../edit-co-benefit-modal/edit-co-benefit-modal.component';
import { ToastrService } from 'ngx-toastr';

const { CO_BENEFIT_TYPE } = AppEnities;

@Component({
  selector: 'casi-co-benefit-type',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    FontAwesomeModule,
    RouterLink, RouterLinkActive,
    InputsModule,
    ButtonsModule,
    DialogsModule,
    WindowModule,
    NotificationModule,
    PagerModule,
    GridModule,
    MulticheckFilterComponent,
    ExcelModule,
    EditCoBenefitComponent,
    EditCoBenefitModalComponent
  ],
  schemas: [NO_ERRORS_SCHEMA],
  templateUrl: './co-benefit-type.component.html',
  styleUrl: './co-benefit-type.component.scss'
})
export class CoBenefitTypeComponent implements OnInit, OnDestroy {

  public view: Observable<CoBenefitType[]> = new Observable<CoBenefitType[]>();
  public rawData: CoBenefitType[] = [];
  public filter: CompositeFilterDescriptor = { logic: "and", filters: [] };
  public gridData: CoBenefitType[] = filterBy(this.rawData, this.filter);

  public currentItem: CoBenefitType = new CoBenefitType();

  public formMode: FormModes = FormModes.NOTSET;

  public editWinOpen: boolean = false;

  public pagerTypes = ["numeric", "input"];
  public type: PagerType = "numeric";
  public info = true;
  public pageSizes = true;
  public previousNext = true;
  public position: PagerPosition = "bottom";

  public pageSize = 10;

  public canCreate: boolean = false;
  public canUpdate: boolean = false;
  public canDestroy: boolean = false;

  // @ViewChild("appendTo", { read: ViewContainerRef, static: false }) appendTo: ViewContainerRef;
  @ViewChild("appendTo", { read: ViewContainerRef }) appendTo: ViewContainerRef | undefined;

  user: any = null;

  public opened: boolean = false;
  public dataSaved: boolean = false;
  public errorOpened: boolean = false;

  private _showTitle = new BehaviorSubject<string>('');
  get showTitle() {
    return this._showTitle.getValue();
  }
  set showTitle(val: string) {
    this._showTitle.next(val);
  }

  private _subs: Subscription[] = [];

  constructor(private logger: LoggerService,
    public authTokenService: AuthTokenService,
    public faIcons: FaIconLibrary,
    public dataService: CoBenefitTypesService,
    private notificationService: NotificationService,
    private toastr: ToastrService,
    private me: MeService,
    private dialogService: DialogService
  ) {
    faIcons.addIcons(
      faTriangleExclamation,
      faPlusCircle, faAddressCard, faEdit,
      faTrash,
      faBan, faDownload
    );

    this.allData = this.allData.bind(this);
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.gridData, {
        sort: [
          { field: "co_benefit", dir: "asc" },
          { field: "co_benefit_group", dir: "asc" },
          { field: "sub_co_benefit", dir: "asc" }
        ]
      }).data
    };
    return result;
  }

  public sort: SortDescriptor[] = [
    { field: "co_benefit", dir: "asc" },
    { field: "co_benefit_group", dir: "asc" },
    { field: "sub_co_benefit", dir: "asc" }
  ];

  ngOnInit(): void {
    this.logger.debug('ngOnInit...');
    const { can_destroy, can_create, can_update } = this.me.allICanDo(CO_BENEFIT_TYPE)
    this.logger.debug('ngOnInit:permissions: ', can_create, can_update, can_destroy);
    this.canCreate = can_create;
    this.canUpdate = can_update;
    this.canDestroy = can_destroy;

    this.dataService.query();
    this._subs.push(this.dataService.subscribe(items => {
      this.logger.debug('ngOnInit:v: ', items);
      this.rawData = items;
      this.loadData();
    }));
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnDestroy...');
    this._subs.forEach(s => s.unsubscribe());
  }

  public onAddType(): void {
    this.logger.debug("onAddType...");
    this.currentItem = new CoBenefitType();
    this.formMode = FormModes.NEW;
    this.logger.debug("onAddRole:currentRole ", this.currentItem);
    // this.openAddEditDialog(this.formMode, this.currentItem);
    this.showTitle = setTitle(this.formMode, 'CO-BENEFIT TYPE');
    this.editWinOpen = true;
  }

  onEditClick(dataItem: any): void {
    this.logger.debug("onEditClick: ", dataItem);
    this.currentItem = CoBenefitType.create(dataItem);
    this.formMode = FormModes.EDIT;
    this.logger.debug("onEditClick:currentRole ", this.currentItem);
    // this.openAddEditDialog(this.formMode, this.currentItem);
    this.showTitle = setTitle(this.formMode, 'CO-BENEFIT TYPE');
    this.editWinOpen = true;
  }

  openAddEditDialog(mode: FormModes, dataItem: CoBenefitType): void {
    this.logger.debug("openAddEditDialog...");
    const dialogRef = this.dialogService.open({
      content: EditCoBenefitModalComponent,
      minWidth: 320,
      width: 750,
      // cssClass: "custom-css-class"
    });

    const contentRef = dialogRef.content.instance as EditCoBenefitModalComponent;
    contentRef.title = setTitle(mode, 'CO-BENEFIT TYPE');
    contentRef.coBenefitType = dataItem;
    contentRef.mode = mode;

    dialogRef.result.subscribe((r: any) => {
      this.logger.debug('openAddEditDialog:result:', r);
      if (r instanceof DialogCloseResult) {
        return;
      }
      if (r.text === CasiCloseOptions.Saved) {
        this.logger.debug("openAddEditDialog:saved... ", contentRef.form.value);
        this.handleSaveAndClose(contentRef.form.value, mode);
      }
    });
  }

  public close(): void {
    this.logger.debug('close...');
    this.editWinOpen = false;
  }

  public onTypeSave(args: any): void {
    this.logger.debug('onTypeSave...', args);
    this.handleSaveAndCloseDeprecated(args);
  }

  public onExportData(): void {
    this.logger.debug('onExportData...');
  }

  public toggleActive(dataItem: any) {
    this.logger.debug('toggleActive:dataItem: ', dataItem);
    let newCbType = CoBenefitType.create(dataItem);
    newCbType.toggleActive();

    this.dataService.update(newCbType)
      .then(resp => {
        this.notify(newCbType.active ? "Co-benefit Type Activated" : "Co-benefit Type Deactivated");
        // this.notificationService.show({
        //   appendTo: this.appendTo,
        //   content: newCbType.active ? "Co-benefit Type Activated" : "Co-benefit Type Deactivated",
        //   animation: { type: "fade", duration: 300 },
        //   type: { style: "success", icon: true },
        //   position: {
        //     horizontal: "right",
        //     vertical: "top"
        //   },
        //   height: 50,
        //   width: 150,
        //   hideAfter: 2000
        // });
      })
      .catch(err => {
        this.logger.error(`toggleActive:error: `, err);
        this.notify('Error updating Co-benefit', err);
        // this.notificationService.show({
        //   appendTo: this.appendTo,
        //   content: `Error updating Co-benefit`,
        //   animation: { type: "fade", duration: 300 },
        //   type: { style: "error", icon: true },
        //   position: {
        //     horizontal: "right",
        //     vertical: "top"
        //   },
        //   height: 50,
        //   width: 150,
        //   hideAfter: 2000
        // });
      })
      .finally(() => this.dataService.query());
  }

  //#region Grid Handlers

  onClearFilters(gridName: string) {
    this.logger.debug("onClearFilters: ", gridName);
    this.filter = { logic: "and", filters: [] };
    this.loadData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.logger.debug("sortChange: ", sort);
    this.sort = sort;
    this.loadData();
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    // this.logger.debug('filterChange: filter: ', filter);
    this.filter = filter;
    this.loadData();
  }

  public distinctPrimitive(fieldName: string): any[] {
    // this.logger.debug('distinctPrimitive: fieldName: ', fieldName);
    return distinct(this.gridData, fieldName).map((item: any) => item[fieldName]);
  }

  //#endregion

  private handleSaveAndCloseDeprecated(args: any): void {
    this.logger.debug('handleSaveAndCloseDeprecated...', args);
    const { action, data, mode } = args;
    this.dataService.addEdit(data, mode)
      .then(resp => {
        this.notify(mode === FormModes.NEW ? 'Co-benefit Type Added' : 'Co-benefit Type Updated')
        this.dataService.query();
      })
      .catch(err => {
        this.logger.error('handleSaveAndCloseDeprecated:error: ', err);
        this.notify("Error Adding Co-benefit Type", true);
      })
      .finally(() => this.editWinOpen = false);
  }

  private handleSaveAndClose(data: any, mode: FormModes): void {
    this.logger.debug('handleSaveAndClose...', data);
    this.dataService.addEdit(data, mode)
      .then(resp => {
        this.notify(mode === FormModes.NEW ? 'Co-benefit Type Added' : 'Co-benefit Type Updated')
        this.dataService.query();
      })
      .catch(err => {
        this.logger.error('handleSaveAndClose:error: ', err);
        this.notify("Error Adding Co-benefit Type", true);
      })
      .finally(() => this.editWinOpen = false);
  }

  private loadData(): void {
    // this.logger.debug('loadData...');
    this.gridData = orderBy(filterBy(this.rawData, this.filter), this.sort);
    this.pageSize = this.gridData.length;
  }

  private notify(msg: string, error: boolean = false): void {
    this.logger.debug("notify", msg);
    if (error)
      this.toastr.error(msg);
    else
      this.toastr.success(msg);
    // this.notificationService.show({
    //   appendTo: this.appendTo,
    //   content: msg,
    //   animation: { type: "fade", duration: 300 },
    //   type: { style: !error ? "success" : "error", icon: true },
    //   position: {
    //     horizontal: "right",
    //     vertical: "top"
    //   },
    //   height: 50,
    //   // width: 150,
    //   hideAfter: 2000
    // });
  }

}
