
export enum CasiLogLevel {
  Error = 'ERROR',
  Warning = 'WARNING',
  Info = 'INFO',
  Log = 'LOG',
  Debug = 'DEBUG',
  Verbose ='VERBOSE',
  Silly = 'SILLY'
};

export enum CasiConsoleLogTypes {
  Silly = "SILLY",
  Verbose = "VERBOSE",
  Debug = "DEBUG",
  Log = "LOG",
  Info = "INFO",
  Warning = "WARN",
  Error = "ERROR",
};
