import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { LoggerService } from '../../shared/logger/logger.service';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { UserDataService } from '../../users/user-data.service';
import { MessageStates } from '../../common/constants.lib';
import { BehaviorSubject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'casi-password-reset',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    FontAwesomeModule,
    RouterLink, RouterLinkActive
  ],
  templateUrl: './password-reset.component.html',
  styleUrl: './password-reset.component.scss'
})
export class PasswordResetComponent implements OnInit {

  form: FormGroup;
  MessageStates = MessageStates;

  private _messageState = new BehaviorSubject<MessageStates>(MessageStates.Before);
  get messageState() {
    return this._messageState.getValue();
  }
  set messageState(val: MessageStates) {
    this._messageState.next(val);
  }

  constructor(
    private logger: LoggerService,
    private fb: FormBuilder,
    public faIcons: FaIconLibrary,
    private router: Router,
    private dataService: UserDataService
  ) {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
    faIcons.addIcons(faSpinner);
  }

  get f() {
    return this.form.controls;
  }

  get email() {
    return this.form.get('email');
  }

  ngOnInit(): void {
    this.setForm();
  }

  setForm() {
    this.messageState = MessageStates.Before; // 'before';
    this.form.patchValue({email: null});
  }

  onSubmit() {
    this.logger.debug('onSubmit...', this.form.value);
    const { email } = this.form.value;
    this.messageState = MessageStates.InProgress;
    this.dataService
    .sendResetRequest(email)
      .then(resData => {
        this.logger.debug('onSubmit:resData: ', resData);
        this.messageState = MessageStates.After;
        //this.form.reset();
       // this.router.navigate(['/logged-in']);
        // this.authService.loggedIn = this.authTokenService.isAuthenticated();
      })
      .catch(err => {
        this.logger.error('onSubmit: error: ', err);
        this.messageState = MessageStates.Error;
      });

  }

}
