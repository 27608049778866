
<div style="margin-bottom: 20px;
background-color: #7ba89e; border-radius: 5px; padding: 20px; position: fixed; top: 0; left: 0; width: 100%; z-index: 10000;" >
<h2 style="color: white;"> <fa-icon icon="info-circle"></fa-icon> Our Licence Agreement has been updated.</h2>
<h3 style="color: white;">Please review the latest version and indicate your acceptance.</h3>
</div>



      <div class="static-content" style="margin: 80px 30px 80px 30px;">

              <!-- <ng-include
                      src="'/app/commonPages/_termsAndConditionsView.html'">
              </ng-include> -->
              <div [innerHTML]="docContent"></div>

              <form [formGroup]="form" style="text-align: center;" >

              <div class="form-group row" style="margin-top: 40px; text-decoration: underline;">
                <div class="controls">
                  <input type="checkbox" id="agreeToTerms" class="k-checkbox k-checkbox-md" formControlName="agreeToTerms" required>
                  <label class="k-checkbox-label" for="agreeToTerms" style="display: inline-block;">I agree to the&nbsp;<a style="text-decoration: underline;">Terms
                      and Conditions</a></label>
                </div>
              </div>

              <div class="form-group row">

                <div class="controls">
                  <button class="btn btn-primary btn-lg" [disabled]="form.invalid" (click)="updateUser()">Continue
                  </button>
                </div>

              </div>
            </form>
      </div>
