import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { LoggerService } from '../../shared/logger/logger.service';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CasiCloseOptions, FormModes } from '../../common/constants.lib';
import { DialogRef, DialogContentBase, DialogModule } from "@progress/kendo-angular-dialog";
import { EditorComponent, EditorModule } from '@progress/kendo-angular-editor';
import { CheckboxListComponent } from '../../common-components/checkbox-list/checkbox-list.component';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { LabelModule } from '@progress/kendo-angular-label';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { HelpButtonComponent } from '../../admin/help-info/help-button/help-button.component';
import { HelpPopoverComponent } from '../../admin/help-info/help-popover/help-popover.component';
import { EditorImageUploadComponent } from '../../common-components/editor-image-upload/editor-image-upload.component';
import { CoBenefitType, ReviewOpportunity } from '../../models/models.lib';
import { Subscription } from 'rxjs';

@Component({
  selector: 'casi-edit-co-benefit-modal',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    FontAwesomeModule,
    RouterLink, RouterLinkActive,
    DialogModule,
    EditorModule,
    CheckboxListComponent,
    InputsModule,
    ButtonsModule,
    NotificationModule,
    LabelModule,
    DropDownsModule,
    DateInputsModule,
    LayoutModule,
    HelpButtonComponent,
    HelpPopoverComponent,
    EditorImageUploadComponent
  ],
  templateUrl: './edit-co-benefit-modal.component.html',
  styleUrl: './edit-co-benefit-modal.component.scss'
})
export class EditCoBenefitModalComponent extends DialogContentBase implements OnInit {

  private _coBenefitType: CoBenefitType | undefined;

  @Input() title: string = 'Title Not Set';

  @Input({ required: true }) set coBenefitType(val: CoBenefitType) {
    this._coBenefitType = val;
    this.form.patchValue(this._coBenefitType);
  }
  get coBenefitType(): CoBenefitType | undefined {
    return this._coBenefitType;
  };

  private _mode: FormModes = FormModes.NOTSET;
  @Input({ required: true }) set mode(val: FormModes) {
    this._mode = val;
  }
  get mode(): FormModes {
    return this._mode;
  };

  form: FormGroup;

  public value: number[] = [];

  constructor(
    public dialog: DialogRef,
    private fb: FormBuilder,
    private logger: LoggerService,
  ) {
    super(dialog);
    this.form = this.fb.group(
      {
        id: [null, []],
        co_benefit: ['', [Validators.required]],
        co_benefit_group: [null, [Validators.required]],
        sub_co_benefit: [null, [Validators.required]],
        active: [null, []]
      }
    );
  }

  //#region Form

  get f() {
    return this.form.controls;
  }
  get co_benefit() {
    return this.form.get('co_benefit');
  }
  get co_benefit_group() {
    return this.form.get('co_benefit_group');
  }
  get sub_co_benefit() {
    return this.form.get('sub_co_benefit');
  }
  get active() {
    return this.form.get('active');
  }

  //#endregion

  ngOnInit(): void {
    this.logger.debug('ngOnInit...');
  }

  public onCancelAction(): void {
    this.logger.debug(`onCancelAction...`);
    this.dialog.close({ text: CasiCloseOptions.Cancelled });
  }

  public onSaveAction(): void {
    this.logger.debug(`onSaveAction...`, this.form.value);

    this.dialog.close({ text: CasiCloseOptions.Saved });
  }
}
