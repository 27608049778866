import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { HelpInfoService } from '../../help-info.service';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogsModule, WindowModule } from '@progress/kendo-angular-dialog';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { LoggerService } from '../../../shared/logger/logger.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
  faTriangleExclamation,
  faPlusCircle, faAddressCard,
  faEdit, faTrash, faBan, faDownload, faLaptopHouse, faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { HelpButtonService } from './help-button.service';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { HelpButtonDisplayComponent } from '../help-button-display/help-button-display.component';
import { SafeHtmlPipe } from '../../../pipes/safe-html.pipe';
import { HelpCardComponent } from '../help-card/help-card.component';

@Component({
  selector: 'casi-help-button',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    FontAwesomeModule,
    RouterLink, RouterLinkActive,
    InputsModule,
    ButtonsModule,
    DialogsModule,
    WindowModule,
    NotificationModule,
    MatButtonModule, MatDividerModule, MatIconModule,
    MatDialogModule,
    HelpButtonDisplayComponent,
    SafeHtmlPipe,
    HelpCardComponent
  ],
  templateUrl: './help-button.component.html',
  styleUrl: './help-button.component.scss'
})
export class HelpButtonComponent implements OnInit, OnDestroy {
  @Input({ required: true }) infoId: number = 0;
  @Input() text: string | undefined;
  @Input() iconStyle: string | undefined;
  @Input() winStyle: string | undefined;
  @Output() onClose = new EventEmitter<void>();

  private _helpContent = new BehaviorSubject<string>('');
  get helpContent() {
    return this._helpContent.getValue();
  }
  set helpContent(val: string) {
    this._helpContent.next(val);
  }

  public isOpen: boolean = false;

  public windowTop = 100;
  public windowLeft = 50;

  public title: string = 'HELP INFORMATION';

  public showText: boolean = false;

  private _subs: Subscription[] = [];

  constructor(
    private dataService: HelpInfoService,
    private logger: LoggerService,
    public faIcons: FaIconLibrary,
    public dialog: MatDialog
  ) {
    faIcons.addIcons(
      faTriangleExclamation,
      faPlusCircle, faAddressCard, faEdit,
      faTrash,
      faBan, faDownload,
      faInfoCircle
    );
  }

  ngOnInit(): void {
    this.showText = !!this.text;
    // this.logger.debug('ngOnInit...', this.showText, this.infoId);
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnInit...');
    this._subs.forEach(s => s.unsubscribe());
  }

  public close(): void {
    this.logger.debug('close...');
    this.isOpen = false;
    this.onClose.emit();
  }

  public displayInfo(args: any): void {
    this.logger.debug('displayInfo: ', args);
    // const dialogRef = this.dialog.open(HelpButtonDisplayComponent);
    // dialogRef.afterClosed().subscribe(result => {
    //   this.logger.debug('displayInfo:afterClosed:results: ', result);
    // });
    this.dataService.getById(+this.infoId)
      .then(helpMeta => {
        this.logger.debug('displayInfo: data: ', helpMeta);
        if (helpMeta) {
          const { id, site_section, element_name,
            html, heading } = helpMeta;
          this.title = heading;
          this.helpContent = html;
          // this.logger.debug('displayInfo: data: ', helpMeta);
        }
        else {
          this.title = 'NO HELP FOUND';
          this.helpContent = 'No hep found for the selected item';
        }
        this.isOpen = true;
      })
      .catch(err => {
        this.logger.error('displayInfo: error: ', err);
      })
    // this.dataService.query(+this.infoId);
    // this._subs.push(this.dataService.subscribe(helpMeta => {
    //   if (helpMeta) {
    //     this.logger.debug('displayInfo: data: ', helpMeta);
    //   }
    // }));
  }


}
