import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { HelpInfoService } from '../../help-info.service';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogsModule, WindowModule } from '@progress/kendo-angular-dialog';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { LoggerService } from '../../../shared/logger/logger.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
  faTriangleExclamation,
  faPlusCircle, faAddressCard,
  faEdit, faTrash, faBan, faDownload, faLaptopHouse, faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { SafeHtmlPipe } from '../../../pipes/safe-html.pipe';

@Component({
  selector: 'casi-help-card',
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterLink, RouterLinkActive,
    InputsModule,
    ButtonsModule,
    MatButtonModule, MatDividerModule, MatIconModule,
    MatDialogModule,
    SafeHtmlPipe
  ],
  templateUrl: './help-card.component.html',
  styleUrl: './help-card.component.scss'
})
export class HelpCardComponent implements OnInit, OnDestroy {
  @Input({ required: true }) infoId: number = 0;
  @Output() onClose = new EventEmitter<void>();

  private _helpContent = new BehaviorSubject<string>('');
  get helpContent() {
    return this._helpContent.getValue();
  }
  set helpContent(val: string) {
    this._helpContent.next(val);
  }

  public isOpen: boolean = false;

  public windowTop = 100;
  public windowLeft = 50;

  public title: string = 'HELP INFORMATION';

  public showText: boolean = false;

  private _subs: Subscription[] = [];

  constructor(
    private dataService: HelpInfoService,
    private logger: LoggerService,
    public faIcons: FaIconLibrary,
    public dialog: MatDialog
  ) {
    faIcons.addIcons(
      faTriangleExclamation,
      faPlusCircle, faAddressCard, faEdit,
      faTrash,
      faBan, faDownload,
      faInfoCircle
    );
  }

  ngOnInit(): void {
    // this.logger.debug('ngOnInit...', this.showText, this.infoId);
    this.fetchHelp();
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnInit...');
    this._subs.forEach(s => s.unsubscribe());
  }

  public closeMe(): void {
    this.logger.debug('closeMe...');
    this.onClose.emit();
  }

  private fetchHelp(
  ) {
    this.dataService.getById(+this.infoId)
      .then(helpMeta => {
        this.logger.debug('fetchHelp: data: ', helpMeta);
        if (helpMeta) {
          const { id, site_section, element_name,
            html, heading } = helpMeta;
          this.title = heading;
          this.helpContent = html;
        } else {
          this.helpContent = "<p>No content found</p>"
        }
      })
      .catch(err => {
        this.logger.error('fetchHelp: error: ', err);
      })
  }

}
