<div class="accordion" id="options-accordian">
  <ng-template ngFor let-item [ngForOf]="items" let-i="index">
    <div class="accordion-item">
      <div class="accordion-header" style="padding-bottom: 0">
        <div class="row">
          <div class="col-3 col-md-2 col-lg-1" style="max-width: 50px;">
            <img src="assets/images/{{item.icon_name}}.png" style="width: 40px; margin-right: 10px; margin-top: -8px; margin-bottom: 10px;">
          </div>
          <div class="col-9 col-md-5 col-lg-6">

          <casi-switch label="{{item.title}}" [isChecked]="item.isOpen" (onChange)="onToggleAccordion($event, i)" style="max-width: 50%;"></casi-switch>
          </div>
          <div class="col-12 col-md-5 col-lg-5" style="padding: 0;">
            <div *ngIf="item.isOpen">
              <div class="k-form-field" style="margin-bottom: 0px !important">
                <kendo-label text="Include" class="form-label k-label" style="padding-top: 0; padding-left: 0; padding-right: 0;"></kendo-label>
                <kendo-label for="filterAllNot{{i}}" text="Any" class="form-label k-label rlabel" style="padding-left: 0; padding-right: 0; margin-left: -5px;"></kendo-label>
                <div class=" rcol">
                  <input type="radio" value=false id="filterAllNot{{i}}" [checked]="!item.filterAll"
                    (change)="onFilterChange(item)" kendoRadioButton />
                </div>

                <kendo-label for="filterAllYes{{i}}" text="All" class="form-label k-label rlabel" style="padding-left: 0; padding-right: 0; margin-left: -5px;"></kendo-label>
                <div class="rcol">
                  <input type="radio" value=true id="filterAllYes{{i}}" [checked]="item.filterAll"
                    (change)="onFilterChange(item)" kendoRadioButton />
                </div>
                <kendo-label text="that match" class="form-label k-label" style="padding-top: 0; padding-left: 0; padding-right: 0; margin-left: -5px;"></kendo-label>
              </div>
            </div>
          </div>
        </div>
        <!-- <div style="width: 50%; display: inline-flex">
          <img src="assets/images/{{item.icon_name}}.png"
            style="float: left; width: 40px; margin-right: 10px; margin-top: -8px">
          <casi-switch label="Filter on {{item.title}}" [isChecked]="item.isOpen"
            (onChange)="onToggleAccordion($event, i)" refId="DiscoverOpportunites"></casi-switch>
        </div> -->

      </div>

      <div class="accordion-content" [class.show]="item.isOpen">
        <!--        <div class="k-form-field" style="margin-bottom: 30px !important">-->
        <!--          <kendo-label text="Include" class="form-label k-label" style="padding-top: 0"></kendo-label>-->
        <!--          <kendo-label for="filterAllNot{{i}}" text="Any" class="form-label k-label rlabel"></kendo-label>-->
        <!--          <div class=" rcol">-->
        <!--            <input type="radio" value=false id="filterAllNot{{i}}" [checked]="!item.filterAll" (change)="onFilterChange(item)"  kendoRadioButton />-->
        <!--          </div>-->

        <!--          <kendo-label for="filterAllYes{{i}}" text="All" class="form-label k-label rlabel"></kendo-label>-->
        <!--          <div class="rcol">-->
        <!--            <input type="radio" value=true id="filterAllYes{{i}}" [checked]="item.filterAll" (change)="onFilterChange(item)" kendoRadioButton />-->
        <!--          </div>-->
        <!--          <kendo-label text="that match" class="form-label k-label" style="padding-top: 0"></kendo-label>-->
        <!--        </div>-->

        <div class="k-form-field form-check" style="width: 100%; display: block;">

          <div class="row" style="width: 100%; padding-left: 10px;">
            <ng-template ngFor let-o [ngForOf]="item.options" let-i="index" >
              <div class="col-12 col-md-6 col-lg-4" style="margin-bottom: 5px;">
                <div style="display: inline-block; width: 50px;">
                  <img src="assets/images/{{o.icon_name}}.png" style="width: 40px; margin-top: -4px" *ngIf="o.icon_name">
                  <span style="width: 40px; margin-right: 20px; margin-top: -5px; display: inline-block;" *ngIf="!o.icon_name"></span>
                </div>
                <div style="display: inline-block; width: 20px; margin-bottom: 5px;">
                  <input type="checkbox" size="large" kendoCheckBox  id="o{{item.title}}{{o.id}}" name="o{{item.title}}{{o.id}}"
                    (click)="onOptionChecked(o, item.field)" [checked]="o.checked"
                    casiClickLogger="checkbox[DiscoverOpportunites:{{o.description}}:{{!o.checked}}]"/>
                </div>
                <div style="display: inline-block; width: calc(100% - 70px); margin-bottom: 5px;">
                  <label for="o{{item.title}}{{o.id}}" style="font-size: 16px; font-weight: normal; text-align: left; padding-right: 0; display: inline-flex;">{{o.description}}</label>
                </div>
              </div>
            </ng-template>
          </div>
          <!-- <div class="col-4">
            <ul style="list-style: none;">
              <ng-template ngFor let-o [ngForOf]="item.options" let-i="index">
                <li *ngIf="i < (item.options ? item.options.length : 0) / 3" style="margin-bottom: 20px">
                  <img src="assets/images/{{o.icon_name}}.png"
                    style="float: left; width: 40px; margin-right: 30px; margin-top: -6px" *ngIf="o.icon_name">
                  <kendo-label for="o{{o.id}}" class="k-checkbox-label">{{o.description}}</kendo-label>
                  <input type="checkbox" size="large" kendoCheckBox class="form-check-input" id="o{{o.id}}"
                    (click)="onOptionChecked(o, item.field)" [checked]="o.checked"
                    casiClickLogger="checkbox[DiscoverOpportunites:{{o.description}}:{{!o.checked}}]" />
                  <br>
                </li>
              </ng-template>
            </ul>
          </div>
          <div class="col-4">
            <ul style="list-style: none;">
              <ng-template ngFor let-o [ngForOf]="item.options" let-i="index">
                <li
                  *ngIf="i >= (item.options ? item.options.length : 0) / 3 && i <  (item.options ? item.options.length : 0) * (2 / 3)"
                  style="margin-bottom: 20px">
                  <img src="assets/images/{{o.icon_name}}.png"
                    style="float: left; width: 40px; margin-right: 30px; margin-top: -6px" *ngIf="o.icon_name">
                  <kendo-label for="o{{o.id}}" class="k-checkbox-label">{{o.description}}</kendo-label>
                  <input type="checkbox" size="large" kendoCheckBox class="form-check-input" id="o{{o.id}}"
                    (click)="onOptionChecked(o, item.field)" [checked]="o.checked"
                    casiClickLogger="checkbox[DiscoverOpportunites:{{o.description}}:{{!o.checked}}]" />
                  <br>
                </li>
              </ng-template>
            </ul>
          </div>
          <div class="col-4">
            <ul style="list-style: none;">
              <ng-template ngFor let-o [ngForOf]="item.options" let-i="index">
                <li *ngIf="i >= (item.options ? item.options.length : 0) * (2 / 3)" style="margin-bottom: 20px">
                  <img src="assets/images/{{o.icon_name}}.png"
                    style="float: left; width: 40px; margin-right: 30px; margin-top: -6px" *ngIf="o.icon_name">
                  <kendo-label for="o{{o.id}}" class="k-checkbox-label">{{o.description}}</kendo-label>
                  <input type="checkbox" size="large" kendoCheckBox class="form-check-input" id="o{{o.id}}"
                    (click)="onOptionChecked(o, item.field)" [checked]="o.checked"
                    casiClickLogger="checkbox[DiscoverOpportunites:{{o.description}}:{{!o.checked}}]" />
                  <br>
                </li>
              </ng-template>
            </ul>
          </div> -->

        </div>
      </div>
    </div>
  </ng-template>

</div>
