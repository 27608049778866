<link rel="stylesheet" href="https://unpkg.com/@progress/kendo-font-icons/dist/index.css" />
<div class="k-overlay" *ngIf="editWinOpen"></div>
<div class="k-overlay" *ngIf="viewCBOpen"></div>
<div class="k-overlay" *ngIf="viewPracticeOpen"></div>
<div class="k-overlay" *ngIf="viewRefLinkOpen"></div>
<div class="container-fluid gx-5">
  <div class="card" #appendTo>
    <div class="card-body no-border">
      <div class="card-title">
        <h3 style="text-align: left;" class="page-header">Manage Opportunities</h3>
      </div>

      <kendo-grid [kendoGridBinding]="gridData" [loading]="dataService.loading" [sort]="sort" [sortable]="true"
        [groupable]="true" [resizable]="true" [filter]="filter" filterable="menu" (filterChange)="filterChange($event)"
        (sortChange)="sortChange($event)" [pageSize]="pageSize" [pageable]="true"
        style="height: calc(100vh - 300px); min-height: 250px;">

        <ng-template kendoGridToolbarTemplate position="top">
          <button [hidden]="!canCreate" (click)="onAddClicked()" kendoButton class="btn btn-primary">
            <fa-icon icon="circle-plus"></fa-icon> Add Opportunity
          </button>
        </ng-template>

        <kendo-grid-column [hidden]="!canUpdate" title="Edit" [width]="70" [headerStyle]="{ 'vertical-align': 'top'}">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <button (click)="onEditClick(dataItem)" kendoButton class="btn btn-stn-grid">
              <fa-icon icon="edit"></fa-icon>
            </button>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Co-benefits" [width]="90" [headerStyle]="{ 'vertical-align': 'top'}">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <button (click)="viewCoBenefits(dataItem)" kendoButton class="btn btn-stn-grid">
              {{dataItem.co_benefits}}
            </button>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Practices" [width]="90" [headerStyle]="{ 'vertical-align': 'top'}">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <button (click)="viewPractices(dataItem)" kendoButton class="btn btn-stn-grid">
              {{dataItem.practices}}
            </button>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Reference Links" [width]="110" [headerStyle]="{ 'vertical-align': 'top'}">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <button (click)="viewReferenceLinks(dataItem)" kendoButton class="btn btn-stn-grid">
              {{dataItem.reference_links}}
            </button>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Resources" [width]="100" [headerStyle]="{ 'vertical-align': 'top'}">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <button (click)="viewResources(dataItem)" kendoButton class="btn btn-stn-grid">
              {{dataItem.resources}}
            </button>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Reviews" [width]="90" [headerStyle]="{ 'vertical-align': 'top'}">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <button (click)="viewReviews(dataItem)" kendoButton class="btn btn-stn-grid">
              {{dataItem.opportunity_reviews}}
            </button>
          </ng-template>
        </kendo-grid-column>


        <kendo-grid-column field="title" title="Title" [width]="180" [headerStyle]="{ 'vertical-align': 'top'}">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
            let-filterService="filterService">
            <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
              [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="short_description" title="Description" [width]="250"
          [headerStyle]="{ 'vertical-align': 'top'}">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-dataItem let-filter="filter"
            let-filterService="filterService">
            <div [innerHTML]="dataItem.short_description"></div>

            <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
              [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="classification_description" title="Classification" [width]="180"
          [headerStyle]="{ 'vertical-align': 'top'}">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
            let-filterService="filterService">
            <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
              [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="updated_by_user" title="Updated By" [width]="160"
          [headerStyle]="{ 'vertical-align': 'top'}">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
            let-filterService="filterService">
            <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
              [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="updated_on" title="Updated On" [width]="120"
          [headerStyle]="{'white-space': 'normal', 'vertical-align': 'top'}">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
            let-filterService="filterService">
            <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
              [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
          </ng-template>
          <!--          <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">-->
          <!--            <kendo-grid-date-filter-menu-->
          <!--              [column]="column"-->
          <!--              [filter]="filter"-->
          <!--              [filterService]="filterService">-->
          <!--            </kendo-grid-date-filter-menu>-->
          <!--          </ng-template>-->
          <!--          <ng-template kendoGridCellTemplate let-dataItem>-->
          <!--            {{dataItem.updated_on | date: 'dd/MM/yyyy'}}-->
          <!--          </ng-template>-->
        </kendo-grid-column>

        <kendo-grid-column [hidden]="!canDestroy" title="Delete" [width]="70"
          [headerStyle]="{ 'vertical-align': 'top'}">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <button (click)="onConfirmDelete(dataItem)" kendoButton class="btn btn-stn-grid">
              <fa-icon icon="trash"></fa-icon>
            </button>
          </ng-template>
        </kendo-grid-column>

        <ng-template kendoPagerTemplate>
          <kendo-pager-info *ngIf="pageSize > 0"></kendo-pager-info>
        </ng-template>
        <kendo-grid-excel fileName="Co-benefit Types.xlsx"></kendo-grid-excel>

      </kendo-grid>

    </div>
  </div>

</div>

<kendo-dialog *ngIf="confirmDeleteItem" title="Please confirm" (close)="onConfirmedClose('cancel')" [minWidth]="250"
  [width]="450">

  <h4>Delete Opportunity - <b>{{currentItem.title}}</b>?</h4>

  <kendo-dialog-actions>
    <button kendoButton (click)="onConfirmedClose('delete')" themeColor="primary">Delete</button>
    <button kendoButton (click)="onConfirmedClose('cancel')">
      Cancel
    </button>
  </kendo-dialog-actions>
</kendo-dialog>

<!-- <kendo-window *ngIf="editWinOpen" (close)="close()" [width]="1000" [(top)]="windowTop" [(left)]="windowLeft"
  autoFocusedElement="#title"> -->
<kendo-window *ngIf="editWinOpen" (close)="close()" [width]="1000" autoFocusedElement="#title">
  <kendo-window-titlebar>
    <span class="k-window-title">{{showTitle}}</span>
    <button kendoWindowCloseAction></button>
  </kendo-window-titlebar>
  <casi-edit-opportunity [opportunity]="currentItem" [mode]="formMode" (onCancel)="close()"
    (onSave)="onItemSave($event)"></casi-edit-opportunity>
</kendo-window>

<!-- <kendo-window *ngIf="viewCBOpen" (close)="closeCB()" [width]="1350" [(top)]="windowTop" [(left)]="windowLeft"
  autoFocusedElement="#title"> -->
<kendo-window *ngIf="viewCBOpen" (close)="closeCB()" [width]="1350" autoFocusedElement="#title">
  <kendo-window-titlebar>
    <span class="k-window-title">MANAGE CO-BENEFITS</span>
    <button kendoWindowCloseAction></button>
  </kendo-window-titlebar>
  <casi-co-benefits [opportunityId]="currentItemId" [ddTreeData]="_coBenDdTree"
    (onClose)="closeViewCB()"></casi-co-benefits>
</kendo-window>

<!-- <kendo-window *ngIf="viewPracticeOpen" (close)="closePractices()" [width]="1350" [(top)]="windowTop"
  [(left)]="windowLeft" autoFocusedElement="#title"> -->
<kendo-window *ngIf="viewPracticeOpen" (close)="closePractices()" [width]="1350" autoFocusedElement="#title">
  <kendo-window-titlebar>
    <span class="k-window-title">MANAGE PRACTICES</span>
    <button kendoWindowCloseAction></button>
  </kendo-window-titlebar>
  <casi-practices [opportunityId]="currentItemId" (onClose)="closeViewPractice()"></casi-practices>
</kendo-window>

<kendo-window *ngIf="viewRefLinkOpen" (close)="closeRefLinks()" [width]="1350" autoFocusedElement="#title">
  <kendo-window-titlebar>
    <span class="k-window-title">MANAGE REFERENCE LINKS</span>
    <button kendoWindowCloseAction></button>
  </kendo-window-titlebar>
  <casi-reference-links [opportunityId]="currentItemId" (onClose)="closeViewRefLinks()"></casi-reference-links>
</kendo-window>


<kendo-window *ngIf="viewResourcesOpen" (close)="closeResources()" [width]="1350" autoFocusedElement="#title">
  <kendo-window-titlebar>
    <span class="k-window-title">RESOURCES LINKED TO THIS OPPORTUNITY</span>
    <button kendoWindowCloseAction></button>
  </kendo-window-titlebar>
  <casi-resources [opportunityId]="currentItemId" (onClose)="closeViewResources()"
    [title]="currentItemTitle"></casi-resources>
</kendo-window>


<kendo-window *ngIf="viewReviewsOpen" (close)="closeViewReviews()" [width]="1350" autoFocusedElement="#title">
  <kendo-window-titlebar>
    <span class="k-window-title">OPPORTUNITY REVIEW</span>
    <button kendoWindowCloseAction></button>
  </kendo-window-titlebar>
  <casi-opportunity-reviews [opportunity]="currentItem" (onClose)="closeViewReviews()"></casi-opportunity-reviews>
</kendo-window>
