import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import { APP_CONFIG } from '../setup/config';
import { DiscoverResourceSearchOutcomes } from '../models/models.lib';
import { ApiService } from '../api/api.service';
import { environment } from '../../environments/environment';

const { apiUrl } = environment;

export abstract class CommonSearchResourceService extends BehaviorSubject<Array<any>> {
  public loading: boolean = false;

  constructor(
    private http: HttpClient,
  ) {
    super([]);
  }

  public doSearch(p: any): void {
    this.fetch(p).subscribe((x) => {
      console.log('query: x: ', x);
      return super.next(x);
    });
  }

  protected fetch(p: any): Observable<DiscoverResourceSearchOutcomes[]> {
    this.loading = true;

    return this.http.post(encodeURI(`${apiUrl}/resource/search`), p) //to change
      .pipe(
        map((response: any, i) => {
          return <DiscoverResourceSearchOutcomes[]>response;
        }),
        map(r => r.map(x => DiscoverResourceSearchOutcomes.create(x))),
        tap(() => (this.loading = false))
      )
  }
}


@Injectable({
  providedIn: 'root'
})
export class SearchResourcesService extends CommonSearchResourceService{

  constructor(
    http: HttpClient,
    private apiService: ApiService
  ) {
    super(http);
  }

  search(p: any): Promise<any> {
    return this.apiService.postHttpCallAsPromise(`resource/search`, p, 'search');
  }

}
