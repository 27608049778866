<link rel="stylesheet" href="https://unpkg.com/@progress/kendo-font-icons/dist/index.css" />
<div class="k-overlay" *ngIf="editWinOpen"></div>
<div class="container-fluid gx-5">
  <div class="card" #appendTo>
    <div class="card-body no-border">
      <div class="card-title">
        <h3 style="text-align: left;" class="page-header">Manage Resources</h3>
      </div>

      <kendo-grid [kendoGridBinding]="gridData" [loading]="dataService.loading" [sort]="sort" [sortable]="true"
        [groupable]="true" [resizable]="true" [filter]="filter" filterable="menu" (filterChange)="filterChange($event)"
        (sortChange)="sortChange($event)" [pageSize]="pageSize" [pageable]="true"
        style="height: calc(100vh - 300px); min-height: 250px;">

        <ng-template kendoGridToolbarTemplate position="top">
          <button [hidden]="!canCreate" (click)="onAddClicked()" kendoButton class="btn btn-primary">
            <fa-icon icon="circle-plus"></fa-icon> Add Resource
          </button>
        </ng-template>

        <kendo-grid-column [hidden]="!canUpdate" title="Edit" [width]="70">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <button (click)="onEditClick(dataItem)" kendoButton class="btn btn-stn-grid">
              <fa-icon icon="edit"></fa-icon>
            </button>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Opportunities" [width]="90">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <button (click)="viewOpportunities(dataItem)" kendoButton class="btn btn-stn-grid">
              {{dataItem.opportunities}}
            </button>
          </ng-template>
        </kendo-grid-column>

        <!-- <kendo-grid-column field="id" title="Id"></kendo-grid-column> -->
        <kendo-grid-column field="title" title="Title" [width]="180">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
            let-filterService="filterService">
            <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
              [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="description" title="Description" [width]="250">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-dataItem let-filter="filter"
            let-filterService="filterService">
            <div [innerHTML]="dataItem.description"></div>

            <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
              [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="key_words" title="Key Words" [width]="180">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
            let-filterService="filterService">
            <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
              [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="URL / Document Name" [width]="180">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div>{{dataItem.url || dataItem.document_file_name}}</div>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column [hidden]="!canDestroy" title="Delete" [width]="70">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <button (click)="onConfirmDelete(dataItem)" kendoButton class="btn btn-stn-grid">
              <fa-icon icon="trash"></fa-icon>
            </button>
          </ng-template>
        </kendo-grid-column>

        <ng-template kendoPagerTemplate>
          <kendo-pager-info *ngIf="pageSize > 0"></kendo-pager-info>
        </ng-template>

      </kendo-grid>

    </div>
  </div>

</div>

<kendo-dialog *ngIf="confirmDeleteItem" title="Please confirm" (close)="onConfirmedClose('cancel')" [minWidth]="250"
  [width]="450">

  <h4>Delete Resource - <b>{{currentItem.title}}</b>?</h4>

  <kendo-dialog-actions>
    <button kendoButton (click)="onConfirmedClose('delete')" themeColor="primary">Delete</button>
    <button kendoButton (click)="onConfirmedClose('cancel')">
      Cancel
    </button>
  </kendo-dialog-actions>
</kendo-dialog>

<!-- <kendo-window *ngIf="editWinOpen" (close)="close()" [width]="1000" [(top)]="windowTop" [(left)]="windowLeft"
  autoFocusedElement="#title"> -->
<kendo-window *ngIf="editWinOpen" (close)="close()" [width]="1000" autoFocusedElement="#title">
  <kendo-window-titlebar>
    <span class="k-window-title">{{showTitle}}</span>
    <button kendoWindowCloseAction></button>
  </kendo-window-titlebar>
  <casi-edit-resource [resource]="currentItem" [hostInfo]="_hostInfo" [mode]="formMode" (onCancel)="close()"
    (onSave)="onItemSave($event)"></casi-edit-resource>
</kendo-window>


<kendo-window *ngIf="viewOpportunitiesOpen" (close)="closeOpportunities()" [width]="1350" autoFocusedElement="#title">
  <kendo-window-titlebar>
    <span class="k-window-title">OPPORTUNITIES LINKED TO THIS RESOURCE</span>
    <button kendoWindowCloseAction></button>
  </kendo-window-titlebar>
  <casi-opportunities [resourceId]="currentItemId"  (onClose)="closeViewOpportunities()" [title]="currentItemTitle"></casi-opportunities>
</kendo-window>
