import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { map, tap } from "rxjs/operators";
import { HelpInfo } from '../models/models.lib';
import { APP_CONFIG } from '../setup/config';
import { FormModes } from '../common/constants.lib';
import { environment } from '../../environments/environment';

const { apiUrl } = environment;

export abstract class EnvironerHelpService extends BehaviorSubject<Array<any>>{
  public loading: boolean = false;

  constructor(
    private http: HttpClient,
  ) {
    super([]);
  }

  public query(): void {
    this.fetch().subscribe((x) => {
      // console.log('query: x: ', x);
      return super.next(x);
    });
  }

  protected fetch(): Observable<HelpInfo[]> {
    this.loading = true;

    return this.http.get(encodeURI(`${apiUrl}/helpinfo`))
      .pipe(
        map((response: any, i) => {
          // console.log('fetch:map:response: ', response, i);
          // let returning =<Role[]>response; // response.map(r =>  Role.create((r)));
          // console.log('fetch:map:response: ', returning);
          return <HelpInfo[]>response;
        }),
        map(r => r.map(x => HelpInfo.create(x))),
        // tap(nr => {
        //   console.log('nr: ', nr);
        // }),
        tap(() => (this.loading = false))
      )
  }
}

@Injectable({
  providedIn: 'root'
})
export class HelpInfoService extends EnvironerHelpService {

  constructor(
    http: HttpClient,
    private apiService: ApiService
  ) {
    super(http)
  }

  get() {
    return this.apiService.getHttpCall('helpinfo', 'get');
  }

  getById(id: number) {
    return this.apiService.getHttpCallAsPromise(`helpinfo/${id}`, 'getById');
  }

  add(p: any): Promise<any> {
    delete p.id;
    return this.apiService.postHttpCallAsPromise('helpinfo', p, 'add');
  }

  update(p: any): Promise<any> {
    return this.apiService.putHttpCallAsPromise(`helpinfo/${p.id}`, p, 'update');
  }

  save(p: any, formMode: FormModes): Promise<any> {
    if (formMode === FormModes.NEW) {
      return this.add(p);
    } else {
      return this.update(p);
    }
  }

}

/**
 *
 * [
  {
    "id": 1,
    "site_section": "Manage Opportunities",
    "element_name": "Add / Edit Opportunities",
    "html": "<p>Demonstrated Financial Benefit (0-2)</p><p><strong>&nbsp;</strong></p>",
    "heading": "Demonstrated Financial Benefit (0-2)"
  },
  {
    "id": 2,
    "site_section": "Manage Opportunities",
    "element_name": "Add / Edit Opportunities",
    "html": "Certification / Accreditation Scheme (0-2)<br />",
    "heading": "Certification / Accreditation Scheme (0-2)"
  },
  {
    "id": 3,
    "site_section": "Manage Opportunities",
    "element_name": "Add / Edit Opportunities",
    "html": "Standards have ANZ / ISO accreditation (0, 2)",
    "heading": "Standards have ANZ / ISO accreditation (0, 2)"
  },
  {
    "id": 4,
    "site_section": "Manage Opportunities",
    "element_name": "Add / Edit Opportunities",
    "html": "Demonstrated Access to Certain Markets (0-2)",
    "heading": "Demonstrated Access to Certain Markets (0-2)"
  },
  {
    "id": 5,
    "site_section": "Manage Opportunities",
    "element_name": "Add / Edit Opportunities",
    "html": "Publicly Accessible Information (0-1)",
    "heading": "Publicly Accessible Information (0-1)"
  },
  {
    "id": 6,
    "site_section": "Manage Opportunities",
    "element_name": "Add / Edit Opportunities",
    "html": "Quality of Information Resources (0, 2, 4)",
    "heading": "Quality of Information Resources (0, 2, 4)"
  }
]
 */
