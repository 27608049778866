<casi-editor-image-upload [editor]="editor" [fieldName]="fieldName" [recordId]="controlParams.id" tableName="control"
                          (onLoad)="onImageLoaded($event)" #uploadDialog></casi-editor-image-upload>

<div class="container-fluid">
  <!--
  <div class="bg-primary-subtle p-5 rounded-lg m-3 border border-primary-subtle rounded-3">
    <h1 class="display-4">Hello, world!</h1>
    <p class="lead">This is a simple hero unit, a simple jumbotron-style component for calling extra attention to
      featured content or information.</p>
    <hr class="my-4">
    <p>It uses utility classes for typography and spacing to space content out within the larger container.</p>
    <a class="btn btn-primary btn-lg" href="#" role="button">Learn more</a>
  </div> -->
  <!--
  <div class="card m-3" #appendTo>
    <div class="card-header">
      <div class="card-title text-center">
        <h3>Company / Email Parameters Edit</h3>
      </div>

    </div>
  </div> -->

  <div class="text-center m-3" #appendTo>
    <h2><strong>Company / Email Parameters Edit</strong></h2>
  </div>
  <br>

  <!-- <div class="card" #appendTo>
    <div class="card-body bg-light">
    </div>
  </div> -->
  <div class="row">
    <div class="bg-light p-5 rounded-lg rounded-2 col-md-8 offset-md-2">

      <form [formGroup]="form">

        <fieldset>

          <div class="form-group row">
            <div class="col-md-5">
              <label class="form-label" for="param_product_name">Product Name</label>
            </div>
            <div class="col-md-6">
              <input type="text" id="param_product_name" formControlName="param_product_name" class="form-control">
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-5">
              <label class="form-label" for="param_company_name">Company Name</label>
            </div>
            <div class="col-md-6">
              <input type="text" id="param_company_name" formControlName="param_company_name" class="form-control">
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-5">
              <label class="form-label" for="param_entity_name">Controlling Entity Name</label>
            </div>
            <div class="col-md-6">
              <input type="text" id="param_entity_name" formControlName="param_entity_name" class="form-control">
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-5">
              <label class="form-label" for="param_company_address">Address</label>
            </div>
            <div class=" col-md-6">
              <textarea rows="5" class="form-control" formControlName="param_company_address"
                id="param_company_address"></textarea>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-5">
              <label class="form-label" for="param_abn">ABN</label>
            </div>
            <div class="col-md-6">
              <input type="text" id="param_abn" formControlName="param_abn" class="form-control">
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-5">
              <label class="form-label" for="param_acn">CRICOS Provider Code</label>
            </div>
            <div class="col-md-6">
              <input type="text" id="param_acn" formControlName="param_acn" class="form-control">
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-5">
              <label class="form-label" for="param_website_url">Website URL</label>
            </div>
            <div class="col-md-6">
              <input type="text" id="param_website_url" formControlName="param_website_url" class="form-control">
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-5">
              <label class="form-label" for="app_base_url">App base URL</label>
            </div>
            <div class="col-md-5">
              <input type="text" id="app_base_url" formControlName="app_base_url" class="form-control">
              <div style="margin: 10px 0px;"><b>Used in all email links</b></div>
            </div>

          </div>

          <div class="form-group row">
            <div class="col-md-5">
              <label class="form-label" for="param_primary_contact_number">Phone 1: Primary Office Number</label>
            </div>
            <div class="col-md-6">
              <input type="text" id="param_primary_contact_number" formControlName="param_primary_contact_number"
                class="form-control">
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-5">
              <label class="form-label" for="param_additional_contact_number">Phone 2: Additional Contact Number</label>
            </div>
            <div class="col-md-6">
              <input type="text" id="param_additional_contact_number" formControlName="param_additional_contact_number"
                class="form-control">
            </div>
          </div>

          <!-- <div class="form-group row">
            <label class="form-label" for="api_notification_email">API Update Notification Email Address (comma
              separated)</label>
            <label>Not implemented yet by the looks.</label>
            <input type="text" id="api_notification_email" formControlName="api_notification_email"
                class="form-control">
          </div> -->

          <div class="form-group row">
            <div class="col-md-5">
              <label class="form-label" for="param_primary_email">Primary Contact Email (from)</label>
            </div>
            <div class="col-md-6">
              <input type="text" id="param_primary_email" formControlName="param_primary_email" class="form-control">
              <div *ngIf="param_primary_email?.invalid && (param_primary_email?.dirty || param_primary_email?.touched)"
                class="alert alert-danger">
                <div *ngIf="param_primary_email?.errors?.['required']">
                  Primary Contact Email is required.
                </div>
                <div *ngIf="param_primary_email?.errors && param_primary_email?.errors?.['email']">Not a valid email
                  address.</div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-5">
              <label class="form-label" for="param_additional_email">Additional Inquiries Email Address</label>
            </div>
            <div class="col-md-6">
              <input type="text" id="param_additional_email" formControlName="param_additional_email"
                class="form-control">
              <div
                *ngIf="param_additional_email?.invalid && (param_additional_email?.dirty || param_additional_email?.touched)"
                class="alert alert-danger">
                <div *ngIf="param_additional_email?.errors && param_additional_email?.errors?.['email']">Not a valid email
                  address.</div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-5">
              <label class="form-label" for="param_reply_to_email">Customer Reply To Email Address</label>
            </div>
            <div class="col-md-6">
              <input type="text" id="param_reply_to_email" formControlName="param_reply_to_email" class="form-control">
              <div *ngIf="param_reply_to_email?.invalid && (param_reply_to_email?.dirty || param_reply_to_email?.touched)"
                class="alert alert-danger">
                <div *ngIf="param_reply_to_email?.errors && param_reply_to_email?.errors?.['email']">Not a valid email
                  address.</div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-5">
              <label class="form-label" for="param_reset_password_bcc">Reset Password - Email Copy (bcc)</label>
            </div>
            <div class="col-md-6">
              <input type="text" id="param_reset_password_bcc" formControlName="param_reset_password_bcc"
                class="form-control">
              <div
                *ngIf="param_reset_password_bcc?.invalid && (param_reset_password_bcc?.dirty || param_reset_password_bcc?.touched)"
                class="alert alert-danger">
                <div *ngIf="param_reset_password_bcc?.errors && param_reset_password_bcc?.errors?.['email']">Not a valid email address.</div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-5">
              <label class="form-label k-label">Review Opportunity - Email</label>
            </div>
            <div class="col-md-6">
              <kendo-editor formControlName="review_opportunity_email"  #review_opportunity_email_editor style="min-height: 600px">
                <kendo-toolbar>
                  <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>

                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                    <kendo-toolbar-button text="Upload Image" (click)="openImageUpload('review_opportunity_email')"></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
                  <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
                  <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
                  <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
                  <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
                </kendo-toolbar>
              </kendo-editor>

            </div>
          </div>

          <div class="form-group row mt-5">
            <div class="col-md-3">
              <label class="form-label" for="param_primary_image">Business Logo Image</label>
            </div>
            <div class="col-md-6">
              <kendo-upload id="param_primary_image" name="businessLogoUpload" [autoUpload]="false"
                [saveUrl]="uploadSaveUrlBusiness" [removeUrl]="uploadRemoveUrl" (cancel)="cancel($event)"
                (clear)="clear()" (complete)="complete()" (error)="error($event)" (blur)="blur()" (focus)="focus()"
                (pause)="pause($event)" (remove)="remove($event)" (resume)="resume($event)" (select)="select($event)"
                (success)="success($event)" (upload)="upload($event)" (uploadProgress)="uploadProgress($event)"
                (valueChange)="valueChange($event)">
              </kendo-upload>
            </div>

            <div class="col-md-3">
              @if(hasBusinessLogo) {
              <img id="businessLogoImage" src="data:image/JPEG;base64,{{logoBusiness}}"  style="max-width: 100%; max-height: 100px;"/>
              } @else {
              <img id="businessLogoImage2"  style="max-width: 100%; max-height: 100px;"/>
              }
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-3">
              <label class="form-label" for="param_additional_image">Product Logo Image</label>
            </div>
            <div class="col-md-6">
              <kendo-upload id="param_additional_image" [autoUpload]="false" [saveUrl]="uploadSaveUrlProduct"
                [removeUrl]="uploadRemoveUrl" (cancel)="cancelProduct($event)" (clear)="clearProduct()"
                (complete)="completeProduct()" (error)="errorProduct($event)" (blur)="blurProduct()"
                (focus)="focusProduct()" (pause)="pauseProduct($event)" (remove)="removeProduct($event)"
                (resume)="resumeProduct($event)" (select)="selectProduct($event)" (success)="successProduct($event)"
                (upload)="uploadProduct($event)" (uploadProgress)="uploadProgressProduct($event)"
                (valueChange)="valueChangeProduct($event)">
              </kendo-upload>
            </div>

            <div class="col-md-3">
              @if(hasProductLogo) {
              <img id="additionalLogoImage" src="data:image/JPEG;base64,{{logoProduct}}"  style="max-width: 100%; max-height: 100px;"/>
              } @else {
              <img id="additionalLogoImage2"  style="max-width: 100%; max-height: 100px;"/>
              }
            </div>
          </div>

          <!-- Buttons -->

          <div class="form-group row mt-4">
            <div class="col-md-3 offset-md-3">
              <button type="button" class="btn btn-primary me-md-2" (click)="onSubmit()" [disabled]="form.invalid">
                Save</button>
            </div>
            <div class="col-md-3">
              <button type="button" class="btn btn-secondary" (click)="closeWindow()"> Cancel</button>
            </div>
          </div>

        </fieldset>
      </form>
    </div>
  </div>



</div>
