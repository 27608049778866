<form class="k-form" [formGroup]="form">

  <fieldset>

    <div class="row">
      <label  class="col-3 col-form-label">Resource</label>
      <div class=" col-9">
        <kendo-dropdownlist #classificationSelection formControlName="resource_id"
                            [data]="resourceList" [defaultItem]="defaultItem" textField="title" valueField="id"
                            [valuePrimitive]="true" required>
        </kendo-dropdownlist>
        <br>

        <div *ngIf="resource_id?.invalid" class="alert alert-danger">
        <div *ngIf="resource_id?.errors?.['required']">Resource is required.</div>
        </div>
      </div>

    </div>


    <!-- Buttons -->

    <div class="d-grid d-md-flex justify-content-md-end mt-4">
      <button type="button" class="btn btn-primary me-md-2" (click)="onSubmit()" [disabled]="form.invalid">Save</button>
      <button type="button" class="btn btn-secondary" (click)="closeWindow()"> Cancel</button>
    </div>

  </fieldset>
</form>

