import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoggerService } from '../../../shared/logger/logger.service';
import { CasiControl, ReferenceLink } from '../../../models/models.lib';
import { FormModes } from '../../../common/constants.lib';
import { EditorModule } from '@progress/kendo-angular-editor';
import { CheckboxListComponent } from '../../../common-components/checkbox-list/checkbox-list.component';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { LabelModule } from '@progress/kendo-angular-label';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { UploadsModule, FileRestrictions } from '@progress/kendo-angular-upload';
import {
  SelectEvent,
  RemoveEvent,
  ErrorEvent,
  CancelEvent,
  PauseEvent,
  ResumeEvent,
  SuccessEvent,
  UploadEvent,
  UploadProgressEvent,
  FileInfo,
} from "@progress/kendo-angular-upload";
import { ControlService } from '../../../admin/control.service';
import { SecurityService } from '../../../auth/security.service';
import { AppConfig, CONFIG_TOKEN } from '../../../setup/config';
import { environment } from '../../../../environments/environment';

const { apiUrl } = environment;

@Component({
  selector: 'casi-reference-link',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    FontAwesomeModule,
    RouterLink, RouterLinkActive,
    EditorModule,
    CheckboxListComponent,
    InputsModule,
    ButtonsModule,
    NotificationModule,
    LabelModule,
    DropDownsModule,
    DateInputsModule,
    LayoutModule,
    UploadsModule
  ],
  templateUrl: './reference-link.component.html',
  styleUrl: './reference-link.component.scss'
})
export class ReferenceLinkComponent implements OnInit, OnDestroy {

  @Input({ required: true }) referenceLink: ReferenceLink = new ReferenceLink();
  @Input({ required: true }) mode: FormModes = FormModes.NOTSET;

  @Output() onCancel = new EventEmitter<void>();
  @Output() onSave = new EventEmitter<any>();

  public value = '';
  public autoCorrect: boolean = true;

  public uploadSaveUrl: string = "fileupload";

  public hostInfo: CasiControl = new CasiControl();

  form: FormGroup;

  public linkTypeIsDocument: boolean = true;
  public showUrl: boolean = true;

  public hasUploadedFile: boolean = false;

  myRestrictions: FileRestrictions = {
    //allowedExtensions: [".pdf"],
    maxFileSize: 5242880
  };

  private _subs: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private logger: LoggerService,
    private controlDataService: ControlService,
    private securityService: SecurityService,
    @Inject(CONFIG_TOKEN) private config: AppConfig,
  ) {
    this.form = this.fb.group(
      {
        id: [null, []],
        opportunity_id: [null, []],
        description: [null, [Validators.required]],
        url: [null, [Validators.required]],
        document_file_name: [null, []],
        // optionSelected: [null, [Validators.required]],
        linkType: [null, []],
        sort_order: [null, []],
        deleted: [null, []]
      }
    );
  }

  //#region Form properties

  get f() {
    return this.form.controls;
  }
  get description() {
    return this.form.get('description');
  }
  get url() {
    return this.form.get('url');
  }
  get document_file_name() {
    return this.form.get('document_file_name');
  }
  get optionSelected() {
    return this.form.get('optionSelected');
  }
  get linkType() {
    return this.form.get('linkType');
  }
  get sort_order() {
    return this.form.get('sort_order');
  }
  get deleted() {
    return this.form.get('deleted');
  }

  //#endregion

  //#region Ng event handlers

  ngOnInit(): void {
    this.logger.debug('ngOnInit...', this.referenceLink);
    // this.dataService.query();
    // this._subs.push(this.dataService.subscribe(list => {
    //   this.ddData = list;
    // }))
    this.uploadSaveUrl = `${apiUrl}/fileupload?path=reference_link/${this.referenceLink.opportunity_id}`
    this.controlDataService.query();
    this._subs.push(this.controlDataService.subscribe(control => {
      this.logger.debug('ngOnInit:control: ', control);
      this.hostInfo = control;
      // this.uploadSaveUrl = `${control.app_base_url}/fileupload?path=reference_link/${this.referenceLink.opportunity_id}`
      // this.uploadSaveUrl = `${control.app_base_url}/fileupload?path=reference_link/${this.referenceLink.opportunity_id}&token=${this.tokenService.getToken()}`
    }))
    this.form.patchValue(this.referenceLink);
    this._subs.push((this.linkType as AbstractControl).valueChanges.subscribe(lt => {
      this.linkTypeIsDocument = lt === 'Document';
      this.showUrl = lt === 'URL';
      //this.showUrl = (this.referenceLink.url && lt === 'Document') || lt === 'URL';
      //this.logger.debug('radio sub showurl', this.showUrl);
    }));
    this.linkTypeIsDocument = this.referenceLink.linkType === 'Document';
    this.showUrl = (this.referenceLink.url && this.referenceLink.linkType === 'Document') || this.referenceLink.linkType === 'URL';
    this.securityService.requestCsrfToken();
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnInit...');
    this._subs.forEach(s => s.unsubscribe());
  }

  //#endregion

  onSubmit(): void {
    this.logger.debug(`onSubmit...`, this.form.value, this.mode);
    const { name } = this.form.value;
    let toSave = ReferenceLink.create(this.form.value);
    toSave.checkSortOrder();
    this.onSave.emit({
      action: "save",
      data: toSave,
      mode: this.mode
    });
  }

  closeWindow(): void {
    this.logger.debug(`closeWindow...`);
    this.onCancel.emit();
  }

  onRadioChange(args: any): void {
    this.logger.debug(`onRadioChange...`, args);
    this.clear();
  }

  //#region Upload event handlers

  public cancel(e: CancelEvent): void {
    this.logger.debug(`cancel event...`, e);
  }

  public clear(): void {
    this.log("clear event");
    this.hasUploadedFile = false;
    this.referenceLink.document_file_name = undefined;
    this.referenceLink.url = undefined;
    this.form.patchValue({ url: null, document_file_name: null });
    if (this.linkTypeIsDocument)
      this.showUrl = false;
  }

  public complete(): void {
    this.log(`complete event`);
  }

  public error(e: ErrorEvent): void {
    this.log(`error event: ${e.files[0].name}`);
  }

  public blur(): void {
    this.log(`blur event`);
  }

  public focus(): void {
    this.log(`focus event`);
  }

  public pause(e: PauseEvent): void {
    this.log(`pause event ${e.file.name}`);
  }

  public resume(e: ResumeEvent): void {
    this.log(`resume event ${e.file.name}`);
  }

  public remove(e: RemoveEvent): void {
    this.log(`remove event: ${e.files[0].name}`);
  }

  public select(e: SelectEvent): void {
    this.logger.debug(`select event`, e);
    // this.referenceLink.document_file_name = e.files[0].name;
    this.referenceLink.document_file_name = e.files[0].name;
    this.form.patchValue({ document_file_name: e.files[0].name });
    this.hasUploadedFile = true;
  }

  public success(e: SuccessEvent): void {
    let name = e.files[0].name;
    let newId = (e.response as any).body.new_id;
    this.logger.debug(`success event ${name}`, e);
    if (this.mode === FormModes.NEW) {
      this.form.patchValue({ id: newId });
    }
    let docUrl = `${apiUrl}/getfile/${this.referenceLink.opportunity_id}?path=reference_link/${this.referenceLink.opportunity_id}/${newId}/${name}`;
    this.form.patchValue({ url: docUrl });
    this.referenceLink.url = docUrl;
    this.showUrl = true;
  }

  public upload(e: UploadEvent): void {
    this.log(`upload event ${e.files[0].name}`);
    this.securityService.secureUploads(e);
  }

  public uploadProgress(e: UploadProgressEvent): void {
    this.log(`uploadProgress event ${e.percentComplete}`);
  }

  public valueChange(e: FileInfo[]): void {
    this.log(`valueChange event ${e[0].name}`);
  }

  private log(event: string): void {
    this.logger.debug(`log => ${event}`);
  }

  //#endregion

  // public valueChange(value: string): void {
  //   this.logger.debug(`valueChange...`, value);
  //   this.form.patchValue({ html: value });
  // }
}

