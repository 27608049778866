import { Component, Input, NO_ERRORS_SCHEMA, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { LabelModule } from "@progress/kendo-angular-label";
import { LayoutModule } from "@progress/kendo-angular-layout";
import { LoggerService } from "../../shared/logger/logger.service";
import { ShowMoreLessService } from "../show-more-less.service";
import * as uuid from "uuid";
import { stripHtmlTags } from '../../common/utils.lib';

@Component({
  selector: 'casi-show-more-less',
  standalone: true,
  imports: [
    CommonModule,
    InputsModule,
    ButtonsModule,
    LabelModule,
    LayoutModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
  templateUrl: './show-more-less.component.html',
  styleUrl: './show-more-less.component.scss'
})
export class ShowMoreLessComponent implements OnInit, OnDestroy {
  @Input({ required: true }) content: string | undefined;
  @Input() maxLength: number = 100;  // Default maximum length for the short content

  isExpanded: boolean = false;
  showButton: boolean = false;
  private stripped: string = "";
  public id: string;

  constructor(
    private logger: LoggerService,
    private sanitizer: DomSanitizer,
    private dataService: ShowMoreLessService
  ) {
    this.id = uuid.v4();
  }

  get displayContent(): SafeHtml {
    let content = "";
    // if(this.content)
    //   content = this.isExpanded || this.content.length <= this.maxLength
    //     ? this.content
    //     : this.content.substring(0, this.maxLength) + '...';
    // return this.sanitizer.bypassSecurityTrustHtml(content);
    if (this.content)
      content = this.isExpanded || this.content.length <= this.maxLength
        ? this.content
        : this.stripped.substring(0, this.maxLength) + '...';
    return content;
  }

  get buttonText(): string {
    return this.isExpanded ? 'Show Less' : 'Show More';
  }

  ngOnInit(): void {
    // this.logger.debug('ngOnInit...',);
    this.showButton = this.content ? this.content.length > this.maxLength : false;
    // this.stripTags(this.content);
    if (this.content) {
      this.stripped = stripHtmlTags(this.content);
    }
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnInit...');
  }

  toggleContent() {
    this.isExpanded = !this.isExpanded;
  }

  stripTags(html: string | undefined): void {
    this.logger.debug("stripTags: ", html);
    if (html)
      this.dataService.stripTags(html)
        .then(resp => {
          this.logger.debug("stripTags: resp", resp);
          this.stripped = resp;
        })
        .catch(err => {
          this.logger.error('stripTags:error: ', err);
        });
  }



}

