<!--Load the font icons package:-->
<link rel="stylesheet" href="https://unpkg.com/@progress/kendo-font-icons/dist/index.css" />

<kendo-dialog-titlebar>
  <div style="font-size: 18px; line-height: 1.3em;">
    <span class="k-icon k-font-icon k-icon-xl k-i-floppy"></span> Save As
  </div>
</kendo-dialog-titlebar>

<div class="container">
  <div class="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2"
    style="margin-left: auto; margin-right: auto; max-width: 765px;">

    <form [formGroup]="form">
      <fieldset>

        <div class="row">
          <div class="row-nomargin">
            <label class="col-form-label col-10" style="text-align:left; margin: 0; padding-bottom:10px"
              for="filename">Filename</label>
          </div>
          <div class="row-nomargin">

            <input type="filename" id="filename" formControlName="filename" class="form-control">
            <div *ngIf="filename?.invalid" class="alert alert-danger">
              <div *ngIf="filename?.errors?.['required']">Filename is required.</div>
            </div>
          </div>
        </div>

      </fieldset>
    </form>
  </div>

</div>

<kendo-dialog-actions>
  <button kendoButton (click)="onSaveAction()" class="btn btn-primary" [disabled]="!form.valid">
    Save
  </button>
  <button kendoButton (click)="onCancelAction()" class="btn btn-secondary">Cancel</button>
</kendo-dialog-actions>
