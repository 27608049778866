<link rel="stylesheet" href="https://unpkg.com/@progress/kendo-font-icons/dist/index.css" />

<ng-template #windowTitleBar let-win>
  <span class="k-window-title">{{showTitle}}</span>
  <button kendoWindowCloseAction [window]="win"></button>
</ng-template>

<div class="k-overlay" *ngIf="editWinOpen"></div>

<div class="container" my-5>
  <div class="card" #appendTo>
    <div class="card-body no-border">
      <div class="card-title">
        <h3 style="text-align: left;" class="page-header">Manage Users</h3>
      </div>

      <kendo-grid [kendoGridBinding]="gridData" [loading]="dataService.loading" [sort]="sort" [sortable]="true"
        [groupable]="true" [resizable]="true" [filter]="filter" filterable="menu" (filterChange)="filterChange($event)"
        (sortChange)="sortChange($event)" style="height: calc(100vh - 300px);" [pageSize]="pageSize" [pageable]="true">

        <ng-template kendoGridToolbarTemplate position="top">
          <button (click)="showAddWindow(windowTitleBar)" kendoButton class="btn btn-primary">
            <fa-icon icon="circle-plus"></fa-icon> Add
            New User
          </button>
          <!-- <button (click)="onAddUser()" kendoButton class="btn btn-primary">
            <fa-icon icon="circle-plus"></fa-icon> Add
            New User
          </button> -->
        </ng-template>

        <kendo-grid-column title="Edit" [width]="70">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <button (click)="showEditWindow(dataItem, windowTitleBar)" kendoButton class="btn btn-stn-grid">
              <fa-icon icon="edit"></fa-icon>
            </button>
            <!-- <button (click)="onEditClick(dataItem)" kendoButton class="btn btn-stn-grid">
              <fa-icon icon="edit"></fa-icon>
            </button> -->
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="first_name" title="First Name" [width]="150">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
            let-filterService="filterService">
            <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
              [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="last_name" title="Last Name" [width]="150">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
            let-filterService="filterService">
            <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
              [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="email" title="Email" [width]="250">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
            let-filterService="filterService">
            <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
              [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Activate/Deactivate" field="active" [width]="120" [filterable]="false">
          <ng-template kendoGridCellTemplate let-dataItem>
            <input type="checkbox" kendoCheckBox size="large" [checked]="dataItem.active"
              (click)="toggleActive(dataItem)" />
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Delete" [width]="70">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <button (click)="onConfirmDelete(dataItem)" kendoButton class="btn btn-stn-grid">
              <fa-icon icon="trash"></fa-icon>
            </button>
          </ng-template>
        </kendo-grid-column>

        <ng-template kendoPagerTemplate>
          <kendo-pager-info *ngIf="pageSize > 0"></kendo-pager-info>
        </ng-template>
      </kendo-grid>

    </div>
  </div>

</div>
<!--
<kendo-window *ngIf="editWinOpen" (close)="close($event)" [minWidth]="320" [maxWidth]="700" [width]="650">
  <kendo-window-titlebar>
    <span class="k-window-title">{{showTitle}}</span>
    <button kendoWindowCloseAction></button>

  </kendo-window-titlebar>
  <casi-edit-user [user]="currentUser" [selectedRoleIds]="selectedRoleIds" [roles]="rolesData" [mode]="formMode"
    (panelClosed)="close($event)">
  </casi-edit-user>
</kendo-window> -->

<kendo-dialog *ngIf="confirmDeleteUser" title="Please confirm" (close)="onConfirmedClose('cancel')" [minWidth]="250"
  [width]="450">

  <h4>Delete User - <b>{{currentUser.first_name}} {{currentUser.last_name}}</b>?</h4>

  <kendo-dialog-actions>
    <button kendoButton (click)="onConfirmedClose('delete')" themeColor="primary">Delete</button>
    <button kendoButton (click)="onConfirmedClose('cancel')">
      Cancel
    </button>
  </kendo-dialog-actions>
</kendo-dialog>

<kendo-window *ngIf="errorOpened" (close)="errorClose($event)" [minWidth]="320" [maxWidth]="700" [width]="650">
  <kendo-window-titlebar>
    <span class="k-window-title">ERROR UPDATING EMAIL ADDRESS</span>
    <button kendoWindowCloseAction></button>
  </kendo-window-titlebar>
  <div style='text-align: center; margin-top: 20px;'>

    <h4><strong>That email address has already been used in another user account.</strong></h4>

    <div class="controls" style="margin-top: 20px;">
      <a class="btn btn-secondary" style="width:40%;" (click)="errorClose('close')">
        Close and Retry</a>
    </div>


  </div>
</kendo-window>

<casi-error-dialog [show]="showErrorMessage" title="ERROR UPDATING EMAIL ADDRESS"
content="That email address has already been used in another user account."
buttonText="Close and Retry"
[minWidth]="320" [width]="750" (closeForm)="closeErrorMessage()"></casi-error-dialog>

<div kendoWindowContainer></div>
