import { Component, ElementRef, EventEmitter, Input, NO_ERRORS_SCHEMA, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import * as uuid from 'uuid';
import { LoggerService } from "../../shared/logger/logger.service";
import { CommonModule } from "@angular/common";
import { ClickLoggerDirective } from '../../directives/click-logger.directive';

@Component({
  selector: 'casi-switch',
  standalone: true,
  imports: [
    CommonModule,
    ClickLoggerDirective
  ],
  schemas: [NO_ERRORS_SCHEMA],
  templateUrl: './casi-switch.component.html',
  styleUrl: './casi-switch.component.scss'
})
export class CasiSwitchComponent implements OnInit, OnDestroy {

  public id: string;

  @Output() onChange = new EventEmitter<any>();
  @Input() isChecked: boolean = false;
  @Input() label: string = "";
  @Input() refId: string = "Not Set";

  @ViewChild("switchid") element: any;

  constructor(
    private logger: LoggerService
  ) {
    this.id = uuid.v4();
  }

  ngOnInit(): void {
    this.logger.debug('ngOnInit...');
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnInit...');
  }

  public change() {
    this.isChecked = !this.isChecked;
    this.logger.debug('change: value ', this.isChecked);
    this.onChange.emit(this.isChecked);
    this.element?.manualLog(`switch[${this.refId}:${this.label }:${this.isChecked}]`);
  }
}
