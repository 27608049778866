import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonModule, NgClass } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoggerService } from '../../../shared/logger/logger.service';
import { UserDataService } from '../../../users/user-data.service';
import { Role } from '../../../models/models.lib';
import { FormModes } from '../../../common/constants.lib';

@Component({
  selector: 'casi-edit-roles',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    FontAwesomeModule,
    RouterLink, RouterLinkActive,
    NgClass
  ],
  templateUrl: './edit-roles.component.html',
  styleUrl: './edit-roles.component.scss'
})
export class EditRolesComponent implements OnInit, OnDestroy {

  @Input({ required: true }) role: Role = new Role();
  @Input({ required: true }) mode: FormModes = FormModes.NOTSET;

  @Output() panelClosed = new EventEmitter<any>();

  form: FormGroup;

  private _subs: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private logger: LoggerService,
  ) {
    this.form = this.fb.group(
      {
        id: [null, []],
        name: [null, [Validators.required]],
        permission_cnt: [null, []]
      }
    );
  }

  get f() {
    return this.form.controls;
  }
  get name() {
    return this.form.get('name');
  }

  ngOnInit(): void {
    this.logger.debug('ngOnInit...');
    this.form.patchValue(this.role);
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnInit...');
    this._subs.forEach(s => s.unsubscribe());
  }

  onSubmit(): void {
    this.logger.debug(`onSubmit...`, this.form.value, this.mode);
    const { name } = this.form.value;
    this.panelClosed.emit({
      action: "save",
      data: this.form.value,
      mode: this.mode
    });
    // return this.dataService.updatePassword({
    //   id: this.userId,
    //   password
    // })
    //   .then(done => {
    //     this.logger.debug('changePassword: done: ', done);
    //     this.panelClosed.emit();
    //   })
    //   .catch(err => {
    //     this.logger.error('changePassword:error: ', err);
    //   });
  }

  closeWindow(): void {
    this.logger.debug(`closeWindow...`);
    this.panelClosed.emit({
      action: "cancel"
    });
  }

}
