import {Component, EventEmitter, Input, NO_ERRORS_SCHEMA, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {CommonModule} from "@angular/common";
import {InputsModule} from "@progress/kendo-angular-inputs";
import {ButtonsModule} from "@progress/kendo-angular-buttons";
import {LabelModule} from "@progress/kendo-angular-label";
import {LayoutModule} from "@progress/kendo-angular-layout";
import {ReviewOpportunity} from "../../models/models.lib";
import {LoggerService} from "../../shared/logger/logger.service";
import {Subscription} from "rxjs";
import {OpportunityReviewsService} from "../opportunity-reviews.service";
import {FaIconLibrary, FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {EditorImageUploadComponent} from "../../common-components/editor-image-upload/editor-image-upload.component";
import {EditorComponent, EditorModule} from "@progress/kendo-angular-editor";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {specificNumbersValidator} from "../../directives/casi.validators";
import {SafeHtmlPipe} from "../../pipes/safe-html.pipe";

@Component({
  selector: 'casi-integrate-review',
  standalone: true,
  imports: [
    CommonModule,
    InputsModule,
    ButtonsModule,
    LabelModule,
    LayoutModule,
    FontAwesomeModule,
    FormsModule, ReactiveFormsModule,
    EditorImageUploadComponent,
    EditorModule,
    SafeHtmlPipe
  ],
  schemas: [NO_ERRORS_SCHEMA],
  templateUrl: './integrate-review.component.html',
  styleUrl: './integrate-review.component.scss'
})
export class IntegrateReviewComponent  implements OnInit, OnDestroy {

  // @Input({ required: true }) review: ReviewOpportunity = new ReviewOpportunity();
  @Input({ required: true }) reviewId?: number = undefined;
  @Output() onCancel = new EventEmitter<void>();
  @Output() onSave = new EventEmitter<any>();

  @ViewChild("uploadDialog") public dialog: EditorImageUploadComponent = <EditorImageUploadComponent>{};

  @Output() @ViewChild("full_description_editor") private full_description_editor: EditorComponent = <EditorComponent>{};
  @Output() @ViewChild("service_providers_editor") private service_providers_editor: EditorComponent = <EditorComponent>{};
  @Output() @ViewChild("elegibility_text_editor") private elegibility_text_editor: EditorComponent = <EditorComponent>{};
  @Output() @ViewChild("benefits_editor") private benefits_editor: EditorComponent = <EditorComponent>{};
  @Output() @ViewChild("negatives_editor") private negatives_editor: EditorComponent = <EditorComponent>{};
  @Output() @ViewChild("risks_editor") private risks_editor: EditorComponent = <EditorComponent>{};
  @Output() @ViewChild("costs_editor") private costs_editor: EditorComponent = <EditorComponent>{};
  @Output() @ViewChild("attribution_editor") private attribution_editor: EditorComponent = <EditorComponent>{};
  @Output() @ViewChild("potential_financial_benefits_editor") private potential_financial_benefits_editor: EditorComponent = <EditorComponent>{};

  public editor: EditorComponent = <EditorComponent>{};
  public fieldName : string = null as any;

  private _subs: Subscription[] = [];
  public review: ReviewOpportunity = new ReviewOpportunity();

  form: FormGroup;

  constructor(
    private logger: LoggerService,
    private dataService: OpportunityReviewsService,
    public faIcons: FaIconLibrary,
    private fb: FormBuilder
  ) {
    faIcons.addIcons(faArrowLeft);

    this.form = this.fb.group(
      {
        id: [null, []],
        title: [null, [Validators.required]],
        short_description: [null, [Validators.required]],
        full_description: [null, []],
        organisation_name: [null, []],
        organisation_contact_email: [null, [Validators.email]],
        organisation_url: [null, []],
        service_providers: [null, []],
        elegibility_text: [null, []],
        benefits: [null, []],
        negatives: [null, []],
        risks: [null, []],
        costs: [null, []],
        attribution: [null, []],
        potential_financial_benefits: [null, []],
      }
    );
  }

  get f() {
    return this.form.controls;
  }
  get title() {
    return this.form.get('title');
  }
  get short_description() {
    return this.form.get('short_description');
  }
  get full_description() {
    return this.form.get('full_description');
  }
  get organisation_name() {
    return this.form.get('organisation_name');
  }
  get organisation_contact_email() {
    return this.form.get('organisation_contact_email');
  }
  get organisation_url() {
    return this.form.get('organisation_url');
  }
  get service_providers() {
    return this.form.get('service_providers');
  }
  get elegibility_text() {
    return this.form.get('elegibility_text');
  }
  get benefits() {
    return this.form.get('benefits');
  }
  get negatives() {
    return this.form.get('negatives');
  }
  get risks() {
    return this.form.get('risks');
  }
  get costs() {
    return this.form.get('costs');
  }
  get attribution() {
    return this.form.get('attribution');
  }
  get potential_financial_benefits() {
    return this.form.get('potential_financial_benefits');
  }


  //#region Ng Handlers

  ngOnInit(): void {
    this.logger.debug('ngOnInit...', this.reviewId);

    if(this.reviewId)
      this.dataService.getById(this.reviewId)
        .then(data => {
          this.review = ReviewOpportunity.create(data[0]);
          this.form.patchValue(this.review);
        })
        .catch(e => this.logger.error("error fetching review", e));

  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnDestroy...');
    this._subs.forEach(s => s.unsubscribe());
  }

  //#endregion

  //#region view methods
  replace(text: string) {
    this.logger.debug('replace ', text);
   (this as any).review[text]= (this as any).review[`opportunity_review_${text}`];
    let updatedValue: any = {};
   updatedValue[text] = (this as any).review[`opportunity_review_${text}`];
    this.form.patchValue(updatedValue);
  }

  public openImageUpload(fieldName: string) {
    this.fieldName = fieldName;
    this.editor = (this as any)[`${fieldName}_editor`];
    setTimeout(() => this.dialog.open(), 200);
  }

  public onImageLoaded(args: any): void {
    this.logger.debug('onImageLoaded...', args);
    const {data } = args;
    this.form.patchValue({ id: data });
    this.review.id = data;
  }

  onSubmit(): void {
    let toSave = { ...this.review, ...this.form.value };
    this.logger.debug(`onSubmit...`, toSave);
    this.onSave.emit({
      action: "integrate",
      data: toSave
    });
  }

  closeWindow(): void {
    this.logger.debug(`closeWindow...`);
    this.onCancel.emit();
  }
  //#endregion

}
