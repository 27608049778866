<ng-template #template>
  <casi-help-card [infoId]="infoId" (onClose)="closeMe()"></casi-help-card>
  <!-- <div class="card casi-popup" (popupAnchorViewportLeave)="closeMe()">
    <div class="card-header">
      <div class="row" style="align-items: center;">
        <div class="col-9">
          {{title}}
        </div>
        <div class="col-2">
          <button mat-button type="button" color="primary" (click)="closeMe()"> close</button>
        </div>
      </div>
    </div>
    <div class="card-body overflow-auto">
      <div class="card-text" [innerHTML]="helpContent"></div>
      <button mat-button type="button" color="primary" (click)="closeMe()"> Close</button>
    </div>
  </div> -->
</ng-template>

@if(!showText) {
<!-- <button mat-icon-button aria-label="Information icon" (click)="toggle()" style="height: unset; width: unset;" #anchor>
    <mat-icon [style]="iconStyle">info_outlined_i</mat-icon>
  </button> kendoButton-->

<button mat-icon-button aria-label="Information icon" (click)="togglePopup(anchor._elementRef, template)"
  style="height: unset; width: unset;" #anchor>
  <mat-icon [style]="iconStyle">info_outlined_i</mat-icon>
</button>
}
@else {
<!-- <button class="btn btn-default" (click)="toggle()" style="text-decoration: underline">{{text}}</button> -->
<button kendoButton class="btn btn-default" (click)="togglePopup(anchor.element, template)"
  style="text-decoration: underline" #anchor>{{text}}</button>
}
<!-- <kendo-popup #popup [anchor]="anchor?.nativeElement" *ngIf="show">
  <div class="card" style="width: 20rem;">
    <div class="card-body">
      <div class="card-title">{{title}}</div>
      <div class="card-text" [innerHTML]="helpContent"></div>
    </div>
  </div>
</kendo-popup> -->
