import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Observable, Subscription } from 'rxjs';
import { LoggerService } from '../shared/logger/logger.service';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { AuthTokenService } from '../auth/auth-token.service';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faCoffee, faPoo, faLock, faEnvelope, faUser,
  faBars
} from '@fortawesome/free-solid-svg-icons';
import { User } from '../auth/me.lib';
import { CommonModule, JsonPipe } from '@angular/common';
import { MenuItem, IMenuItem } from '../common/obj.lib';
import { AppEnities } from '../common/constants.lib';

import _ from 'lodash';
import { ClickLoggerDirective } from '../directives/click-logger.directive';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink, RouterLinkActive, FontAwesomeModule,
    JsonPipe,
    ClickLoggerDirective
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit, OnDestroy {
  faLock = faLock;

  // isLoggedIn$: Observable<boolean> = new Observable<any>();
  // me$: Observable<User | null> = new Observable<User | null>(); //null;
  me: User | null = null;
  isLoggedIn: boolean = this.authTokenService.loggedIn;

  menu: any[] = [];
  private _subs: Subscription[] = [];
  private previousStatus: boolean = this.authTokenService.isLoggedIn;

  private fullMenu = [
    MenuItem.create({ id: 0, title: "My User Details", url: "/user-profile" }),
    MenuItem.create({ id: 1, title: "Edit Company Parameters", url: "/admin-edit-params", entity: AppEnities.COMPANY_PARAMS }),
    MenuItem.create({ id: 2, title: "Manage Users", url: "/user-list", entity: AppEnities.USER }),
    MenuItem.create({ id: 3, title: "Manage Roles", url: "/admin-roles", entity: AppEnities.ROLES }),
    MenuItem.create({ id: 4, title: "Edit License Agreement", url: "/admin-edit-policy", entity: AppEnities.LICENSE }),
    MenuItem.create({ id: 5, title: "Manage Enquiries", url: "/admin-enquires-list", entity: AppEnities.ENQUIRIES }),
    // { title: "Manage FAQ", url: "base.editFaq", entity: FAQ },
    //  { title: "Manage Permits & Permissions", url: "base.permit", entity: PERMIT },
    //  { title: "Manage Personnel", url: "base.personnel", entity: PERSONNEL },
    MenuItem.create({ id: 6, title: "Manage Help Information", url: "/admin-help-info", entity: AppEnities.HELP }),
    MenuItem.create({ id: 7, title: "Manage Co-benefit Type", url: "/cobenefit-types", entity: AppEnities.CO_BENEFIT_TYPE }),
    MenuItem.create({ id: 8, title: "Manage Opportunities", url: "/opportunites-view", entity: AppEnities.OPPORTUNITIES }),
    MenuItem.create({ id: 9, title: "Manage Resources", url: "/resources-list", entity: AppEnities.RESOURCE }),
    MenuItem.create({ id: 10, title: "Review Opportunities", url: "/review-opportunities", entity: AppEnities.REVIEW }),


    //  { title: "Manage Blogs", url: "base.blogAdmin" },

  ];

  constructor(public authService: AuthService,
    public authTokenService: AuthTokenService,
    private logger: LoggerService,
    private router: Router,
    library: FaIconLibrary,
  ) {
    library.addIcons(faLock, faEnvelope, faUser, faBars);
  }

  ngOnInit(): void {
    this.logger.debug('ngOnInit...');
    this._subs.push(this.authTokenService.user$.subscribe(u => {
      this.me = u;
      this.buildMenu();
      // this.logger.debug('ngOnInit:me:', this.me);
    }));
    this._subs.push(this.authTokenService.isLoggedIn$.subscribe(status => {
      this.logger.debug('status changed: **>> ', status, this.previousStatus);
      this.isLoggedIn = status;
    }));
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnDestroy...');
    this._subs.forEach(s => s.unsubscribe());
  }

  signOut(): void {
    this.logger.debug('signOut...');
    this.authTokenService.removeToken();
  };

  private buildMenu(): void {
    this.logger.debug('buildMenu:fullMenu', this.fullMenu);
    this.logger.debug('buildMenu:accessPermissions: ', this.authTokenService.getAccessClaims());
    this.menu = _.sortBy(this.fullMenu, "title").filter(m => {
      return !(m as IMenuItem).entity || this.authTokenService.canIAccess(m.entity as string);
    });

    this.logger.debug('buildMenu:menu', this.menu);
  }

}
