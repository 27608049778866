<div class="container-fluid" style="overflow: auto;">

  <div>

    <div class="mx-auto" style="max-width: 950px; margin-top: 40px; height: calc(100vh - 310px);">
      <h2>Reset Password</h2>

      <div class="bg-light p-3 rounded-lg m-3 rounded-2">

        @if(hasUser) {
        <h3 style="margin-bottom: 20px;">Email or reset token incorrect</h3>
        <h4 style="margin-bottom: 20px;">Please check the link and try again</h4>
        <a class="btn btn-default" routerLink="/home" routerLinkActive="active" style="margin-left: 95px;">Go to
          Home</a>
        }
        @else {

        <form [formGroup]="form" (ngSubmit)="onSubmit()" class="k-form-inline">

          <fieldset>

            <div class="k-form-field" style="margin-bottom:8px;">
              <div style="margin-bottom: 20px;">
                <h4><b>Enter your new password</b></h4>
              </div>
            </div>

            <div class="k-form-field">
              <label for="password" class="form-label col col-md-3">New Password</label>
              <div class="col col-md-8">
                <input type="password" id="password" formControlName="password" class="form-control">
                <div *ngIf="f['password'].touched && f['password'].invalid" class="alert alert-danger">
                  <div *ngIf="f['password'].errors && f['password'].errors['required']">Password is required.</div>
                  <div *ngIf="password?.errors?.['minlength']">
                    Name must be at least 6 characters long.
                  </div>
                </div>
              </div>
            </div>

            <div class="k-form-field">
              <label class="form-label col col-md-3" for="confirmedPassword">Confirm Password</label>
              <div class="col col-md-8">
                <input type="password" formControlName="confirmedPassword" class="form-control" name="confirmedPassword"
                  id="confirmedPassword" autocomplete="new-password">
                <div *ngIf="confirmedPassword?.invalid && (confirmedPassword?.dirty || confirmedPassword?.touched)"
                  class="alert alert-danger">
                  <div *ngIf="confirmedPassword?.errors?.['required']">Confirm password is required.</div>
                </div>
                <div *ngIf="form.errors?.['confirmedPasswordValidator'] && (form.touched || form.dirty)"
                  class="alert alert-danger">
                  Passwords must match.</div>
              </div>
            </div>

            <div class="d-grid d-flex justify-content-end">
              <button type="button" class="btn btn-primary me-2" type="submit" [disabled]="form.invalid">
                Save</button>
              <button type="button" class="btn btn-secondary" (click)="cancelPassword()"> Cancel</button>
            </div>

          </fieldset>

        </form>
        }
      </div>

    </div>
  </div>

</div>

<kendo-dialog *ngIf="updateSuccess" title="Please confirm" (close)="reloadUser()" [minWidth]="250" [width]="450">

  <h4>Password Successfully Updated!</h4>

  <kendo-dialog-actions>
    <button kendoButton (click)="reloadUser()" themeColor="primary">Continue</button>
  </kendo-dialog-actions>
</kendo-dialog>
