import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { LoggerService } from '../shared/logger/logger.service';

@Injectable({
  providedIn: 'root'
})
export class EnquiresService {

  constructor(
    private apiService: ApiService
  ) { }


  getEnquiries() {
    return this.apiService.getHttpCallAsPromise('findenquiries');
  }

  createEnquiry(body: any): Promise<any> {
    return this.apiService.postHttpCallAsPromise('enquiries', body, 'createEnquiry');
  }

  updateEnquiry(id: number, completed: boolean) {
    return this.apiService.putHttpCallAsPromise(`enquiries/${id}`, { completed: completed }, 'updateEnquiry');
  }

  getEnquiryAddresses() {
    return this.apiService.getHttpCallAsPromise('findenquiryaddresses', 'getEnquiryAddresses');
  }

  createEnquiryAddress(address:any) {
    return this.apiService.postHttpCallAsPromise('enquiryaddresses', address, 'createEnquiryAddress');
  }

  deleteEnquiryAddress(id:number) {
    return this.apiService.deleteHttpCallAsPromise(`enquiryaddresses/${id}`, 'deleteEnquiryAddress');
  }

  sendEmails(enquiry: any) {
    return this.apiService.postHttpCallAsPromise('email/send', { messageInfo: enquiry }, 'sendEmails');
  }


}
