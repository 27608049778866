<casi-editor-image-upload [editor]="editor" [fieldName]="fieldName" [recordId]="review.id" tableName="opportunity"
                          (onLoad)="onImageLoaded($event)" #uploadDialog></casi-editor-image-upload>
<div class="row" style="margin-bottom: 30px; font-weight: bold;">
  <div style="width: 120px;">Please Note:</div>
  <div class="col-6">Fields updated are identified by the visibility of a ‘Replace’ button for the field.<br>
    Content changes within the field are not highlighted.
  </div>
</div>
<div class="row" style="margin-bottom: 30px;">
  <div  class="col-5"><h4 style="text-decoration: underline">Current Content</h4></div>
  <div  class="col-2"></div>
  <div class="col-5"><h4 style="text-decoration: underline">Suggested Content</h4></div>
</div>
<form [formGroup]="form" style="height: calc(100vh - 220px); overflow-y: scroll; padding: 0 20px">
  <fieldset>


    <div class="row" [class]="review.opportunity_review_title == review.title ? 'review-input' : 'changed-review-input'">
      <div class="col-5">
        <label class="form-label ps-0" style="margin-bottom: 15px">Title</label>
        <div style="padding-top: 20px">
          <textarea class="form-control" formControlName="title" id="title" style="height: 70%"></textarea>
          <div *ngIf="title?.invalid" class="alert alert-danger">
            <div *ngIf="title?.errors?.['required']">Title is required.</div>
          </div>
        </div>
      </div>
      <div class="col-2" style="text-align: center; padding-top: 20px;">
        <button type="button" class="btn btn-secondary" (click)="replace('title')"
                *ngIf="review.opportunity_review_title != review.title"> <fa-icon icon="arrow-left"></fa-icon> Replace</button>
      </div>
      <div  class="col-5">
        <label class="form-label" style="margin-bottom: 30px"> </label>
        <div style="padding-top: 20px">{{review.opportunity_review_title}}</div>
      </div>
    </div>

    <div class="row" style="margin-bottom: 20px; padding: 10px"
         [class]="review.opportunity_review_short_description == review.short_description ?  'review-input' : 'changed-review-input'">
      <div class="col-5">
        <label class="form-label ps-0" style="margin-bottom: 15px">Short Description</label>
        <div style="padding-top: 20px">
          <textarea class="form-control" formControlName="short_description" id="short_description" required style="height: 82%"></textarea>
          <div *ngIf="short_description?.invalid" class="alert alert-danger">
            <div *ngIf="short_description?.errors?.['required']">Short Description is required.</div>
          </div>
        </div>
      </div>
      <div class="col-2" style="text-align: center; padding-top: 20px;">
        <button type="button" class="btn btn-secondary" (click)="replace('short_description')"
                *ngIf="review.opportunity_review_short_description != review.short_description"> <fa-icon icon="arrow-left"></fa-icon> Replace</button>
      </div>
      <div  class="col-5">
        <label class="form-label" style="margin-bottom: 30px"></label>
        <div style="padding-top: 20px">{{review.opportunity_review_short_description}}</div>
      </div>
    </div>

    <div class="row" [class]="review.opportunity_review_full_description == review.full_description ? 'review-input' : 'changed-review-input'">
      <div class="col-5">
        <label class="form-label ps-0" style="margin-bottom: 15px">Full Description</label>
        <div style="padding-top: 20px">
          <kendo-editor formControlName="full_description" style="min-height: 300px; height: 90%"  #full_description_editor>
            <kendo-toolbar>
<!--              <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>

              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                <kendo-toolbar-button text="Upload Image"
                                      (click)="openImageUpload('full_description')"></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
              <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
<!--              <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
<!--              <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>-->
<!--              <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>-->
            </kendo-toolbar>
          </kendo-editor>
        </div>
      </div>
      <div class="col-2" style="text-align: center; padding-top: 20px;">
        <button type="button" class="btn btn-secondary" (click)="replace('full_description')"
                *ngIf="review.opportunity_review_full_description != review.full_description"> <fa-icon icon="arrow-left"></fa-icon> Replace</button>
      </div>
      <div  class="col-5">
        <label class="form-label" style="margin-bottom: 30px"></label>
        <div [innerHTML]="review.opportunityReviewFullDescription | safeHtml" style="padding-top: 20px"></div>
      </div>
    </div>

    <div class="row" [class]="review.opportunity_review_organisation_name == review.organisation_name ? 'review-input' : 'changed-review-input'">
      <div class="col-5">
        <label class="form-label ps-0" style="margin-bottom: 15px">Organisation Name</label>
        <div style="padding-top: 20px">
          <input type="text" id="organisation_name" formControlName="organisation_name" class="form-control">
        </div>
      </div>
      <div class="col-2" style="text-align: center; padding-top: 20px;">
        <button type="button" class="btn btn-secondary" (click)="replace('organisation_name')"
                *ngIf="review.opportunity_review_organisation_name != review.organisation_name"> <fa-icon icon="arrow-left"></fa-icon> Replace</button>
      </div>
      <div  class="col-5">
        <label class="form-label" style="margin-bottom: 30px"></label>
        <div style="padding-top: 20px">{{review.opportunity_review_organisation_name}} </div>
      </div>
    </div>

    <div class="row" [class]="review.opportunity_review_organisation_contact_email == review.organisation_contact_email ? 'review-input' : 'changed-review-input'">
      <div class="col-5">
        <label class="form-label ps-0" style="margin-bottom: 15px">Organisation Contact Email</label>
        <div style="padding-top: 20px">
          <input type="text" id="organisation_contact_email" formControlName="organisation_contact_email"
                 class="form-control">
          <div *ngIf="organisation_contact_email?.invalid" class="alert alert-danger">
            <div *ngIf="organisation_contact_email?.errors && organisation_contact_email?.errors?.['email']">Please, enter valid email address.</div>
          </div>
        </div>
      </div>
      <div class="col-2" style="text-align: center; padding-top: 20px;">
        <button type="button" class="btn btn-secondary" (click)="replace('organisation_contact_email')"
                *ngIf="review.opportunity_review_organisation_contact_email != review.organisation_contact_email"> <fa-icon icon="arrow-left"></fa-icon> Replace</button>
      </div>
      <div  class="col-5">
        <label class="form-label" style="margin-bottom: 30px"></label>
        <div style="padding-top: 20px">{{review.opportunity_review_organisation_contact_email}} </div>
      </div>
    </div>

    <div class="row" [class]="review.opportunity_review_organisation_url == review.organisation_url ? 'review-input' : 'changed-review-input'">
      <div class="col-5">
        <label class="form-label ps-0" style="margin-bottom: 15px">Organisation URL</label>
        <div style="padding-top: 20px">
          <textarea id="organisation_url" formControlName="organisation_url" class="form-control"></textarea>
        </div>

      </div>
      <div class="col-2" style="text-align: center; padding-top: 20px;">
        <button type="button" class="btn btn-secondary" (click)="replace('organisation_url')"
                *ngIf="review.opportunity_review_organisation_url != review.organisation_url"> <fa-icon icon="arrow-left"></fa-icon> Replace</button>
      </div>
      <div  class="col-5">
        <label class="form-label" style="margin-bottom: 30px"></label>
        <div style="padding-top: 20px">{{review.opportunity_review_organisation_url}} </div>
      </div>
    </div>

    <div class="row" [class]="review.opportunity_review_service_providers == review.service_providers ? 'review-input' : 'changed-review-input'">
      <div class="col-5">
        <label class="form-label ps-0" style="margin-bottom: 15px">Service Providers</label>
        <div style="padding-top: 20px">
          <kendo-editor formControlName="service_providers" style="min-height: 300px; height: 90%" #service_providers_editor>
            <kendo-toolbar>
<!--              <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>

              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                <kendo-toolbar-button text="Upload Image"
                                      (click)="openImageUpload('service_providers')"></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
              <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
<!--              <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
<!--              <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>-->
<!--              <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>-->
            </kendo-toolbar>
          </kendo-editor>
        </div>
      </div>
      <div class="col-2" style="text-align: center; padding-top: 20px;">
        <button type="button" class="btn btn-secondary" (click)="replace('service_providers')"
                *ngIf="review.opportunity_review_service_providers != review.service_providers"> <fa-icon icon="arrow-left"></fa-icon> Replace</button>
      </div>
      <div  class="col-5">
        <label class="form-label" style="margin-bottom: 30px"></label>
        <div [innerHTML]="review.opportunityReviewServiceProviders | safeHtml" style="padding-top: 20px"></div>
      </div>
    </div>

    <div class="row" [class]="review.opportunity_review_elegibility_text == review.elegibility_text ? 'review-input' : 'changed-review-input'">
      <div class="col-5">
        <label class="form-label ps-0" style="margin-bottom: 15px">Eligibility Description</label>
        <div  style="padding-top: 20px">
          <kendo-editor formControlName="elegibility_text" style="min-height: 300px; height: 90%" #elegibility_text_editor>
            <kendo-toolbar>
<!--              <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>

              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                <kendo-toolbar-button text="Upload Image"
                                      (click)="openImageUpload('elegibility_text')"></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
              <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
<!--              <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
<!--              <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>-->
<!--              <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>-->
            </kendo-toolbar>
          </kendo-editor>
        </div>
      </div>
      <div class="col-2" style="text-align: center; padding-top: 20px;">
        <button type="button" class="btn btn-secondary" (click)="replace('elegibility_text')"
                *ngIf="review.opportunity_review_elegibility_text != review.elegibility_text"> <fa-icon icon="arrow-left"></fa-icon> Replace</button>
      </div>
      <div  class="col-5">
        <label class="form-label" style="margin-bottom: 30px"></label>
        <div [innerHTML]="review.opportunityReviewElegibility | safeHtml" style="padding-top: 20px"></div>
      </div>
    </div>

    <div class="row" [class]="review.opportunity_review_benefits == review.benefits ? 'review-input' : 'changed-review-input'">
      <div class="col-5">
        <label class="form-label ps-0" style="margin-bottom: 15px">Benefits</label>
        <div style="padding-top: 20px">
          <kendo-editor formControlName="benefits" style="min-height: 300px; height: 90%" #benefits_editor>
            <kendo-toolbar>
<!--              <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>

              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                <kendo-toolbar-button text="Upload Image" (click)="openImageUpload('benefits')"></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
              <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
<!--              <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
<!--              <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>-->
<!--              <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>-->
            </kendo-toolbar>
          </kendo-editor>
        </div>
      </div>
      <div class="col-2" style="text-align: center; padding-top: 20px;">
        <button type="button" class="btn btn-secondary" (click)="replace('benefits')"
                *ngIf="review.opportunity_review_benefits != review.benefits"> <fa-icon icon="arrow-left"></fa-icon> Replace</button>
      </div>
      <div  class="col-5">
        <label class="form-label" style="margin-bottom: 30px"></label>
        <div [innerHTML]="review.opportunityReviewBenefit | safeHtml" style="padding-top: 20px"></div>
      </div>
    </div>

    <div class="row" [class]="review.opportunity_review_negatives == review.negatives ? 'review-input' : 'changed-review-input'">
      <div class="col-5">
        <label class="form-label ps-0" style="margin-bottom: 15px">Negatives</label>
        <div  style="padding-top: 20px">
          <kendo-editor formControlName="negatives" style="min-height: 300px; height: 90%" #negatives_editor>
            <kendo-toolbar>
<!--              <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>

              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                <kendo-toolbar-button text="Upload Image" (click)="openImageUpload('negatives')"></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
              <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
<!--              <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
<!--              <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>-->
<!--              <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>-->
            </kendo-toolbar>
          </kendo-editor>
        </div>
      </div>
      <div class="col-2" style="text-align: center; padding-top: 20px;">
        <button type="button" class="btn btn-secondary" (click)="replace('negatives')"
                *ngIf="review.opportunity_review_negatives != review.negatives"> <fa-icon icon="arrow-left"></fa-icon> Replace</button>
      </div>
      <div  class="col-5">
        <label class="form-label" style="margin-bottom: 30px"></label>
        <div [innerHTML]="review.opportunityReviewNegatives | safeHtml" style="padding-top: 20px"></div>
      </div>
    </div>

    <div class="row" [class]="review.opportunity_review_risks == review.risks ? 'review-input' : 'changed-review-input'">
      <div class="col-5">
        <label class="form-label ps-0" style="margin-bottom: 15px">Risks</label>
        <div style="padding-top: 20px">
          <kendo-editor formControlName="risks" style="min-height: 300px; height: 90%" #risks_editor>
            <kendo-toolbar>
<!--              <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>

              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                <kendo-toolbar-button text="Upload Image" (click)="openImageUpload('risks')"></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
              <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
<!--              <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
<!--              <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>-->
<!--              <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>-->
            </kendo-toolbar>
          </kendo-editor>
        </div>
      </div>
      <div class="col-2" style="text-align: center; padding-top: 20px;">
        <button type="button" class="btn btn-secondary" (click)="replace('risks')"
                *ngIf="review.opportunity_review_risks != review.risks"> <fa-icon icon="arrow-left"></fa-icon> Replace</button>
      </div>
      <div  class="col-5">
        <label class="form-label" style="margin-bottom: 30px"></label>
        <div [innerHTML]="review.opportunityReviewRisks | safeHtml" style="padding-top: 20px"></div>
      </div>
    </div>

    <div class="row" [class]="review.opportunity_review_costs == review.costs ? 'review-input' : 'changed-review-input'">
      <div class="col-5">
        <label class="form-label ps-0" style="margin-bottom: 15px">Costs</label>
        <div style="padding-top: 20px">
          <kendo-editor formControlName="costs" style="min-height: 300px; height: 90%" #costs_editor>
            <kendo-toolbar>
<!--              <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>

              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                <kendo-toolbar-button text="Upload Image" (click)="openImageUpload('costs')"></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
              <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
<!--              <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
<!--              <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>-->
<!--              <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>-->
            </kendo-toolbar>
          </kendo-editor>
        </div>
      </div>
      <div class="col-2" style="text-align: center; padding-top: 20px;">
        <button type="button" class="btn btn-secondary" (click)="replace('costs')"
                *ngIf="review.opportunity_review_costs != review.costs"> <fa-icon icon="arrow-left"></fa-icon> Replace</button>
      </div>
      <div  class="col-5">
        <label class="form-label" style="margin-bottom: 30px"></label>
        <div [innerHTML]="review.opportunityReviewCosts | safeHtml" style="padding-top: 20px"></div>
      </div>
    </div>

    <div class="row" [class]="review.opportunity_review_attribution == review.attribution ? 'review-input' : 'changed-review-input'">
      <div class="col-5">
        <label class="form-label ps-0" style="margin-bottom: 15px">Attribution</label>
        <div  style="padding-top: 20px">
          <kendo-editor formControlName="attribution" style="min-height: 300px; height: 90%" #attribution_editor>
            <kendo-toolbar>
<!--              <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>

              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                <kendo-toolbar-button text="Upload Image" (click)="openImageUpload('attribution')"></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
              <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
<!--              <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
<!--              <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>-->
<!--              <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>-->
            </kendo-toolbar>
          </kendo-editor>
        </div>
      </div>
      <div class="col-2" style="text-align: center; padding-top: 20px;">
        <button type="button" class="btn btn-secondary" (click)="replace('attribution')"
                *ngIf="review.opportunity_review_attribution != review.attribution"> <fa-icon icon="arrow-left"></fa-icon> Replace</button>
      </div>
      <div  class="col-5">
        <label class="form-label" style="margin-bottom: 30px"></label>
        <div [innerHTML]="review.opportunityReviewAttribution | safeHtml" style="padding-top: 20px"></div>
      </div>
    </div>

    <div class="row" [class]="review.opportunity_review_potential_financial_benefits == review.potential_financial_benefits ? 'review-input' : 'changed-review-input'">
      <div class="col-5">
        <label class="form-label ps-0" style="margin-bottom: 15px">Potential Financial Benefits</label>
        <div  style="padding-top: 20px">
          <kendo-editor formControlName="potential_financial_benefits" style="min-height: 300px; height: 90%" #potential_financial_benefits_editor>
            <kendo-toolbar>
<!--              <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>

              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                <kendo-toolbar-button text="Upload Image"
                                      (click)="openImageUpload('potential_financial_benefits')"></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
              <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
              </kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
<!--              <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
<!--              <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>-->
<!--              <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>-->
            </kendo-toolbar>
          </kendo-editor>
        </div>
      </div>
      <div class="col-2" style="text-align: center; padding-top: 20px;">
        <button type="button" class="btn btn-secondary" (click)="replace('potential_financial_benefits')"
                *ngIf="review.opportunity_review_potential_financial_benefits != review.potential_financial_benefits"><fa-icon icon="arrow-left"></fa-icon> Replace</button>
      </div>
      <div  class="col-5">
        <label class="form-label" style="margin-bottom: 30px"></label>
        <div [innerHTML]="review.opportunityReviewPotentialFinancialBenefits | safeHtml" style="padding-top: 20px"></div>
      </div>
    </div>

    <div class="d-grid d-md-flex justify-content-md-end">
      <button type="button" class="btn btn-primary me-md-2" (click)="onSubmit()" >Save</button>

      <button type="button" class="btn btn-secondary" (click)="closeWindow()"> Cancel</button>
    </div>

  </fieldset>
</form>
