import { Component, EventEmitter, Input, NO_ERRORS_SCHEMA, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoggerService } from '../../shared/logger/logger.service';
import { FormModes } from '../../common/constants.lib';
import { User, Role } from '../../models/models.lib';
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { LabelModule } from "@progress/kendo-angular-label";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { PDFViewerDownloadEvent, PDFViewerModule, PDFViewerTool } from '@progress/kendo-angular-pdfviewer';

@Component({
  selector: 'casi-pdf-viewer',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    FontAwesomeModule,
    RouterLink, RouterLinkActive,
    DropDownsModule,
    LabelModule, InputsModule,
    ButtonsModule,
    PDFViewerModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
  templateUrl: './casi-pdf-viewer.component.html',
  styleUrl: './casi-pdf-viewer.component.scss'
})
export class CasiPdfViewerComponent implements OnInit, OnDestroy {

  //#region Properties

  private _url: string | undefined;

  @Input({ required: true })
  set url(value: string) {
    this._url = value;
  }
  get url(): string | undefined {
    return this._url
  };
  _filename: string = 'Download.pdf';
  @Input({ required: true }) set filename(val: string) {
    this._filename = val;
  }
  get filename(): string {
    return this._filename;
  }

  public casiSaveOptions = {};
  public casiFilename: string = 'Wally.pdf';

  public tools: PDFViewerTool[] = [
    "pager",
    "spacer",
    "zoomInOut",
    "zoom",
    "selection",
    "spacer",
    "search",
    // "open",
    "download",
    "print",
  ];

  constructor(
    private logger: LoggerService
  ) {
  }

  //#endregion

  //#region Ng Events

  ngOnInit(): void {
    this.logger.debug('ngOnInit...', this.url);
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnInit...');
  }

  //#endregion

  public onDownload(event: PDFViewerDownloadEvent): void {
    this.logger.debug(`download event`, event);
    // this.openSaveAsPrompt(event.fileName);
  }

  closeWindow(): void {
    this.logger.debug(`closeWindow...`);
    // this.closeForm.emit();
  }

}
