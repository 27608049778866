<form [formGroup]="form" (ngSubmit)="onSubmit()" class="k-form-inline">

  <div class="casi-alert-info" style="font-size: 14px;">
    <!-- <div class="k-form-field" style="margin: 0">
      <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-12 no-padding" ><b>Note: </b></div>
      <div class="col-xl-11 col-lg-11 col-md-11 col-sm-11 col-12 no-padding">To add images, use the 'Insert Image' button below. New images can be uploaded. Pasting doesn't work.</div>
      <div class="col-xl-11 col-lg-11 col-md-11 col-sm-11 offset-xl-1 offset-lg-1 offset-md-1 offset-sm-1 col-xs-12 offset-0 no-padding">Images will be inserted at actual size unless width is set.</div>
    </div> -->
    <div class="k-form-field row" style="margin: 15px 0 0 0;">
      <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-12 no-padding"><b>Note: </b></div>
      <div class="col-xl-11 col-lg-11 col-md-11 col-sm-11 col-12 no-padding">The field names are for descriptive
        purposes.</div>
      <div
        class="col-xl-11 col-lg-11 col-md-11 col-sm-11 offset-xl-1 offset-lg-1 offset-md-1 offset-sm-1 col-xs-12 offset-0 no-padding">
        Creating a new item adds it to the database but a developer will still need to
        add the icon/link to the new information on the relevant page</div>
    </div>

  </div>

  <fieldset>
    <div class="k-form-field">
      <label for="site_section" class="form-label col-3 k-label">Site Section</label>
      <div class="col-8">
        <input type="text" id="site_section" formControlName="site_section" class="form-control">
        <div *ngIf="site_section?.invalid" class="alert alert-danger">

          <div *ngIf="site_section?.errors?.['required']">
            Site section is required.
          </div>
        </div>
      </div>

    </div>


    <div class="k-form-field">
      <label for="element_name" class="form-label col-3 k-label">Element Name</label>
      <div class="col-8">
        <input type="text" class="form-control" formControlName="element_name" id="lastname">
        <div *ngIf="element_name?.invalid" class="alert alert-danger">

          <div *ngIf="element_name?.errors?.['required']">
            Element name is required.
          </div>
        </div>
      </div>
    </div>

    <div class="k-form-field">
      <label for="heading" class="form-label col-3 k-label">Heading</label>
      <div class="col-8">
        <input type="text" class="form-control" formControlName="heading" id="lastname">
        <div *ngIf="heading?.invalid" class="alert alert-danger">

          <div *ngIf="heading?.errors?.['required']">
            Heading is required.
          </div>
        </div>
      </div>

    </div>

    <kendo-editor [value]="value" style="height: 400px;" (valueChange)="valueChange($event)">
      <kendo-toolbar>
        <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
        <kendo-toolbar-buttongroup>
          <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
          <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
          <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
        </kendo-toolbar-buttongroup>
        <kendo-toolbar-buttongroup>
          <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
          <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
          <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
        </kendo-toolbar-buttongroup>
        <kendo-toolbar-buttongroup>
          <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
          <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
          <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
        </kendo-toolbar-buttongroup>

        <kendo-toolbar-buttongroup>
          <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
<!--          <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button>-->
        </kendo-toolbar-buttongroup>
        <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
      </kendo-toolbar>
    </kendo-editor>

    <div class="form-group">
      <div class=" col-12">
        <input hidden type="text" class="form-control" formControlName="html" id="html">
      </div>
      <div *ngIf="html?.invalid" class="alert alert-danger">

        <div *ngIf="html?.errors?.['required']">
          You must write a description.
        </div>
      </div>
    </div>

    <div class="d-grid d-md-flex justify-content-md-end">
      <button type="button" class="btn btn-primary me-md-2" type="submit" [disabled]="form.invalid"> Save</button>
      <button type="button" class="btn btn-secondary" (click)="closeWindow()"> Cancel</button>
    </div>

  </fieldset>
</form>
