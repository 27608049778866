
export const EMAIL_REGEX = '/^[a-zA-Z0-9]+[a-zA-Z0-9._+-]+@[a-zA-Z0-9-_]+\.[a-zA-Z.]{2,}$/';

export const PHONE_REGEX = '^[0-9()+ ]+$';

export const GOOGLE_MAP_API_KEY = "https://maps.googleapis.com/maps/api/js?key=AIzaSyCLO8aNtTDLg-Nzbt4OUrIsBwfPM1Aucuo&callback=initMap";


export enum MessageStates {
  Before = 'before',
  InProgress = 'inprogress',
  After = 'after',
  Error = 'error'
};

export enum AppEnities {
  COMPANY_PARAMS = "Company Parameters",
  ENQUIRIES = "Manage Enquiries",
  LICENSE = "License Agreement",
  ROLES = "Manage Roles",
  USER = "Manage Users",
  HELP = "Help",
  CO_BENEFIT_TYPE = "Manage Co-Benefit Type",
  OPPORTUNITIES = "Opportunities",
  OPPORTUNITY_CO_BENEFITS = "Manage Opportunity Co-benefits",
  OPPORTUNITY_PRACTICES = "Manage Opportunity Practices",
  OPPORTUNITY_REFERENCE_LINK = "Manage Opportunity Reference Links",
  RESOURCE= "Manage Resource",
  REVIEW = "Review Opportunity"
};

export enum FormModes {
  NOTSET = 'Not Set',
  NEW = 'New',
  EDIT = 'Edit'
};

export interface IDocType {
  value: string;
  text: string;
};

export const docTypes: Array<IDocType> = [
  <IDocType>{ value: 'terms', text: 'Customer Licence Agreement' },
  <IDocType>{ value: 'disclaimer', text: 'Terms of Use' },
  <IDocType>{ value: 'privacy', text: 'Privacy Policy' }
];

export enum CasiCloseOptions {
  Saved = 'saved',
  Cancelled = 'cancelled',
  Ok = 'ok',
  Closed = 'closed'
};
