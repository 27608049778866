<div class="container-fluid">
  <div class="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2"
    style="margin-left: auto; margin-right: auto; max-width: 765px;">

    <h3 style="text-align: left;">My User Details</h3>

    <div class="bg-light p-3 rounded-lg rounded-2">
      <!--        <div class="card-title">-->
      <!--          <h3 style="text-align: left;">My User Details</h3>-->
      <!--        </div>-->
      <!-- <fa-icon [icon]="['fat', 'memo-circle-info']"></fa-icon> -->
      <form [formGroup]="form" (ngSubmit)="onSubmit()" class="k-form-inline">

        <fieldset>

          <div class="k-form-field">
            <label for="firstName" class="form-label col-4 col-md-3 ">First name</label>
            <div class="col-8">
              <input type="text" class="form-control" formControlName="firstName" id="firstName"
                placeholder="First Name">
            </div>
          </div>

          <div class="k-form-field" style="margin-bottom:8px;">
            <label for="lastName" class="form-label col-4 col-md-3 ">Last name</label>
            <div class="col-8">
              <input type="text" class="form-control" formControlName="lastName" id="lastname" placeholder="Last Name">
            </div>
          </div>

          <div class="k-form-field" style="margin-bottom:8px;">
            <label for="email" class="form-label col-4 col-md-3">Email</label>
            <div class="col-8">
              <input type="text" class="form-control" id="email" formControlName="email" class="form-control">
              <div *ngIf="email?.invalid && (email?.dirty || email?.touched)" class="alert alert-danger">

                <div *ngIf="email?.errors?.['required']">
                  Email is required.
                </div>
                <div *ngIf="email?.errors && email?.errors?.['email']">Please, enter valid email address.</div>

              </div>
            </div>
          </div>

          <div class="row" style="margin-bottom:8px;">
            <div class="form-label col-4 col-md-3"></div>
            <div class="col-8">
              <a class="btn btn-primary" style="float:right; margin-bottom:0px;" (click)="open()">
                Change Password</a>
            </div>
          </div>

          <div class="k-form-field">
            <div class="form-label col-4 col-md-3"></div>
            <div class="form-check col-8" style="margin-top:5px; margin-left: 25px;">
              <input type="checkbox" size="large" kendoCheckBox class="form-check-input"
                formControlName="researchConsent" id="researchConsent" class="form-check-input" />
              <label for="researchConsent" class="form-check-label"><b>Send me news about this project</b></label>
            </div>
          </div>


          <div class="mt-4 text-right" style="float: inline-end;">
            <button class="btn btn-primary m-1" type="submit" [disabled]="form.invalid"> Save</button>
            <button type="button" class="btn btn-secondary" (click)="cancelProfile()"> Cancel</button>
          </div>

        </fieldset>
      </form>
    </div>
  </div>

</div>

<kendo-window *ngIf="opened" (close)="close($event)" [style]="{'min-width': '310px', 'max-width': '95%'}" [width]="400">
  <kendo-window-titlebar>
    <span class="k-window-title">Change Password</span>
  </kendo-window-titlebar>
  <casi-change-password [userId]="user.id" (panelClosed)="close($event)"></casi-change-password>
</kendo-window>

<kendo-window *ngIf="errorOpened" (close)="errorClose('cancel')" [style]="{'min-width': '250px', 'max-width': '95%'}"
  [width]="600">
  <kendo-window-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      <fa-icon icon="triangle-exclamation"></fa-icon> Error Updating Email Address
    </div>
  </kendo-window-titlebar>

  <div style='text-align: center; margin-top: 20px;'>
    <h4><strong>That email address has already been used in another user account.</strong></h4>
  </div>

  <kendo-dialog-actions>
    <button kendoButton (click)="errorClose('yes')">
      Close and retry
    </button>
  </kendo-dialog-actions>
</kendo-window>
