import { Component } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { CommonModule } from '@angular/common';
import { LoggerService } from '../../../shared/logger/logger.service';
import { DiscoverOpportunitiesService } from '../../../discoverOpportunities/discover-opportunities.service';
import { ClickLoggerDirective } from '../../../directives/click-logger.directive';
import {AuthTokenService} from "../../../auth/auth-token.service";

@Component({
  selector: 'casi-icon-block',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink, RouterLinkActive,
    ClickLoggerDirective
  ],
  templateUrl: './icon-block.component.html',
  styleUrl: './icon-block.component.scss'
})
export class IconBlockComponent {

  private isLoggedIn: boolean = this.authTokenService.loggedIn;

  constructor(
    public authTokenService: AuthTokenService,
    private logger: LoggerService,
    private router: Router,
    private discoverService: DiscoverOpportunitiesService
  ) { }

  public discoverOpportunity(item: string, ids: any): void {
    this.logger.debug('discoverOpportunity: ', item);

    if(!this.isLoggedIn)
      return;

    if (ids && ids.length > 0)
      this.discoverService.setIds(ids ? ids : []);
    this.discoverService.setFilterBy(item || "");
    this.router.navigate(['/discover-opportunities']);
  }

}
