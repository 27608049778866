import { Component, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { LoggerService } from '../../shared/logger/logger.service';
import { AuthTokenService } from '../../auth/auth-token.service';
import { Subscription } from 'rxjs';
import { User } from '../../auth/me.lib';
import { HelpButtonComponent } from '../../admin/help-info/help-button/help-button.component';
import { Router } from "@angular/router";
import { DiscoverOpportunityComponent } from '../shared/discover-opportunity/discover-opportunity.component';
import { SearchAustraliaLinkComponent } from '../shared/search-australia-link/search-australia-link.component';
import {DiscoverOpportunitiesService} from "../../discoverOpportunities/discover-opportunities.service";
import { IconBlockComponent } from '../shared/icon-block/icon-block.component';

@Component({
  selector: 'app-logged-in-content',
  standalone: true,
  imports: [
    HelpButtonComponent,
    DiscoverOpportunityComponent,
    SearchAustraliaLinkComponent,
    IconBlockComponent
  ],
  templateUrl: './logged-in-content.component.html',
  styleUrl: './logged-in-content.component.scss'
})
export class LoggedInContentComponent implements OnInit, OnDestroy, AfterViewInit {

  loggedIn: boolean = this.authTokenService.loggedIn;
  me: User | null = null;

  private _subs: Subscription[] = [];

  constructor(
    private logger: LoggerService,
    private authTokenService: AuthTokenService,
    private router: Router,
    private discoverService: DiscoverOpportunitiesService
  ) { }

  ngOnDestroy(): void {
    this.logger.debug('ngOnDestroy...');
    this._subs.forEach(s => s.unsubscribe());
  }

  ngOnInit(): void {
    this.logger.debug('ngOnInit...');
    this._subs.push(this.authTokenService.isLoggedIn$.subscribe(status => {
      this.logger.debug('ngOnInit:status: ', status);
      this.loggedIn = status;
    }));
    this.me = this.authTokenService.getClaims();
  }

  ngAfterViewInit(): void {
    setTimeout(() => window.scrollTo({top: 0, left: 0, behavior: "instant"}));
  }

  public discoverOpportunity(item:string, ids: any[]) : void {
    this.logger.debug('discoverOpportunity: ', item);
    if(ids && ids.length > 0)
      this.discoverService.setIds(ids ? ids : []);
      this.discoverService.setFilterBy(item || "");
    this.router.navigate(['/discover-opportunities']);
  }
}
