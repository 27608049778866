<div class="container-fluid" style="padding: 40px; background-color: #eee;">
  <div class="row">

    <div class="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2"
      style="margin-left: auto; margin-right: auto; max-width: 765px;">
      <!-- <h3 style="text-align: left;">Contact Us</h3> -->
      <div style="margin-bottom: 100px;">
<!--        <p>Message State: {{messageState}}</p>-->
        <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
          @if(messageState===MessageStates.Before){
          <fieldset>



              <div class="row">
                <!-- <div class="col-3"></div> -->
                <div class="col-12" style="text-align: center;">
                  <h3 style="margin-bottom: auto">QUESTIONS/COMMENTS</h3>
                  <h4> GET IN TOUCH! WE'D LOVE TO HEAR FROM YOU.</h4>
                </div>
              </div>

              <div class="form-group">
                <label for="firstName" class="form-label">First name</label>
                <div class=" col-12">
                  <input type="text" class="form-control" formControlName="firstName" id="firstName"
                    placeholder="First Name">
                </div>
              </div>

              <div class="form-group">
                <label for="lastName" class="form-label">Last name</label>
                <div class=" col-12">
                  <input type="text" class="form-control" formControlName="lastName" id="lastname"
                    placeholder="Last Name">
                </div>
              </div>

              <div class="form-group">
                <label for="email" class="form-label">Email</label>
                <input type="text" class="form-control" id="email" formControlName="email" class="form-control">
                <div *ngIf="email?.invalid && (email?.dirty || email?.touched)" class="alert alert-danger">

                  <div *ngIf="email?.errors?.['required']">
                    Email address is required.
                  </div>
                  <div *ngIf="email?.errors && email?.errors?.['email']">Please, enter valid email address.</div>

                </div>
              </div>

              <div class="form-group">
                <label for="phone" class="form-label">Phone</label>
                <div class=" col-12">
                  <input type="text" class="form-control" name="phone" formControlName="phone" id="phone"
                    placeholder="Phone Number">
                  <div *ngIf="phone?.invalid && (phone?.dirty || phone?.touched)" class="alert alert-danger">
                    <div *ngIf="phone?.errors?.['invalidPhoneValidator']">Invalid Phone Number</div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="message" class="form-label">Message</label>
                <div class=" col-12">
                  <textarea rows="8" class="form-control" formControlName="message" id="message"
                    placeholder="Your Enquiry*"></textarea>
                  <div *ngIf="message?.invalid && (message?.dirty || message?.touched)" class="alert alert-danger">

                    <div *ngIf="message?.errors?.['required']">
                      You must enter a message.
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <!-- <div class="col-3"></div> -->
                <div class=" col-12">
                  <button class="btn btn-primary" type="submit" [disabled]="contactForm.invalid">
                    <fa-icon icon="envelope"></fa-icon> SEND</button>
                </div>
              </div>



          </fieldset>
          }
        </form>

        @if(messageState === MessageStates.After){
        <div>
          <div class="message-sent">
            <img src="assets/images/envelope.png" style="margin-bottom: 30px;" />
            <h2>Message Sent!</h2>
            <br><br>
            <h4>Thank you for your enquiry. We will get back to you soon.</h4>
            <br><br>
            <button type="button" class="btn btn-link" (click)="resetForm()"> Send another message.</button>

            <!-- <a href ng-click="resetForm()" style="text-decoration: underline;">Send another message.</a> -->
          </div>
        </div>
        }

        @if(messageState===MessageStates.InProgress){
        <div>
          <div class="message-sent">
            <i class="fa fa-spinner fa-pulse fa-4x" aria-hidden="true"></i>
            <h2>Sending Message...</h2>
          </div>
        </div>
        }

        @if(messageState===MessageStates.Error){
        <div>
          <div class="message-sent">
            <h2 style="margin-bottom: 30px;">An error occurred sending the email</h2>
            <h4 style="margin-bottom: 30px;">Please try again later</h4>
            <a class="btn btn-stn" href="/">Go to homepage</a>
          </div>
        </div>
        }

      </div>
    </div>

  </div>
</div>
