import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {EditorImageUploadComponent} from "../../common-components/editor-image-upload/editor-image-upload.component";
import {EditorComponent, EditorModule} from "@progress/kendo-angular-editor";
import {CasiDateRange} from "../../common/obj.lib";
import {DateInputsModule, FormatSettings} from "@progress/kendo-angular-dateinputs";
import {ReviewOpportunity} from "../../models/models.lib";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {LoggerService} from "../../shared/logger/logger.service";
import {specificNumbersValidator} from "../../directives/casi.validators";
import moment from "moment";
import {CommonModule} from "@angular/common";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {CheckboxListComponent} from "../../common-components/checkbox-list/checkbox-list.component";
import {InputsModule} from "@progress/kendo-angular-inputs";
import {ButtonsModule} from "@progress/kendo-angular-buttons";
import {NotificationModule} from "@progress/kendo-angular-notification";
import {LabelModule} from "@progress/kendo-angular-label";
import {DropDownsModule} from "@progress/kendo-angular-dropdowns";
import {LayoutModule} from "@progress/kendo-angular-layout";
import {HelpButtonComponent} from "../../admin/help-info/help-button/help-button.component";
import {HelpPopoverComponent} from "../../admin/help-info/help-popover/help-popover.component";
import {SafeHtmlPipe} from "../../pipes/safe-html.pipe";

@Component({
  selector: 'casi-edit-review',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    FontAwesomeModule,
    RouterLink, RouterLinkActive,
    EditorModule,
    CheckboxListComponent,
    InputsModule,
    ButtonsModule,
    NotificationModule,
    LabelModule,
    DropDownsModule,
    DateInputsModule,
    LayoutModule,
    HelpButtonComponent,
    HelpPopoverComponent,
    EditorImageUploadComponent,
    SafeHtmlPipe
  ],
  templateUrl: './edit-review.component.html',
  styleUrl: './edit-review.component.scss'
})
export class EditReviewComponent  implements OnInit, OnDestroy {

  @Input({ required: true }) review: ReviewOpportunity = new ReviewOpportunity();

  @Output() onCancel = new EventEmitter<void>();
  @Output() onSave = new EventEmitter<any>();

  @ViewChild("uploadDialog") public dialog: EditorImageUploadComponent = <EditorImageUploadComponent>{};

  @Output() @ViewChild("opportunity_review_full_description_editor") private opportunity_review_full_description_editor: EditorComponent = <EditorComponent>{};
  @Output() @ViewChild("opportunity_review_service_providers_editor") private opportunity_review_service_providers_editor: EditorComponent = <EditorComponent>{};
  @Output() @ViewChild("opportunity_review_elegibility_text_editor") private opportunity_review_elegibility_text_editor: EditorComponent = <EditorComponent>{};
  @Output() @ViewChild("opportunity_review_benefits_editor") private opportunity_review_benefits_editor: EditorComponent = <EditorComponent>{};
  @Output() @ViewChild("opportunity_review_negatives_editor") private opportunity_review_negatives_editor: EditorComponent = <EditorComponent>{};
  @Output() @ViewChild("opportunity_review_risks_editor") private opportunity_review_risks_editor: EditorComponent = <EditorComponent>{};
  @Output() @ViewChild("opportunity_review_costs_editor") private opportunity_review_costs_editor: EditorComponent = <EditorComponent>{};
  @Output() @ViewChild("opportunity_review_attribution_editor") private opportunity_review_attribution_editor: EditorComponent = <EditorComponent>{};
  @Output() @ViewChild("opportunity_review_potential_financial_benefits_editor") private opportunity_review_potential_financial_benefits_editor: EditorComponent = <EditorComponent>{};

  public editor: EditorComponent = <EditorComponent>{};
  public fieldName : string = null as any;

  form: FormGroup;

  private _subs: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private logger: LoggerService,
  ) {
    this.form = this.fb.group(
      {
        id: [null, []],
        opportunity_review_title: [null, [Validators.required]],
        opportunity_review_short_description: [null, [Validators.required]],
        opportunity_review_full_description: [null, []],
        opportunity_review_organisation_name: [null, []],
        opportunity_review_organisation_contact_email: [null, [Validators.email]],
        opportunity_review_organisation_url: [null, []],
        opportunity_review_service_providers: [null, []],
        opportunity_review_elegibility_text: [null, []],
        opportunity_review_benefits: [null, []],
        opportunity_review_negatives: [null, []],
        opportunity_review_risks: [null, []],
        opportunity_review_costs: [null, []],
        opportunity_review_attribution: [null, []],
        opportunity_review_potential_financial_benefits: [null, []],
      }
    );
  }

  get f() {
    return this.form.controls;
  }
  get opportunity_review_title() {
    return this.form.get('opportunity_review_title');
  }
  get opportunity_review_short_description() {
    return this.form.get('opportunity_review_short_description');
  }
  get opportunity_review_organisation_name() {
    return this.form.get('opportunity_review_organisation_name');
  }
  get opportunity_review_organisation_contact_email() {
    return this.form.get('opportunity_review_organisation_contact_email');
  }
  get opportunity_review_organisation_url() {
    return this.form.get('opportunity_review_organisation_url');
  }
  get opportunity_review_service_providers() {
    return this.form.get('opportunity_review_service_providers');
  }
  get opportunity_review_elegibility_text() {
    return this.form.get('opportunity_review_elegibility_text');
  }
  get opportunity_review_benefits() {
    return this.form.get('opportunity_review_benefits');
  }
  get opportunity_review_negatives() {
    return this.form.get('opportunity_review_negatives');
  }
  get opportunity_review_risks() {
    return this.form.get('opportunity_review_risks');
  }
  get opportunity_review_costs() {
    return this.form.get('opportunity_review_costs');
  }
  get attribution() {
    return this.form.get('opportunity_review_attribution');
  }
  get opportunity_review_potential_financial_benefits() {
    return this.form.get('opportunity_review_potential_financial_benefits');
  }


  //#endregion

  //#region Ng Handlers

  ngOnInit(): void {
    this.logger.debug('ngOnInit...', this.review);
    this.form.patchValue(this.review);
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnInit...');
    this._subs.forEach(s => s.unsubscribe());
  }

  //#endregion

//#region Form Handlers

  public openImageUpload(fieldName: string) {
    this.fieldName = fieldName;
    this.editor = (this as any)[`${fieldName}_editor`];
    setTimeout(() => this.dialog.open(), 200);
  }

  public onImageLoaded(args: any): void {
    this.logger.debug('onImageLoaded...', args);
    const {data } = args;
    this.form.patchValue({ id: data });
    this.review.opportunity_review_id = data;
  }

  onSubmit(): void {
    this.logger.debug(`onSubmit...`, this.form.value);
    // this.opportunity.setFundingDates(this.fundingRange);
    let toSave = { ...this.review, ...this.form.value };
    // const { start, end } = this.fundingRange;
    // toSave.opportunity_review_funding_scheme_start = start ? moment(start).format("YYYY-MM-DD") :  null;
    // toSave.opportunity_review_funding_scheme_end = end ? moment(end).format("YYYY-MM-DD") : null;
    this.logger.debug(`onSubmit:toSave: `, toSave);

    this.onSave.emit({
      action: "save",
      data: toSave
    });
  }

  closeWindow(): void {
    this.logger.debug(`closeWindow...`);
    this.onCancel.emit();
  }

  //#endregion

}
