import { AppEnities } from './constants.lib';
import _ from 'lodash';

export class UserEnquiry {
  static create(args: any) {
    const {
      phone, message,
      firstName, lastName,
      email, id
    } = args;
    return new UserEnquiry(
      email, message, id,
      firstName, lastName,
      phone
    );
  };

  constructor(
    public email: string,
    public message: string,
    public id?: number,
    public firstName?: string,
    public lastName?: string,
    public phone?: string,) { }


  forSubmit(): any {
    return {
      message: this.message,
      first_name: this.firstName || null,
      last_name: this.lastName || null,
      email: this.email,
      phone: this.phone || null,
      user_id: this.id || null
    };
  }
};

export interface IMenuItem {
  id: number,
  title: string,
  url: string,
  entity?: AppEnities
};

export class MenuItem implements IMenuItem {
  static create(args: any) {
    const {
      id,
      title, url, entity
    } = args;
    return new MenuItem(id, title, url, entity);
  }

  constructor(
    public id: number,
    public title: string,
    public url: string,
    public entity?: AppEnities
  ) { }
};

export interface ICasiDateRange {
  start: any;
  end: any;
};

export class CasiDateRange implements ICasiDateRange {
  /**
   * Assumes that the start and date are valid date strings.
   *
   * @param start
   * @param end
   * @returns
   */
  static create(start?: string, end?: string) {
    if (start && end) {
      return new CasiDateRange(new Date(start), new Date(end));
    } else if (start && !end) {
      return new CasiDateRange(new Date(start), null);
    } else if (!start && end) {
      return new CasiDateRange(null, new Date(end));
    } else {
      return new CasiDateRange(null, null);
    }
  }
  constructor(
    public start: any,
    public end: any
  ) { }
};
