import {
  Component,
  NO_ERRORS_SCHEMA,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
  Inject,
  Injectable,
  Output
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive } from '@angular/router';
import {
  faTriangleExclamation,
  faPlusCircle, faAddressCard,
  faEdit, faTrash, faBan, faDownload
} from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { NotificationService, NotificationModule } from "@progress/kendo-angular-notification";
import { LoggerService } from '../../shared/logger/logger.service';
import { AppEnities } from '../../common/constants.lib';
import { MeService } from '../../auth/me.service';
import { CasiControlWithParams } from '../../models/models.lib';
import { isNotNull } from '../../common/utils.lib';
import { ControlService } from '../control.service';
import {EditorComponent, EditorModule} from '@progress/kendo-angular-editor';
import { CheckboxListComponent } from '../../common-components/checkbox-list/checkbox-list.component';
import { LabelModule } from '@progress/kendo-angular-label';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { HelpButtonComponent } from '../help-info/help-button/help-button.component';
import { UploadsModule } from '@progress/kendo-angular-upload';
import {
  SelectEvent,
  RemoveEvent,
  ErrorEvent,
  CancelEvent,
  PauseEvent,
  ResumeEvent,
  SuccessEvent,
  UploadEvent,
  UploadProgressEvent,
  FileInfo,
} from "@progress/kendo-angular-upload";
import { AlertService } from '../../commonServices/alert.service';
import { AppConfig, CONFIG_TOKEN } from '../../setup/config';
import { ApiService } from '../../api/api.service';
import { SecurityService } from '../../auth/security.service';
import { environment } from '../../../environments/environment';
import {EditorImageUploadComponent} from "../../common-components/editor-image-upload/editor-image-upload.component";

const { apiUrl } = environment;

const { OPPORTUNITIES } = AppEnities;

@Component({
  selector: 'app-control-params',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    FontAwesomeModule,
    RouterLink, RouterLinkActive,
    EditorModule,
    CheckboxListComponent,
    InputsModule,
    ButtonsModule,
    NotificationModule,
    LabelModule,
    DropDownsModule,
    DateInputsModule,
    LayoutModule,
    HelpButtonComponent,
    UploadsModule,
    EditorImageUploadComponent
  ],
  schemas: [NO_ERRORS_SCHEMA],
  templateUrl: './control-params.component.html',
  styleUrl: './control-params.component.scss'
})
@Injectable({
  providedIn: 'root'
})
export class ControlParamsComponent implements OnInit, OnDestroy {

  @ViewChild("appendTo", { read: ViewContainerRef }) appendTo: ViewContainerRef | undefined;

  @ViewChild("uploadDialog") public dialog: EditorImageUploadComponent = <EditorImageUploadComponent>{};
  @Output() @ViewChild("review_opportunity_email_editor") private review_opportunity_email_editor: EditorComponent = <EditorComponent>{};

  public editor: EditorComponent = <EditorComponent>{};
  public fieldName : string = null as any;

  public form: FormGroup;

  public uploadSaveUrlBusiness: string = "businessLogoUpload";
  public uploadSaveUrlProduct: string = 'productLogoUpload';
  public uploadRemoveUrl: string = 'logoRemove';

  public hasBusinessLogo: boolean = true;
  public hasProductLogo: boolean = true;

  public logoProduct: string | undefined;
  public logoBusiness: string | undefined;

  public controlParams: CasiControlWithParams = new CasiControlWithParams();
  private _subs: Subscription[] = [];

  constructor(private logger: LoggerService,
    public faIcons: FaIconLibrary,
    public dataService: ControlService,
    private notificationService: NotificationService,
    private me: MeService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private alertService: AlertService,
    @Inject(CONFIG_TOKEN) private config: AppConfig,
    private securityService: SecurityService
  ) {
    faIcons.addIcons(
      faTriangleExclamation,
      faPlusCircle, faAddressCard, faEdit,
      faTrash,
      faBan, faDownload
    );
    this.form = this.fb.group(
      {
        id: [null, []],
        db_major_version: [null, [Validators.required]],
        db_minor_version: [null, [Validators.required]],
        app_base_url: [null, []],
        param_company_name: [null, []],
        param_entity_name: [null, []],
        param_company_address: [null, []],
        param_abn: [null, []],
        param_acn: [null, []],
        param_primary_email: [null, [Validators.email, Validators.required]],
        param_additional_email: [null, [Validators.email]],
        param_website_url: [null, []],
        param_primary_contact_number: [null, []],
        param_additional_contact_number: [null, []],
        param_primary_image: [null, []],
        param_additional_image: [null, []],
        param_product_name: [null, []],
        param_reply_to_email: [null, [Validators.email]],
        param_reset_password_bcc: [null, [Validators.email]],
        review_opportunity_email: [null, []]
      }
    );
  }

  //#region Form properties

  get f() {
    return this.form.controls;
  }
  get app_base_url() {
    return this.form.get('app_base_url');
  }
  get param_company_name() {
    return this.form.get('param_company_name');
  }
  get param_entity_name() {
    return this.form.get('param_entity_name');
  }
  get param_company_address() {
    return this.form.get('param_company_address');
  }
  get param_abn() {
    return this.form.get('param_abn');
  }
  get param_acn() {
    return this.form.get('param_acn');
  }
  get param_primary_email() {
    return this.form.get('param_primary_email');
  }
  get param_additional_email() {
    return this.form.get('param_additional_email');
  }
  get param_website_url() {
    return this.form.get('param_website_url');
  }
  get param_primary_contact_number() {
    return this.form.get('param_primary_contact_number');
  }
  get param_additional_contact_number() {
    return this.form.get('param_additional_contact_number');
  }
  get param_primary_image() {
    return this.form.get('param_primary_image');
  }
  get param_additional_image() {
    return this.form.get('param_additional_image');
  }
  get param_product_name() {
    return this.form.get('param_product_name');
  }
  get param_reply_to_email() {
    return this.form.get('param_reply_to_email');
  }
  get param_reset_password_bcc() {
    return this.form.get('param_reset_password_bcc');
  }

  get review_opportunity_email() {
    return this.form.get('review_opportunity_email');
  }

  //#endregion

  //#region Ng Handlers

  ngOnInit(): void {
    this.logger.debug('ngOnInit...');
    const { can_create, can_update, can_destroy } = this.me.allICanDo(OPPORTUNITIES);

    this.logger.debug('ngOnInit:permissions: ', can_create, can_update, can_destroy);

    this._subs.push(this.route.data.subscribe(({
      control
    }) => {
      this.logger.debug('ngOnInit:resolves: ', control);
      this.controlParams = control;
      this.form.patchValue(this.controlParams);
      this.hasBusinessLogo = isNotNull(control.param_primary_image);
      this.hasProductLogo = isNotNull(control.param_additional_image);
      this.logoBusiness = control.param_primary_image;
      this.logoProduct = control.param_additional_image;
      this.uploadSaveUrlBusiness = `${apiUrl}/businessLogoUpload`;
      this.uploadSaveUrlProduct = `${apiUrl}/productLogoUpload`;
      this.uploadRemoveUrl = `${apiUrl}/logoRemove`; //not implemented
    }));
    // this.dataService.query();
    // this._subs.push(this.dataService.subscribe(items => {
    //   this.logger.debug('ngOnInit:items: ', items);
    //   this.rawData = items;
    //   this.loadData();
    // }));
    this.logger.debug('CONFIG?', this.config);
    this.securityService.requestCsrfToken();
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnDestroy...');
    this._subs.forEach(s => s.unsubscribe());
  }

  //#endregion

  onSubmit(): void {
    this.logger.debug(`onSubmit...`, this.form.value);
    this.dataService.update(this.form.value)
      .then(data => {
        this.logger.debug('onSubmit:data: ', data);
        this.notify('Control Parameters Saved');
        this.router.navigate(['logged-in']);
      })
      .catch(err => {
        this.logger.error('onSubmit:error: ', err);
      });

    // this.opportunity.setLists();
    // this.opportunity.setFundingDates(this.fundingRange);
    // let toSave = { ...this.opportunity, ...this.form.value };
    // this.logger.debug(`onSubmit:toSave: `, toSave);

    // this.onSave.emit({
    //   action: "save",
    //   data: toSave,
    //   mode: this.mode
    // });
    // this.router.navigate(['logged-in']);

  }

  closeWindow(): void {
    this.logger.debug(`closeWindow...`);
    this.router.navigate(['logged-in']);
  }

  public openImageUpload(fieldName: string) {
    this.fieldName = fieldName;
    this.editor = (this as any)[`${fieldName}_editor`];
    setTimeout(() => this.dialog.open(), 200);
  }

  public onImageLoaded(args: any): void {
    this.logger.debug('onImageLoaded...', args);
    const {data } = args;
    this.form.patchValue({ id: data });
    this.controlParams.id = data;
  }

  //#region Upload event handlers

  //#region Business Logo

  public cancel(e: CancelEvent): void {
    this.logger.debug(`cancel event...`, e);
  }

  public clear(): void {
    this.log("clear event");
  }

  public complete(): void {
    this.log(`complete event`);
  }

  public error(e: ErrorEvent): void {
    this.log(`error event: ${e.files[0].name}`);
  }

  public blur(): void {
    this.log(`blur event`);
  }

  public focus(): void {
    this.log(`focus event`);
  }

  public pause(e: PauseEvent): void {
    this.log(`pause event ${e.file.name}`);
  }

  public resume(e: ResumeEvent): void {
    this.log(`resume event ${e.file.name}`);
  }

  public remove(e: RemoveEvent): void {
    this.log(`remove event: ${e.files[0].name}`);
  }

  public select(e: SelectEvent): void {
    this.logger.debug(`select event`, e);
  }

  public success(e: SuccessEvent): void {
    if (e.files) {
      let name = e.files[0].name;
      let img = (e.response as any).body.img;
      this.logger.debug(`success event ${name}`, e);
      this.form.patchValue({ param_primary_image: img })
      this.logoBusiness = img;
      this.hasBusinessLogo = true;
    } else {
      this.form.patchValue({ param_primary_image: undefined });
      this.logoBusiness = undefined;
      this.hasBusinessLogo = false;
    }
  }

  public upload(e: UploadEvent):void {
    this.log(`upload event ${e.files[0].name}`);
    this.securityService.secureUploads(e, 'Logo uploading');
  }

  public uploadProgress(e: UploadProgressEvent): void {
    this.log(`uploadProgress event ${e.percentComplete}`);
  }

  public valueChange(e: FileInfo[]): void {
    this.log(`valueChange event ${e[0].name}`);
  }

  //#endregion

  //#region Product Logo

  public cancelProduct(e: CancelEvent): void {
    this.log(`cancelProduct event ${e.files[0].name}`);
  }

  public clearProduct(): void {
    this.log("clearProduct event");
  }

  public completeProduct(): void {
    this.log(`completeProduct event`);
  }

  public errorProduct(e: ErrorEvent): void {
    this.log(`errorProduct event: ${e.files[0].name}`);
  }

  public blurProduct(): void {
    this.log(`blurProduct event`);
  }

  public focusProduct(): void {
    this.log(`focusProduct event`);
  }

  public pauseProduct(e: PauseEvent): void {
    this.log(`pauseProduct event ${e.file.name}`);
  }

  public resumeProduct(e: ResumeEvent): void {
    this.log(`resumeProduct event ${e.file.name}`);
  }

  public removeProduct(e: RemoveEvent): void {
    this.log(`removeProduct event: ${e.files[0].name}`);
  }

  public selectProduct(e: SelectEvent): void {
    this.logger.debug(`selectProduct event`, e);
  }

  public successProduct(e: SuccessEvent): void {
    if (e.files) {
      let name = e.files[0].name;
      let img = (e.response as any).body.img;
      this.logger.debug(`successProduct event ${name}`, e);
      this.form.patchValue({ param_additional_image: img });
      this.logoProduct = img;
      this.hasProductLogo = true;
    } else {
      this.form.patchValue({ param_additional_image: undefined });
      this.logoProduct = undefined;
      this.hasProductLogo = false;
    }
  }

  public uploadProduct(e: UploadEvent): void {
    this.log(`uploadProduct event ${e.files[0].name}`);
    this.securityService.secureUploads(e, 'Product uploading');
  }

  public uploadProgressProduct(e: UploadProgressEvent): void {
    this.log(`uploadProgressProduct event ${e.percentComplete}`);
  }

  public valueChangeProduct(e: FileInfo[]): void {
    this.log(`valueChangeProduct event ${e[0].name}`);
  }

  //#endregion

  private log(event: string): void {
    this.logger.debug(`log => ${event}`);
  }

  //#endregion

  private notify(msg: string): void {
    this.logger.debug("notify", msg);
    this.alertService.openSnackBar(msg);
    // this.notificationService.show({
    //   appendTo: this.appendTo,
    //   content: msg,
    //   animation: { type: "fade", duration: 300 },
    //   type: { style: "success", icon: true },
    //   position: {
    //     horizontal: "right",
    //     vertical: "top"
    //   },
    //   height: 50,
    //   // width: 150,
    //   hideAfter: 2000
    // });
  }

}
