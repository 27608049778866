import { Inject, Injectable, isDevMode } from '@angular/core';
import { CasiLogLevel, CasiConsoleLogTypes } from '../../common/logger.lib';
import { AppConfig, CONFIG_TOKEN } from '../../setup/config';

// const VOID_FUNCTION = (message?: any, ...optionalParams: any[]): void => { };
const VOID_FUNCTION = (...data: any[]): void => { };

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  // private $console: Partial<Console> = {
  //   log: console.log.bind(console.log),
  //   info: console.info.bind(console.info),
  //   error: console.error.bind(console.error),
  //   warn: console.warn.bind(console.warn),
  //   debug: console.debug.bind(console.debug),
  //   clear: console.clear.bind(console.clear)
  // }

  debug = console.debug.bind(console.debug); //this.$console.debug; // console.debug.bind(console.debug);
  log = console.debug.bind(console.debug); // this.$console.log; // console.log.bind(console.log);
  info = console.info.bind(console.info); // this.$console.info; // console.info.bind(console.info);
  error = console.error.bind(console.error); // this.$console.error; // console.error.bind(console.error);
  warn = console.warn.bind(console.warn); //this.$console.warn; // console.warn.bind(console.warn);
  clear = console.clear.bind(console.clear); //this.$console.clear;

  constructor(
    @Inject(CONFIG_TOKEN) appConfig: AppConfig
  ) {
    if (appConfig.isDev) {
      switch (appConfig.logLevel) {
        case CasiLogLevel.Warning:
          this.debug = VOID_FUNCTION;
          this.info = VOID_FUNCTION;
          this.log = VOID_FUNCTION;
          this.error = VOID_FUNCTION;
          break;

        case CasiLogLevel.Error:
          this.debug = VOID_FUNCTION;
          this.info = VOID_FUNCTION;
          this.log = VOID_FUNCTION;
          break;
        case CasiLogLevel.Info:
          this.debug = VOID_FUNCTION;
          this.log = VOID_FUNCTION;
          break;
        // case CasiLogLevel.Log:
        //   this.debug = VOID_FUNCTION;
        //   break;
        default:
        // Do nothing :D
      }

    } else {
      this.debug = VOID_FUNCTION;
      this.info = VOID_FUNCTION;
    }
  }

}
