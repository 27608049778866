<!--Load the font icons package:-->
<link rel="stylesheet" href="https://unpkg.com/@progress/kendo-font-icons/dist/index.css" />

<kendo-dialog-titlebar>
  <div style="font-size: 18px; line-height: 1.3em;">
    <span class="k-icon k-font-icon k-icon-xl k-i-form-element"></span> {{title}}
  </div>
</kendo-dialog-titlebar>

<form [formGroup]="form"  class="k-form-inline">
  <fieldset>

    <div class="k-form-field" >
      <label class="form-label col-3 k-label" for="name">Name</label>
      <div class="col-8">
        <input type="name" id="name" formControlName="name" class="form-control">
        <div *ngIf="name?.invalid" class="alert alert-danger">
          <div *ngIf="name?.errors?.['required']">Name is required.</div>
        </div>
      </div>
    </div>

  </fieldset>
</form>

<kendo-dialog-actions>
  <button kendoButton (click)="onSaveAction()" class="btn btn-primary" [disabled]="form.invalid">
    Save
  </button>
  <button kendoButton (click)="onCancelAction()" class="btn btn-secondary">Cancel</button>
</kendo-dialog-actions>
