import moment from 'moment';

export const storageTokenName = '__environerTokenUser';
export const storageTokenAccess = '__environerTokenAccess';

export const accessEntities = {
  COMPANY_PARAMS: "Company Parameters",
  ENQUIRIES: "Manage Enquiries",
  LICENSE: "License Agreement",
  ROLES: "Manage Roles",
  USER: "Manage Users",
  HELP: "Help",
  CO_BENEFIT_TYPE: "Manage Co-Benefit Type",
  OPPORTUNITIES: "Opportunities",
  OPPORTUNITY_CO_BENEFITS: "Manage Opportunity Co-benefits",
  OPPORTUNITY_PRACTICES: "Manage Opportunity Practices",
  OPPORTUNITY_REFERENCE_LINK: "Manage Opportunity Reference Links",
  RESOURCE: "Manage Resource"
};

export interface IPermission {
  entity: string,
  actions: string[]
};

export class Permission implements IPermission {
  entity: string;
  actions: string[];

  constructor(
    entity: string,
    actions: string[]
  ) {
    this.entity = entity;
    this.actions = actions;
  }

  hasAction(action: string): boolean {
    return this.actions.indexOf(action) > -1;
  }

  asObject(): any {
    let tmpPermissions: any = { entity: this.entity };
    this.actions.forEach((key, indx) => {
      tmpPermissions[`can_${key}`] = true;
    });
    return tmpPermissions;
  }
};

export interface IAccessPermissions {
  accessPermissions: Permission[]
};

export class AccessPermissions implements IAccessPermissions {
  accessPermissions: Permission[];

  constructor() {
    this.accessPermissions = [
      new Permission("Manage Enquiries", ["view", "enquiry_recipients"]),
      new Permission("License Agreement", ["update"]),
      new Permission("Manage Users", ["update"]),
      new Permission("Help", ["update"]),
      new Permission("Manage Roles", ["update"]),
      new Permission("Company Parameters", ["update"]),
      new Permission("Opportunities", ["create", "update", "destroy"]),
      new Permission("Manage Co-Benefit Type", ["create", "update", "destroy"]),
      new Permission("Manage Opportunity Co-benefits", ["create", "update", "destroy"]),
      new Permission("Manage Opportunity Practices", ["create", "update", "destroy"]),
      new Permission("Manage Opportunity Reference Links", ["create", "update", "destroy"]),
      new Permission("Manage Resource", ["create", "update", "destroy"]),
      new Permission("Review Opportunity", ["create", "update"]),
    ]
  }

  accessPermission(entity: string): Permission | undefined {
    let permission = this.accessPermissions.find(ap => ap.entity === entity);
    return permission;
  };
}

export interface ICanDo {
  entity: string,
  action: string
};

export class CanDo implements ICanDo {
  entity: string;
  action: string;

  constructor(
    entity: string,
    action: string
  ) {
    this.entity = entity;
    this.action = action;
  }
};

export interface IResponseToken {
  meToken: string,
  accessToken: string,
  refreshToken?: string,
  longToken?: string
};

export interface IClaimSub {
  id: number,
  roles: any[],
  admin: boolean,
  superuser: boolean,
  firstName: string,
  lastName: string,
  email: string,
  acceptedTermsVersion: number,
  sessionId?: any,
  researchConsent?: boolean,
  phone?: string
};

export interface IClaimSubAccess {
  permissions: Permission[],
  menuAccess: string[]
};

export interface IClaimsUser {
  iat: number;
  exp: number;
  sub?: IClaimSub;
  client_id?: string;
  jti?: string;
  aud?: string[] | string;
  nbf?: number;
  iss?: string;
};

export interface IClaimsAccess {
  iat: number;
  exp: number;
  sub?: IClaimSubAccess;
  client_id?: string;
  jti?: string;
  aud?: string[] | string;
  nbf?: number;
  iss?: string;
};

export class UserClaim implements IClaimsUser {
  static create(args: any) {
    const {
      iat, exp,
      sub, client_id, jti, aud,
      iss, nbf
    } = args;
    return new UserClaim(
      iat, exp,
      sub, client_id, jti, aud,
      iss, nbf
    );
  }
  constructor(
    public iat: number,
    public exp: number,
    public sub?: IClaimSub,
    public client_id?: string,
    public jti?: string,
    public aud?: string[] | string,
    public iss?: string,
    public nbf?: number
  ) { }


  get isExpired(): boolean {
    if (this.exp) {
      return Date.now() / 1000 > this.exp;
      // return moment().unix() > this.exp;
    }    // return Date.now() > claims.exp;
    return true;
  }

  get expiresIn(): number {
    let expirySeconds: number = this.exp - Date.now() / 1000;
    // let expirySeconds: number = this.exp - moment().unix();
    if (expirySeconds < 0) {
      return -1;
    }
    return expirySeconds;
  }

}

export class CasiInvalidTokenError extends Error { }
CasiInvalidTokenError.prototype.name = "CasiInvalidTokenError";

export const USER_ROLES = {
  SUPER_USER: "Superuser",
  ADMIN: "Administrator",
  GENERAL: "General",
  READ_ONLY: "ReadOnly",
  BASIC: "User"
};

export class User implements IClaimSub {
  static create(args: any) {
    const {
      id, roles,
      admin, superuser, firstName, lastName,
      email, acceptedTermsVersion, sessionId,
      researchConsent, phone
    } = args;
    return new User(
      id, roles,
      admin, superuser, firstName, lastName,
      email, acceptedTermsVersion, researchConsent, sessionId,
      phone
    );
  };
  static createFromSub(args: IClaimSub) {
    const {
      id, roles,
      admin, superuser, firstName, lastName,
      email, acceptedTermsVersion, sessionId,
      researchConsent, phone
    } = args;
    return new User(
      id, roles,
      admin, superuser, firstName, lastName,
      email, acceptedTermsVersion, researchConsent, sessionId,
      phone
    );
  };

  constructor(
    public id: number,
    public roles: any[],
    public admin: boolean,
    public superuser: boolean,
    public firstName: string,
    public lastName: string,
    public email: string,
    public acceptedTermsVersion: number,
    public researchConsent?: boolean | undefined,
    public sessionId?: any | undefined,
    public phone?: string) { }

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }
  get userId() {
    return this.id;
  }

  get isAdmin() {
    return this.hasRoles(USER_ROLES.ADMIN);
  }

  get isSuperUser() {
    return this.hasRoles(USER_ROLES.SUPER_USER);
  }

  get isBasic() {
    return this.hasRoles(USER_ROLES.BASIC);
  }

  hasRoles(role: string): boolean {
    if (this.roles) {
      return this.roles.map(r => r.name).indexOf(role) >= 0;
    }
    return false;
  }

  forSubmit(): any {
    return {
      first_name: this.firstName,
      last_name: this.lastName,
      email: this.email,
      user_id: this.id
    };
  }
};

