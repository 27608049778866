<div style="padding-top: 40px;">
  <form [formGroup]="form" (ngSubmit)="onSubmit()" class="k-form-inline">
    <fieldset>

      <div class="k-form-field">
        <label class="form-label col-3 k-label" for="address">Email
          Address</label>
        <div class="col-8">
          <input type="email" id="address" formControlName="address" class="form-control">
          <div *ngIf="address?.invalid" class="alert alert-danger">
            <div *ngIf="address?.errors?.['required']">
              Contact Email Address is required.
            </div>
            <div *ngIf="address?.errors && address?.errors?.['email']">Please enter a valid email address</div>
          </div>
        </div>
      </div>

      <div class="d-grid d-md-flex justify-content-md-end">
        <button type="button" class="btn btn-primary me-md-2" type="submit" [disabled]="form.invalid"> Save</button>
        <button type="button" class="btn btn-secondary" (click)="closeWindow()"> Cancel</button>
      </div>

    </fieldset>
  </form>

</div>
