import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {APP_CONFIG} from "../setup/config";
import {map, tap} from "rxjs/operators";
import {ResourceOpportunity} from "../models/models.lib";
import {ApiService} from "../api/api.service";
import { environment } from '../../environments/environment';

const { apiUrl } = environment;

export abstract class EnvResourceOpportunityService extends BehaviorSubject<Array<any>>{
  public loading: boolean = false;

  constructor(
    private http: HttpClient,
  ) {
    super([]);
  }

  public query(id:number, fromOpportunity: boolean): void {
    this.fetch(id, fromOpportunity).subscribe((x) => {
      return super.next(x);
    });
  }

  protected fetch(id:number, fromOpportunity: boolean): Observable<ResourceOpportunity[]> {
    this.loading = true;

    let url = fromOpportunity ? "resource/opportunity/byopportunity" : "resource/opportunity/byresource";

    return this.http.get(encodeURI(`${apiUrl}/${url}/${id}`))
      .pipe(
        map((response: any, i) => {
          return <ResourceOpportunity[]>response;
        }),
        map(r => r.map(x => ResourceOpportunity.create(x))),
        tap(() => (this.loading = false))
      )
  }
}


@Injectable({
  providedIn: 'root'
})
export class ResourceOpportunityService extends EnvResourceOpportunityService{

  constructor(
    http: HttpClient,
    private apiService: ApiService
  ) {
    super(http);
  }

  getByResourceId(id:number):Promise<any> {
    return this.apiService.getHttpCallAsPromise(`resource/opportunity/byresource/${id}`, 'getByResourceId');
  }

  getByOpportunityId(id:number):Promise<any> {
    return this.apiService.getHttpCallAsPromise(`resource/opportunity/byopportunity/${id}`, 'getByOpportunityId');
  }

  getById(id: number): Promise<any> {
    return this.apiService.getHttpCallAsPromise(`resource/opportunity/${id}`, 'getById');
  }

  add(p: any): Promise<any> {
    return this.apiService.postHttpCallAsPromise('resource/opportunity', p, 'add');
  }

  update(p: any): Promise<any> {
    return this.apiService.putHttpCallAsPromise(`resource/opportunity/${p.id}`, p, 'update');
  }

  destroy(id: number): Promise<any> {
    return this.apiService.deleteHttpCallAsPromise(`resource/opportunity/${id}`, 'destroy');
  }

}
