<link rel="stylesheet" href="https://unpkg.com/@progress/kendo-font-icons/dist/index.css" />
<div class="container" my-5>
  <div class="card">

    <div class="card-body no-border" #appendTo>
      <div class="card-title">
        <h3 style="text-align: left;" class="page-header">Manage Help Information</h3>
      </div>

      <kendo-grid [kendoGridBinding]="gridData" [loading]="dataService.loading" [sort]="sort" [sortable]="true"
        [groupable]="true" [resizable]="true" [filter]="filter" filterable="menu" (filterChange)="filterChange($event)"
        (sortChange)="sortChange($event)" [pageSize]="pageSize" [pageable]="true"
        style="height: calc(100vh - 300px); min-height: 250px;">


        <ng-template kendoGridToolbarTemplate position="top">
          <button (click)="onAddItem()" kendoButton class="btn btn-primary"><fa-icon icon="circle-plus"></fa-icon> Add
            Help Information</button>
        </ng-template>

        <kendo-grid-column [hidden]="!canUpdate" title="Edit" [width]="70" [style]="{'vertical-align': 'top'}">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <button (click)="onEditClick(dataItem)" kendoButton class="btn btn-stn-grid">
              <fa-icon icon="edit"></fa-icon>
            </button>
          </ng-template>
        </kendo-grid-column>

        <!-- <kendo-grid-column field="id" title="Id"></kendo-grid-column> -->
        <kendo-grid-column field="id" title="Id" [width]="80" [style]="{'vertical-align': 'top'}">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
            let-filterService="filterService">
            <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
              [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="site_section" title="Site Section" [width]="150" [style]="{'vertical-align': 'top'}">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
            let-filterService="filterService">
            <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
              [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="element_name" title="Element Name" [width]="150" [style]="{'vertical-align': 'top'}">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
            let-filterService="filterService">
            <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
              [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="heading" title="Heading" [width]="200" [style]="{'vertical-align': 'top'}">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
            let-filterService="filterService">
            <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
              [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="html" title="Content" [width]="350" [style]="{'vertical-align': 'top'}">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div [innerHTML]="dataItem.html"></div>
          </ng-template>
        </kendo-grid-column>

        <ng-template kendoPagerTemplate>
          <kendo-pager-info *ngIf="pageSize > 0"></kendo-pager-info>
        </ng-template>

      </kendo-grid>

    </div>
  </div>

</div>
<!--
<kendo-window *ngIf="addressPopupOpen" (close)="close($event)" [width]="1000">
  <kendo-window-titlebar>
    <span class="k-window-title">Edit Enquiry Recipient Email Addresses</span>
  </kendo-window-titlebar>
  <casi-enquiry-recipients (onClose)="close($event)"></casi-enquiry-recipients>
</kendo-window>
-->

<kendo-window *ngIf="editWinOpen" (close)="close()" [width]="900">
  <kendo-window-titlebar>
    <span class="k-window-title">{{showTitle}}</span>
    <button kendoWindowCloseAction></button>

  </kendo-window-titlebar>
  <casi-edit-help [helpInfo]="currentItem" [mode]="formMode" (onSave)="onSave($event)" (onCancel)="close()">
  </casi-edit-help>
</kendo-window>
