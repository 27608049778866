import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export const confirmedPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  // console.log(`confirmedPasswordValidator: `, control);
  const password = control.get('password');
  const confirmedPassword = control.get('confirmedPassword');
  // console.log(`confirmedPasswordValidator: `, {
  //   password: password?.value, confirmedPassword: confirmedPassword?.value
  // });
  const returning = password && confirmedPassword && password.value !== confirmedPassword.value ?
    { confirmedPasswordValidator: true } : null;
  // console.log(`confirmedPasswordValidator: `, {
  //   password: password ? password.value : null,
  //   confirmedPassword: confirmedPassword ? confirmedPassword.value : null,
  //   returning
  // });
  // console.log(`confirmedPasswordValidator: returning`, returning);
  return returning;
};

export const invalidPhoneValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

  let returning = control.value && (!/^0[1-9]\d{8}$/.test(control.value)) && (!/^61[1-9]\d{8}$/.test(control.value))
    && (!/^\+61[1-9]\d{8}$/.test(control.value)) && (!/^\(0[1-9]\)\d{8}$/.test(control.value)) ?
    { invalidPhoneValidator: true } : null;
  // console.log(`invalidPhoneValidator: `, {
  //   value: control ? control.value : null,
  //   returning
  // });
  return returning;
};

export const invalidMobileValidator = (control: AbstractControl, isRequired: boolean): ValidationErrors | null => {
  // console.log('invalidMobileValidator:isRequired: ', isRequired);
  let returning = isRequired && control.value && (!/^04\d{8}$/.test(control.value)) && (!/^614\d{8}$/.test(control.value)) && (!/^\+614\d{8}$/.test(control.value)) ?
    { invalidMobileValidator: true } : null;
  // console.log(`invalidMobileValidator: `, {
  //   value: control ? control.value : null,
  //   isRequired,
  //   returning
  // });
  return returning;
};

export function specificNumbersValidator(specifiedNumbers: number[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as number;

    if (!specifiedNumbers.includes(value)) {
      return { 'specificNumbers': true }; // Validation failed; value is not in specified numbers
    } else {
      return null; // Validation passed; the value is unique.
    }
  };
}
