import { Component, NO_ERRORS_SCHEMA, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DialogsModule } from "@progress/kendo-angular-dialog";
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive } from '@angular/router';
import {
  faTriangleExclamation,
  faPlusCircle, faAddressCard,
  faEdit, faTrash, faBan, faDownload,
} from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { WindowModule } from "@progress/kendo-angular-dialog";
import { NotificationService, NotificationModule } from "@progress/kendo-angular-notification";
import { LoggerService } from '../../shared/logger/logger.service';
import { AuthTokenService } from '../../auth/auth-token.service';
import { AppEnities, FormModes } from '../../common/constants.lib';
import { MeService } from '../../auth/me.service';
import { CommonBenefitType, CommonClassification, CommonElegibility, CommonGoal, CommonIndustry, CommonState, Elegibility, Opportunity } from '../../models/models.lib';
import { CompositeFilterDescriptor, SortDescriptor, filterBy, distinct, orderBy } from "@progress/kendo-data-query";
import { ExcelModule, GridModule, PagerModule, PagerPosition, PagerType } from '@progress/kendo-angular-grid';
import { MulticheckFilterComponent } from '../../filters/multicheck-filter/multicheck-filter.component';
import { setTitle } from '../../common/utils.lib';
import { OpportunitiesService } from '../opportunities.service';
import { EditOpportunityComponent } from '../edit-opportunity/edit-opportunity.component';
import { CoBenefitsComponent } from '../co-benefits/co-benefits.component';
import { PracticesComponent } from '../practices/practices.component';
import { ReferenceLinksComponent } from '../reference-links/reference-links.component';
import { ResourcesComponent } from "../resources/resources.component";
import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';
import {OpportunityReviewsComponent} from "../../opportunityReviews/opportunity-reviews/opportunity-reviews.component";
import { HelpPopupComponent } from '../../admin/help-info/help-popup/help-popup.component';
import { HelpPopoverComponent } from '../../admin/help-info/help-popover/help-popover.component';

const { OPPORTUNITIES } = AppEnities;

@Component({
  selector: 'app-opportunity',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    FontAwesomeModule,
    RouterLink, RouterLinkActive,
    InputsModule,
    ButtonsModule,
    DialogsModule,
    WindowModule,
    NotificationModule,
    PagerModule,
    GridModule,
    MulticheckFilterComponent,
    ExcelModule,
    EditOpportunityComponent,
    CoBenefitsComponent,
    PracticesComponent,
    ReferenceLinksComponent,
    ResourcesComponent,
    SafeHtmlPipe,
    OpportunityReviewsComponent,
    HelpPopupComponent,
    HelpPopoverComponent
  ],
  schemas: [NO_ERRORS_SCHEMA],
  templateUrl: './opportunity.component.html',
  styleUrl: './opportunity.component.scss'
})
export class OpportunityComponent implements OnInit, OnDestroy {

  public view: Observable<Opportunity[]> = new Observable<Opportunity[]>();
  public rawData: Opportunity[] = [];
  public filter: CompositeFilterDescriptor = { logic: "and", filters: [] };
  public gridData: Opportunity[] = filterBy(this.rawData, this.filter);

  public currentItem: Opportunity = new Opportunity();
  public currentItemId: number = 0;
  public currentItemTitle: string = "";

  // public currentCoBenefit: CoBenefit = new CoBenefit();
  // public cbMode: FormModes = FormModes.NOTSET;
  // public cbTitle: string = '';

  public formMode: FormModes = FormModes.NOTSET;

  public pagerTypes = ["numeric", "input"];
  public type: PagerType = "numeric";
  public info = true;
  public pageSizes = true;
  public previousNext = true;
  public position: PagerPosition = "bottom";

  public pageSize = 10;

  // @ViewChild("appendTo", { read: ViewContainerRef, static: false }) appendTo: ViewContainerRef;
  @ViewChild("appendTo", { read: ViewContainerRef }) appendTo: ViewContainerRef | undefined;

  user: any = null;

  public opened: boolean = false;
  public dataSaved: boolean = false;
  public errorOpened: boolean = false;

  public editWinOpen: boolean = false;
  public confirmDeleteItem: boolean = false;
  public viewCBOpen: boolean = false;
  public viewPracticeOpen: boolean = false;
  public viewRefLinkOpen: boolean = false;
  public viewResourcesOpen: boolean = false;
  public viewReviewsOpen: boolean = false;

  public editCoBens: boolean = false;

  public windowTop = 100;
  public windowLeft = 50;

  public canCreate: boolean = false;
  public canUpdate: boolean = false;
  public canDestroy: boolean = false;

  private _showTitle = new BehaviorSubject<string>('');
  get showTitle() {
    return this._showTitle.getValue();
  }
  set showTitle(val: string) {
    this._showTitle.next(val);
  }

  public _states: CommonState[] = [];
  private _benefits: CommonBenefitType[] = [];
  private _industries: CommonIndustry[] = [];
  private _goals: CommonGoal[] = [];
  private _classifications: CommonClassification[] = [];
  private _elegbilities: CommonElegibility[] = [];
  public _coBenDdTree: any[] = [];
  // public coBenDdTree() {
  //   return this._coBenDdTree;
  // }

  private _subs: Subscription[] = [];

  constructor(private logger: LoggerService,
    public authTokenService: AuthTokenService,
    public faIcons: FaIconLibrary,
    public dataService: OpportunitiesService,
    private notificationService: NotificationService,
    private me: MeService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    faIcons.addIcons(
      faTriangleExclamation,
      faPlusCircle, faAddressCard, faEdit,
      faTrash,
      faBan, faDownload
    );
  }

  public sort: SortDescriptor[] = [
    { field: "title", dir: "asc" },
    { field: "short_description", dir: "asc" }
  ];

  //#region Ng Handlers

  ngOnInit(): void {
    this.logger.debug('ngOnInit...');
    const { can_create, can_update, can_destroy } = this.me.allICanDo(OPPORTUNITIES);

    this.logger.debug('ngOnInit:permissions: ', can_create, can_update, can_destroy);
    this.canCreate = can_create;
    this.canUpdate = can_update;
    this.canDestroy = can_destroy;

    this._subs.push(this.route.data.subscribe(({
      stateList, benefitList, industryList,
      goalList, classificationList, elegibilityList,
      coBenDdTreeList
    }) => {
      // this.logger.debug('ngOnInit:resolves: ', stateList, benefitList, goalList, elegibilityList);
      this._states = stateList;
      this._benefits = benefitList;
      this._industries = industryList;
      this._goals = goalList;
      this._classifications = classificationList;
      this._elegbilities = elegibilityList;
      this._coBenDdTree = coBenDdTreeList;
      // this.logger.debug('ngOnInit:_coBenDdTree: ', this._coBenDdTree);

    }));
    this.dataService.query();
    this._subs.push(this.dataService.subscribe(items => {
      this.logger.debug('ngOnInit:items: ', items);
      this.rawData = items;
      this.loadData();
    }));
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnDestroy...');
    this._subs.forEach(s => s.unsubscribe());
  }

  //#endregion

  //#region Window handlers

  //#region Opportunities

  public onAddClicked(): void {
    this.logger.debug("onAddClicked...");
    this.currentItem = new Opportunity(this._goals, this._industries, this._states,
      this._benefits, this._classifications, this._elegbilities);
    this.formMode = FormModes.NEW;
    this.logger.debug("onAddClicked:currentItem ", this.currentItem);
    this.showTitle = setTitle(this.formMode, 'OPPORTUNITY');
    this.editWinOpen = true;
  }

  onEditClick(dataItem: any): void {
    this.logger.debug("onEditClick: ", dataItem);
    this.currentItem = Opportunity.createWithLists(dataItem,
      this._goals, this._industries, this._states, this._benefits,
      this._classifications, this._elegbilities);
    this.formMode = FormModes.EDIT;
    this.logger.debug("onEditClick:currentItem ", this.currentItem);
    this.showTitle = setTitle(this.formMode, 'OPPORTUNITY');
    this.editWinOpen = true;
  }

  public onItemSave(args: any): void {
    this.logger.debug('onItemSave...', args);
    this.handleSaveAndClose(args);
  }

  onConfirmDelete(dataItem: any): void {
    this.logger.debug("onConfirmDelete: ", dataItem);
    this.currentItem = dataItem;
    this.confirmDeleteItem = true;
  }

  onConfirmedClose(status: string): void {
    this.logger.debug("onConfirmedClose: ", status, this.currentItem);
    this.confirmDeleteItem = false;
    if (status === 'delete') {
      this.handleRemove(this.currentItem);
    }
  }

  public close(): void {
    this.logger.debug('close...');
    this.editWinOpen = false;
  }

  //#endregion

  //#region Co Benefits

  viewCoBenefits(dataItem: any): void {
    this.logger.debug('viewCoBenefits:', dataItem);
    // this.currentItem = Opportunity.createWithLists(dataItem,
    //   this._goals, this._industries, this._states, this._benefits,
    //   this._classifications);
    this.currentItemId = dataItem.id; // (this.currentItem.id as number);
    this.viewCBOpen = true;
  }

  public closeViewCB(): void {
    this.logger.debug('closeViewCB...');
    this.viewCBOpen = false;
    this.dataService.query();
  }

  public closeCB(): void {
    this.logger.debug('closeCB...');
    this.viewCBOpen = false;
  }

  //#endregion

  //#region Practices

  viewPractices(dataItem: any): void {
    this.logger.debug('viewPractices: ', dataItem);
    this.currentItemId = dataItem.id; //(this.currentItem.id as number);
    this.viewPracticeOpen = true;
  }

  public closePractices(): void {
    this.logger.debug('closePractices...');
    this.viewPracticeOpen = false;
  }

  public closeViewPractice(): void {
    this.logger.debug('closeViewPractice...');
    this.viewPracticeOpen = false;
    this.dataService.query();
  }

  //#endregion

  //#region Reference Links

  viewReferenceLinks(dataItem: any): void {
    this.logger.debug('viewReferenceLinks: ', dataItem);
    this.currentItemId = dataItem.id; //(this.currentItem.id as number);
    this.viewRefLinkOpen = true;
  }

  public closeRefLinks(): void {
    this.logger.debug('closeRefLinks...');
    this.viewRefLinkOpen = false;
  }

  public closeViewRefLinks(): void {
    this.logger.debug('closeViewRefLinks...');
    this.viewRefLinkOpen = false;
    this.dataService.query();
  }

  //#endregion

  //#region Resources

  viewResources(dataItem: any): void {
    this.logger.debug('viewResources:', dataItem);
    this.currentItemId = dataItem.id;
    this.currentItemTitle = dataItem.title;
    this.viewResourcesOpen = true;
  }

  public closeViewResources(): void {
    this.logger.debug('closeViewResources...');
    this.viewResourcesOpen = false;
    this.dataService.query();
  }

  public closeResources(): void {
    this.logger.debug('closeResources...');
    this.viewResourcesOpen = false;
  }

  //#endregion

  //#region Reviews

  viewReviews(dataItem: any): void {
    this.logger.debug('viewReviews:', dataItem);
    this.currentItem = dataItem;
    this.viewReviewsOpen = true;
  }

  public closeViewReviews(): void {
    this.logger.debug('closeViewReviews...');
    this.viewReviewsOpen = false;
    this.dataService.query();
  }

  // public closeReviews(): void {
  //   this.logger.debug('closeReviews...');
  //   this.viewReviewsOpen = false;
  // }

  //#endregion

  //#endregion

  //#region Grid handlers

  onClearFilters(gridName: string) {
    this.logger.debug("onClearFilters: ", gridName);
    this.filter = { logic: "and", filters: [] };
    this.loadData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.logger.debug("sortChange: ", sort);
    this.sort = sort;
    this.loadData();
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    // this.logger.debug('filterChange: filter: ', filter);
    this.filter = filter;
    this.loadData();
  }

  public distinctPrimitive(fieldName: string): any[] {
    // this.logger.debug('distinctPrimitive: fieldName: ', fieldName);
    return distinct(this.gridData, fieldName).map((item: any) => item[fieldName]);
  }

  //#endregion

  //#region Private functions

  private handleSaveAndClose(args: any): void {
    this.logger.debug('handleSaveAndClose...', args);
    const { action, data, mode } = args;
    this.dataService.addEdit(data, mode)
      .then(resp => {
        this.notify(mode === FormModes.NEW ? 'Opportunity Added' : 'Opportunity Updated')
        this.dataService.query();
      })
      .catch(err => {
        this.logger.error('handleSaveAndClose:error: ', err);
        this.notify("Error Adding/Updating Opportunity", true);
      })
      .finally(() => this.editWinOpen = false);
    // this.editWinOpen = false;
  }

  private handleRemove(args: any): void {
    if (args && !args.id) {
      this.logger.error('handlRemove: No id given');
    }

    this.dataService.destroy(args.id)
      .then(resp => {
        this.logger.debug('handleRemove:resp: ', resp);
        this.notify('Opportunity Deleted');
        this.dataService.query();
      })
      .catch(err => {
        this.logger.error('handleRemove:err: ', err);
        this.notify("Error Deleting Opportunity", true);
        this.confirmDeleteItem = true;
      });
  }

  private loadData(): void {
    // this.logger.debug('loadData...');
    this.gridData = orderBy(filterBy(this.rawData, this.filter), this.sort);
    this.pageSize = this.gridData.length;
  }

  private notify(msg: string, error: boolean = false): void {
    this.logger.debug("notify", msg);
    this.notificationService.show({
      appendTo: this.appendTo,
      content: msg,
      animation: { type: "fade", duration: 300 },
      type: { style: !error ? "success" : "error", icon: true },
      position: {
        horizontal: "right",
        vertical: "top"
      },
      height: 50,
      // width: 150,
      hideAfter: 2000
    });
  }

  //#endregion

}
