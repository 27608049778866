<form class="k-form" [formGroup]="form" class="k-form-inline">

  <fieldset>

    <div class="k-form-field">
      <label for="title" class="col-3 form-label k-label">Title</label>
      <div class=" col-6">
        <input class="form-control" formControlName="title" id="title" />
        <div *ngIf="title?.invalid" class="alert alert-danger">
          <div *ngIf="title?.errors?.['required']">Title is required.</div>
        </div>
      </div>

    </div>

    <div class="k-form-field">
      <label for="description" class="col-3 form-label k-label">Description</label>
      <div class=" col-6">
        <textarea class="form-control" formControlName="description" id="description" rows="4"></textarea>
        <div *ngIf="description?.invalid" class="alert alert-danger">
          <div *ngIf="description?.errors?.['required']">Description is required.</div>
        </div>
      </div>

    </div>

    <div class="k-form-field">
      <label for="key_words" class="col-3 form-label k-label">Key Words</label>
      <div class=" col-6">
        <input class="form-control" formControlName="key_words" id="key_words" />
        <div *ngIf="key_words?.invalid" class="alert alert-danger">
          <div *ngIf="key_words?.errors?.['required']">Key Words is required.</div>
        </div>
      </div>

    </div>


    <div class="k-form-field">
      <label class="col-3 form-label k-label">Link Type</label>
      <div class=" col-6">
      @for(item of resource.radioOptions; track item){
        <div>
          <input type="radio" value={{item}} id="opt{{item}}" formControlName="linkType" kendoRadioButton (change)="onRadioChange(item)" />

          <kendo-label for="opt{{item}}" text={{item}}></kendo-label>
        </div>



      }
      </div>
    </div>



    <!-- <div >
      <kendo-formfield showHints="initial" style="margin-bottom: 10px;">

        <ul class="k-radio-list">
          @for(item of resource.radioOptions; track item){
          <li class="k-radio-item">
            <kendo-label for="opt{{item}}" text={{item}} class="col-3 form-label k-label"></kendo-label>
            <input type="radio" value={{item}} id="opt{{item}}" formControlName="linkType" kendoRadioButton (change)="onRadioChange(item)" />

          </li>
          }
          @empty {
          <li>No options found.</li>
          }
        </ul>
      </kendo-formfield>
    </div> -->



<!--    @if(this.resource.linkType === 'Document') {-->
    <div class="k-form-field" *ngIf="resource.linkType === 'Document'">
      <label class="col-3 form-label k-label">Document</label>
      <div class="col-6">
        <kendo-upload [autoUpload]="false" [saveUrl]="uploadSaveUrl" (cancel)="cancel($event)" (clear)="clear()"
                      (complete)="complete()" (error)="error($event)" (blur)="blur()" (focus)="focus()"
                      (pause)="pause($event)"
                      (remove)="remove($event)" (resume)="resume($event)" (select)="select($event)"
                      (success)="success($event)"
                      (upload)="upload($event)" (uploadProgress)="uploadProgress($event)"
                      (valueChange)="valueChange($event)"
                      [restrictions]="myRestrictions">
          <kendo-upload-messages dropFilesHere="or drop documents here to add (max size 20 MB)"></kendo-upload-messages>
        </kendo-upload>
        <input type="hidden" class="form-control" formControlName="url">
        <div class="demo-hint" style="color: #231f20 !important; margin-bottom: 10px;">You can only upload
          <strong>PDF</strong> files.
        </div>
        <div *ngIf="url?.invalid" class="alert alert-danger">
          <div *ngIf="url?.errors?.['required']"> Document is required.</div>
        </div>
      </div>

    </div>
<!--    }-->

    <div class="k-form-field" *ngIf="(resource.url && resource.linkType === 'Document') || resource.linkType === 'URL'">
      <label for="url" class="col-3 form-label">Url</label>
      <div class=" col-6">
        <div style="padding-top: 7.5px;" *ngIf="resource.linkType === 'Document'">
          <a href="{{resource.url}}" target="_blank">{{resource.url}}</a>
        </div>

        <input type="text" *ngIf="resource.linkType === 'URL'" class="form-control" formControlName="url" id="url"/>
        <div *ngIf="url?.invalid && resource.linkType === 'URL'" class="alert alert-danger">
          <div *ngIf="url?.errors?.['required'] && resource.linkType === 'URL'">Url is required.</div>
        </div>
      </div>
    </div>

<!--    @if(showUrl) {-->
<!--    <div class="k-form-field">-->
<!--      <label for="url" class="col-3 form-label">Url</label>-->
<!--      <div class=" col-6">-->
<!--        @if(linkTypeIsDocument){-->
<!--        <div style="padding-top: 7.5px;">-->
<!--          <a href="{{resource.url}}" target="_blank">{{resource.url}}</a>-->
<!--        </div>-->
<!--        }-->
<!--        <input  type="text" *ngIf=" !linkTypeIsDocument" class="form-control" formControlName="url" id="url" />-->
<!--        <div *ngIf="url?.invalid && !linkTypeIsDocument" class="alert alert-danger">-->
<!--          <div *ngIf="url?.errors?.['required'] && !linkTypeIsDocument">-->
<!--            Url is required.-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    }-->

    <div class="k-form-field form-check">
      <label class="form-label k-label col-3" style="padding-top: 0;">Goals</label>
      <div class="col-8">
        <ul>
          @for(goal of resource.goalListDisplay; track goal.id){
          <kendo-label for="goal{{goal.id}}" class="k-checkbox-label">{{goal.goal}}</kendo-label>
          <input type="checkbox" size="large" kendoCheckBox class="form-check-input" id="goal{{goal.id}}"
            (click)="onGoalChecked(goal)" [checked]="goal.checked" />
          <br>
          }
          @empty {
          <li>No goals found.</li>
          }

        </ul>
      </div>
    </div>

    <div class="k-form-field form-check">
      <label class="form-label k-label col-3" style="padding-top: 0;">Industries</label>
      <div class="col-8">
        <ul>
          @for(industry of resource.industryListDisplay; track industry.id){
          <kendo-label for="industry{{industry.id}}" class="k-checkbox-label">{{industry.description}}</kendo-label>
          <input type="checkbox" size="large" kendoCheckBox class="form-check-input" id="industry{{industry.id}}"
            (click)="onIndustryChecked(industry)" [checked]="industry.checked" />
          <br>
          }
          @empty {
          <li>No industries found.</li>
          }
        </ul>
      </div>
    </div>


    <!-- Buttons -->

    <div class="d-grid d-md-flex justify-content-md-end">
      <button type="button" class="btn btn-primary me-md-2" (click)="onSubmit()" [disabled]="form.invalid">Save</button>
      <button type="button" class="btn btn-secondary" (click)="closeWindow()"> Cancel</button>
    </div>

  </fieldset>
</form>
