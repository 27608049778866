import { Directive, HostListener, Input } from '@angular/core';
import { LoggerService } from '../shared/logger/logger.service';
import { ApiService } from '../api/api.service';
import { TrackerApiService } from '../api/tracker-api.service';

@Directive({
  selector: '[casiClickLogger]',
  exportAs: 'casiclicklogger',
  standalone: true
})
export class ClickLoggerDirective {
  @Input('casiClickLogger') activity: string = 'No activity set';

  constructor(
    private logger: LoggerService,
    private apiService: TrackerApiService
  ) { }

  /**
   * Used to manually log metadata
   * @param text
   */
  manualLog(text: string): void {
    this.logger.debug(text);
    this.logActivity(text);
  }

  @HostListener('click')
  onClick() {
    this.logger.debug('click listener fired...', this.activity);
    if (this.activity && this.activity === "false") {
      this.logger.debug('Not auto tracking here');
      return;
    }
    this.logActivity(this.activity);
  }

  // @HostListener('mouseenter')
  // mouseenter() {
  //   this.logger.debug("OMG It's a Mouse!!!");
  // }

  private logActivity(msg: string = 'No activity'): void {
    this.apiService.trackIt({
      activityMeta: {
        title: 'Track activity'
      },
      bodyMeta: {
        activity: msg
      }
    });

  }
}
