import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { map, tap } from "rxjs/operators";
import { CasiControl } from '../models/models.lib';
import { APP_CONFIG } from '../setup/config';
import { environment } from '../../environments/environment';

const { apiUrl } = environment;

export abstract class EnvironerControlService extends BehaviorSubject<any>{
  public loading: boolean = false;

  constructor(
    private http: HttpClient,
  ) {
    super({});
  }

  public query(): void {
    this.fetch().subscribe((x) => {
      // console.log('query: x: ', x);
      return super.next(x);
    });
  }

  protected fetch(): Observable<CasiControl> {
    this.loading = true;

    return this.http.get(encodeURI(`${apiUrl}/hostinfo`))
      .pipe(
        map((response: any, i) => {
          return <CasiControl>response;
        }),
        map(r => CasiControl.create(r)),
        // tap(nr => {
        //   console.log('nr: ', nr);
        // }),
        tap(() => (this.loading = false))
      )
  }
}

@Injectable({
  providedIn: 'root'
})
export class ControlService  extends EnvironerControlService {

  constructor(
    http: HttpClient,
    private apiService: ApiService
  ) {
    super(http);
  }

  get() {
    return this.apiService.getHttpCallAsPromise('control', 'get');
  }

  getControlWithImages(): Promise<any> {
    return this.apiService.getHttpCallAsPromise(`controlWithImages`, 'getControlWithImages');
  }

  fetchLogoUrl(): Promise<any> {
    return this.apiService.getHttpCallAsPromise(`control/logourl`, 'fetchLogoUrl');
  }

  update(p: any): Promise<any> {
    return this.apiService.putHttpCallAsPromise(`updatecontrol`, p, 'update');
  }

  getHostInfo(): Promise<any> {
    return this.apiService.getHttpCallAsPromise(`hostinfo`, 'getHostInfo');
  }
}

/**
 * {
    "id": 1,
    "db_major_version": 1,
    "db_minor_version": 0,
    "app_base_url": "https://environer-dev.une.edu.au",
    "param_company_name": "AgriFutures Australia",
    "param_entity_name": "AgriFutures Australia",
    "param_company_address": "Building 007, Tooma Way, Charles Sturt University, Locked Bag 588, Wagga Wagga NSW 2650",
    "param_abn": "25 203 754 319",
    "param_acn": null,
    "param_primary_email": "no-reply@environer-dev.une.edu.au",
    "param_additional_email": null,
    "param_website_url": "https://agrifutures.com.au",
    "param_primary_contact_number": null,
    "param_additional_contact_number": null,
    "param_primary_image": null,
    "param_additional_image": "",
    "param_product_name": "Environer",
    "param_reply_to_email": null,
    "param_reset_password_bcc": null,
    "createdAt": "2023-10-19T01:14:51.284Z",
    "updatedAt": "2023-11-07T23:16:48.128Z"
}
 */
