import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { map, tap } from "rxjs/operators";
import { CoBenefit } from '../models/models.lib';
import { APP_CONFIG } from '../setup/config';
import { FormModes } from '../common/constants.lib';
import { environment } from '../../environments/environment';

const { apiUrl } = environment;

export abstract class EnvironerCoBenefitService extends BehaviorSubject<Array<any>>{
  public loading: boolean = false;

  constructor(
    private http: HttpClient,
  ) {
    super([]);
  }

  public query(id:number): void {
    this.fetch(id).subscribe((x) => {
      // console.log('query: x: ', x);
      return super.next(x);
    });
  }

  protected fetch(id:number): Observable<CoBenefit[]> {
    this.loading = true;

    return this.http.get(encodeURI(`${apiUrl}/cobenefit/all/${id}`))
      .pipe(
        map((response: any, i) => {
          // console.log('fetch:map:response: ', response, i);
          // let returning =<CoBenefit[]>response; // response.map(r =>  CoBenefit.create((r)));
          // console.log('fetch:map:response: ', returning);
          return <CoBenefit[]>response;
        }),
        map(r => r.map(x => CoBenefit.create(x))),
        // tap(nr => {
        //   console.log('nr: ', nr);
        // }),
        tap(() => (this.loading = false))
      )
  }
}

@Injectable({
  providedIn: 'root'
})
export class CoBenefitsService extends EnvironerCoBenefitService {

  constructor(
    http: HttpClient,
    private apiService: ApiService
  ) {
    super(http);
  }

  get(id:number):Promise<any> {
    return this.apiService.getHttpCallAsPromise(`cobenefit/all/${id}`, 'get');
  }

  getById(id: number): Promise<any> {
    return this.apiService.getHttpCallAsPromise(`cobenefit/${id}`, 'getById');
  }

  add(p: any): Promise<any> {
    return this.apiService.postHttpCallAsPromise('cobenefit', p, 'add');
  }

  update(p: any): Promise<any> {
    return this.apiService.putHttpCallAsPromise(`cobenefit/${p.id}`, p, 'update');
  }

  destroy(id: number): Promise<any> {
    return this.apiService.deleteHttpCallAsPromise(`cobenefit/${id}`, 'destroy');
  }

  addEdit(p: any, formMode: FormModes): Promise<any> {
    if (formMode === FormModes.NEW) {
      return this.add(p);
    } else {
      return this.update(p);
    }
  }
}

/**
 * {
    "id": 2,
    "CoBenefit_id": 1,
    "co_benefit_type_id": 4,
    "positive": false,
    "negative": true,
    "neutral": true,
    "summary": "jhfkjhfghkjgfhjk",
    "visible": true,
    "co_benefit": "Environment",
    "co_benefit_group": "Net emissions, sequestration, storage",
    "sub_co_benefit": "GHG net emissions arising from the production of the commodity"
  },
 */
