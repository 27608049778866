import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormGroup, FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { LoggerService } from '../../shared/logger/logger.service';
import { CommonModule } from '@angular/common';
import { ApiService } from '../../api/api.service';
import { AuthTokenService } from '../auth-token.service';
import { AuthService } from '../auth.service';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NotificationModule, NotificationService } from '@progress/kendo-angular-notification';
import { UserDataService } from '../../users/user-data.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule, FormsModule, ReactiveFormsModule,
    MatSlideToggleModule, NotificationModule,
    RouterLink, RouterLinkActive
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  @ViewChild("appendTo", { read: ViewContainerRef }) appendTo: ViewContainerRef | undefined;

  constructor(
    private logger: LoggerService,
    private fb: NonNullableFormBuilder,
    private apiService: ApiService,
    private authTokenService: AuthTokenService,
    private authService: AuthService,
    private router: Router,
    // private notificationService: NotificationService,
    private toastr: ToastrService,
    private userDataService: UserDataService,
  ) {

    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
    });

  }

  get f() {
    return this.loginForm.controls;
  }

  get email() {
    return this.loginForm.get('email');
  }
  get password() {
    return this.loginForm.get('password');
  }

  ngOnInit(): void {
    // this.loginForm = this.fb.group({
    //   email: ['', [Validators.required, Validators.email]],
    //   password: ['', [Validators.required]],
    // });
  }

  onSubmit() {
    this.logger.debug('onSubmit...', this.loginForm.value);
    const { email, password } = this.loginForm.value;
    this.authService
      .login(email, password)
      .then(resData => {
        this.logger.debug('onSubmit:resData: ', resData);
        this.authTokenService.setToken(resData);
        this.apiService.getCsrfAsPromise();
        this.loginForm.reset();
        this.userDataService.checkTerms()
          .then((agreed) => {
            if (agreed) {
              this.router.navigate(['/logged-in']);
            } else {
              this.router.navigate(['/terms-check']);
            }
          })
          .catch(err => {
            this.logger.error('reloadUser:error: ', err.message);
          })
        //  this.router.navigate(['/logged-in']);
        // this.authService.loggedIn = this.authTokenService.isAuthenticated();
      })
      .catch(err => {
        this.logger.error('onSubmit: error: ', err);
        if (err.error.toLowerCase().indexOf("incorrect password") != -1 || err.error.toLowerCase().indexOf("not found") != -1)
          this.toastr.error('Invalid email/password combination.', 'Login', {
            positionClass: 'toast-center-center'
          });
        // this.notificationService.show({
        //   appendTo: this.appendTo,
        //   content: 'Invalid email/password combination.',
        //   animation: { type: "fade", duration: 300 },
        //   type: { style: "error", icon: true },
        //   position: {
        //     horizontal: "right",
        //     vertical: "top"
        //   },
        //   height: 50,
        //   width: 300,
        //   hideAfter: 2000
        // });
      });

  }

}
