import {Component, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef} from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {EditorComponent, EditorModule} from '@progress/kendo-angular-editor';
import {
  IDocType,
  docTypes
} from '../../common/constants.lib';
import { NotificationModule, NotificationService } from '@progress/kendo-angular-notification';
import { Terms } from '../../models/models.lib';
import { LoggerService } from '../../shared/logger/logger.service';
import { TermsService } from '../terms.service';
import {
  faPlusCircle, faAddressCard,
  faEdit, faTrash, faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons';
import { LabelModule } from "@progress/kendo-angular-label";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import {EditorImageUploadComponent} from "../../common-components/editor-image-upload/editor-image-upload.component";

@Component({
  selector: 'casi-edit-policy',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    FontAwesomeModule,
    RouterLink, RouterLinkActive,
    EditorModule,
    NotificationModule,
    LabelModule,
    InputsModule,
    ButtonsModule,
    DropDownsModule,
    EditorImageUploadComponent
  ],
  templateUrl: './edit-policy.component.html',
  styleUrl: './edit-policy.component.scss'
})
export class EditPolicyComponent implements OnInit, OnDestroy {
  public view: Observable<Terms> = new Observable<Terms>();

  public docTypeList: Array<IDocType> = docTypes;
  public currentDocType: IDocType = docTypes[0];
  public isApproved: boolean = false;
  public value = '';
  form: FormGroup;
  public recordId: number | undefined;

  @ViewChild("appendTo", { read: ViewContainerRef }) appendTo: ViewContainerRef | undefined;

  @ViewChild("uploadDialog") public dialog: EditorImageUploadComponent = <EditorImageUploadComponent>{};
  @Output() @ViewChild("editor") private editor: EditorComponent = <EditorComponent>{};

  private _subs: Subscription[] = [];
  constructor(
    private fb: FormBuilder,
    private logger: LoggerService,
    public dataService: TermsService,
    public faIcons: FaIconLibrary,
    private notificationService: NotificationService,
  ) {
    faIcons.addIcons(
      faPlusCircle, faAddressCard, faEdit,
      faTrash, faTriangleExclamation
    );
    this.form = this.fb.group(
      {
        id: [null, []],
        html: ['', [Validators.required]],
        version_number: [null, [Validators.required]],
        approved: [null, [Validators.required]],
        doc_type: [null, [Validators.required]]
      }
    );
  }

  get f() {
    return this.form.controls;
  }
  get html() {
    return this.form.get('html');
  }
  get version_number() {
    return this.form.get('version_number');
  }
  get approved() {
    return this.form.get('approved');
  }
  get doc_type() {
    return this.form.get('doc_type');
  }

  ngOnInit(): void {
    this.logger.debug('ngOnInit...');
    this.dataService.query(docTypes[0].value);
    this._subs.push(this.dataService.subscribe(term => {
      this.logger.debug('ngOnInit:term: ', term);
      this.loadData(term);
    }));
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnDestroy...');
    this._subs.forEach(s => s.unsubscribe());
  }

  onSubmit() {
    this.logger.debug('onSubmit...', this.currentDocType, this.form.value);
    let toSubmit = Terms.create(this.form.value);
    this.logger.debug('onSubmit:toSubmit: ', toSubmit, toSubmit.prepareForSubmit());
    this.dataService.add(toSubmit.prepareForSubmit())
      .then(resp => {
        this.logger.debug('onSubmit:resp: ', resp);
        this.notify('Document Updated. Click Approve/Publish to Publish');
        this.loadData(Terms.create(resp));
      })
      .catch(err => {
        this.logger.error('onSumbit:error: ', err);
      })
  }

  approvePage() {
    this.logger.debug('approvePage...', this.form.value);
    const { id } = this.form.value;
    this.dataService.approvePage(id)
      .then(resp => {
        this.logger.debug('onSubmit:resp: ', resp);
        this.notify('Document Published');
        this.loadData(Terms.create(resp));
      })
      .catch(err => {
        this.logger.error('onSumbit:error: ', err);
      });
  }

  public valueChange(value: any): void {
    this.logger.debug("valueChange", value);
    this.dataService.query(value.value);
  }

  public editorChange(value: any): void {
    this.logger.debug("editorChange", value);
    this.form.patchValue({ html: value });
  }

  private loadData(item: any): void {
    this.logger.debug('loadData...', item);
    this.form.patchValue(item);
    this.value = (item.html as string);
    let {id} = this.form.value;
    this.recordId = id;
  }

  private notify(msg: string): void {
    this.logger.debug("notify", msg);
    this.notificationService.show({
      appendTo: this.appendTo,
      content: msg,
      animation: { type: "fade", duration: 300 },
      type: { style: "success", icon: true },
      position: {
        horizontal: "right",
        vertical: "top"
      },
      height: 50,
      // width: 150,
      hideAfter: 2000
    });
  }

  public openImageUpload() {
    setTimeout(() => this.dialog.open(), 200);
  }

  public onImageLoaded(args: any): void {
    this.logger.debug('onImageLoaded...', args);
    const {data } = args;
    this.form.patchValue({ id: data });
    // this.opportunity.id = data;
  }
}
