<link rel="stylesheet" href="https://unpkg.com/@progress/kendo-font-icons/dist/index.css" />
<form [formGroup]="form" (ngSubmit)="onSubmit()" class="k-form-inline">
  <fieldset>

    <div class="k-form-field">
      <label for="first_name" class="form-label col-3">First name</label>
      <div class="col-8">
        <input type="text" class="form-control" formControlName="first_name" id="first_name">
        <div *ngIf="first_name?.invalid" class="alert alert-danger">

          <div *ngIf="first_name?.errors?.['required']">
            First name is required.
          </div>
        </div>
      </div>
    </div>

    <div class="k-form-field">
      <label for="last_name" class="form-label col-3">Last name</label>
      <div class="col-8">
        <input type="text" class="form-control" formControlName="last_name" id="lastname">
        <div *ngIf="last_name?.invalid" class="alert alert-danger">
          <div *ngIf="last_name?.errors?.['required']">Last name is required.</div>
        </div>
      </div>
    </div>

    <div class="k-form-field">
      <label for="email" class="form-label col-3">Email</label>
      <div class="col-8">
        <input type="text" id="email" formControlName="email" class="form-control">
        <div *ngIf="email?.invalid" class="alert alert-danger">
          <div *ngIf="email?.errors?.['required']">Email is required.</div>
          <div *ngIf="email?.errors && email?.errors?.['email']">Please, enter valid email address.</div>
        </div>
      </div>
    </div>

    <div class="k-form-field">
      <label for="userRoles" class="form-label col-3">User Roles</label>
      <div class="col-8">
        <div class="component-container">
          <kendo-formfield showHints="always">
            <kendo-multiselect [data]="roles" textField="name" valueField="id" [(ngModel)]="selectedRoleIds"
              formControlName="userRoles"></kendo-multiselect>
            <!-- <kendo-label text="">
            </kendo-label> -->
            <div *ngIf="userRoles?.invalid" class="alert alert-danger">
              <div *ngIf="userRoles?.errors?.['required']">At least one User Role is required.</div>
            </div>
          </kendo-formfield>
        </div>
      </div>
    </div>

    <div class="d-grid d-md-flex justify-content-md-end">
      <button type="button" class="btn btn-primary me-md-2" type="submit" [disabled]="form.invalid"> Save</button>
      <button type="button" class="btn btn-secondary" (click)="closeWindow()"> Cancel</button>
    </div>

  </fieldset>
</form>
