import { CanActivateFn, Router } from '@angular/router';
import { AuthTokenService } from '../auth/auth-token.service';
import { inject } from '@angular/core';
import { AlertService } from '../commonServices/alert.service';

export const isLoggedInGuard: CanActivateFn = (route, state) => {
  let loggedIn = inject(AuthTokenService).isLoggedIn;
  // console.log('isLoggedInGuard...', loggedIn);
  if (!loggedIn) {
    // redirect to login page.
    // console.log('isLoggedInGuard...redirect to login...');
    inject(AlertService).openSnackBar('Must be logged in to access this service');
    inject(Router).navigate(['login']);
  } else {
    // do nothing
  }
  return loggedIn;
};
