<div class="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2"
  style="margin-left: auto; margin-right: auto; max-width: 765px;">

  <form [formGroup]="lForm" (ngSubmit)="changePassword()">
    <fieldset>

      <div class="row">
        <div class="row-nomargin">
          <label class="col-form-label col-10" style="text-align:left; margin: 0; padding-bottom:10px"
            for="password">New Password</label>
        </div>
        <div class="row-nomargin">

          <input type="password" id="password" formControlName="password" class="form-control">
          <div *ngIf="f['password'].touched && f['password'].invalid" class="alert alert-danger">
            <div *ngIf="f['password'].errors && f['password'].errors['required']">Password is required.</div>
            <div *ngIf="password?.errors?.['minlength']">
              Name must be at least 6 characters long.
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="row-nomargin">
          <label class="col-form-label col-10" style="text-align:left; margin: 0; padding-bottom:10px"
            for="password">Confirm Password</label>
        </div>
        <div class="row-nomargin">

          <input type="password" formControlName="confirmedPassword" class="form-control" name="confirmedPassword"
            id="confirmedPassword" autocomplete="new-password">
          <div *ngIf="confirmedPassword?.invalid && (confirmedPassword?.dirty || confirmedPassword?.touched)"
            class="alert alert-danger">
            <div *ngIf="confirmedPassword?.errors?.['required']">Confirm password is required.</div>
          </div>
          <div *ngIf="lForm.errors?.['confirmedPasswordValidator'] && (lForm.touched || lForm.dirty)"
            class="alert alert-danger">
            Passwords must match.</div>
        </div>
      </div>

      <div class="row">
        <div class="d-grid d-flex justify-content-end" style="margin-top: 20px;">
          <button type="button" class="btn btn-primary me-2" type="submit" [disabled]="lForm.invalid"> Save</button>
          <button type="button" class="btn btn-secondary" (click)="cancelPassword()"> Cancel</button>
        </div>
      </div>

    </fieldset>
  </form>
</div>
