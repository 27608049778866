import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef} from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoggerService } from '../../shared/logger/logger.service';
import { Opportunity } from '../../models/models.lib';
import { FormModes } from '../../common/constants.lib';
import {EditorComponent, EditorModule} from '@progress/kendo-angular-editor';
import { CheckboxListComponent } from '../../common-components/checkbox-list/checkbox-list.component';
import { InputsModule } from "@progress/kendo-angular-inputs";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { NotificationModule } from '@progress/kendo-angular-notification';
import { LabelModule } from "@progress/kendo-angular-label";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { DateInputsModule, FormatSettings } from '@progress/kendo-angular-dateinputs';
import { CasiDateRange } from '../../common/obj.lib';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { specificNumbersValidator } from '../../directives/casi.validators';
import { HelpButtonComponent } from '../../admin/help-info/help-button/help-button.component';
import moment from 'moment';
import {EditorImageUploadComponent} from "../../common-components/editor-image-upload/editor-image-upload.component";
import { HelpPopoverComponent } from '../../admin/help-info/help-popover/help-popover.component';

interface Item {
  id: number | null,
  description: string
}

@Component({
  selector: 'casi-edit-opportunity',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    FontAwesomeModule,
    RouterLink, RouterLinkActive,
    EditorModule,
    CheckboxListComponent,
    InputsModule,
    ButtonsModule,
    NotificationModule,
    LabelModule,
    DropDownsModule,
    DateInputsModule,
    LayoutModule,
    HelpButtonComponent,
    HelpPopoverComponent,
    EditorImageUploadComponent
  ],
  templateUrl: './edit-opportunity.component.html',
  styleUrl: './edit-opportunity.component.scss'
})
export class EditOpportunityComponent implements OnInit, OnDestroy {

  @Input({ required: true }) opportunity: Opportunity = new Opportunity();
  @Input({ required: true }) mode: FormModes = FormModes.NOTSET;

  @Output() onCancel = new EventEmitter<void>();
  @Output() onSave = new EventEmitter<any>();

  @ViewChild("uploadDialog") public dialog: EditorImageUploadComponent = <EditorImageUploadComponent>{};

  @Output() @ViewChild("full_description_editor") private full_description_editor: EditorComponent = <EditorComponent>{};
  @Output() @ViewChild("service_providers_editor") private service_providers_editor: EditorComponent = <EditorComponent>{};
  @Output() @ViewChild("elegibility_text_editor") private elegibility_text_editor: EditorComponent = <EditorComponent>{};
  @Output() @ViewChild("benefits_editor") private benefits_editor: EditorComponent = <EditorComponent>{};
  @Output() @ViewChild("negatives_editor") private negatives_editor: EditorComponent = <EditorComponent>{};
  @Output() @ViewChild("risks_editor") private risks_editor: EditorComponent = <EditorComponent>{};
  @Output() @ViewChild("costs_editor") private costs_editor: EditorComponent = <EditorComponent>{};
  @Output() @ViewChild("attribution_editor") private attribution_editor: EditorComponent = <EditorComponent>{};
  @Output() @ViewChild("potential_financial_benefits_editor") private potential_financial_benefits_editor: EditorComponent = <EditorComponent>{};

  public editor: EditorComponent = <EditorComponent>{};
  public fieldName : string = null as any;
  public value: string = '';
  public serviceProviderValue: string = '';
  public benefitsValue: string = '';
  public negativesValue: string = '';
  public risksValue: string = '';
  public costsValue: string = '';
  public attributionValue: string = '';
  public potentialFinancialBenefitsValue: string = '';

  public fundingRange: CasiDateRange = new CasiDateRange(null, null);
  public format: FormatSettings = {
    displayFormat: "dd/MM/yyyy",
    inputFormat: "dd/MM/yy",
  };
  public defaultItem: Item = {
    id: null,
    description: "Select item..."
  };

  public autoCorrect: boolean = true;
  public anzStep: number = 2;
  public infoQualStep: number = 2;

  public totalledRanking: number = 0;

  private _anzSpecifiedNumbers: number[] = [0, 2];
  private _infoQualSpecifiedNumbers: number[] = [0, 2, 4];

  form: FormGroup;

  private _subs: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private logger: LoggerService,
  ) {
    this.form = this.fb.group(
      {
        id: [null, []],
        title: [null, [Validators.required]],
        short_description: [null, [Validators.required]],
        full_description: [null, []],
        classification_id: [null, [Validators.required]],
        organisation_name: [null, []],
        organisation_contact_email: [null, [Validators.email]],
        organisation_url: [null, []],
        service_providers: [null, []],
        elegibility_text: [null, []],
        benefits: [null, []],
        negatives: [null, []],
        risks: [null, []],
        costs: [null, []],
        attribution: [null, []],
        funding_scheme_start: [null, []],
        funding_scheme_end: [null, []],
        potential_financial_benefits: [null, []],
        demonstrated_financial_benefit: [null, [Validators.required]],
        demonstrated_risks: [null, [Validators.required]],
        certification_accreditation_scheme: [null, [Validators.required]],
        anz_iso_certification: [null, [Validators.required, specificNumbersValidator(this._anzSpecifiedNumbers)]],
        demonstrated_access_to_markets: [null, [Validators.required]],
        public_information: [null, [Validators.required]],
        information_quality: [null, [Validators.required, specificNumbersValidator(this._infoQualSpecifiedNumbers)]],
        demonstrated_non_financial_benefits: [null, [Validators.required]],
        demonstrated_costs: [null, [Validators.required]],
        total_ranking: [null, []]
      }
    );
  }

  //#region Form properties

  get f() {
    return this.form.controls;
  }
  get title() {
    return this.form.get('title');
  }
  get short_description() {
    return this.form.get('short_description');
  }
  get classification_id() {
    return this.form.get('classification_id');
  }
  get organisation_name() {
    return this.form.get('organisation_name');
  }
  get organisation_contact_email() {
    return this.form.get('organisation_contact_email');
  }
  get organisation_url() {
    return this.form.get('organisation_url');
  }
  get service_providers() {
    return this.form.get('service_providers');
  }
  get elegibility_text() {
    return this.form.get('elegibility_text');
  }
  get benefits() {
    return this.form.get('benefits');
  }
  get negatives() {
    return this.form.get('negatives');
  }
  get risks() {
    return this.form.get('risks');
  }
  get costs() {
    return this.form.get('costs');
  }
  get attribution() {
    return this.form.get('attribution');
  }
  get funding_scheme_start() {
    return this.form.get('funding_scheme_start');
  }
  get funding_scheme_end() {
    return this.form.get('funding_scheme_end');
  }
  get potential_financial_benefits() {
    return this.form.get('potential_financial_benefits');
  }
  get demonstrated_financial_benefit() {
    return this.form.get('demonstrated_financial_benefit');
  }
  get demonstrated_risks() {
    return this.form.get('demonstrated_risks');
  }
  get certification_accreditation_scheme() {
    return this.form.get('certification_accreditation_scheme');
  }
  get anz_iso_certification() {
    return this.form.get('anz_iso_certification');
  }
  get demonstrated_access_to_markets() {
    return this.form.get('demonstrated_access_to_markets');
  }
  get public_information() {
    return this.form.get('public_information');
  }
  get information_quality() {
    return this.form.get('information_quality');
  }
  get demonstrated_non_financial_benefits() {
    return this.form.get('demonstrated_non_financial_benefits');
  }
  get demonstrated_costs() {
    return this.form.get('demonstrated_costs');
  }
  get total_ranking() {
    return this.form.get('total_ranking');
  }

  //#endregion

  //#region Ng Handlers

  ngOnInit(): void {
    this.logger.debug('ngOnInit...', this.opportunity);
    this.form.patchValue(this.opportunity);
    this.value = (this.opportunity.full_description as string);
    this.serviceProviderValue = (this.opportunity.service_providers as string);
    this.benefitsValue = (this.opportunity.benefits as string);
    this.negativesValue = (this.opportunity.negatives as string);
    this.risksValue = (this.opportunity.risks as string);
    this.costsValue = (this.opportunity.costs as string);
    this.attributionValue = (this.opportunity.attribution as string);
    this.potentialFinancialBenefitsValue = (this.opportunity.potential_financial_benefits as string);
    this.fundingRange = CasiDateRange.create(this.opportunity.funding_scheme_start, this.opportunity.funding_scheme_end);
    this.opportunity.totalRanking();
    this.totalledRanking = this.opportunity.totalledRanking;
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnInit...');
    this._subs.forEach(s => s.unsubscribe());
  }

  //#endregion

  //#region Form Handlers
  public openImageUpload(fieldName: string) {
    this.fieldName = fieldName;
    this.editor = (this as any)[`${fieldName}_editor`];
    setTimeout(() => this.dialog.open(), 200);
  }

  public onImageLoaded(args: any): void {
    this.logger.debug('onImageLoaded...', args);
    const {data } = args;
    this.form.patchValue({ id: data });
    this.opportunity.id = data;
  }

  onSubmit(): void {
    this.logger.debug(`onSubmit...`, this.form.value, this.mode);
    this.opportunity.setLists();
    // this.opportunity.setFundingDates(this.fundingRange);
    let toSave = { ...this.opportunity, ...this.form.value };
    const { start, end } = this.fundingRange;
    toSave.funding_scheme_start = start ? moment(start).format("YYYY-MM-DD") :  null;
    toSave.funding_scheme_end = end ? moment(end).format("YYYY-MM-DD") : null;
    this.logger.debug(`onSubmit:toSave: `, toSave);

    this.onSave.emit({
      action: "save",
      data: toSave,
      mode: this.mode
    });
  }

  closeWindow(): void {
    this.logger.debug(`closeWindow...`);
    this.onCancel.emit();
  }

  //#endregion

  //#region Kendo editor handlers

  public valueChange(value: string): void {
    this.logger.debug(`valueChange:`, value);
    this.form.patchValue({ full_description: value });
  }

  public serviceProviderValueChange(value: string): void {
    this.logger.debug('serviceProviderValueChange: ', value);
    this.form.patchValue({ service_providers: value });
  }

  public benefitsValueChange(value: string): void {
    this.logger.debug('benefitsValueChange: ', value);
    this.form.patchValue({ benefits: value });
  }

  public negativesValueChange(value: string): void {
    this.logger.debug('negativesValueChange: ', value);
    this.form.patchValue({ negatives: value });
  }

  public risksValueChange(value: string): void {
    this.logger.debug('risksValueChange: ', value);
    this.form.patchValue({ risks: value });
  }

  public costsValueChange(value: string): void {
    this.logger.debug('costsValueChange: ', value);
    this.form.patchValue({ costs: value });
  }

  public attributionValueChange(value: string): void {
    this.logger.debug('attributionValueChange: ', value);
    this.form.patchValue({ attribution: value });
  }

  public potentialFinancialBenefitsValueChange(value: string): void {
    this.logger.debug('potentialFinancialBenefitsValueChange: ', value);
    this.form.patchValue({ potential_financial_benefits: value });
  }

  //#endregion

  //#region Checkbox handlers

  public onGoalChecked(dataItem: any): void {
    this.toggleChecked(dataItem);
    this.logger.debug('onGoalChecked: ', dataItem);
  }

  public onIndustryChecked(dataItem: any): void {
    this.toggleChecked(dataItem);
    this.logger.debug('onIndustryChecked: ', dataItem);
  }

  public onStateChecked(dataItem: any): void {
    this.toggleChecked(dataItem);
    // dataItem.checked = !dataItem.checked;
    this.logger.debug('onIndustryChecked: ', dataItem);
  }

  public onElegibilityChecked(dataItem: any): void {
    this.toggleChecked(dataItem);
    // dataItem.checked = !dataItem.checked;
    this.logger.debug('onElegibilityChecked: ', dataItem);
  }

  public onBTypeChecked(dataItem: any): void {
    this.toggleChecked(dataItem);
    this.logger.debug('onBTypeChecked: ', dataItem);
  }

  //#endregion

  //#region Dropdown handlers

  //#endregion

  public onNumericChange(value: string): void {
    this.logger.debug(`onNumericChange: ${value}`);
    this.totalledRanking = this.totalRankings();
  }

  public toggleAllStates(): void {
    this.logger.debug('toggleAllStates...');
    this.opportunity.toggleAllStates();
  }

  //#region Private functions

  private toggleChecked(dataItem: any): void {
    this.logger.debug('toggleChecked: ', dataItem);
    dataItem.checked = !dataItem.checked;
  }

  private totalRankings(): number {
    this.logger.debug('totalRankings...');
    const { demonstrated_financial_benefit,
      certification_accreditation_scheme,
      anz_iso_certification,
      demonstrated_access_to_markets,
      public_information,
      information_quality,
      demonstrated_non_financial_benefits,
      demonstrated_risks, demonstrated_costs
    } = this.form.value;
    //  this.logger.debug('totalRankings:', demonstrated_access_to_markets, certification_accreditation_scheme,
    //  anz_iso_certification, demonstrated_financial_benefit,
    //  public_information, information_quality);

    let totalled = (demonstrated_financial_benefit || 0) +
      (certification_accreditation_scheme || 0) +
      (anz_iso_certification || 0) +
      (demonstrated_access_to_markets || 0) +
      (public_information || 0) + (information_quality || 0) +
      (demonstrated_non_financial_benefits || 0) +
      (demonstrated_risks || 0) +
      (demonstrated_costs || 0);
    this.form.patchValue({ total_ranking: totalled });

    return totalled;
  }

  //#endregion

}
