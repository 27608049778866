/**-----------------------------------------------------------------------------------------
* Copyright © 2024 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import * as i0 from '@angular/core';
import { Directive, Input, HostBinding, forwardRef, Component, NgModule, EventEmitter, isDevMode, Output, ContentChild, ViewChild } from '@angular/core';
import { isDocumentAvailable, guid, hasObservers, KendoInput } from '@progress/kendo-angular-common';
import * as i1 from '@progress/kendo-angular-l10n';
import { ComponentMessages, LocalizationService, L10N_PREFIX } from '@progress/kendo-angular-l10n';
import * as i3 from '@angular/common';
import { CommonModule } from '@angular/common';
import { NgControl } from '@angular/forms';
import { validatePackage } from '@progress/kendo-licensing';
import { Observable, Subscription } from 'rxjs';

/**
 * @hidden
 */
const _c0 = ["*"];
function FloatingLabelComponent_label_2_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 4);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1("(", ctx_r0.textFor("optional"), ")");
  }
}
function FloatingLabelComponent_label_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "label", 2);
    i0.ɵɵtext(1);
    i0.ɵɵtemplate(2, FloatingLabelComponent_label_2_span_2_Template, 2, 1, "span", 3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", ctx_r0.labelCssClass)("ngStyle", ctx_r0.labelCssStyle)("for", ctx_r0.id);
    i0.ɵɵattribute("id", ctx_r0.labelId);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r0.text, "");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.optional);
  }
}
function LabelComponent_span_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 3);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1("(", ctx_r0.textFor("optional"), ")");
  }
}
const isInputElement = component => component instanceof HTMLElement;
/**
 * @hidden
 */
const inputElementHasAttr = (element, attribute) => element.hasAttribute(attribute);
/**
 * @hidden
 */
const getWrappedNativeInput = element => element.querySelector('kendo-label > input, kendo-label > textarea, kendo-label > select');
/**
 * @hidden
 */
const getRootElement = element => {
  if (!element) {
    return null;
  }
  let rootElement = element;
  while (rootElement.parentElement) {
    rootElement = rootElement.parentElement;
  }
  return rootElement;
};

/**
 * Represents the [Kendo UI Label directive for Angular]({% slug overview_label %}).
 * The Label associates a focusable Angular component or an HTML element
 * with a `label` tag by using the `[for]` property binding.
 *
 * To associate a component by using the `label` element, either:
 * * Set the `[for]` property binding to a
 * [template reference variable](link:site.data.urls.angular['templatesyntax']#template-reference-variables--var-), or
 * * Set the `[for]` property binding to an `id` HTML string value.
 *
 * @example
 * ```ts
 * _@Component({
 * selector: 'my-app',
 * template: `
 *  <div class="row example-wrapper" style="min-height: 450px;">
 *    <div class="col-xs-12 col-md-6 example-col">
 *      <label [for]="datepicker">DatePicker: </label>
 *      <kendo-datepicker #datepicker></kendo-datepicker>
 *    </div>
 *
 *    <div class="col-xs-12 col-md-6 example-col">
 *      <label for="input">Input: </label>
 *      <input id="input" />
 *    </div>
 *  </div>
 * `
 * })
 * class AppComponent { }
 * ```
 */
class LabelDirective {
  constructor(label, renderer, zone) {
    this.label = label;
    this.renderer = renderer;
    this.zone = zone;
    /**
     * @hidden
     *
     * Allows the user to specify if the label CSS class should be rendered or not.
     */
    this.labelClass = true;
    this.handleClick = () => {
      const component = this.getFocusableComponent();
      if (!component) {
        return;
      }
      if (component.focus) {
        component.focus();
      }
    };
  }
  get labelFor() {
    if (typeof this.for === 'string') {
      return this.for;
    }
    if (!isDocumentAvailable()) {
      return null;
    }
    const component = this.getFocusableComponent() || {};
    if (isInputElement(component) && !inputElementHasAttr(component, 'id')) {
      this.renderer.setAttribute(component, 'id', `k-${guid()}`);
    }
    return component.focusableId || component.id || null;
  }
  /**
   * @hidden
   */
  ngAfterViewInit() {
    this.setAriaLabelledby();
    this.zone.runOutsideAngular(() => this.clickListener = this.renderer.listen(this.label.nativeElement, 'click', this.handleClick));
  }
  /**
   * @hidden
   */
  ngOnDestroy() {
    if (this.clickListener) {
      this.clickListener();
    }
  }
  /**
   * @hidden
   */
  setAriaLabelledby() {
    if (!isDocumentAvailable()) {
      return;
    }
    const component = this.getFocusableComponent();
    if (component && component.focusableId) {
      const rootElement = getRootElement(this.label.nativeElement);
      const labelTarget = rootElement.querySelector(`#${component.focusableId}`);
      const labelElement = this.label.nativeElement;
      const id = labelElement.id || `k-${guid()}`;
      if (!labelElement.getAttribute('id')) {
        this.renderer.setAttribute(labelElement, 'id', id);
      }
      // Editor in iframe mode needs special treatment
      if (component.focusableId.startsWith('k-editor') && component.iframe) {
        component.contentAreaLoaded.subscribe(() => {
          this.zone.runOutsideAngular(() => {
            setTimeout(() => {
              const editableElement = component.container.element.nativeElement.contentDocument.body.firstElementChild;
              this.renderer.setAttribute(editableElement, 'aria-label', labelElement.textContent);
            });
          });
        });
      }
      if (!labelTarget) {
        return;
      }
      this.renderer.setAttribute(labelTarget, 'aria-labelledby', id);
    }
  }
  getFocusableComponent() {
    const target = this.for;
    return target && target.focus !== undefined ? target : null;
  }
}
LabelDirective.ɵfac = function LabelDirective_Factory(t) {
  return new (t || LabelDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.NgZone));
};
LabelDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: LabelDirective,
  selectors: [["label", "for", ""]],
  hostVars: 3,
  hostBindings: function LabelDirective_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("for", ctx.labelFor);
      i0.ɵɵclassProp("k-label", ctx.labelClass);
    }
  },
  inputs: {
    for: "for",
    labelClass: "labelClass"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LabelDirective, [{
    type: Directive,
    args: [{
      // eslint-disable-next-line @angular-eslint/directive-selector
      selector: 'label[for]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.NgZone
    }];
  }, {
    for: [{
      type: Input
    }],
    labelFor: [{
      type: HostBinding,
      args: ['attr.for']
    }],
    labelClass: [{
      type: Input
    }, {
      type: HostBinding,
      args: ['class.k-label']
    }]
  });
})();

/**
 * @hidden
 */
class Messages extends ComponentMessages {}
Messages.ɵfac = /* @__PURE__ */(() => {
  let ɵMessages_BaseFactory;
  return function Messages_Factory(t) {
    return (ɵMessages_BaseFactory || (ɵMessages_BaseFactory = i0.ɵɵgetInheritedFactory(Messages)))(t || Messages);
  };
})();
Messages.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: Messages,
  selectors: [["kendo-label-messages-base"]],
  inputs: {
    optional: "optional"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Messages, [{
    type: Directive,
    args: [{
      // eslint-disable-next-line @angular-eslint/directive-selector
      selector: 'kendo-label-messages-base'
    }]
  }], null, {
    optional: [{
      type: Input
    }]
  });
})();

/**
 * @hidden
 */
class LocalizedMessagesDirective extends Messages {
  constructor(service) {
    super();
    this.service = service;
  }
}
LocalizedMessagesDirective.ɵfac = function LocalizedMessagesDirective_Factory(t) {
  return new (t || LocalizedMessagesDirective)(i0.ɵɵdirectiveInject(i1.LocalizationService));
};
LocalizedMessagesDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: LocalizedMessagesDirective,
  selectors: [["", "kendoLabelLocalizedMessages", ""], ["", "kendoFloatingLabelLocalizedMessages", ""]],
  features: [i0.ɵɵProvidersFeature([{
    provide: Messages,
    useExisting: forwardRef(() => LocalizedMessagesDirective)
  }]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LocalizedMessagesDirective, [{
    type: Directive,
    args: [{
      providers: [{
        provide: Messages,
        useExisting: forwardRef(() => LocalizedMessagesDirective)
      }],
      selector: `
      [kendoLabelLocalizedMessages],
      [kendoFloatingLabelLocalizedMessages]
    `
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }];
  }, null);
})();

/**
 * Custom component messages override default component messages
 * ([see example]({% slug label_globalization %}#toc-localization)).
 */
class CustomMessagesComponent extends Messages {
  constructor(service) {
    super();
    this.service = service;
  }
  get override() {
    return true;
  }
}
CustomMessagesComponent.ɵfac = function CustomMessagesComponent_Factory(t) {
  return new (t || CustomMessagesComponent)(i0.ɵɵdirectiveInject(i1.LocalizationService));
};
CustomMessagesComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: CustomMessagesComponent,
  selectors: [["kendo-label-messages"], ["kendo-floatinglabel-messages"]],
  features: [i0.ɵɵProvidersFeature([{
    provide: Messages,
    useExisting: forwardRef(() => CustomMessagesComponent)
  }]), i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function CustomMessagesComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CustomMessagesComponent, [{
    type: Component,
    args: [{
      providers: [{
        provide: Messages,
        useExisting: forwardRef(() => CustomMessagesComponent)
      }],
      selector: 'kendo-label-messages, kendo-floatinglabel-messages',
      template: ``
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }];
  }, null);
})();
const SHARED_DIRECTIVES = [LocalizedMessagesDirective, CustomMessagesComponent];
/**
 * @hidden
 */
class SharedDirectivesModule {}
SharedDirectivesModule.ɵfac = function SharedDirectivesModule_Factory(t) {
  return new (t || SharedDirectivesModule)();
};
SharedDirectivesModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: SharedDirectivesModule
});
SharedDirectivesModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SharedDirectivesModule, [{
    type: NgModule,
    args: [{
      declarations: [SHARED_DIRECTIVES],
      exports: [SHARED_DIRECTIVES]
    }]
  }], null, null);
})();

/**
 * @hidden
 */
const packageMetadata = {
  name: '@progress/kendo-angular-label',
  productName: 'Kendo UI for Angular',
  productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
  publishDate: 1713437442,
  version: '15.5.0',
  licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/'
};

/**
 * @hidden
 */
class FloatingLabelInputAdapter {
  constructor(component, formControl) {
    this.component = component;
    const isObservableOrEventEmitter = event => event instanceof Observable || event instanceof EventEmitter;
    if (isObservableOrEventEmitter(component.onFocus)) {
      this.onFocus = component.onFocus;
    }
    if (isObservableOrEventEmitter(component.autoFillStart)) {
      this.autoFillStart = component.autoFillStart;
    }
    if (isObservableOrEventEmitter(component.autoFillEnd)) {
      this.autoFillEnd = component.autoFillEnd;
    }
    if (isObservableOrEventEmitter(component.onBlur)) {
      this.onBlur = component.onBlur;
    }
    if (formControl) {
      this.onValueChange = formControl.valueChanges;
    } else if (component.valueChange) {
      this.onValueChange = component.valueChange;
    }
  }
  get focusableId() {
    const component = this.component;
    if ('focusableId' in component) {
      return component.focusableId;
    } else if ('id' in component) {
      return component.id;
    }
    return "";
  }
  set focusableId(value) {
    const component = this.component;
    if ('focusableId' in component) {
      component.focusableId = value;
    } else if ('id' in component) {
      component.id = value;
    }
  }
}
const isFunction = x => Object.prototype.toString.call(x) === '[object Function]';
/**
 * Represents the [Kendo UI FloatingLabel component for Angular]({% slug overview_floatinglabel %}).
 * Provides floating labels to `input` elements.
 *
 * The FloatingLabel supports both Template and Reactive Forms and
 * [can contain Kendo UI for Angular Input components such as `kendo-combobox` and `kendo-numerictextbox`,
 * or `kendo-textbox`](slug:overview_floatinglabel#toc-implementing-floating-labels).
 *
 * @example
 * ```html
 *     <kendo-floatinglabel text="First name">
 *       <kendo-textbox [(ngModel)]="name"></kendo-textbox>
 *     </kendo-floatinglabel>
 * ```
 */
class FloatingLabelComponent {
  constructor(elementRef, renderer, changeDetectorRef, localization) {
    this.elementRef = elementRef;
    this.renderer = renderer;
    this.changeDetectorRef = changeDetectorRef;
    this.localization = localization;
    this.hostClasses = true;
    /**
     * Fires after the floating label position is changed.
     */
    this.positionChange = new EventEmitter();
    /**
     * @hidden
     */
    this.focused = false;
    /**
     * @hidden
     */
    this.empty = true;
    /**
     * @hidden
     */
    this.invalid = false;
    /**
     * @hidden
     */
    this.labelId = `k-${guid()}`;
    this.autoFillStarted = false;
    validatePackage(packageMetadata);
    this.direction = localization.rtl ? 'rtl' : 'ltr';
    this.renderer.removeAttribute(this.elementRef.nativeElement, "id");
  }
  /**
   * Represents the current floating label position.
   */
  get labelPosition() {
    if (!this.empty) {
      return 'Out';
    }
    return this.focused ? 'Out' : 'In';
  }
  get focusedClass() {
    return this.focused;
  }
  get invalidClass() {
    return this.invalid;
  }
  /**
   * @hidden
   */
  ngAfterContentInit() {
    if (!isDocumentAvailable()) {
      return;
    }
    this.validateSetup();
    const control = new FloatingLabelInputAdapter(this.kendoInput || this.formControl.valueAccessor, this.formControl);
    this.addHandlers(control);
    this.setLabelFor(control);
  }
  ngAfterViewInit() {
    if (this.kendoInput) {
      this.setAriaLabelledby(this.kendoInput);
    }
  }
  /**
   * @hidden
   */
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  /**
   * @hidden
   */
  textFor(key) {
    return this.localization.get(key);
  }
  subscribe(control, eventName, handler) {
    if (control[eventName] instanceof EventEmitter) {
      const subscription = control[eventName].subscribe(handler);
      if (!this.subscription) {
        this.subscription = subscription;
      } else {
        this.subscription.add(subscription);
      }
    }
  }
  updateState() {
    const empty = value => {
      // zero is not an empty value (e.g., NumericTextBox)
      if (value === 0 || value === false) {
        return false;
      }
      // empty arrays are an empty value (e.g., MultiSelect)
      if (Array.isArray(value) && !value.length) {
        return true;
      }
      return !value;
    };
    const formControl = this.formControl;
    if (formControl) {
      const valueAccessor = formControl.valueAccessor;
      if (isFunction(valueAccessor.isEmpty)) {
        this.empty = valueAccessor.isEmpty();
      } else {
        this.empty = empty(formControl.value);
      }
      this.invalid = formControl.invalid && (formControl.touched || formControl.dirty);
    } else {
      this.empty = isFunction(this.kendoInput.isEmpty) ? this.kendoInput.isEmpty() : empty(this.kendoInput.value);
    }
    if (this.empty) {
      this.renderer.addClass(this.elementRef.nativeElement, 'k-empty');
    } else {
      this.renderer.removeClass(this.elementRef.nativeElement, 'k-empty');
    }
    this.changeDetectorRef.markForCheck();
  }
  setAriaLabelledby(component) {
    const componentId = component.focusableId || component.id;
    if (componentId) {
      const focusableElement = this.elementRef.nativeElement.querySelector(`#${componentId}`);
      this.renderer.setAttribute(focusableElement, 'aria-labelledby', this.labelId);
    }
  }
  setLabelFor(control) {
    const controlId = control.focusableId || control.id;
    if (this.id && controlId) {
      // input wins
      this.id = controlId;
    } else if (this.id) {
      control.focusableId = this.id;
    } else if (controlId) {
      this.id = controlId;
    } else {
      const id = `k-${guid()}`;
      control.focusableId = id;
      this.id = id;
    }
  }
  handleAutofill(control) {
    this.subscribe(control, 'autoFillStart', () => {
      this.autoFillStarted = true;
      this.renderer.removeClass(this.elementRef.nativeElement, 'k-empty');
    });
    this.subscribe(control, 'autoFillEnd', () => {
      if (this.autoFillStarted) {
        this.autoFillStarted = false;
        if (this.empty) {
          this.renderer.addClass(this.elementRef.nativeElement, 'k-empty');
        }
      }
    });
  }
  addHandlers(control) {
    const setFocus = isFocused => () => {
      this.focused = isFocused;
      this.updateState();
      if (!this.empty) {
        return;
      }
      if (hasObservers(this.positionChange)) {
        this.positionChange.emit(isFocused ? 'Out' : 'In');
      }
    };
    this.subscribe(control, 'onFocus', setFocus(true));
    this.subscribe(control, 'onBlur', setFocus(false));
    this.handleAutofill(control);
    const updateState = () => this.updateState();
    updateState();
    this.subscribe(control, 'onValueChange', updateState);
  }
  validateSetup() {
    if (!this.formControl && !this.kendoInput) {
      if (isDevMode()) {
        throw new Error("The FloatingLabelComponent requires a Kendo Input component" + " or a forms-bound component to function properly.");
      }
      return;
    }
  }
}
FloatingLabelComponent.ɵfac = function FloatingLabelComponent_Factory(t) {
  return new (t || FloatingLabelComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i1.LocalizationService));
};
FloatingLabelComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FloatingLabelComponent,
  selectors: [["kendo-floatinglabel"]],
  contentQueries: function FloatingLabelComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, KendoInput, 5);
      i0.ɵɵcontentQuery(dirIndex, NgControl, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.kendoInput = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.formControl = _t.first);
    }
  },
  hostVars: 7,
  hostBindings: function FloatingLabelComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("dir", ctx.direction);
      i0.ɵɵclassProp("k-floating-label-container", ctx.hostClasses)("k-focus", ctx.focusedClass)("k-invalid", ctx.invalidClass);
    }
  },
  inputs: {
    labelCssStyle: "labelCssStyle",
    labelCssClass: "labelCssClass",
    id: "id",
    text: "text",
    optional: "optional"
  },
  outputs: {
    positionChange: "positionChange"
  },
  exportAs: ["kendoFloatingLabel"],
  features: [i0.ɵɵProvidersFeature([LocalizationService, {
    provide: L10N_PREFIX,
    useValue: 'kendo.floatinglabel'
  }])],
  ngContentSelectors: _c0,
  decls: 3,
  vars: 1,
  consts: () => {
    let i18n_0;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text for the optional segment of a FloatingLabel component
       * @meaning kendo.floatinglabel.optional
       */
      const MSG__USERS_BSCOTT28_CODE_2021_CASI_NODEJSWEB_ENVIRONER_NG_NODE_MODULES__PROGRESS_KENDO_ANGULAR_LABEL_FESM2020_PROGRESS_KENDO_ANGULAR_LABEL_MJS_0 = goog.getMsg("Optional");
      i18n_0 = MSG__USERS_BSCOTT28_CODE_2021_CASI_NODEJSWEB_ENVIRONER_NG_NODE_MODULES__PROGRESS_KENDO_ANGULAR_LABEL_FESM2020_PROGRESS_KENDO_ANGULAR_LABEL_MJS_0;
    } else {
      i18n_0 = $localize`:kendo.floatinglabel.optional|The text for the optional segment of a FloatingLabel component:Optional`;
    }
    return [["kendoFloatingLabelLocalizedMessages", "", "optional", i18n_0], ["class", "k-label", 3, "ngClass", "ngStyle", "for", 4, "ngIf"], [1, "k-label", 3, "ngClass", "ngStyle", "for"], ["class", "k-label-optional", 4, "ngIf"], [1, "k-label-optional"]];
  },
  template: function FloatingLabelComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵelementContainer(0, 0);
      i0.ɵɵprojection(1);
      i0.ɵɵtemplate(2, FloatingLabelComponent_label_2_Template, 3, 6, "label", 1);
    }
    if (rf & 2) {
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", ctx.text);
    }
  },
  dependencies: [LocalizedMessagesDirective, i3.NgIf, i3.NgClass, i3.NgStyle],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FloatingLabelComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-floatinglabel',
      exportAs: 'kendoFloatingLabel',
      providers: [LocalizationService, {
        provide: L10N_PREFIX,
        useValue: 'kendo.floatinglabel'
      }],
      template: `
        <ng-container kendoFloatingLabelLocalizedMessages
            i18n-optional="kendo.floatinglabel.optional|The text for the optional segment of a FloatingLabel component"
            optional="Optional"
         >
        </ng-container>
        <ng-content></ng-content>
        <label *ngIf="text" [ngClass]="labelCssClass" [ngStyle]="labelCssStyle" [for]="id" [attr.id]="labelId" class="k-label">
            {{ text }}<span *ngIf="optional" class="k-label-optional">({{textFor('optional')}})</span>
        </label>
    `
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ChangeDetectorRef
    }, {
      type: i1.LocalizationService
    }];
  }, {
    hostClasses: [{
      type: HostBinding,
      args: ['class.k-floating-label-container']
    }],
    focusedClass: [{
      type: HostBinding,
      args: ['class.k-focus']
    }],
    invalidClass: [{
      type: HostBinding,
      args: ['class.k-invalid']
    }],
    direction: [{
      type: HostBinding,
      args: ['attr.dir']
    }],
    labelCssStyle: [{
      type: Input
    }],
    labelCssClass: [{
      type: Input
    }],
    id: [{
      type: Input
    }],
    text: [{
      type: Input
    }],
    optional: [{
      type: Input
    }],
    positionChange: [{
      type: Output
    }],
    kendoInput: [{
      type: ContentChild,
      args: [KendoInput, {
        static: false
      }]
    }],
    formControl: [{
      type: ContentChild,
      args: [NgControl, {
        static: false
      }]
    }]
  });
})();
const COMPONENT_DIRECTIVES$1 = [FloatingLabelComponent];
/**
 * Represents the [NgModule](link:site.data.urls.angular['ngmoduleapi'])
 * definition for the TextBox directive.
 *
 * @example
 *
 * ```ts-no-run
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, FloatingLabelModule], // import FloatingLabel module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
class FloatingLabelModule {}
FloatingLabelModule.ɵfac = function FloatingLabelModule_Factory(t) {
  return new (t || FloatingLabelModule)();
};
FloatingLabelModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FloatingLabelModule
});
FloatingLabelModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, SharedDirectivesModule], SharedDirectivesModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FloatingLabelModule, [{
    type: NgModule,
    args: [{
      declarations: [...COMPONENT_DIRECTIVES$1],
      exports: [...COMPONENT_DIRECTIVES$1, SharedDirectivesModule],
      imports: [CommonModule, SharedDirectivesModule]
    }]
  }], null, null);
})();

/**
 * Represents the [Kendo UI Label component for Angular]({% slug overview_label %}).
 *
 * Associates a label with input elements or components.
 *
 * @example
 * ```html
 * <kendo-label [for]="input" text="First name">
 *   <kendo-textbox #input></kendo-textbox>
 * </kendo-label>
 * ```
 */
class LabelComponent {
  constructor(elementRef, renderer, localization) {
    this.elementRef = elementRef;
    this.renderer = renderer;
    this.localization = localization;
    this.subscriptions = new Subscription();
    validatePackage(packageMetadata);
    this.direction = localization.rtl ? 'rtl' : 'ltr';
    this.renderer.removeAttribute(this.elementRef.nativeElement, 'id');
  }
  /**
   * @hidden
   */
  ngAfterContentInit() {
    if (this.for) {
      this.control = this.for;
      return;
    }
    const wrappedNativeInput = getWrappedNativeInput(this.elementRef.nativeElement);
    if (wrappedNativeInput) {
      if (!wrappedNativeInput.hasAttribute('id')) {
        this.renderer.setAttribute(wrappedNativeInput, 'id', `k-${guid()}`);
      }
      this.control = wrappedNativeInput;
      return;
    }
    this.control = this.kendoInput;
  }
  /**
   * @hidden
   */
  ngOnInit() {
    this.subscriptions.add(this.localization.changes.subscribe(({
      rtl
    }) => {
      this.direction = rtl ? 'rtl' : 'ltr';
    }));
  }
  /**
   * @hidden
   */
  ngAfterViewInit() {
    this.labelDirective.setAriaLabelledby();
  }
  /**
   * @hidden
   */
  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
  /**
   * @hidden
   */
  textFor(key) {
    return this.localization.get(key);
  }
}
LabelComponent.ɵfac = function LabelComponent_Factory(t) {
  return new (t || LabelComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i1.LocalizationService));
};
LabelComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: LabelComponent,
  selectors: [["kendo-label"]],
  contentQueries: function LabelComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, KendoInput, 7);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.kendoInput = _t.first);
    }
  },
  viewQuery: function LabelComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(LabelDirective, 7);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.labelDirective = _t.first);
    }
  },
  hostVars: 1,
  hostBindings: function LabelComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("dir", ctx.direction);
    }
  },
  inputs: {
    text: "text",
    for: "for",
    optional: "optional",
    labelCssStyle: "labelCssStyle",
    labelCssClass: "labelCssClass"
  },
  exportAs: ["kendoLabel"],
  features: [i0.ɵɵProvidersFeature([LocalizationService, {
    provide: L10N_PREFIX,
    useValue: 'kendo.label'
  }])],
  ngContentSelectors: _c0,
  decls: 5,
  vars: 7,
  consts: () => {
    let i18n_1;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text for the optional segment of a Label component
       * @meaning kendo.label.optional
       */
      const MSG__USERS_BSCOTT28_CODE_2021_CASI_NODEJSWEB_ENVIRONER_NG_NODE_MODULES__PROGRESS_KENDO_ANGULAR_LABEL_FESM2020_PROGRESS_KENDO_ANGULAR_LABEL_MJS_1 = goog.getMsg("Optional");
      i18n_1 = MSG__USERS_BSCOTT28_CODE_2021_CASI_NODEJSWEB_ENVIRONER_NG_NODE_MODULES__PROGRESS_KENDO_ANGULAR_LABEL_FESM2020_PROGRESS_KENDO_ANGULAR_LABEL_MJS_1;
    } else {
      i18n_1 = $localize`:kendo.label.optional|The text for the optional segment of a Label component:Optional`;
    }
    return [["kendoLabelLocalizedMessages", "", "optional", i18n_1], [3, "for", "ngClass", "ngStyle"], ["class", "k-label-optional", 4, "ngIf"], [1, "k-label-optional"]];
  },
  template: function LabelComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵelementContainer(0, 0);
      i0.ɵɵelementStart(1, "label", 1);
      i0.ɵɵtext(2);
      i0.ɵɵtemplate(3, LabelComponent_span_3_Template, 2, 1, "span", 2);
      i0.ɵɵelementEnd();
      i0.ɵɵprojection(4);
    }
    if (rf & 2) {
      i0.ɵɵadvance();
      i0.ɵɵclassProp("k-label-empty", !ctx.text);
      i0.ɵɵproperty("for", ctx.control)("ngClass", ctx.labelCssClass)("ngStyle", ctx.labelCssStyle);
      i0.ɵɵadvance();
      i0.ɵɵtextInterpolate1(" ", ctx.text, "");
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.optional);
    }
  },
  dependencies: [LocalizedMessagesDirective, LabelDirective, i3.NgClass, i3.NgStyle, i3.NgIf],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LabelComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-label',
      exportAs: 'kendoLabel',
      providers: [LocalizationService, {
        provide: L10N_PREFIX,
        useValue: 'kendo.label'
      }],
      template: `
        <ng-container kendoLabelLocalizedMessages
            i18n-optional="kendo.label.optional|The text for the optional segment of a Label component"
            optional="Optional"
         >
        </ng-container>
        <label
            [for]="control"
            [class.k-label-empty]="!text"
            [ngClass]="labelCssClass"
            [ngStyle]="labelCssStyle"
        >
            {{ text }}<span *ngIf="optional" class="k-label-optional">({{textFor('optional')}})</span>
        </label>
        <ng-content></ng-content>
    `
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i1.LocalizationService
    }];
  }, {
    direction: [{
      type: HostBinding,
      args: ['attr.dir']
    }],
    text: [{
      type: Input
    }],
    for: [{
      type: Input
    }],
    optional: [{
      type: Input
    }],
    labelCssStyle: [{
      type: Input
    }],
    labelCssClass: [{
      type: Input
    }],
    labelDirective: [{
      type: ViewChild,
      args: [LabelDirective, {
        static: true
      }]
    }],
    kendoInput: [{
      type: ContentChild,
      args: [KendoInput, {
        static: true
      }]
    }]
  });
})();
const COMPONENT_DIRECTIVES = [LabelDirective, LabelComponent];
/**
 * The exported package module.
 *
 * The package exports:
 * - `LabelDirective`&mdash;The Label directive class.
 * - `LabelComponent`&mdash;The Label component class
 * - `FLoatingLabel`&mdash;The FloatingLabel component class.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the Label module
 * import { LabelModule } from '@progress/kendo-angular-label';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, LabelModule], // import Label module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
class LabelModule {}
LabelModule.ɵfac = function LabelModule_Factory(t) {
  return new (t || LabelModule)();
};
LabelModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: LabelModule
});
LabelModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, SharedDirectivesModule], FloatingLabelModule, SharedDirectivesModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LabelModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, SharedDirectivesModule],
      declarations: [...COMPONENT_DIRECTIVES],
      exports: [...COMPONENT_DIRECTIVES, FloatingLabelModule, SharedDirectivesModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CustomMessagesComponent, FloatingLabelComponent, FloatingLabelModule, LabelComponent, LabelDirective, LabelModule, LocalizedMessagesDirective, SharedDirectivesModule };