import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class TrackerApiService {

  constructor(
    private apiService: ApiService
  ) {
  }

  trackIt(p: any): Promise<any> {
    return this.apiService.postHttpCallAsPromise(`logtrackactivity`, p, 'trackIt');
  }

}
