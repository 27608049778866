<ul>
  <li *ngIf="showFilter">
    <input
      class="k-textbox k-input k-rounded-md"
      (input)="onInput($event)"
    />
  </li>
  <li
    #itemElement
    *ngFor="let item of currentData; let i = index"
    (click)="onSelectionChange(valueAccessor(item), itemElement)"
    [ngClass]="{ 'k-selected': isItemSelected(item) }"
    [ngStyle]="{'display':'flex'}"
  >
    <input
      type="checkbox"
      #notification
      kendoCheckBox
      [checked]="isItemSelected(item)"
      [ngStyle]="{'margin-top':'3px'}"
    />
    <kendo-label
      class="k-checkbox-label"
      [for]="notification"
      [text]="textAccessor(item)"
      [ngStyle]="{'width':'100%', 'text-align':'right'}"
    ></kendo-label>
  </li>
</ul>
