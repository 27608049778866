import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { LoggerService } from '../shared/logger/logger.service';
import { BehaviorSubject } from 'rxjs';

import { AuthService } from './auth.service';
import { AuthTokenService } from './auth-token.service';
import {
  AccessPermissions, CanDo, IClaimSubAccess, IPermission, Permission,
  User,
  accessEntities
} from './me.lib';
import { jwtDecode } from 'jwt-decode';

import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class MeService {

  availablePermissions: AccessPermissions = new AccessPermissions(); //

  //#region Private properties

  private _cachedToken = new BehaviorSubject<string | null>(null);

  //#endregion

  constructor(
    private storage: StorageService,
    private logger: LoggerService,
    private authService: AuthService,
    private authTokenService: AuthTokenService
  ) { }

  get(): User | null {
    return this.authTokenService.getClaims();
  }

  isLoggedIn(): boolean {
    return this.authTokenService.loggedIn;
  }

  hasPermission(permission: CanDo, accessClaims?: IClaimSubAccess): boolean {
    if (this.authTokenService.loggedIn) {
      let { entity, action } = permission;
      // let access = this.authTokenService.getAccessClaims();
      if (!accessClaims) {
        return false;
      }
      let myPermission = accessClaims.permissions.filter(p => p.entity == entity)[0];

      if (!myPermission) {
        return false;
      }
      for (let a of myPermission.actions) {
        if (a === action) {
          return true;
        }
      }
      return false;
    } else {
      return false;
    }
  };


  allICanDo(entityParam: string): any {
    let permission = this.availablePermissions.accessPermission(entityParam);
    if (!permission) {
      return undefined;
    }
    let { entity } = permission;
    interface IObj {
      [key: string]: boolean
    };
    let returnObj: IObj = {};

    let accessClaims = this.authTokenService.getAccessClaims();
    for (let prop of permission.actions) {
      returnObj[`can_${prop}`] = this.hasPermission(new CanDo(entity, prop), accessClaims);
    }
    return returnObj;
  };



}
