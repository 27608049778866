import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { map, tap } from "rxjs/operators";
import { Terms } from '../models/models.lib';
import { APP_CONFIG } from '../setup/config';
import { FormModes } from '../common/constants.lib';
import { environment } from '../../environments/environment';

const { apiUrl } = environment;

export abstract class EnvironerTermsService extends BehaviorSubject<any>{
  public loading: boolean = false;

  constructor(
    private http: HttpClient,
  ) {
    super([]);
  }

  public query(docType: string = 'terms'): void {
    this.fetch(docType)
      .subscribe((x) => {
        // console.log('query: x: ', x);
        return super.next(x);
      });
  }

  protected fetch(docType: string = 'terms'): Observable<Terms> {
    this.loading = true;

    return this.http.get(encodeURI(`${apiUrl}/latesttermsconditions?doc_type=${docType}`))
      .pipe(
        map((response: any, i) => {
          // console.log('fetch:map:response: ', response, i);
          // let returning =<Role[]>response; // response.map(r =>  Role.create((r)));
          // console.log('fetch:map:response: ', returning);
          return <Terms>response;
        }),
        map(r => Terms.create(r)),
        // tap(nr => {
        //   console.log('nr: ', nr);
        // }),
        tap(() => (this.loading = false))
      )
  }
}

@Injectable({
  providedIn: 'root'
})
export class TermsService extends EnvironerTermsService {

  constructor(
    http: HttpClient,
    private apiService: ApiService
  ) {
    super(http)
  }

  get(docType: string = 'terms') {
    return this.apiService.getHttpCall(`termsconditions?doc_type=${docType}`, 'get');
  }

  getLatest(docType: string = 'terms') {
    return this.apiService.getHttpCall(`latesttermsconditions?doc_type=${docType}`, 'getLatest');
  }

  add(p: any): Promise<any> {
    return this.apiService.postHttpCallAsPromise('termsconditions', p, 'add');
  }

  update(p: any): Promise<any> {
    return this.apiService.putHttpCallAsPromise('updateuserterms', p, 'update');
  }

  approvePage(id: number): Promise<any> {
    return this.apiService.putHttpCallAsPromise(`termsconditions/${id}`, 'approvePage');
  }

}
/**
 *
 * {
  "id": 8,
  "html": "...",
  "version_number": 6,
  "approved": true,
  "created_by_user_id": null,
  "updated_by_user_id": null,
  "doc_type": "terms",
  "createdAt": "2023-11-07T22:57:59.107Z",
  "updatedAt": "2023-11-07T22:58:02.537Z"
}
 */
