import { Component, NO_ERRORS_SCHEMA, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { LoggerService } from '../../shared/logger/logger.service';
import { DialogsModule } from "@progress/kendo-angular-dialog";
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterLink, RouterLinkActive } from '@angular/router';
import {
  faPlusCircle, faAddressCard,
  faEdit, faTrash, faBan
} from '@fortawesome/free-solid-svg-icons';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { WindowModule } from "@progress/kendo-angular-dialog";
import { NotificationService, NotificationModule } from "@progress/kendo-angular-notification";
import { AppEnities, FormModes } from '../../common/constants.lib';
import { MeService } from '../../auth/me.service';
import { HelpInfo } from '../../models/models.lib';
import { CompositeFilterDescriptor, SortDescriptor, filterBy, distinct, orderBy } from "@progress/kendo-data-query";
import { GridModule, PageChangeEvent, PagerModule, PagerPosition, PagerType } from '@progress/kendo-angular-grid';
import { MulticheckFilterComponent } from '../../filters/multicheck-filter/multicheck-filter.component';
import { findNearestNumber, setTitle } from '../../common/utils.lib';
import { HelpInfoService } from '../help-info.service';
import { EditHelpComponent } from './edit-help/edit-help.component';
import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';

const { HELP } = AppEnities;

@Component({
  selector: 'casi-help-info',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    FontAwesomeModule,
    RouterLink, RouterLinkActive,
    InputsModule,
    ButtonsModule,
    DialogsModule,
    WindowModule,
    NotificationModule,
    PagerModule,
    GridModule,
    MulticheckFilterComponent,
    EditHelpComponent,
    SafeHtmlPipe
  ],
  templateUrl: './help-info.component.html',
  styleUrl: './help-info.component.scss'
})
export class HelpInfoComponent implements OnInit, OnDestroy {

  public view: Observable<HelpInfo[]> = new Observable<HelpInfo[]>();
  public rawData: HelpInfo[] = [];
  public filter: CompositeFilterDescriptor = { logic: "and", filters: [] };
  public gridData: HelpInfo[] = filterBy(this.rawData, this.filter);

  public currentItem: HelpInfo = new HelpInfo();

  public pagerTypes = ["numeric", "input"];
  public type: PagerType = "numeric";
  public info = true;
  public pageSizes = true;
  public previousNext = true;
  public position: PagerPosition = "bottom";

  public pageSize = 10;

  public formMode: FormModes = FormModes.NOTSET;

  public editWinOpen: boolean = false;
  public errorWinOpen: boolean = false;
  public confirmDeleteRole: boolean = false;
  public accessRightsWinOpen: boolean = false;
  public errorMsgOpen: boolean = false;

  public canUpdate: boolean = false;

  @ViewChild("appendTo", { read: ViewContainerRef }) appendTo: ViewContainerRef | undefined;

  private _showTitle = new BehaviorSubject<string>('');
  get showTitle() {
    return this._showTitle.getValue();
  }
  set showTitle(val: string) {
    this._showTitle.next(val);
  }

  private _subs: Subscription[] = [];

  constructor(
    private logger: LoggerService,
    private notificationService: NotificationService,
    private me: MeService,
    public dataService: HelpInfoService,
    public faIcons: FaIconLibrary
  ) {
    faIcons.addIcons(
      faPlusCircle, faAddressCard, faEdit,
      faTrash,
      faBan
    );
  }

  public sort: SortDescriptor[] = [
    {
      field: "completed",
      dir: "asc",
    },
  ];

  ngOnInit(): void {
    this.logger.debug('ngOnInit...');
    const { can_update } = this.me.allICanDo(HELP)
    this.logger.debug('ngOnInit:permissions: ', can_update);
    this.canUpdate = can_update;

    this.dataService.query();
    this._subs.push(this.dataService.subscribe(items => {
      this.logger.debug('ngOnInit:items: ', items);
      this.rawData = items;
      this.loadData();
    }));
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnDestroy...');
    this._subs.forEach(s => s.unsubscribe());
  }

  public onAddItem(): void {
    this.logger.debug("onAddItem...");
    this.currentItem = new HelpInfo();
    this.formMode = FormModes.NEW;
    this.logger.debug("onAddItem:currentItem ", this.currentItem);
    this.showTitle = setTitle(this.formMode, 'HELP INFORMATION');
    this.editWinOpen = true;
  }

  onEditClick(dataItem: any): void {
    this.logger.debug("onEditClick: ", dataItem);
    this.currentItem = HelpInfo.create(dataItem);
    this.formMode = FormModes.EDIT;
    this.logger.debug("onEditClick:currentItem ", this.currentItem);
    this.showTitle = setTitle(this.formMode, 'HELP INFORMATION');
    this.editWinOpen = true;
  }

  confirmComplete(dataItem: any) {
    dataItem.completed = !dataItem.completed;
    this.logger.debug('confirmComplete:dataItem: ', dataItem);
    this.currentItem = dataItem;
    this.dataService.update(dataItem)
      .then(resp => {
        this.notificationService.show({
          appendTo: this.appendTo,
          content: `Enquiry marked as ${dataItem.completed ? "completed" : "not completed"}`,
          animation: { type: "fade", duration: 300 },
          type: { style: "success", icon: true },
          position: {
            horizontal: "right",
            vertical: "top"
          },
          height: 50,
          width: 150,
          hideAfter: 2000
        });
      })
      .catch(err => {
        this.logger.error(`confirmComplete:error: `, err);
        this.notificationService.show({
          appendTo: this.appendTo,
          content: `Error updating enquiry`,
          animation: { type: "fade", duration: 300 },
          type: { style: "error", icon: true },
          position: {
            horizontal: "right",
            vertical: "top"
          },
          height: 50,
          width: 150,
          hideAfter: 2000
        });
      })
      .finally(() => this.dataService.query());
  }

  onClearFilters(gridName: string) {
    this.logger.debug("onClearFilters: ", gridName);
    this.filter = { logic: "and", filters: [] };
    this.loadData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.logger.debug("sortChange: ", sort);
    this.sort = sort;
    this.loadData();
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    // this.logger.debug('filterChange: filter: ', filter);
    this.filter = filter;
    this.loadData();
  }

  public distinctPrimitive(fieldName: string): any[] {
    // this.logger.debug('distinctPrimitive: fieldName: ', fieldName);
    return distinct(this.gridData, fieldName).map((item: any) => item[fieldName]);
  }


  public close(): void {
    this.logger.debug('close...');
    this.editWinOpen = false;
  }

  public onSave(args: any): void {
    this.logger.debug('onSave...', args);
    this.handleSaveAndClose(args);
  }

  private loadData(): void {
    // this.logger.debug('loadData...');
    this.gridData = orderBy(filterBy(this.rawData, this.filter), this.sort);
    this.pageSize = this.gridData.length;
  }

  private handleSaveAndClose(args: any): void {
    this.logger.debug('handleSaveAndClose...', args);
    const { data, mode } = args;
    this.dataService.save(data, mode)
      .then(resp => {
        this.notificationService.show({
          appendTo: this.appendTo,
          content: `Help Information ${mode == FormModes.NEW ? 'Added' : 'Updated'}`,
          animation: { type: "fade", duration: 300 },
          type: { style: "success", icon: true },
          position: {
            horizontal: "right",
            vertical: "top"
          },
          // closable: true,
          height: 50,
          width: 150,
          hideAfter: 2000
        });
        this.dataService.query();
      })
      .catch(err => {
        this.logger.error('handleAddAddressClose:err: ', err);
      })
      .finally(() => this.editWinOpen = false);
    // this.editWinOpen = false;
  }

}
