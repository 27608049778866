<casi-editor-image-upload [editor]="editor" [fieldName]="fieldName" [recordId]="opportunity.id" tableName="opportunity"
  (onLoad)="onImageLoaded($event)" #uploadDialog></casi-editor-image-upload>

<form [formGroup]="form" class="k-form-inline">
  <fieldset>
    <div class="k-form-field">
      <label for="title" class="form-label col-3">Title</label>
      <div class="col-8">
<!--        <input type="text" id="title" formControlName="title" class="form-control">-->
        <textarea class="form-control" formControlName="title" id="title"></textarea>
        <div *ngIf="title?.invalid" class="alert alert-danger">
          <div *ngIf="title?.errors?.['required']">Title is required.</div>
        </div>
      </div>
    </div>

    <div class="k-form-field">
      <label for="short_description" class="form-label col-3">Short Description</label>
      <div class=" col-8">
        <textarea class="form-control" formControlName="short_description" id="short_description" required></textarea>
        <div *ngIf="short_description?.invalid" class="alert alert-danger">
          <div *ngIf="short_description?.errors?.['required']">Short Description is required.</div>
        </div>
      </div>
    </div>

    <div class="k-form-field">
      <label class="form-label k-label col-3">Full Description</label>
      <div class=" col-8">
        <kendo-editor formControlName="full_description" style="height: 300px;" #full_description_editor>
          <kendo-toolbar>
<!--            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>

            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
              <kendo-toolbar-button text="Upload Image"
                (click)="openImageUpload('full_description')"></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
            <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
<!--            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
<!--            <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>-->
<!--            <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>-->
          </kendo-toolbar>
        </kendo-editor>
      </div>
    </div>

    <div class="k-form-field form-check">
      <label class="form-label k-label col-3" style="padding-top: 0;">Opportunity Goals</label>
      <div class="col-8">
        <ul>
          @for(goal of opportunity.goalListDisplay; track goal.id){
          <kendo-label for="goal{{goal.id}}" class="k-checkbox-label">{{goal.goal}}</kendo-label>
          <input type="checkbox" size="large" kendoCheckBox class="form-check-input" id="goal{{goal.id}}"
            (click)="onGoalChecked(goal)" [checked]="goal.checked" />
          <br>
          }
          @empty {
          <li>No goals found.</li>
          }

        </ul>
      </div>
    </div>

    <div class="k-form-field">
      <!--      <kendo-label [for]="classificationSelection" text="Classification"></kendo-label>-->
      <!--      <br />-->
      <label class="form-label col-3 k-label">Classification</label>
      <div class=" col-8">
        <kendo-dropdownlist #classificationSelection formControlName="classification_id"
          [data]="opportunity.classificationDisplay" [defaultItem]="defaultItem" textField="description" valueField="id"
          [valuePrimitive]="true" required>
        </kendo-dropdownlist>
        <br>
        <div *ngIf="classification_id?.invalid" class="alert alert-danger">
          <div *ngIf="classification_id?.errors?.['required']">Classification is required.</div>
        </div>
      </div>
    </div>

    <div class="k-form-field form-check">
      <label class="form-label k-label col-3" style="padding-top: 0;">Industries</label>
      <div class="col-8">
        <ul>
          @for(industry of opportunity.industryListDisplay; track industry.id){
          <kendo-label for="industry{{industry.id}}" class="k-checkbox-label">{{industry.description}}</kendo-label>
          <input type="checkbox" size="large" kendoCheckBox class="form-check-input" id="industry{{industry.id}}"
            (click)="onIndustryChecked(industry)" [checked]="industry.checked" />
          <br>
          }
          @empty {
          <li>No industries found.</li>
          }
        </ul>
      </div>
    </div>

    <div class="k-form-field form-check">
      <label class="form-label k-label col-3">Location</label>

      <div class="col-7 mt-2">
        <ul>
          @for(state of opportunity.stateListDisplay; track state.id){
          <!-- <li>{{goal | json}}</li> -->
          <kendo-label for="state{{state.id}}" class="k-checkbox-label">{{state.description}}</kendo-label>
          <input type="checkbox" size="large" kendoCheckBox class="form-check-input" id="state{{state.id}}"
            (click)="onElegibilityChecked(state)" [checked]="state.checked" />
          <br>
          }
          @empty {
          <li>No locations found.</li>
          }
        </ul>
      </div>
      <div class="col-1 mt-2">
        <button class="btn btn-secondary" kendoButton (click)="toggleAllStates()">{{opportunity.stateLabel}}All</button>
      </div>
    </div>

    <div class="k-form-field">
      <label for="organisation_name" class="form-label col-3">Organisation Name</label>
      <div class="col-8">
        <input type="text" id="organisation_name" formControlName="organisation_name" class="form-control">

      </div>
    </div>

    <div class="k-form-field">
      <label for="organisation_contact_email" class="form-label col-3">Organisation Contact Email</label>
      <div class="col-8">
        <input type="text" id="organisation_contact_email" formControlName="organisation_contact_email"
          class="form-control">
        <div *ngIf="organisation_contact_email?.invalid" class="alert alert-danger">
          <div *ngIf="organisation_contact_email?.errors && organisation_contact_email?.errors?.['email']">Please, enter
            valid email address.</div>
        </div>
      </div>

    </div>

    <div class="k-form-field">
      <label for="organisation_url" class="form-label col-3">Organisation URL</label>
      <div class="col-8">
        <textarea id="organisation_url" formControlName="organisation_url" class="form-control"></textarea>
      </div>
    </div>

    <div class="k-form-field">
      <label  class="form-label k-label col-3">Service Providers</label>
      <div class=" col-8">
        <kendo-editor formControlName="service_providers" style="height: 300px;" #service_providers_editor>
          <kendo-toolbar>
<!--            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>

            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
              <kendo-toolbar-button text="Upload Image"
                (click)="openImageUpload('service_providers')"></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
            <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
<!--            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
<!--            <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>-->
<!--            <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>-->
          </kendo-toolbar>
        </kendo-editor>
      </div>
    </div>

    <div class="k-form-field form-check">
      <label class="form-label k-label col-3">Only Accessible For</label>
      <div class="col-sm-5 mt-2">
        <ul>
          @for(item of opportunity.elegibilityDisplay; track item.id){
          <!-- <li>{{goal | json}}</li> -->
          <kendo-label for="elegibility_json{{item.id}}" class="k-checkbox-label">{{item.description}}</kendo-label>
          <input type="checkbox" size="large" kendoCheckBox class="form-check-input" id="elegibility_json{{item.id}}"
            (click)="onElegibilityChecked(item)" [checked]="item.checked" />
          <br>
          }
          @empty {
          <li>No elegibilities found.</li>
          }
        </ul>
      </div>
    </div>

    <div class="k-form-field">
      <label for="service_providers" class="form-label k-label col-3">Eligibility Description</label>
      <div class=" col-8">
        <kendo-editor formControlName="elegibility_text" style="height: 300px;" #elegibility_text_editor>
          <kendo-toolbar>
<!--            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>

            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
              <kendo-toolbar-button text="Upload Image"
                (click)="openImageUpload('elegibility_text')"></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
            <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
<!--            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
<!--            <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>-->
<!--            <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>-->
          </kendo-toolbar>
        </kendo-editor>
      </div>
    </div>

    <div class="k-form-field form-check">
      <label class="form-label k-label col-3" style="padding-top: 0;">Outcomes</label>
      <div class="col-8">
        <ul>
          @for(item of opportunity.benefitListDisplay; track item.id){
          <kendo-label for="bType{{item.id}}" class="k-checkbox-label">{{item.description}}</kendo-label>
          <input type="checkbox" size="large" kendoCheckBox class="form-check-input" id="bType{{item.id}}"
            (click)="onBTypeChecked(item)" [checked]="item.checked" />
          <br>
          }
          @empty {
          <li>No locations found.</li>
          }
        </ul>
      </div>
    </div>

    <div class="k-form-field">
      <label for="benefits" class="form-label k-label col-3">Benefits</label>
      <div class=" col-8">
        <kendo-editor formControlName="benefits" style="height: 300px;" #benefits_editor>
          <kendo-toolbar>
<!--            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>

            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
              <kendo-toolbar-button text="Upload Image" (click)="openImageUpload('benefits')"></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
            <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
<!--            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
<!--            <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>-->
<!--            <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>-->
          </kendo-toolbar>
        </kendo-editor>
      </div>
    </div>

    <div class="k-form-field">
      <label for="negatives" class="form-label k-label col-3">Negatives</label>
      <div class=" col-8">
        <kendo-editor formControlName="negatives" style="height: 300px;" #negatives_editor>
          <kendo-toolbar>
<!--            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>

            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
              <kendo-toolbar-button text="Upload Image" (click)="openImageUpload('negatives')"></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
            <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
<!--            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
<!--            <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>-->
<!--            <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>-->
          </kendo-toolbar>
        </kendo-editor>
      </div>
    </div>

    <div class="k-form-field">
      <label for="risks" class="form-label k-label col-3">Risks</label>
      <div class=" col-8">
        <kendo-editor formControlName="risks" style="height: 300px;" #risks_editor>
          <kendo-toolbar>
<!--            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>

            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
              <kendo-toolbar-button text="Upload Image" (click)="openImageUpload('risks')"></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
            <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
<!--            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
<!--            <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>-->
<!--            <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>-->
          </kendo-toolbar>
        </kendo-editor>
      </div>
    </div>

    <div class="k-form-field">
      <label for="costs" class="form-label k-label col-3">Costs</label>
      <div class=" col-8">
        <kendo-editor formControlName="costs" style="height: 300px;" #costs_editor>
          <kendo-toolbar>
<!--            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>

            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
              <kendo-toolbar-button text="Upload Image" (click)="openImageUpload('costs')"></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
            <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
<!--            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
<!--            <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>-->
<!--            <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>-->
          </kendo-toolbar>
        </kendo-editor>
      </div>
    </div>

    <div class="k-form-field">
      <label for="attribution" class="form-label k-label col-3">Attribution</label>
      <div class=" col-8">
        <kendo-editor formControlName="attribution" style="height: 300px;" #attribution_editor>
          <kendo-toolbar>
<!--            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>

            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
              <kendo-toolbar-button text="Upload Image" (click)="openImageUpload('attribution')"></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
            <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
<!--            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
<!--            <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>-->
<!--            <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>-->
          </kendo-toolbar>
        </kendo-editor>
      </div>
    </div>

    <div class="k-form-field">
      <label for="funding_scheme_start" class="form-label col-3">Funding Scheme</label>
      <div class="col-8">
        <kendo-daterange>
          <kendo-label text="Start">
            <kendo-dateinput [format]="format" kendoDateRangeStartInput [(value)]="fundingRange.start"
              [readonly]="true"></kendo-dateinput>
          </kendo-label>
          <kendo-label text="End">
            <kendo-dateinput [format]="format" kendoDateRangeEndInput [(value)]="fundingRange.end"
              [readonly]="true"></kendo-dateinput>
          </kendo-label>
        </kendo-daterange>
      </div>
    </div>

    <div class="k-form-field">
      <label for="potential_financial_benefits" class="form-label k-label col-3">Potential Financial Benefits</label>
      <div class=" col-8">
        <kendo-editor formControlName="potential_financial_benefits" style="height: 300px;"
          #potential_financial_benefits_editor>
          <kendo-toolbar>
<!--            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>

            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
              <kendo-toolbar-button text="Upload Image"
                (click)="openImageUpload('potential_financial_benefits')"></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
            <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
<!--            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
<!--            <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>-->
<!--            <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>-->
          </kendo-toolbar>
        </kendo-editor>
      </div>
    </div>

    <div class="k-form-field row">
      <label class="form-label k-label col-3" style="text-decoration: underline">Rank this Opportunity:</label>
    </div>

    <div class="k-form-field">

      <label class="col-6 form-label k-label">Demonstrated Financial Benefit (0-1) </label>
      <div><casi-help-popover [infoId]="1"></casi-help-popover></div>

      <div class="col-5">
        <kendo-numerictextbox format="##" [autoCorrect]="autoCorrect" [style.width.px]="100" [min]="0" [max]="1"
          formControlName="demonstrated_financial_benefit" (valueChange)="onNumericChange($event)">
        </kendo-numerictextbox>
        <div *ngIf="demonstrated_financial_benefit?.invalid" class="alert alert-danger">
          <div *ngIf="demonstrated_financial_benefit?.errors?.['required']">Value is required.</div>
        </div>
      </div>
    </div>


    <div class="k-form-field">

      <label class="col-6 form-label k-label">Defined Risks (0-1) </label>
      <div><casi-help-popover [infoId]="7"></casi-help-popover></div>

      <div class="col-5">
        <kendo-numerictextbox format="##" [autoCorrect]="autoCorrect" [style.width.px]="100" [min]="0" [max]="1"
          formControlName="demonstrated_risks" (valueChange)="onNumericChange($event)">
        </kendo-numerictextbox>
        <div *ngIf="demonstrated_risks?.invalid" class="alert alert-danger">
          <div *ngIf="demonstrated_risks?.errors?.['required']">Value is required.</div>
        </div>
      </div>
    </div>

    <div class="k-form-field">

      <label class="col-6 form-label k-label">Certification / Accreditation Scheme (0-2) </label>
      <div><casi-help-popover [infoId]="2"></casi-help-popover></div>

      <div class="col-5">
        <kendo-numerictextbox format="##" [autoCorrect]="autoCorrect" [style.width.px]="100" [min]="0" [max]="2"
          formControlName="certification_accreditation_scheme" (valueChange)="onNumericChange($event)">
        </kendo-numerictextbox>
        <div *ngIf="certification_accreditation_scheme?.invalid" class="alert alert-danger">

          <div *ngIf="certification_accreditation_scheme?.errors?.['required']">Value is required.</div>
        </div>
      </div>
    </div>

    <div class="k-form-field">

      <label class="col-6 form-label k-label">Standards have ANZ / ISO accreditation (0, 2) </label>
      <div><casi-help-popover [infoId]="3"></casi-help-popover></div>

      <div class="col-5">
        <kendo-numerictextbox format="##" [step]="anzStep" [autoCorrect]="autoCorrect" [style.width.px]="100" [min]="0"
          [max]="2" formControlName="anz_iso_certification" (valueChange)="onNumericChange($event)">
        </kendo-numerictextbox>
        <div *ngIf="anz_iso_certification?.invalid" class="alert alert-danger">

          <div *ngIf="anz_iso_certification?.errors?.['required']">Value is required.</div>
          <div
            *ngIf="!anz_iso_certification?.errors?.['required'] && anz_iso_certification?.errors?.['specificNumbers']">
            Must be in the specified numbers.</div>
        </div>
      </div>
    </div>

    <div class="k-form-field">


      <label class="col-6 form-label k-label">Demonstrated Access to Certain Markets (0-2) </label>
      <div><casi-help-popover [infoId]="4"></casi-help-popover></div>

      <div class="col-5">
        <kendo-numerictextbox format="##" [autoCorrect]="autoCorrect" [style.width.px]="100" [min]="0" [max]="2"
          formControlName="demonstrated_access_to_markets" (valueChange)="onNumericChange($event)">
        </kendo-numerictextbox>
        <div *ngIf="demonstrated_access_to_markets?.invalid" class="alert alert-danger">
          <div *ngIf="demonstrated_access_to_markets?.errors?.['required']">Value is required.</div>
        </div>
      </div>
    </div>

    <div class="k-form-field">

      <label class="col-6 form-label k-label">Publicly Accessible Information (0-1) </label>
      <div><casi-help-popover [infoId]="5"></casi-help-popover></div>

      <div class="col-5">
        <kendo-numerictextbox format="##" [autoCorrect]="autoCorrect" [style.width.px]="100" [min]="0" [max]="1"
          formControlName="public_information" (valueChange)="onNumericChange($event)">
        </kendo-numerictextbox>
        <div *ngIf="public_information?.invalid" class="alert alert-danger">
          <div *ngIf="public_information?.errors?.['required']">Value is required.</div>
        </div>
      </div>
    </div>

    <div class="k-form-field">


      <label class="col-6 form-label k-label">Quality of Information Resources (0, 2, 4)</label>
      <div><casi-help-popover [infoId]="6"></casi-help-popover></div>

      <div class="col-5">
        <kendo-numerictextbox format="##" [step]="infoQualStep" [autoCorrect]="autoCorrect" [style.width.px]="100"
          [min]="0" [max]="4" formControlName="information_quality" (valueChange)="onNumericChange($event)">
        </kendo-numerictextbox>
        <div *ngIf="information_quality?.invalid" class="alert alert-danger">
          <div *ngIf="information_quality?.errors?.['required']">Value is required.</div>
          <div *ngIf="!information_quality?.errors?.['required'] && information_quality?.errors?.['specificNumbers']">
            Must be in the specified numbers.</div>
        </div>
      </div>
    </div>

    <div class="k-form-field">

      <label class="col-6 form-label k-label">Demonstrated Non Financial Benefits (0-1) </label>
      <div><casi-help-popover [infoId]="8"></casi-help-popover></div>

      <div class="col-5">
        <kendo-numerictextbox format="##" [autoCorrect]="autoCorrect" [style.width.px]="100" [min]="0" [max]="1"
          formControlName="demonstrated_non_financial_benefits" (valueChange)="onNumericChange($event)">
        </kendo-numerictextbox>
        <div *ngIf="demonstrated_non_financial_benefits?.invalid" class="alert alert-danger">
          <div *ngIf="demonstrated_non_financial_benefits?.errors?.['required']">Value is required.</div>
        </div>
      </div>
    </div>

    <div class="k-form-field">

      <label class="col-6 form-label k-label">Quantified Costs (0-1) </label>
      <div><casi-help-popover [infoId]="9"></casi-help-popover></div>

      <div class="col-5">
        <kendo-numerictextbox format="##" [autoCorrect]="autoCorrect" [style.width.px]="100" [min]="0" [max]="1"
          formControlName="demonstrated_costs" (valueChange)="onNumericChange($event)">
        </kendo-numerictextbox>
        <div *ngIf="demonstrated_costs?.invalid" class="alert alert-danger">
          <div *ngIf="demonstrated_costs?.errors?.['required']">Value is required.</div>
        </div>
      </div>
    </div>

    <div class="k-form-field">
      <label class="form-label k-label col-6">Total Ranking </label>
      <div style="visibility: hidden;"><casi-help-popover [infoId]="9"></casi-help-popover></div>
      <!-- hidden: just for the same space-->
      <div class="col-5">
        <!--      <kendo-label class="k-form" text="Total Ranking">-->
        <!--      </kendo-label>-->
        <kendo-numerictextbox format="##" [readonly]="true" [style.width.px]="100" [value]="totalledRanking">
        </kendo-numerictextbox>
      </div>
    </div>



    <div class="d-grid d-md-flex justify-content-md-end">
      <button type="button" class="btn btn-primary me-md-2" (click)="onSubmit()" [disabled]="form.invalid">Save</button>

      <button type="button" class="btn btn-secondary" (click)="closeWindow()"> Cancel</button>
    </div>

  </fieldset>
</form>
