import {Component, EventEmitter, Input, NO_ERRORS_SCHEMA, OnDestroy, OnInit, Output} from '@angular/core';
import {CommonModule} from "@angular/common";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {LoggerService} from "../../shared/logger/logger.service";
import {OpportunityReviewsService} from "../opportunity-reviews.service";
import {Opportunity} from "../../models/models.lib";

@Component({
  selector: 'casi-send-review',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
  ],
  schemas: [NO_ERRORS_SCHEMA],
  templateUrl: './send-review.component.html',
  styleUrl: './send-review.component.scss'
})
export class SendReviewComponent implements OnInit, OnDestroy  {

  @Input({ required: true }) opportunity: Opportunity = null as any;
  @Output() onCancel = new EventEmitter<void>();
  @Output() onSave = new EventEmitter<any>();

  form: FormGroup;
  disableSave: boolean = false;

  constructor(
    private fb: FormBuilder,
    private logger: LoggerService,
    public dataService: OpportunityReviewsService
  ) {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
      });
  }

  get f() {
    return this.form.controls;
  }
  get email() {
    return this.form.get('email');
  }

  ngOnInit(): void {
    this.logger.debug('ngOnInit...');
    this.disableSave = false;
    if(this.opportunity.organisation_contact_email)
      this.form.patchValue({email: this.opportunity.organisation_contact_email});
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnInit...');
  }

  onSubmit(): void {
    this.logger.debug(`onSubmit...`);
    this.disableSave = true;
    this.onSave.emit({
      action: "save",
      data: {id: this.opportunity.id, email: this.email?.value}
    });
  }

  closeWindow(): void {
    this.logger.debug(`closeWindow...`);
    this.onCancel.emit();
  }
}
