import { Plugin, PluginKey } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';
import { styleValue } from './../mark';
export const caretColorKey = new PluginKey('caret-color');
export function caretColor() {
    return new Plugin({
        key: caretColorKey,
        props: {
            decorations: (state) => {
                const { doc, selection, storedMarks } = state;
                if (!selection.empty || !storedMarks) {
                    return DecorationSet.empty;
                }
                const color = styleValue((storedMarks || []).find((m) => m.type.name === 'style'), { name: 'color', value: /^.+$/ });
                if (!color) {
                    return DecorationSet.empty;
                }
                const parentNode = selection.$anchor.parent;
                const decorations = [];
                doc.descendants((node, pos) => {
                    if (node.eq(parentNode)) {
                        decorations.push(Decoration.node(pos, pos + node.nodeSize, {
                            style: 'caret-color: ' + color
                        }));
                    }
                });
                return DecorationSet.create(doc, decorations);
            }
        }
    });
}
