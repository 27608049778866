<link rel="stylesheet" href="https://unpkg.com/@progress/kendo-font-icons/dist/index.css" />
<div class="k-overlay" *ngIf="editWinOpen"></div>
<div class="k-overlay" *ngIf="accessRightsWinOpen"></div>
<div class="container" my-5>
  <div class="card" #appendTo>
    <div class="card-body no-border">
      <div class="card-title">
        <h3 style="text-align: left;" class="page-header">Manage Roles</h3>
      </div>

      <kendo-grid [kendoGridBinding]="gridData" [loading]="dataService.loading" [sort]="sort" [sortable]="true"
        [groupable]="true" [resizable]="true" [filter]="filter" filterable="menu" (filterChange)="filterChange($event)"
        (sortChange)="sortChange($event)" style="height: calc(100vh - 400px); min-height: 250px;" [pageSize]="pageSize"
        [pageable]="true">

        <ng-template kendoGridToolbarTemplate position="top">
          <button (click)="onAddRole()" kendoButton class="btn btn-primary">
            <fa-icon icon="circle-plus"></fa-icon> Add
            Role
          </button>
        </ng-template>

        <kendo-grid-column title="Edit" [width]="70">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <button (click)="onEditClick(dataItem)" kendoButton class="btn btn-stn-grid">
              <fa-icon icon="edit"></fa-icon>
            </button>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Access Rights" [width]="150">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <button (click)="onEditPermisions(dataItem)" kendoButton class="btn btn-stn-grid" style="width: 70px;">
              <fa-icon icon="address-card"></fa-icon>
              <span> {{dataItem.permissionCnt}}</span>
            </button>
          </ng-template>

        </kendo-grid-column>

        <!-- <kendo-grid-column field="id" title="Id"></kendo-grid-column> -->
        <kendo-grid-column field="name" title="Name">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
            let-filterService="filterService">
            <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
              [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Delete" [width]="70">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <button (click)="onConfirmDelete(dataItem)" kendoButton class="btn btn-stn-grid">
              <fa-icon icon="trash"></fa-icon>
            </button>
          </ng-template>
        </kendo-grid-column>

        <ng-template kendoPagerTemplate>
          <kendo-pager-info *ngIf="pageSize > 0"></kendo-pager-info>
        </ng-template>

      </kendo-grid>

    </div>
  </div>

</div>

<kendo-window *ngIf="editWinOpen" (close)="close($event)" [minWidth]="320" [maxWidth]="500" [width]="450">
  <kendo-window-titlebar>
    <span class="k-window-title">{{showTitle}}</span>
  </kendo-window-titlebar>
  <casi-edit-roles [role]="currentRole" [mode]="formMode" (panelClosed)="close($event)"></casi-edit-roles>
</kendo-window>

<kendo-dialog *ngIf="errorWinOpen" (close)="onDialogClose()">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      <span class="k-icon k-font-icon k-i-exclamation-circle"></span> Duplicate Role Name
    </div>
  </kendo-dialog-titlebar>

  <h4>That Role already exists!</h4>

  <kendo-dialog-actions>
    <button kendoButton (click)="onDialogClose()">Close and Retry</button>
  </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog *ngIf="confirmDeleteRole" title="Please confirm" (close)="onConfirmedClose('cancel')" [minWidth]="250"
  [width]="450">

  <h4>Delete Role - <b>{{currentRole.name}}</b>?</h4>

  <kendo-dialog-actions>
    <button kendoButton (click)="onConfirmedClose('delete')" themeColor="primary">Delete</button>
    <button kendoButton (click)="onConfirmedClose('cancel')">
      Cancel
    </button>
  </kendo-dialog-actions>
</kendo-dialog>

<kendo-window *ngIf="accessRightsWinOpen" (close)="closeAccessRights()" [minWidth]="320" [width]="700">
  <kendo-window-titlebar>
    <span class="k-window-title">MANAGE ACCESS RIGHTS</span>
    <button kendoWindowCloseAction></button>

  </kendo-window-titlebar>
  <casi-role-permission [role]="currentRole" [permissionSet]="permissionSet" (onSave)="onRolesPermissionSave()"
    (onCancel)="closeAccessRights()"></casi-role-permission>
</kendo-window>

<kendo-dialog *ngIf="errorMsgOpen" (close)="onErrorDialogClose()">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      <span class="k-icon k-font-icon k-i-exclamation-circle"></span> Update Issue
    </div>
  </kendo-dialog-titlebar>

  <h4>The current role: {{this.currentRole.name}} has permissions. Please remove permissions first before deleting.</h4>

  <kendo-dialog-actions>
    <button kendoButton (click)="onErrorDialogClose()" themeColor="primary">Close</button>
  </kendo-dialog-actions>
</kendo-dialog>

<div kendoDialogContainer></div>
