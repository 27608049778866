import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { LoggerService } from '../../../shared/logger/logger.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
  faTriangleExclamation,
  faPlusCircle, faAddressCard,
  faEdit, faTrash, faBan, faDownload, faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { SafeHtmlPipe } from '../../../pipes/safe-html.pipe';
import { HelpCardComponent } from '../help-card/help-card.component';
import { PopoverContainerDirective } from "@progress/kendo-angular-tooltip";
import { TooltipsModule } from '@progress/kendo-angular-tooltip';

@Component({
  selector: 'casi-help-popover',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    FontAwesomeModule,
    RouterLink, RouterLinkActive,
    InputsModule,
    ButtonsModule,
    MatButtonModule, MatDividerModule, MatIconModule,
    SafeHtmlPipe,
    HelpCardComponent,
    TooltipsModule
  ],
  templateUrl: './help-popover.component.html',
  styleUrl: './help-popover.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class HelpPopoverComponent implements OnInit, OnDestroy {
  @Input({ required: true }) infoId: number = 0;
  @Input() text: string | undefined;
  @Input() iconStyle: string | undefined;

  public showText: boolean = false;

  @ViewChild("anchor", { read: ElementRef }) public anchor: ElementRef | undefined;
  @ViewChild("container", { static: false })
  private container: PopoverContainerDirective | undefined;

  private _subs: Subscription[] = [];
  public events: string[] = [];

  constructor(
    private logger: LoggerService,
    public faIcons: FaIconLibrary
  ) {
    faIcons.addIcons(
      faTriangleExclamation,
      faPlusCircle, faAddressCard, faEdit,
      faTrash,
      faBan, faDownload,
      faInfoCircle
    );
  }

  ngOnInit(): void {
    this.showText = !!this.text;
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnInit...');
    this._subs.forEach(s => s.unsubscribe());
  }

  public onShowPopup(anchor: Element): void {
    this.container?.hide();

    this.container?.show(anchor);
  }

  public onToggle(anchor: Element): void {
    this.container?.toggle(anchor);
  }

  public closeMe() {
    this.logger.debug('closeMe...');
    if (this.container) {
      this.container.hide();
    }
  }

  public onShow(): void {
    this.log("show");
  }

  public onShown(): void {
    this.log("shown");
  }

  public onHide(): void {
    this.log("hide");
  }

  public onHidden(): void {
    this.log("hidden");
  }

  private log(event: string, arg?: unknown): void {
    this.events.unshift(`${event} ${arg || ""}`);
    this.logger.debug('log: ', event);
  }
}
