import { Injectable } from '@angular/core';
import { ApiService } from "../api/api.service";
import { stripHtmlTags } from '../common/utils.lib';

@Injectable({
  providedIn: 'root'
})
export class ShowMoreLessService {

  constructor(
    private apiService: ApiService
  ) { }

  stripTags(html: string): Promise<any> {
    return Promise.resolve(stripHtmlTags(html));
    // return this.apiService.postHttpCallAsPromise('striptags', { html }, 'stripTags');
  }

}
