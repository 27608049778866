<!-- @if(!this.authTokenService.loggedIn){
<div style="height: 40px; text-align: right; padding-right: 90px; padding-top: 10px; font-size: 13pt;">
  <a routerLinkActive="active" routerLink="/login"><fa-icon [icon]="faLock"></fa-icon> Sign In</a>
</div>
}
<p>
  LoggedIn: {{ isLoggedIn }}<br>
  FullName: {{ me?.fullName}}<br>
  Me: {{me | json}} <br>
  Menu: {{menu | json}}<br>
</p> -->

<nav class="navbar navbar-custom navbar-expand-md bg-body-tertiary fixed-top">
  <div class="container-fluid"
    style="padding-left: 0px !important; padding-right: 0px !important; background: url('assets/images/navbg.png'); min-height: 101.5px;">
    @if(isLoggedIn){
    <a class="navbar-brand" routerLinkActive="active" routerLink="/logged-in" style="margin-right: 0;"
      casiClickLogger="a[header:loggedInHome]">
      <img src="assets/images/logo.png" style="width: 288px;" class="d-none d-lg-block">
      <img src="assets/images/logo.png" style="width: 200px;" class="d-block d-lg-none">
    </a>
    }
    @else {
    <a class="navbar-brand" routerLinkActive="active" routerLink="/home" style="margin-right: 0;"
      casiClickLogger="a[header:loggedOutHome]">
      <img src="assets/images/logo.png" style="width: 288px;" class="d-none d-lg-block">
      <img src="assets/images/logo.png" style="width: 200px;" class="d-block d-lg-none">
    </a>
    }

    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon" style="background-color: white; border-radius: 5px;"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <div style="width: 100%; height: 80px; padding-right: 50px; padding-top: 30px;">
        <!-- <ul class="navbar-nav me-auto mb-2 mb-lg-0" style="float: right; margin-right: 50px;"> -->
        <ul class="navbar-nav ml-auto" style="float: right;">

          @if(isLoggedIn){
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" routerLink="/discover-opportunities"
              casiClickLogger="a[header:Explore Opportunity]" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Explore Opportunities</a>
          </li>

          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" routerLink="/discover-resources"
              casiClickLogger="a[header:Resources]" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Resources</a>
          </li>
          }
          <li class="nav-item">
            <a class="nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
               routerLinkActive="active" routerLink="/about" casiClickLogger="a[header:About]">About us</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" routerLinkActive="active" routerLink="/contact-us">Contact Us</a>
          </li>


          @if(isLoggedIn){
          <li class="nav-item dropdown" style="margin-top: 0;">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"
              style="border: 0px; font-weight: 500; padding-left: 0; color: white; background-color: transparent;">
              <fa-icon icon="user"></fa-icon> {{me?.fullName}}
            </a>
            <ul class="dropdown-menu dropdown-menu-custom dropdown-menu-right">
              <!-- <a class="dropdown-item" routerLinkActive="active" routerLink="/contact-us"><fa-icon
                  icon="envelope"></fa-icon> Contact Us</a> -->
              @for(line of menu; track line.id) {
              <a class="dropdown-item" routerLink="{{line.url}}"
                casiClickLogger="a[header:{{line.title}}]" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">{{line.title}}</a>
              }
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="signOut()" casiClickLogger="a[header:signout]" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Sign Out</a>

            </ul>

          </li>
          } @else {
          <!--          <li class="nav-item">-->
          <!--            <a class="nav-link" routerLink="/auth-sign-up">Create Account</a>-->

          <!--            &lt;!&ndash; <a class="dropdown-item" routerLink="/contact-us"><fa-icon icon="envelope"></fa-icon> Contact Us</a> &ndash;&gt;-->

          <!--          </li>-->
          <li class="nav-item">
            <a class="nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" style="border-radius: 5px 5px 0 0;" routerLink="/login"
              casiClickLogger="a[header:login]"> Login</a>
          </li>
          }
        </ul>

      </div>

    </div>

  </div>
</nav>
