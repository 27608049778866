import { Component } from '@angular/core';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';

@Component({
  selector: 'casi-help-button-display',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule],
  templateUrl: './help-button-display.component.html',
  styleUrl: './help-button-display.component.scss'
})
export class HelpButtonDisplayComponent {

}
