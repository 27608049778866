import { Component, NO_ERRORS_SCHEMA, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { LoggerService } from '../../shared/logger/logger.service';
import { DialogsModule } from "@progress/kendo-angular-dialog";
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterLink, RouterLinkActive } from '@angular/router';
import {
  faPlusCircle, faAddressCard,
  faEdit, faTrash, faBan
} from '@fortawesome/free-solid-svg-icons';

import { Observable, Subscription } from 'rxjs';
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { WindowModule } from "@progress/kendo-angular-dialog";
import { NotificationService, NotificationModule } from "@progress/kendo-angular-notification";
import { AppEnities } from '../../common/constants.lib';
import { MeService } from '../../auth/me.service';
import { Enquiry } from '../../models/models.lib';
import { CompositeFilterDescriptor, SortDescriptor, filterBy, distinct, orderBy } from "@progress/kendo-data-query";
import { GridModule, PageChangeEvent, PagerModule, PagerPosition, PagerType } from '@progress/kendo-angular-grid';
import { MulticheckFilterComponent } from '../../filters/multicheck-filter/multicheck-filter.component';
import { EnquiresService } from '../enquires.service';
import { findNearestNumber } from '../../common/utils.lib';
import { EnquiryRecipientsComponent } from '../enquiry-recipients/enquiry-recipients.component';

const { ENQUIRIES } = AppEnities;

@Component({
  selector: 'casi-enquires-list',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    FontAwesomeModule,
    RouterLink, RouterLinkActive,
    InputsModule,
    ButtonsModule,
    DialogsModule,
    WindowModule,
    NotificationModule,
    PagerModule,
    GridModule,
    MulticheckFilterComponent,
    EnquiryRecipientsComponent
  ],
  schemas: [NO_ERRORS_SCHEMA],
  templateUrl: './enquires-list.component.html',
  styleUrl: './enquires-list.component.scss'
})
export class EnquiresListComponent implements OnInit, OnDestroy {

  public view: Observable<Enquiry[]> = new Observable<Enquiry[]>();
  public rawData: Enquiry[] = [];
  public filter: CompositeFilterDescriptor = { logic: "and", filters: [] };
  public gridData: Enquiry[] = filterBy(this.rawData, this.filter);

  public currentItem: Enquiry | null = null;

  public addressPopupOpen: Boolean = false;

  public pagerTypes = ["numeric", "input"];
  public type: PagerType = "numeric";
  public info = true;
  public pageSizes = true;
  public previousNext = true;
  public position: PagerPosition = "bottom";

  public pageSize = 10;

  public editRecipients: boolean = true;

  public canView: boolean = false;

  @ViewChild("appendTo", { read: ViewContainerRef }) appendTo: ViewContainerRef | undefined;

  private _subs: Subscription[] = [];

  constructor(
    private logger: LoggerService,
    private notificationService: NotificationService,
    private me: MeService,
    public dataService: EnquiresService,
    public faIcons: FaIconLibrary
  ) {
    faIcons.addIcons(
      faPlusCircle, faAddressCard, faEdit,
      faTrash,
      faBan
    );
  }

  public sort: SortDescriptor[] = [
    {field: "completed", dir: "asc"},
    {field: "createdAt", dir: "desc"},
  ];

  ngOnInit(): void {
    this.logger.debug('ngOnInit...');
    // const { can_view } = this.me.allICanDo(ENQUIRIES)                                       // THIS PERMISSION DOESN'T EXIST
    // this.logger.debug('ngOnInit:permissions: ', can_view);
    // this.canView = can_view;
    //
    // if (can_view) {
      this.dataService.query();
    // }

    this._subs.push(this.dataService.subscribe(e => {
      this.logger.debug('ngOnInit:v: ', e);
      this.rawData = e;
      this.loadData();
    }));
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnDestroy...');
    this._subs.forEach(s => s.unsubscribe());
  }

  confirmComplete(dataItem: any) {
    dataItem.completed = !dataItem.completed;
    this.logger.debug('confirmComplete:dataItem: ', dataItem);
    this.currentItem = dataItem;
    this.dataService.update(dataItem.id, dataItem.completed)
      .then(resp => {
        this.notificationService.show({
          appendTo: this.appendTo,
          content: `Enquiry marked as ${dataItem.completed ? "completed" : "not completed"}`,
          animation: { type: "fade", duration: 300 },
          type: { style: "success", icon: true },
          position: {
            horizontal: "right",
            vertical: "top"
          },
          height: 50,
          width: 150,
          hideAfter: 2000
        });
      })
      .catch(err => {
        this.logger.error(`confirmComplete:error: `, err);
        this.notificationService.show({
          appendTo: this.appendTo,
          content: `Error updating enquiry`,
          animation: { type: "fade", duration: 300 },
          type: { style: "error", icon: true },
          position: {
            horizontal: "right",
            vertical: "top"
          },
          height: 50,
          width: 150,
          hideAfter: 2000
        });
      })
      .finally(() => this.dataService.query());
  }

  onClearFilters(gridName: string) {
    this.logger.debug("onClearFilters: ", gridName);
    this.filter = { logic: "and", filters: [] };
    this.loadData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.logger.debug("sortChange: ", sort);
    this.sort = sort;
    this.loadData();
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    // this.logger.debug('filterChange: filter: ', filter);
    this.filter = filter;
    this.loadData();
  }

  public distinctPrimitive(fieldName: string): any[] {
    // this.logger.debug('distinctPrimitive: fieldName: ', fieldName);
    return distinct(this.gridData, fieldName).map((item: any) => item[fieldName]);
  }


  public addressPopup(): void {
    this.logger.debug('addressPopup...');
    this.addressPopupOpen = true;
  }

  public close(args: any): void {
    this.logger.debug('close...', args);
    this.addressPopupOpen = false;
  }

  private loadData(): void {
    // this.logger.debug('loadData...');
    this.gridData = orderBy(filterBy(this.rawData, this.filter), this.sort);
    this.pageSize =this.gridData.length;
  }

}
