<kendo-dialog *ngIf="opened" title="Upload Image" (close)="close()" [minWidth]="250" [width]="600">

  <div class="form-group">
    <label class="form-label">Image</label>
    <div class=" col-12">
      <kendo-upload [autoUpload]="false" [saveUrl]="uploadSaveUrl" (cancel)="cancel($event)" (clear)="clear()"
        (complete)="complete()" (error)="error($event)" (blur)="blur()" (focus)="focus()" (pause)="pause($event)"
        (remove)="remove($event)" (resume)="resume($event)" (select)="select($event)" (success)="success($event)"
        (upload)="upload($event)" (uploadProgress)="uploadProgress($event)" (valueChange)="valueChange($event)"
        [restrictions]="fileRestrictions">
        <kendo-upload-messages select="Select image"> </kendo-upload-messages>
        <kendo-upload-messages dropFilesHere="or drop images here to add (max size 5 MB)"></kendo-upload-messages>
      </kendo-upload>
      <div class="demo-hint" style="color: #231f20 !important">You can only upload <strong>PNG, JPG, JPEG</strong>
        files.</div>
    </div>
  </div>

  <div class="form-group">
    <label class="form-label">Height (px)</label>
    <div class=" col-12">
      <kendo-numerictextbox format="n0" [(value)]="height" [min]="0"></kendo-numerictextbox>
    </div>
  </div>

  <div class="form-group">
    <label class="form-label">Width (px)</label>
    <div class=" col-12">
      <kendo-numerictextbox format="n0" [(value)]="width" [min]="0"></kendo-numerictextbox>
    </div>
  </div>

  <kendo-dialog-actions>
    <button kendoButton (click)="close()">Cancel</button>
    <button kendoButton [disabled]="canInsert" (click)="insert()" themeColor="primary">Insert</button>
  </kendo-dialog-actions>
</kendo-dialog>
