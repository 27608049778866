import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { LoggerService } from '../../shared/logger/logger.service';
import { DialogsModule } from "@progress/kendo-angular-dialog";
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { AuthTokenService } from '../../auth/auth-token.service';
import {
  faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { ButtonsModule } from "@progress/kendo-angular-buttons";
// import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { WindowModule } from "@progress/kendo-angular-dialog";
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { UserDataService } from '../user-data.service';
import { NotificationService, NotificationModule } from "@progress/kendo-angular-notification";
import { CasiCloseOptions } from '../../common/constants.lib';
import { ToastrService } from 'ngx-toastr';
import { casiNotify } from '../../common/utils.lib';
import { CasiToastrService } from '../../shared/casi-toastr.service';

import { all } from '@awesome.me/kit-787edf9aa0/icons';

@Component({
  selector: 'app-user-profile',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    FontAwesomeModule,
    RouterLink, RouterLinkActive,
    InputsModule,
    ButtonsModule,
    DialogsModule,
    WindowModule,
    NotificationModule,
    ChangePasswordComponent
  ],
  templateUrl: './user-profile.component.html',
  styleUrl: './user-profile.component.scss'
})
export class UserProfileComponent implements OnInit, OnDestroy {

  // @ViewChild("appendTo", { read: ViewContainerRef, static: false }) appendTo: ViewContainerRef;
  @ViewChild("appendTo", { read: ViewContainerRef }) appendTo: ViewContainerRef | undefined;

  form: FormGroup;

  user: any = null;

  public opened: boolean = false;
  public dataSaved: boolean = false;
  public errorOpened: boolean = false;

  private _subs: Subscription[] = [];

  constructor(private logger: LoggerService,
    public authTokenService: AuthTokenService,
    private fb: FormBuilder,
    public faIcons: FaIconLibrary,
    private dataService: UserDataService,
    private notificationService: NotificationService,
    private toastr: ToastrService
  ) {
    faIcons.addIcons(faTriangleExclamation);
    faIcons.addIcons(...all);
    this.form = this.fb.group({
      id: [null, []],
      firstName: [null, []],
      lastName: [null, []],
      email: [null, [Validators.required, Validators.email]],
      researchConsent: [null, []]
    });
  }

  get f() {
    return this.form.controls;
  }
  get email() {
    return this.form.get('email');
  }
  get firstName() {
    return this.form.get('firstName');
  }
  get lastName() {
    return this.form.get('lastName');
  }
  get researchConsent() {
    return this.form.get('researchConsent');
  }

  ngOnInit(): void {
    this.logger.debug('ngOnInit...');
    this.initForm();
    // this.user = this.authTokenService.getClaims();
    // this.logger.debug('ngOnInit...', this.user);
    // this.form.patchValue({ ...this.user });
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnDestroy...');
    this._subs.forEach(s => s.unsubscribe());
  }

  onSubmit(): void {
    this.logger.debug('onSubmit...form.value', this.form.value);
    let toSubmit = this.form.value;
    this.logger.debug('onSubmit:toSubmit', toSubmit);
    this.dataService.updateUserProfile(toSubmit)
      .then(resp => {
        this.logger.debug('onSubmit:resp:', resp);
        this.authTokenService.setToken(resp);
        this.notify("Profile Updated");
      })
      .catch(err => {
        this.logger.error('onSubmit:error', err);
        const emailAlreadyInUse = err.status === 409;
        if (emailAlreadyInUse) {
          toSubmit.email = this.user.email;
          this.form.patchValue(toSubmit);
          this.errorOpened = true;
        }
      })
  }

  cancelProfile() {
    this.logger.debug('cancelProfile...');
    this.initForm();
  }

  handleClose() {
    this.logger.debug('handleClose...');
    this.opened = false;
  }
  // public close(status: string): void {
  //   console.log(`Dialog result: ${status}`);
  //   this.opened = false;
  // }

  public close(args: any): void {
    this.logger.debug('close...', args);
    this.opened = false;
    if (args === CasiCloseOptions.Saved)
      this.notify("Password Updated");
  }

  public open(): void {
    this.logger.debug('open...');
    this.opened = true;
  }

  public openError(): void {
    this.logger.debug('openError...');
    this.errorOpened = true;
  }

  // public submit(): void {
  //   this.logger.debug('submit...');
  //   this.dataSaved = true;
  //   this.close();
  // }

  public errorClose(status: string): void {
    this.logger.debug('errorClose:status: ', status);
    this.errorOpened = false;
  }

  private initForm() {
    this.user = this.authTokenService.getClaims();
    this.logger.debug('initForm...', this.user);
    this.form.patchValue({ ...this.user });
  }

  private notify(msg: string, error: boolean = false): void {
    this.logger.debug("notify", msg);
    if (error) {
      this.toastr.error(msg, 'Error');
    } else {
      this.toastr.success(msg, 'Updated');
    }
    // this.notificationService.show({
    //   appendTo: this.appendTo,
    //   content: msg,
    //   animation: { type: "fade", duration: 300 },
    //   type: { style: !error ? "success" : "error", icon: true },
    //   position: {
    //     horizontal: "right",
    //     vertical: "top"
    //   },
    //   height: 50,
    //   // width: 150,
    //   hideAfter: 2000
    // });
  }
}
