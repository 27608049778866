import { Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ContactUsComponent } from './common-pages/contact-us/contact-us.component';
import { AboutComponent } from './common-pages/about/about.component';
import { SignedOutComponent } from './base/signed-out/signed-out.component';
import { LoggedInContentComponent } from './base/logged-in-content/logged-in-content.component';
import { UserListComponent } from './users/user-list/user-list.component';
import { UserProfileComponent } from './users/user-profile/user-profile.component';
import { ControlParamsComponent } from './admin/control-params/control-params.component';
import { RolesComponent } from './admin/roles/roles.component';
import { EditPolicyComponent } from './admin/edit-policy/edit-policy.component';
import { EnquiresListComponent } from './admin/enquires-list/enquires-list.component';
import { HelpInfoComponent } from './admin/help-info/help-info.component';
import { CoBenefitTypeComponent } from './coBenefits/co-benefit-type/co-benefit-type.component';
import { OpportunityComponent } from './opportunities/opportunity/opportunity.component';
import { SignupComponent } from './auth/signup/signup.component';
import { statesResolver } from './resolvers/states.resolver';
import { benefitsResolver } from './resolvers/benefits.resolver';
import { industriesResolver } from './resolvers/industries.resolver';
import { goalsResolver } from './resolvers/goals.resolver';
import { classificationsResolver } from './resolvers/classifications.resolver';
import { ResourceListComponent } from './resources/resource-list/resource-list.component';
import { controlHostResolver } from './resolvers/control-host.resolver';
import { elegibilityResolver } from './resolvers/elegibility.resolver';
import { isLoggedInGuard } from './guards/is-logged-in.guard';
import { controlParamsResolver } from './resolvers/control-params.resolver';
import { PasswordResetComponent } from './auth/password-reset/password-reset.component';
import { ResetPasswordViewComponent } from './auth/reset-password-view/reset-password-view.component';
import {TermsConditionsComponent} from "./common-pages/terms-conditions/terms-conditions.component";
import { TermsCheckComponent } from './common-pages/terms-conditions/terms-check.component';
import {PrivacyComponent} from "./common-pages/privacy/privacy.component";
import {DisclaimerComponent} from "./common-pages/disclaimer/disclaimer.component";
import { ContentComponent } from './base/content/content.component';
import { coBenDdTreeResolver } from './resolvers/co-ben-dd-tree.resolver';
import {
  DiscoverOpportunitiesComponent
} from "./discoverOpportunities/discover-opportunities/discover-opportunities.component";
import {opportunityOptionsResolver} from "./resolvers/opportunity-options.resolver";
import { DiscoverResourcesComponent } from './discoverResources/discover-resources/discover-resources.component';
import { resourceOptionsResolver } from './resolvers/resource-options.resolver';
import {ReviewOpportunity} from "./models/models.lib";
import {
  ReviewOpportunityListComponent
} from "./reviewOpportunity/review-opportunity-list/review-opportunity-list.component";

export const routes: Routes = [
  { title: 'Reset Password', path: 'reset-password', component: PasswordResetComponent },
  { title: 'Password reset with token', path: 'resetpassword', component: ResetPasswordViewComponent },
  { title: 'Environer Login', path: 'login', component: LoginComponent },
  { title: 'Environer Contact Us', path: 'contact-us', component: ContactUsComponent },
  { title: 'Environer About', path: 'about', component: AboutComponent },
  { title: 'Environer Home', path: 'home', component: ContentComponent},
  { title: 'Environer Signed Out',path: 'signed-out', component: SignedOutComponent },
  { title: 'Environer Home',path: 'logged-in', component: LoggedInContentComponent },
  { title: 'Environer Users', path: 'user-list', component: UserListComponent, canActivate: [isLoggedInGuard] },
  { title: 'Environer User Profile', path: 'user-profile', component: UserProfileComponent, canActivate: [isLoggedInGuard] },
  {
    title: 'Environer Parameters',
    path: 'admin-edit-params',
    component: ControlParamsComponent,
    canActivate: [isLoggedInGuard],
    resolve: {
      control: controlParamsResolver
    }
  },
  { title: 'Environer Roles', path: 'admin-roles', component: RolesComponent, canActivate: [isLoggedInGuard] },
  { title: 'Environer Policy', path: 'admin-edit-policy', component: EditPolicyComponent, canActivate: [isLoggedInGuard] },
  { title: 'Environer Enquiries', path: 'admin-enquires-list', component: EnquiresListComponent, canActivate: [isLoggedInGuard] },
  { title: 'Environer Help Information', path: 'admin-help-info', component: HelpInfoComponent, canActivate: [isLoggedInGuard] },
  { title: 'Environer Co-benefit Type', path: 'cobenefit-types', component: CoBenefitTypeComponent, canActivate: [isLoggedInGuard] },
  {
    title: 'Environer Opportunities',
    path: 'opportunites-view',
    component: OpportunityComponent,
    canActivate: [isLoggedInGuard],
    resolve: {
      stateList: statesResolver,
      benefitList: benefitsResolver,
      industryList: industriesResolver,
      goalList: goalsResolver,
      classificationList: classificationsResolver,
      elegibilityList: elegibilityResolver,
      coBenDdTreeList: coBenDdTreeResolver
    }
  },

  {
    title: 'Environer Review Opportunities', path: 'review-opportunities', component: ReviewOpportunityListComponent
  },

  {
    title: 'Environer Discover Opportunities', path: 'discover-opportunities', component: DiscoverOpportunitiesComponent,
    // canActivate: [isLoggedInGuard],
    resolve: {options: opportunityOptionsResolver}
  },
  {
    title: 'Environer Discover Resources', path: 'discover-resources', component: DiscoverResourcesComponent,
    // canActivate: [isLoggedInGuard],
    resolve: {options: resourceOptionsResolver}
  },
  {
    title: 'Environer Discover Resources', path: 'discover-resources-home/:filterBy/:id', component: DiscoverResourcesComponent,
    // canActivate: [isLoggedInGuard],
    resolve: {options: resourceOptionsResolver}
  },
  {
    title: 'Environer Resources',
    path: 'resources-list', component: ResourceListComponent, canActivate: [isLoggedInGuard],
    resolve: {
      hostInfo: controlHostResolver,
      industryList: industriesResolver,
      goalList: goalsResolver
    }
  },
  { title: 'Sign Up', path: 'auth-sign-up', component: SignupComponent },
  { title: 'Environer Customer Licence Agreement', path: 'terms-conditions', component: TermsConditionsComponent },
  { title: 'New License Agreement', path: 'terms-check', component: TermsCheckComponent},
  { title: 'Environer Privacy Policy', path: 'privacy', component: PrivacyComponent },
  { title: 'Environer Terms of Use', path: 'disclaimer', component: DisclaimerComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  //{ path: '', redirectTo: '/login', pathMatch: 'full' }, //go to app component
  { path: "**", component: PageNotFoundComponent }
];
