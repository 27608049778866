import {inject, Renderer2} from '@angular/core';
import { FormModes } from './constants.lib';
import _ from 'lodash';
import { LoggerService } from '../shared/logger/logger.service';
import { DropDownTreeItem, LevelDropDownTree } from '../models/models.lib';
import {
  UploadEvent
} from "@progress/kendo-angular-upload";
import { ApiService } from '../api/api.service';
import { AuthTokenService } from '../auth/auth-token.service';
import { ToastrService } from 'ngx-toastr';


export const setTitle = (formMode: FormModes, title: string): string => {
  return `${formMode === FormModes.NEW ? 'ADD' : 'EDIT'} ${title}`;
};

export const findNearestNumber = (arr: number[], target: number): number => {
  // console.log('findNearestNumber: ', target, arr);
  let nearest = arr[0];
  let smallestDifference = Math.abs(target - nearest);
  arr.forEach(a => {
    const difference = Math.abs(target - a);
    if (difference < smallestDifference) {
      nearest = a;
      smallestDifference = difference;
    }
  })
  return nearest;
};

export function isNotNull(val = null) {
  return !!val || !(val === null);
};

export const buildLevel3DropDownTree = (list: any[]): any[] => {
  // console.log('buildLevel3DropDownTree: ', list);
  let lvl1 = _.groupBy(list, 'co_benefit');
  const returning: any[] = [];
  let fakeId = -1;
  let fakeId2 = -1000;
  for (const key in lvl1) {
    // console.log('buildLevel3DropDownTree:key: ', key);

    let itemsL1: any[] = [];
    let subKeyL1 = lvl1[key];
    let lvl2 = _.groupBy(subKeyL1, 'co_benefit_group');
    for (const sKey in lvl2) {
      // console.log('buildLevel3DropDownTree:key:sKey ', key, sKey);

      let itemsL2: any[] = [];
      let subKeyL2 = lvl2[sKey];
      let lvl3 = _.groupBy(subKeyL2, 'sub_co_benefit');
      for (let l3Key in lvl3) {
        // console.log('buildLevel3DropDownTree:key:sKey:l3Key ', key, sKey, l3Key);
        let subKeyL3 = lvl3[l3Key];
        itemsL2.push(new DropDownTreeItem(l3Key, subKeyL3[0].id, subKeyL3[0].co_benefit, subKeyL3[0].co_benefit_group));
      }

      itemsL1.push(new LevelDropDownTree(sKey, itemsL2, fakeId2));
      fakeId2--;
    }

    returning.push(new LevelDropDownTree(key, itemsL1, fakeId));
    fakeId--;
  }
  // console.log('buildLevel3DropDownTree:returning: ', returning);
  return returning;
};

export function casiNotify(msg: string = 'Not set', error: boolean = false): void {
  let toastr = inject(ToastrService);
  if (error) {
    toastr.error(msg, 'Error');
  } else {
    toastr.success(msg, 'Sucess');
  }
};

/**
 * Strip HTML tags from a html string.
 *
 * @param html
 * @returns
 */
export function stripHtmlTags(html: string = ''): string {
  const htmlRegX = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;
  return html.replace(htmlRegX, '');
};

export function lockBodyScroll(renderer: Renderer2, scrollPosition: any): void {
  // const renderer = inject(Renderer2);
  scrollPosition = {
    top: window.scrollY,
    left: window.scrollX
  };

  renderer.setStyle(document.body, 'position', 'fixed');
  renderer.setStyle(document.body, 'top', `-${scrollPosition.top}px`);
  renderer.setStyle(document.body, 'left', `-${scrollPosition.left}px`);
  renderer.setStyle(document.body, 'right', '0');
  renderer.setStyle(document.body, 'bottom', '0');
}

export function unlockBodyScroll(renderer: Renderer2, scrollPosition: any) {
  // const renderer = inject(Renderer2);
  renderer.removeStyle(document.body, 'position');
  renderer.removeStyle(document.body, 'top');
  renderer.removeStyle(document.body, 'left');
  renderer.removeStyle(document.body, 'right');
  renderer.removeStyle(document.body, 'bottom');

  window.scrollTo(scrollPosition.left, scrollPosition.top);
}

export function escapeStyle(text: string): string {
  // let style = 'font-size:14px   !important; font-family: &quot;Manrope&quot;, sans-serif !important;';
  let style = 'font: 400 14px / 20px Roboto, sans-serif !important;';
  let str = text.replace(/style="[^"]*"/g, `style="${style}"`);
  str = str.replace(/<p>/g, `<p style="${style}">`);
  str = str.replace(/<span>/g,  `<span style="${style}">`);

  return str;
}
