<div class="container-fluid" >
  <div class="row">
    <div class="col-12" style="height: calc(100vh - 400px); min-height: 300px;">
      <div style="padding: 40px; border-radius: 10px; background-color: #DDD; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
          <h3>You are signed out of your account.</h3>
          <p>Close this browser tab, or <button type="button" class="btn btn-link" (click)="signIn()">Sign In</button> again.</p>
      </div>
    </div>
  </div>
</div>
