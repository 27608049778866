import { Component, Input, NO_ERRORS_SCHEMA, OnInit } from '@angular/core';
import { LoggerService } from '../../shared/logger/logger.service';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogContentBase, DialogRef, DialogsModule, WindowModule } from '@progress/kendo-angular-dialog';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { CasiCloseOptions } from '../../common/constants.lib';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'casi-filename-prompt',
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    InputsModule,
    ButtonsModule,
    DialogsModule,
    WindowModule,
    NotificationModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
  templateUrl: './filename-prompt.component.html',
  styleUrl: './filename-prompt.component.scss'
})
export class FilenamePromptComponent extends DialogContentBase implements OnInit {

  private _defaultFilename: string = 'Download.pdf';
  @Input() set defaultFilename(val: string) {
    this._defaultFilename = val;
    this.form.patchValue({ filename: this._defaultFilename });
  }
  get defaultFilename(): string {
    return this._defaultFilename;
  };

  public form: FormGroup;

  constructor(
    public dialog: DialogRef,
    private fb: FormBuilder,
    private logger: LoggerService,
  ) {
    super(dialog);
    this.form = this.fb.group(
      {
        filename: [null, [Validators.required]]
      }
    );
  }

  //#region Form

  get f() {
    return this.form.controls;
  }
  get filename() {
    return this.form.get('filename');
  }

  //#endregion

  //#region Ng Events

  ngOnInit(): void {
    this.logger.debug('ngOnInit...');
  }

  //#endregion


  public onCancelAction(): void {
    this.logger.debug(`onCancelAction...`);
    this.dialog.close({ text: CasiCloseOptions.Cancelled });
  }

  public onSaveAction(): void {
    this.logger.debug(`onSaveAction...`, this.form.value);
    this.dialog.close({ text: CasiCloseOptions.Saved });
  }

}
