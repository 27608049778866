import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { EditorComponent, EditorModule } from "@progress/kendo-angular-editor";
import { LoggerService } from "../../shared/logger/logger.service";
import {
  CancelEvent,
  ErrorEvent,
  FileInfo, FileRestrictions,
  PauseEvent, RemoveEvent, ResumeEvent,
  SelectEvent, SuccessEvent, UploadEvent, UploadProgressEvent,
  UploadsModule
} from "@progress/kendo-angular-upload";
import { DialogsModule } from "@progress/kendo-angular-dialog";
import { CommonModule } from "@angular/common";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { NotificationModule } from "@progress/kendo-angular-notification";
import { LabelModule } from "@progress/kendo-angular-label";
import { AppConfig, CONFIG_TOKEN } from "../../setup/config";
import { ImageUploadService } from "../editor-image-upload.service";
import { SecurityService } from '../../auth/security.service';
import { environment } from '../../../environments/environment';

const { apiUrl } = environment;

export interface ImageInfo {
  src: string;
  width: number;
  height: number;
}

@Component({
  selector: 'casi-editor-image-upload',
  standalone: true,
  imports: [
    EditorModule,
    DialogsModule,
    CommonModule,
    InputsModule,
    ButtonsModule,
    LabelModule,
    UploadsModule
  ],
  templateUrl: './editor-image-upload.component.html',
  styleUrl: './editor-image-upload.component.scss'
})
export class EditorImageUploadComponent implements OnInit, OnDestroy {

  @Output() onLoad = new EventEmitter<any>();
  @Input() public editor: EditorComponent = <EditorComponent>{};
  // @Input({ required: true }) opportunity: Opportunity = new Opportunity();
  @Input({ required: true }) recordId: number | undefined;
  @Input({ required: true }) fieldName: string = null as any;
  @Input({ required: true }) tableName: string = null as any;

  public opened = false;
  public src: string = null as any;
  public height: number = 0;
  public width: number = 0;

  private fileName: string = "";

  uploadSaveUrl: string = "imageupload";
  uploadRemoveUrl = "removeUrl";

  fileRestrictions: FileRestrictions = {
    maxFileSize: 5242880,
    allowedExtensions: [".png", ".jpeg", ".jpg"]
  };

  public get canInsert(): boolean {
    return !this.src;
  }

  public get imageInfo(): ImageInfo {
    return {
      src: this.src,
      height: this.height,
      width: this.width,
    };
  }

  public setImageInfo(value: ImageInfo) {
    if (value) {
      this.src = value.src;
      this.height = value.height;
      this.width = value.width;
    } else {
      this.resetData();
    }
  }

  constructor(
    private logger: LoggerService,
    @Inject(CONFIG_TOKEN) private config: AppConfig,
    private dataService: ImageUploadService,
    private securityService: SecurityService
  ) { }

  ngOnInit(): void {
    this.logger.debug('ngOnInit...');
    this.securityService.requestCsrfToken();
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnInit...');
  }

  open() {
    this.opened = true;
    this.fieldName = this.fieldName || "pippo";
    this.uploadSaveUrl = `${apiUrl}/imageupload?path=${this.tableName}&subdir=${this.fieldName}&uniqueId=${this.recordId}`;
  }

  public close(): void {
    this.opened = false;
    this.resetData();
    this.dataService.deleteImage(this.recordId, "opportunity", this.fieldName, this.fileName)
      .then(() => {
        this.logger.debug('deleteImage:done...');
      })
      .catch(err => this.logger.error('deleteImage:error: ', err))
  }

  public resetData(): void {
    this.src = "";
    this.width = 0;
    this.height = 0;
  }

  public insert(): void {
    this.editor.exec("insertImage", this.imageInfo);
    this.opened = false;
    this.resetData();
  }

  //#region Upload event handlers

  public cancel(e: CancelEvent): void {
    this.logger.debug(`cancel event...`, e);
  }

  public clear(): void {
    this.log("clear event");
  }

  public complete(): void {
    this.log(`complete event`);
  }

  public error(e: ErrorEvent): void {
    this.log(`error event: ${e.files[0].name}`);
  }

  public blur(): void {
    this.log(`blur event`);
  }

  public focus(): void {
    this.log(`focus event`);
  }

  public pause(e: PauseEvent): void {
    this.log(`pause event ${e.file.name}`);
  }

  public resume(e: ResumeEvent): void {
    this.log(`resume event ${e.file.name}`);
  }

  public remove(e: RemoveEvent): void {
    this.log(`remove event: ${e.files[0].name}`);
    this.resetData();
  }

  public select(e: SelectEvent): void {
    this.logger.debug(`select event`, e);
  }

  public success(e: SuccessEvent): void {
    let file = e.files[0];
    let name = file.name;
    let newId = (e.response as any).body.new_id;
    this.logger.debug(`success event ${name}`, e);
    this.recordId = newId;
    this.fileName = name;

    if (file.rawFile) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const img = new Image();

        img.src = <string>reader.result;
        img.onload = () => {
          this.setImageInfo({
            src: img.src,
            height: img.height,
            width: img.width,
          });
        };

        this.onLoad.emit({
          data: newId
        });
      };

      reader.readAsDataURL(file.rawFile);
    }

  }

  public upload(e: UploadEvent):void {
    this.log(`upload event ${e.files[0].name}`);
    this.securityService.secureUploads(e);
  }

  public uploadProgress(e: UploadProgressEvent): void {
    this.log(`uploadProgress event ${e.percentComplete}`);
  }

  public valueChange(e: FileInfo[]): void {
    this.log(`valueChange event ${e[0].name}`);
  }

  private log(event: string): void {
    this.logger.debug(`log => ${event}`);
  }

  //#endregion
}
