<link rel="stylesheet" href="https://unpkg.com/@progress/kendo-font-icons/dist/index.css" />
<div class="k-overlay" *ngIf="editWinOpen"></div>
<div class="container" my-5>
  <div class="card" #appendTo>
    <div class="card-body no-border">
      <div class="card-title">
        <h3 style="text-align: left;" class="page-header">Manage Co-benefit Type</h3>
      </div>

      <kendo-grid [kendoGridBinding]="gridData" [loading]="dataService.loading" [sort]="sort" [sortable]="true"
        [groupable]="true" [resizable]="true" [filter]="filter" filterable="menu" (filterChange)="filterChange($event)"
        (sortChange)="sortChange($event)" [pageSize]="pageSize" [pageable]="true"
        style="height: calc(100vh - 280px); min-height: 250px;">

        <ng-template kendoGridToolbarTemplate position="top">
          <button [hidden]="!canCreate" (click)="onAddType()" kendoButton class="btn btn-primary">
            <fa-icon icon="circle-plus"></fa-icon> Add Co-Benefit Type</button>
          <button kendoGridExcelCommand class="btn btn-primary">
            <fa-icon icon="download"></fa-icon> Export Data</button>
        </ng-template>

        <kendo-grid-column [hidden]="!canUpdate" title="Edit" [width]="70">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <button (click)="onEditClick(dataItem)" kendoButton class="btn btn-stn-grid">
              <fa-icon icon="edit"></fa-icon>
            </button>
          </ng-template>
        </kendo-grid-column>

        <!-- <kendo-grid-column field="id" title="Id"></kendo-grid-column> -->
        <kendo-grid-column field="co_benefit" title="Co-benefit" [width]="180">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
            let-filterService="filterService">
            <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
              [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="co_benefit_group" title="Co-benefit Group" [width]="220">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
            let-filterService="filterService">
            <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
              [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="sub_co_benefit" title="Sub Co-benefit" [width]="280">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
            let-filterService="filterService">
            <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
              [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column [hidden]="!canDestroy" title="Activate/Deactivate" field="active" [width]="150">
          <ng-template kendoGridCellTemplate let-dataItem>
            <input type="checkbox" kendoCheckBox size="large" [checked]="dataItem.active"
              (click)="toggleActive(dataItem)" />
          </ng-template>
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
            let-filterService="filterService">
            <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
              [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
          </ng-template>
        </kendo-grid-column>

        <ng-template kendoPagerTemplate>
          <kendo-pager-info *ngIf="pageSize > 0"></kendo-pager-info>
        </ng-template>

        <kendo-grid-excel fileName="Co-benefit Types.xlsx" [fetchData]="allData"></kendo-grid-excel>

      </kendo-grid>

    </div>
  </div>

</div>


<kendo-window *ngIf="editWinOpen" (close)="close()" [width]="1000">
  <kendo-window-titlebar>
    <span class="k-window-title">{{showTitle}}</span>
  </kendo-window-titlebar>
  <casi-edit-co-benefit [coBenefitType]="currentItem" [mode]="formMode" (onCancel)="close()"
    (onSave)="onTypeSave($event)"></casi-edit-co-benefit>
</kendo-window>

<div kendoDialogContainer></div>
