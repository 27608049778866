import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { HelpInfoService } from '../../help-info.service';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { LoggerService } from '../../../shared/logger/logger.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
  faTriangleExclamation,
  faPlusCircle, faAddressCard,
  faEdit, faTrash, faBan, faDownload, faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { SafeHtmlPipe } from '../../../pipes/safe-html.pipe';
import { PopupModule, PopupRef, PopupService } from "@progress/kendo-angular-popup";
import { HelpCardComponent } from '../help-card/help-card.component';

@Component({
  selector: 'casi-help-popup',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    FontAwesomeModule,
    RouterLink, RouterLinkActive,
    InputsModule,
    ButtonsModule,
    MatButtonModule, MatDividerModule, MatIconModule,
    MatDialogModule,
    SafeHtmlPipe,
    PopupModule,
    HelpCardComponent
  ],
  templateUrl: './help-popup.component.html',
  styleUrl: './help-popup.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class HelpPopupComponent implements OnInit, OnDestroy {
  @Input({ required: true }) infoId: number = 0;
  @Input() text: string | undefined;
  @Input() iconStyle: string | undefined;
  @Output() onOpen = new EventEmitter<void>();
  @Output() onClose = new EventEmitter<void>();

  private _helpContent = new BehaviorSubject<string>('searching for help...');
  get helpContent() {
    return this._helpContent.getValue();
  }
  set helpContent(val: string) {
    this._helpContent.next(val);
  }

  public windowTop = 100;
  public windowLeft = 50;

  public title: string = 'HELP INFORMATION';

  public showText: boolean = false;

  public show: boolean = false;
  @ViewChild("anchor", { read: ElementRef }) public anchor: ElementRef | undefined;
  @ViewChild("popup", { read: ElementRef }) public popup: ElementRef | undefined;

  private popupRef: PopupRef | undefined;

  private _subs: Subscription[] = [];

  constructor(
    private dataService: HelpInfoService,
    private logger: LoggerService,
    public faIcons: FaIconLibrary,
    public dialog: MatDialog,
    private popupService: PopupService
  ) {
    faIcons.addIcons(
      faTriangleExclamation,
      faPlusCircle, faAddressCard, faEdit,
      faTrash,
      faBan, faDownload,
      faInfoCircle
    );
  }

  ngOnInit(): void {
    this.showText = !!this.text;
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnInit...');
    this._subs.forEach(s => s.unsubscribe());
  }

  @HostListener("document:keydown", ["$event"])
  public keydown(event: KeyboardEvent): void {
    if (event.code === "Escape") {
      this.toggle(false);
    }
  }

  @HostListener("document:click", ["$event"])
  public documentClick(event: KeyboardEvent): void {
    if (!this.contains((event as any).target)) {
      this.toggle(false);
    }
  }

  @HostListener("document:popupAnchorViewportLeave", ["$event"])
  public lostView(event: any): void {
    this.logger.debug('lostView', event);
    // if (!this.contains((event as any).target)) {
    //   this.toggle(false);
    // }
  }

  public toggle(show?: boolean): void {
    this.show = show !== undefined ? show : !this.show;
    this.logger.debug('toggle:show: ', this.show);
    // // this.onToggle.emit(!this.show);
    // if (this.show) {
    //   this.onOpen.emit();
    //   this.fetchHelp();
    // } else {
    //   this.onClose.emit();
    // }
  }

  public togglePopup(
    anchor: ElementRef | HTMLElement,
    template: TemplateRef<{ [Key: string]: unknown }>
  ): void {
    if (this.popupRef) {
      this.popupRef.close();
      this.popupRef = undefined;
    } else {
      this.fetchHelp(anchor, template);
      // this.popupRef = this.popupService.open({
      //   content: template,
      //   offset: { top: 60, left: 30 },
      // });
    }
  }

  public closeMe() {
    this.logger.debug('closeMe...');
    if (this.popupRef) {
      this.popupRef.close();
      this.popupRef = undefined;
    }
  }
  
  private fetchHelp(
    anchor: ElementRef | HTMLElement,
    template: TemplateRef<{ [Key: string]: unknown }>
  ) {
    this.dataService.getById(+this.infoId)
      .then(helpMeta => {
        this.logger.debug('fetchHelp: data: ', helpMeta);
        if (helpMeta) {
          const { id, site_section, element_name,
            html, heading } = helpMeta;
          this.title = heading;
          this.helpContent = html;
        } else {
          this.helpContent = "<p>No content found</p>"
        }
        this.popupRef = this.popupService.open({
          content: template,
          anchor: anchor,
          // offset: { top: 60, left: 30 },
        });
        // this.popupRef.popupAnchorViewportLeave= "closeMe()";

      })
      .catch(err => {
        this.logger.error('fetchHelp: error: ', err);
        this.popupRef = undefined;
      })
  }

  private contains(target: EventTarget): boolean {
    return (
      (this.anchor as ElementRef).nativeElement.contains(target) ||
      (this.popup ? this.popup.nativeElement.contains(target) : false)
    );
  }
}
