<casi-editor-image-upload
  [editor]="practice_description_editor"
  fieldName="practice_description"
  [recordId]="practice.id"
  tableName="practice"
  (onLoad)="onImageLoaded($event)" #uploadDialog></casi-editor-image-upload>

<form class="k-form" [formGroup]="form">

  <fieldset>

    <div class="row">
      <label  class="col-3 col-form-label">Practice Description</label>
      <div class=" col-9">
        <kendo-editor class="form-control" formControlName="practice_description" style="height: 300px;" #practice_description_editor>
          <kendo-toolbar>

            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>

            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
              <kendo-toolbar-button text="Upload Image" (click)="openImageUpload()"></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
            <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
<!--            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
<!--            <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>-->
<!--            <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>-->
            <!-- <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
            <kendo-toolbar-colorpicker kendoEditorBackColor view="gradient"></kendo-toolbar-colorpicker> -->
          </kendo-toolbar>
        </kendo-editor>
        <div *ngIf="practice_description?.invalid" class="alert alert-danger">

          <div *ngIf="practice_description?.errors?.['required']">
            Practice Description is required.
          </div>
        </div>
      </div>

   </div>


    <!-- Buttons -->

    <div class="d-grid d-md-flex justify-content-md-end mt-4">
      <button type="button" class="btn btn-primary me-md-2" (click)="onSubmit()" [disabled]="form.invalid">Save</button>
      <button type="button" class="btn btn-secondary" (click)="closeWindow()"> Cancel</button>
    </div>

  </fieldset>
</form>
