import { Injectable } from '@angular/core';
import {ApiService} from "../api/api.service";

@Injectable({
  providedIn: 'root'
})
export class TermsConditionsService {

  constructor(
    private apiService: ApiService
  ) { }

  get(docType: string):Promise<any> {
    return this.apiService.getHttpCallAsPromise(`termsconditions?doc_type=${docType}`, 'get');
  }

  updateUserTerms(info: any):Promise<any> {
    return this.apiService.putHttpCallAsPromise(`updateuserterms`, info, 'updateUserTerms');
  }

}
