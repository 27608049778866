import { Inject, Injectable } from '@angular/core';
import { LoggerService } from '../shared/logger/logger.service';
import { HttpClient, HttpHeaders, HttpClientXsrfModule, HttpResponse } from '@angular/common/http';
import { AuthResponseData } from './api.lib';
import { BehaviorSubject, Observable, catchError, of, tap, throwError, from } from 'rxjs';
import { AppConfig, CONFIG_TOKEN } from '../setup/config';
import { environment } from '../../environments/environment';

const { apiUrl } = environment;

const securityHeaders = new HttpHeaders({
  // 'Access-Control-Request-Headers': 'authorization,content-security-policy,content-type,strict-transport-security,x-frame-options,x-xss-protection',
  // "access-control-allow-origin": "*",
  // 'Access-Control-Allow-Headers': 'Content-Type, Authorization',
  // 'Access-Control-Allow-Methods': '*',
  // 'Content-Security-Policy': `default-src 'self'`,
  // 'X-Frame-Options': 'DENY',
  // 'X-XSS-Protection': '1; mode=block',
  // 'Strict-Transport-Security': 'max-age=31536000; includeSubDomains; preload'
});

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  // private _csrfToken: string = 'not set';
  // get csrfToken(): string {
  //   return this._csrfToken;
  // }

  private _csrfToken: BehaviorSubject<string> = new BehaviorSubject<string>('not set');
  get csrfToken$() {
    return this._csrfToken.asObservable();
  }
  get csrfToken() {
    return this._csrfToken.getValue();
  }
  set csrfToken(val: string) {
    this._csrfToken.next(val);
  }

  // private headers: HttpHeaders = new HttpHeaders({
  //   'X-CSRF-TOKEN': this._csrfToken,
  //   'XSRF-TOKEN': this._csrfToken
  //   // 'Content-Type': 'application/json'
  // });
  private headers: HttpHeaders = new HttpHeaders();

  constructor(
    private logger: LoggerService,
    private http: HttpClient,
    @Inject(CONFIG_TOKEN) private config: AppConfig,
  ) { }

  testGet() {
    return this.http
      .get('http://localhost:1338/testapi', {
        responseType: 'json'
      });
  }

  getHealth() {
    return this.http
      .get('http://localhost:1338/api/health', {
        responseType: 'json'
      });
  }


  getCsrf() {
    this.logger.debug('getCsrf:apiUrl: ', apiUrl);
    return this.http.get<any>(encodeURI(`${apiUrl}/csrfToken`), {
      // observe: 'response',
      withCredentials: true
    })
      .pipe(
        tap((resp: any) => {
          this.logger.debug('getCsrf:tap:resp: ', resp);
          this.csrfToken = resp._csrf;
          // this.headers = new HttpHeaders().set('X-CSRF-TOKEN', this.csrfToken)
          // .set('XSRF-TOKEN', this._csrfToken)
          ;
          // 'Content-Type': 'application/json'
          // this.logger.debug('getCsrf:tap:_csrfToken: ', this._csrfToken);
          // this.logger.debug('getCsrf:tap:header: ', this.headers);
          // this.logger.debug('getCsrf:tap:header: ', this.headers.get('X-CSRF-TOKEN'));
          // this.logger.debug('set-cookie: ', resp.headers);
          // this.logger.debug('getCsrf:tap:header: ', this.headers.get('XSRF-TOKEN'));
          // let wallyHeaders = new HttpHeaders()
          //   .set('wally', this._csrfToken);
          // this.logger.debug('wally: ', wallyHeaders.get('wally'));
        }),
        catchError(errorRes => {
          this.logger.error(`getCsrf:error:`, errorRes.message);
          return throwError(() => errorRes);
        })
      );
    // .subscribe({
    //   next: resData => {
    //     this.logger.debug('getCsrf:resData: ', resData);
    //     // return resolve(true);
    //   },
    //   error: err => {
    //     this.logger.error('getCsrf:error: ', err.message);
    //     // return reject(err);
    //   }
    // });
  }

  getCsrfAsPromise() {
    return new Promise((resolve, reject) => {
      this.logger.debug('getCsrfAsPromise...');
      return this.getCsrf()
        .subscribe({
          next: resData => resolve(resData),
          error: err => {
            this.logger.error('getCsrfAsPromise:error: ', err.message);
            resolve(null);
          }
        })
    })
  }

  //#region Generics

  getHttpCall(url: string, calledFrom: string = 'calledFrom') {
    this.logger.debug(`getHttpCall:<${calledFrom}>
      url: ${url} `);
    return this.http
      .get<any>(encodeURI(`${apiUrl}/${url}`), {
        // headers: this.headers,
        responseType: 'json',
        withCredentials: true
      })
      .pipe(
        tap(() => {
          this.logger.debug('getHttpCall:tap...');
          this.getCsrf();
        }),
        catchError(errorRes => {
          this.logger.error(`getHttpCall:${calledFrom}:error: `, errorRes.message);
          return throwError(() => errorRes);
        })
      );
  }

  getHttpCallAsPromise(url: string, calledFrom: string = 'calledFrom'): Promise<any> {
    return new Promise((resolve, reject) => {
      this.logger.debug('getHttpCallAsPromise: ', url, calledFrom);
      return this.getCsrfAsPromise()
        .then(done => {
          return this.getHttpCall(url, calledFrom)
            .subscribe({
              next: resData => resolve(resData),
              error: err => reject(err)
            });

        })
        .catch(err => reject(err));
    });
  }

  postHttpCall(url: string, body: any | null, calledFrom: string = 'calledFrom') {
    this.logger.debug(`postHttpCall:<${calledFrom}>
      url: ${url} `);
    return this.http
      .post<any>(encodeURI(`${apiUrl}/${url}`), body, {
        responseType: 'json',
        withCredentials: true
      })
      .pipe(
        catchError(errorRes => {
          this.logger.error(`postHttpCall:${calledFrom}:error: `, errorRes.message);
          return throwError(() => errorRes);// new Error(errorRes));
        })
      );
  }

  postHttpCallAsPromise(url: string, body: any | null, calledFrom: string = 'calledFrom'): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.getCsrfAsPromise()
        .then(done => {
          return this.postHttpCall(url, body, calledFrom)
            .subscribe({
              next: resData => resolve(resData),
              error: err => reject(err)
            });
        })
        .catch(err => reject(err));
    });
  }

  putHttpCall(url: string, body: any | null, calledFrom: string = 'calledFrom') {
    this.logger.debug(`putHttpCall:<${calledFrom}>
      url: ${url} `);
    return this.http
      .put<any>(encodeURI(`${apiUrl}/${url}`), body, {
        responseType: 'json',
        withCredentials: true
      })
      .pipe(
        catchError(errorRes => {
          this.logger.error(`putHttpCall:${calledFrom}:error: `, errorRes.message);
          return throwError(() => errorRes);
        })
      );
  }

  putHttpCallAsPromise(url: string, body: any | null, calledFrom: string = 'calledFrom'): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.getCsrfAsPromise()
        .then(done => {
          return this.putHttpCall(url, body, calledFrom)
            .subscribe({
              next: resData => resolve(resData),
              error: err => reject(err)
            });
        })
        .catch(err => reject(err));
    });
  }

  deleteHttpCall(url: string, calledFrom: string = 'calledFrom') {
    this.logger.debug(`getHttpCall:<${calledFrom}>
      url: ${url} `);
    return this.http
      .delete<any>(encodeURI(`${apiUrl}/${url}`), {
        withCredentials: true
      })
      .pipe(
        catchError(errorRes => {
          this.logger.error(`getHttpCall:${calledFrom}:error: `, errorRes.message);
          return throwError(() => errorRes);
        })
      );
  }

  deleteHttpCallAsPromise(url: string, calledFrom: string = 'calledFrom'): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.getCsrfAsPromise()
        .then(done => {
          return this.deleteHttpCall(url, calledFrom)
            .subscribe({
              next: resData => resolve(resData),
              error: err => reject(err)
            });
        })
        .catch(err => reject(err));
    });
  }


  getHttpReport(url: string, calledFrom: string = 'calledFrom') {
    this.logger.debug(`getHttpReport:<${calledFrom}>   url: ${url} `);
    return this.http.get<ArrayBuffer>(encodeURI(`${apiUrl}/${url}`), {
        // headers: this.headers,
        responseType: 'arraybuffer'  as 'json',
        withCredentials: true
      })
      .pipe(
        tap(() => {
          this.logger.debug('getHttpReport:tap...');
          this.getCsrf();
        }),
        catchError(errorRes => {
          this.logger.error(`getHttpReport:${calledFrom}:error: `, errorRes.message);
          return throwError(() => errorRes);
        })
      );
  }

  getHttpReportAsPromise(url: string, calledFrom: string = 'calledFrom'): Promise<any> {
    return new Promise((resolve, reject) => {
      this.logger.debug('getHttpReportAsPromise: ', url, calledFrom);
      return this.getCsrfAsPromise()
        .then(done => {
          return this.getHttpReport(url, calledFrom)
            .subscribe({
              next: resData => resolve(resData),
              error: err => reject(err)
            });

        })
        .catch(err => reject(err));
    });
  }

  //#endregion
}
