import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { Subscription, map } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoggerService } from '../../../shared/logger/logger.service';
import { CoBenefit, DropDownTreeItem } from '../../../models/models.lib';
import { FormModes } from '../../../common/constants.lib';
import { EditorModule } from '@progress/kendo-angular-editor';
import { CoBenefitTypesService } from '../../../coBenefits/co-benefit-types.service';
import { CheckboxListComponent } from '../../../common-components/checkbox-list/checkbox-list.component';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { LabelModule } from '@progress/kendo-angular-label';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import _ from 'lodash';

interface CbItem {
  id: number | null,
  co_benefit: string
}

@Component({
  selector: 'casi-edit-co-benefit',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    FontAwesomeModule,
    RouterLink, RouterLinkActive,
    EditorModule,
    CheckboxListComponent,
    InputsModule,
    ButtonsModule,
    NotificationModule,
    LabelModule,
    DropDownsModule,
    DateInputsModule,
    LayoutModule
  ],
  templateUrl: './edit-co-benefit.component.html',
  styleUrl: './edit-co-benefit.component.scss'
})
export class EditCoBenefitComponent implements OnInit, OnDestroy {

  @Input({ required: true }) coBenefit: CoBenefit = new CoBenefit();
  @Input({ required: true }) mode: FormModes = FormModes.NOTSET;
  @Input({ required: true }) ddTreeData: any[] = [];

  @Output() onCancel = new EventEmitter<void>();
  @Output() onSave = new EventEmitter<any>();

  public value = '';

  public ddData: any[] = [];
  public fooData: any[] = [];
  public baz: any[] = [];
  public fthis: any = { text: null, co_benefit_type_id: null };
  public defaultItem: CbItem = {
    id: null,
    co_benefit: "Select..."
  };

  form: FormGroup;

  private _subs: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private logger: LoggerService,
    private dataService: CoBenefitTypesService
  ) {
    /**
     * co_benefit:"Environment"
     * co_benefit_group:"Net emissions, sequestration, storage"
     * co_benefit_type_id:4
     * id:5
     * negative:false
     * neutral:false
     * opportunity_id:1
     * optionSelected:"positive"
     * positive:true
     * radioOptions:(3) [{…}, {…}, {…}]
     * sub_co_benefit:"GHG net emissions arising from the production of the commodity"
     * summary:"werd"
     * visible:true
     */
    this.form = this.fb.group(
      {
        id: [null, []],
        opportunity_id: [null, []],
        co_benefit_type_id: [null, [Validators.required, Validators.min(1)]],
        coBenefitTree: [null, [Validators.required]],
        optionSelected: [null, [Validators.required]],
        summary: [null, [Validators.required]],
        visible: [null, []],
        co_benefit: [null, []],
        co_benefit_group: [null, []],
        negative: [null, []],
        neutral: [null, []],
        positive: [null, []],
        sub_co_benefit: [null, []]
      }
    );
  }

  get f() {
    return this.form.controls;
  }
  get co_benefit_type_id() {
    return this.form.get('co_benefit_type_id');
  }
  get optionSelected() {
    return this.form.get('optionSelected');
  }
  get summary() {
    return this.form.get('summary');
  }
  get visible() {
    return this.form.get('visible');
  }
  get coBenefitTree() {
    return this.form.get('coBenefitTree');
  }

  ngOnInit(): void {
    this.logger.debug('ngOnInit...', this.ddTreeData);
    this.form.patchValue(this.coBenefit);
    this.logger.debug('co', this.coBenefit);
    if (this.coBenefit) {
      const defaultValue = new DropDownTreeItem(
        (this.coBenefit.sub_co_benefit as string),
        (this.coBenefit.co_benefit_type_id as number),
        (this.coBenefit.co_benefit as string),
        (this.coBenefit.co_benefit_group as string)
      );
      this.logger.debug('defaultValue: ', defaultValue);
      this.form.patchValue({ coBenefitTree: defaultValue });
    }
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnDestroy...');
    this._subs.forEach(s => s.unsubscribe());
  }

  onSubmit(): void {
    this.logger.debug(`onSubmit...`, this.form.value, this.mode);
    let toSave = CoBenefit.create(this.form.value);
    toSave.setBooleanValues();
    this.onSave.emit({
      action: "save",
      data: toSave,
      mode: this.mode
    });
  }

  closeWindow(): void {
    this.logger.debug(`closeWindow...`);
    this.onCancel.emit();
  }

  setCoId(args: any) {
    this.logger.debug('args', args);
    this.form.patchValue({ co_benefit_type_id: args && args.id ? args.id : null });
  }
}
