import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoggerService } from '../../../shared/logger/logger.service';
import { Permission, Role, RolePermission } from '../../../models/models.lib';

import { GridModule } from "@progress/kendo-angular-grid";
import {
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";

import _ from 'lodash';
import { RolePermissionService } from '../../role-permission.service';
import { NotificationService, NotificationModule } from "@progress/kendo-angular-notification";
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'casi-role-permission',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    FontAwesomeModule,
    RouterLink, RouterLinkActive,
    GridModule,
    NotificationModule,
    InputsModule,
    ButtonsModule
  ],
  templateUrl: './role-permission.component.html',
  styleUrl: './role-permission.component.scss'
})
export class RolePermissionComponent implements OnInit, OnDestroy {

  @Input({ required: true }) role: Role = new Role();
  @Input({ required: true }) permissionSet: Permission[] = [];

  @Output() onCancel = new EventEmitter<void>();
  @Output() onSave = new EventEmitter<void>();

  public view: Observable<RolePermission[]> = new Observable<RolePermission[]>();
  public rawData: RolePermission[] = [];
  public filter: CompositeFilterDescriptor = { logic: "and", filters: [] };
  public gridData: Permission[] = []; // filterBy(this.rawData, this.filter);

  @ViewChild("appendTo", { read: ViewContainerRef }) appendTo: ViewContainerRef | undefined;

  private _subs: Subscription[] = [];
  private _selectedPermissions: number[] = [];
  public toggleFlag: boolean = false;

  constructor(
    private logger: LoggerService,
    public dataService: RolePermissionService,
    // private notificationService: NotificationService,
    private toastr: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.logger.debug('ngOnInit...', this.role);
    this.dataService.query((this.role.id as number));
    this._subs.push(this.dataService.subscribe(rp => {
      this.logger.debug('ngOnInit:rp: ', rp);
      this.rawData = rp;
      this.gridData = this.handleDataInit(rp);// filterBy(this.rawData, this.filter);
    }));

  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnInit...');
    this._subs.forEach(s => s.unsubscribe());
  }

  closeWindow(): void {
    this.logger.debug(`closeWindow...`);
    this.onCancel.emit();
  }

  closeAndSaveWindow(): void {
    this.logger.debug(`closeAndSaveWindow...`);
    this.handleSave()
  }

  toggleAll(): void {
    this.logger.debug('toggleAll...');
    this.toggleFlag = !this.toggleFlag;
    this.toggleAllPermissions(this.toggleFlag);
  };

  setSelection(dataItem: any) {
    this.logger.debug('setSelection:dataItem: ', dataItem);
    const { id } = dataItem;
    if (!dataItem.selected) {
      dataItem.selected = true;
      if (this._selectedPermissions.indexOf(id) == -1) {
        this._selectedPermissions.push(id);
      }
    }
    else {
      dataItem.selected = false;
      if (this._selectedPermissions.indexOf(id) != -1) {
        this._selectedPermissions.splice(this._selectedPermissions.indexOf(id), 1);
      }
    }

    this.logger.debug(`selected items`, {
      selectedPermissions: this._selectedPermissions,
      dataItem, id
    });
  };

  private toggleAllPermissions(flag: boolean = false): void {
    this.logger.debug('toggleAllPermissions...');
    this.gridData.forEach(gd => gd.selected = flag);
    this._selectedPermissions = this.gridData.filter(f => f.selected === true).map(gd => gd.id);
    this.logger.debug('toggleAllPermissions...', this._selectedPermissions);
    // this._selectedPermissions = this.handleDataInit(this.rawData).map(p => {
    //   p.selected = flag;
    //   return p.id;
    // });
    // if (!flag)
    //   this._selectedPermissions.length = 0;
  };

  private handleDataInit(data: RolePermission[]): Permission[] {
    this.logger.debug('handleDataInit...');
    this.toggleFlag = false;
    this._selectedPermissions = data.map(r => r.permission_id);
    let lRolePermissions = this.permissionSet.map(d => Permission.create(d));
    data.forEach((rp) => {
      let found = _.find(lRolePermissions, (lrp) => lrp.id === rp.permission_id);
      if (found) {
        found.select = true;
        this.toggleFlag = true;
      }
    });
    return lRolePermissions;
  }

  private handleSave() {

    this.logger.debug(`handleSave: selectedPermissions: `, this._selectedPermissions);
    return this.dataService
      .update((this.role.id as number), this._selectedPermissions)
      .then((done) => {
        this.toastr.success('Role permissions updated.', 'Updated');
        // this.notificationService.show({
        //   appendTo: this.appendTo,
        //   content: 'Role Permissions Updated.',
        //   animation: { type: "fade", duration: 300 },
        //   type: { style: "success", icon: true },
        //   position: {
        //     horizontal: "right",
        //     vertical: "top"
        //   },
        //   // closable: true,
        //   height: 50,
        //   width: 150,
        //   hideAfter: 2000
        // });
        this.onSave.emit();
      })
      .catch(err => {
        this.toastr.error('Problem updating permissions.', 'Error');
        // this.notificationService.show({
        //   appendTo: this.appendTo,
        //   content: 'Problem updating permissions.',
        //   animation: { type: "fade", duration: 300 },
        //   type: { style: "error", icon: true },
        //   position: {
        //     horizontal: "right",
        //     vertical: "top"
        //   },
        //   // closable: true,
        //   height: 50,
        //   width: 150,
        //   hideAfter: 2000
        // });
        this.logger.error(`handleSave: ** ERROR **`, err);
      });
  }

}
