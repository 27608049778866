import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoggerService } from '../../shared/logger/logger.service';
import { Opportunity } from '../../models/models.lib';
import { FormModes } from '../../common/constants.lib';
import { EditorModule } from '@progress/kendo-angular-editor';

@Component({
  selector: 'casi-checkbox-list',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    FontAwesomeModule,
    RouterLink, RouterLinkActive,
    EditorModule
  ],
  templateUrl: './checkbox-list.component.html',
  styleUrl: './checkbox-list.component.scss'
})
export class CheckboxListComponent implements OnInit, OnDestroy {

  @Input({ required: true }) item: Opportunity = new Opportunity();
  // @Input({ required: true }) selection: any[] = [];

  // @Output() onUpdate = new EventEmitter<any>();

  public items: any[] = [];

  private _subs: Subscription[] = [];

  constructor(
    private logger: LoggerService,
  ) {
  }

  ngOnInit(): void {
    this.logger.debug('ngOnInit...');
    this.items = this.item.goalList;
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnInit...');
    this._subs.forEach(s => s.unsubscribe());
  }
}
