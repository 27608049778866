import { Schema } from 'prosemirror-model';
import { tableNodes } from 'prosemirror-tables';
import { domToPmDoc, htmlToFragment, pmDocToFragment } from '../source';
import { rowTypeAttr, colgroupAttr, resizableAttr } from './constants';
const hole = 0;
const blockquoteDOM = ['blockquote', hole], hrDOM = ['hr'], preDOM = ['pre', ['code', hole]];
const olDOM = ['ol', 0], ulDOM = ['ul', 0], liDOM = ['li', 0];
const domAttributes = (dom) => {
    const result = {};
    const attributes = dom.attributes;
    let attr;
    for (let i = 0; i < attributes.length; i++) {
        attr = attributes[i];
        result[attr.name] = attr.value;
    }
    return result;
};
const defaultAttrs = (attrs) => {
    const nodeAttrs = {};
    attrs.forEach(attr => {
        nodeAttrs[attr] = { default: null };
    });
    return nodeAttrs;
};
const commonAttributes = () => defaultAttrs(['style', 'class', 'id']);
const hasAttrs = (attrs, exclude) => {
    for (const attr in attrs) {
        if (attr && attrs[attr] !== null && attr !== exclude) {
            return true;
        }
    }
    return false;
};
const pmAttributes = (attrs, exclude = []) => {
    const result = {};
    for (const attr in attrs) {
        if (attr && attrs[attr] !== null && !exclude.find(e => e === attr)) {
            result[attr] = attrs[attr];
        }
    }
    return result;
};
const tagMark = tag => {
    return {
        [tag]: {
            name: tag,
            inclusive: true,
            parseDOM: [{ tag: tag }],
            toDOM: () => [tag, hole]
        }
    };
};
const marks = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ 
    // :: MarkSpec A link. Has `href` and `title` attributes. `title`
    // defaults to the empty string. Rendered and parsed as an `<a>`
    // element.
    link: {
        attrs: Object.assign(Object.assign({}, commonAttributes()), { href: { default: null }, target: { default: null }, title: { default: null } }),
        inclusive: false,
        parseDOM: [{ tag: 'a', getAttrs: domAttributes }],
        toDOM: (node) => ['a', pmAttributes(node.attrs), hole]
    } }, tagMark('strong')), tagMark('b')), tagMark('em')), tagMark('i')), tagMark('del')), tagMark('sub')), tagMark('sup')), tagMark('code')), { style: {
        attrs: Object.assign({}, commonAttributes()),
        parseDOM: [{
                tag: 'span',
                getAttrs: domAttributes
            }],
        toDOM: node => hasAttrs(node.attrs) ?
            ['span', pmAttributes(node.attrs), hole] : ['span', hole]
    } }), tagMark('u'));
const cellAttribute = (name) => {
    return {
        [name]: {
            default: null,
            getFromDOM: (cell) => cell.getAttribute(name),
            setDOMAttr: (value, attrs) => { attrs[name] = value; }
        }
    };
};
const cellAttributes = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, cellAttribute('style')), cellAttribute('class')), cellAttribute('id')), cellAttribute('headers')), cellAttribute('scope'));
const colgroupNodes = {
    doc: { content: 'colgroup*' },
    col: {
        attrs: defaultAttrs(['id', 'class', 'style', 'span']),
        parseDOM: [{ getAttrs: domAttributes, tag: 'col' }],
        toDOM: node => ['col', node.attrs]
    },
    colgroup: {
        attrs: defaultAttrs(['id', 'class', 'style', 'span']),
        content: 'col*',
        parseDOM: [{ getAttrs: domAttributes, tag: 'colgroup' }],
        toDOM: node => ['colgroup', node.attrs, 0]
    },
    text: { inline: true, group: 'inline' }
};
const colgroupSchema = new Schema({ nodes: colgroupNodes, marks: {} });
// will be removed when we implement our own columnResizing
const shouldSkipColgroup = (node) => {
    let shouldSkip = false;
    const row = node.child(0);
    for (let r = 0; r < row.childCount; r++) {
        const cell = row.child(r);
        if (cell.attrs.colwidth) {
            shouldSkip = true;
            break;
        }
    }
    return shouldSkip;
};
export const parseStrColgroup = (colgroup) => {
    const doc = domToPmDoc(htmlToFragment(colgroup), colgroupSchema, { preserveWhitespace: false });
    const fragment = pmDocToFragment(doc);
    const colgroupEl = fragment.firstChild;
    return colgroupEl;
};
const tNodes = tableNodes({ tableGroup: 'block', cellContent: 'block+', cellAttributes });
tNodes.table_row.attrs = Object.assign(Object.assign({}, tNodes.table_row.attrs), defaultAttrs([rowTypeAttr, 'style', 'class', 'id']));
tNodes.table_row.toDOM = node => ['tr', pmAttributes(node.attrs), 0];
tNodes.table_row.parseDOM = [{ tag: 'tr', getAttrs: domAttributes }];
tNodes.table.attrs = Object.assign(Object.assign({}, tNodes.table.attrs), defaultAttrs(['style', 'class', 'id', colgroupAttr, resizableAttr]));
tNodes.table.toDOM = (node) => {
    const tableAttrs = hasAttrs(node.attrs) ? pmAttributes(node.attrs, [colgroupAttr, resizableAttr]) : {};
    let colgroup = null;
    if (node.attrs[colgroupAttr] && !shouldSkipColgroup(node)) {
        const colgroupEl = parseStrColgroup(node.attrs[colgroupAttr]);
        if (colgroupEl) {
            const cols = Array.from(colgroupEl.children).map((c) => ['col', domAttributes(c)]);
            colgroup = [
                'colgroup',
                domAttributes(colgroupEl)
            ].concat(cols);
        }
    }
    return colgroup ? ['table', tableAttrs, colgroup, ['tbody', 0]] :
        ['table', tableAttrs, ['tbody', 0]];
};
tNodes.table.parseDOM = [{
        tag: 'table',
        getAttrs: (node) => {
            const attrs = domAttributes(node);
            const colgroup = Array.from(node.childNodes).find(c => c.nodeName === 'COLGROUP');
            if (colgroup) {
                attrs[colgroupAttr] = colgroup.outerHTML;
            }
            return attrs;
        }
    }];
const nodes = Object.assign({ 
    // :: NodeSpec The top level document node.
    doc: {
        content: 'block+'
    }, 
    // :: NodeSpec A plain paragraph textblock. Represented in the DOM
    // as a `<p>` element.
    paragraph: {
        content: 'inline*',
        group: 'block',
        attrs: Object.assign({}, commonAttributes()),
        parseDOM: [{
                tag: 'p',
                getAttrs: domAttributes
            }],
        toDOM: node => hasAttrs(node.attrs) ? ['p', pmAttributes(node.attrs), hole] : ['p', hole]
    }, table_wrapper: {
        content: '(table_caption_external | table)+',
        group: 'block',
        defining: true,
        attrs: {
            table: { default: null },
            style: { default: null }
        },
        parseDOM: [{
                tag: 'div[table]',
                getAttrs: domAttributes
            }],
        toDOM: node => hasAttrs(node.attrs) ? ['div', pmAttributes(node.attrs), hole] : ['div', hole]
    }, table_caption_external: {
        content: 'inline+',
        group: 'block',
        attrs: Object.assign({ caption: { default: null } }, commonAttributes()),
        parseDOM: [{
                tag: 'div[caption]',
                getAttrs: domAttributes
            }],
        toDOM: node => hasAttrs(node.attrs) ? ['div', pmAttributes(node.attrs), hole] : ['div', hole]
    }, div: {
        // Uncaught SyntaxError: Mixing inline and block content (in content expression '(block | inline)*')
        // content: '(block | inline)*',
        content: 'block*',
        group: 'block',
        attrs: Object.assign({}, commonAttributes()),
        parseDOM: [{
                tag: 'div',
                getAttrs: domAttributes
            }],
        toDOM: node => hasAttrs(node.attrs) ? ['div', pmAttributes(node.attrs), hole] : ['div', hole]
    }, 
    // :: NodeSpec A blockquote (`<blockquote>`) wrapping one or more blocks.
    blockquote: {
        attrs: Object.assign({}, commonAttributes()),
        content: 'block+',
        group: 'block',
        defining: true,
        parseDOM: [{
                tag: 'blockquote',
                getAttrs: domAttributes
            }],
        toDOM: node => hasAttrs(node.attrs) ? ['blockquote', pmAttributes(node.attrs), hole] : blockquoteDOM
    }, 
    // :: NodeSpec A horizontal rule (`<hr>`).
    horizontal_rule: {
        group: 'block',
        parseDOM: [{ tag: 'hr' }],
        toDOM: () => hrDOM
    }, 
    // :: NodeSpec A heading textblock, with a `level` attribute that
    // has to hold the numbers from 1 to 6. Parsed and serialized as `<h1>` to
    // `<h6>` elements.
    heading: {
        attrs: Object.assign(Object.assign({}, commonAttributes()), { level: { default: 1 } }),
        content: 'inline*',
        group: 'block',
        defining: true,
        parseDOM: [
            { tag: 'h1', getAttrs: node => (Object.assign(Object.assign({}, domAttributes(node)), { level: 1 })) },
            { tag: 'h2', getAttrs: node => (Object.assign(Object.assign({}, domAttributes(node)), { level: 2 })) },
            { tag: 'h3', getAttrs: node => (Object.assign(Object.assign({}, domAttributes(node)), { level: 3 })) },
            { tag: 'h4', getAttrs: node => (Object.assign(Object.assign({}, domAttributes(node)), { level: 4 })) },
            { tag: 'h5', getAttrs: node => (Object.assign(Object.assign({}, domAttributes(node)), { level: 5 })) },
            { tag: 'h6', getAttrs: node => (Object.assign(Object.assign({}, domAttributes(node)), { level: 6 })) }
        ],
        toDOM: node => hasAttrs(node.attrs, 'level') ?
            ['h' + node.attrs.level, pmAttributes(node.attrs, ['level']), hole] :
            ['h' + node.attrs.level, hole]
    }, 
    // :: NodeSpec A code listing. Disallows marks or non-text inline
    // nodes by default. Represented as a `<pre>` element with a
    // `<code>` element inside it.
    code_block: {
        content: 'text*',
        marks: '',
        group: 'block',
        code: true,
        defining: true,
        parseDOM: [{ tag: 'pre', preserveWhitespace: 'full' }],
        toDOM: () => preDOM
    }, 
    // :: NodeSpec The text node.
    text: {
        inline: true,
        group: 'inline'
    }, 
    // :: NodeSpec An inline image (`<img>`) node. Supports `src`,
    // `alt`, and `href` attributes. The latter two default to an empty
    // string.
    image: {
        inline: true,
        attrs: Object.assign({ src: { default: null }, alt: { default: null }, title: { default: null }, width: { default: null }, height: { default: null } }, commonAttributes()),
        group: 'inline',
        draggable: true,
        parseDOM: [{ tag: 'img', getAttrs: domAttributes }],
        toDOM: node => hasAttrs(node.attrs) ? ['img', pmAttributes(node.attrs)] : ['img']
    }, 
    // :: NodeSpec A hard line break represented in the DOM as a `<br>` element.
    hard_break: {
        inline: true,
        attrs: Object.assign({}, commonAttributes()),
        group: 'inline',
        selectable: false,
        parseDOM: [{
                tag: 'br',
                getAttrs: domAttributes
            }],
        toDOM: node => hasAttrs(node.attrs) ? ['br', pmAttributes(node.attrs)] : ['br']
    }, 
    // :: NodeSpec
    // An ordered list [node spec](#model.NodeSpec). Has a single
    // attribute, `order`, which determines the number at which the list
    // starts counting, and defaults to 1. Represented as an `<ol>`
    // element.
    ordered_list: {
        content: 'list_item+',
        group: 'block',
        attrs: Object.assign(Object.assign({}, commonAttributes()), { type: { default: null }, order: { default: 1 } }),
        parseDOM: [{ tag: 'ol', getAttrs: (dom) => {
                    return Object.assign(Object.assign({}, domAttributes(dom)), { order: dom.hasAttribute('start') ? parseInt(dom.getAttribute('start') || '1', 10) : 1 });
                } }],
        toDOM: node => {
            return node.attrs.order === 1 ?
                (hasAttrs(node.attrs, 'order') ? ['ol', pmAttributes(node.attrs, ['order']), hole] : olDOM) :
                ['ol', Object.assign(Object.assign({}, pmAttributes(node.attrs, ['order'])), { start: node.attrs.order }), hole];
        }
    }, 
    // :: NodeSpec
    // A bullet list node specification represented in the DOM as a `<ul>` element.
    bullet_list: {
        content: 'list_item+',
        group: 'block',
        attrs: Object.assign({}, commonAttributes()),
        parseDOM: [{ tag: 'ul', getAttrs: domAttributes }],
        toDOM: node => hasAttrs(node.attrs) ? ['ul', pmAttributes(node.attrs), hole] : ulDOM
    }, 
    // :: NodeSpec
    // A list item (`<li>`) specification.
    list_item: {
        content: '(paragraph | heading) block*',
        attrs: Object.assign({}, commonAttributes()),
        parseDOM: [{ tag: 'li', getAttrs: domAttributes }],
        toDOM: node => hasAttrs(node.attrs) ? ['li', pmAttributes(node.attrs), hole] : liDOM,
        defining: true
    } }, tNodes);
export { nodes, marks };
