import { Component, EventEmitter, Input, NO_ERRORS_SCHEMA, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoggerService } from '../../shared/logger/logger.service';
import { UserDataService } from '../../users/user-data.service';
import { FormModes } from '../../common/constants.lib';
import { CoBenefitType, Role } from '../../models/models.lib';
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { LabelModule } from "@progress/kendo-angular-label";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { ButtonsModule } from "@progress/kendo-angular-buttons";

@Component({
  selector: 'casi-edit-co-benefit',
  standalone: true,
  imports: [CommonModule,
    FormsModule, ReactiveFormsModule,
    FontAwesomeModule,
    RouterLink, RouterLinkActive,
    DropDownsModule,
    LabelModule, InputsModule,
    ButtonsModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
  templateUrl: './edit-co-benefit.component.html',
  styleUrl: './edit-co-benefit.component.scss'
})
export class EditCoBenefitComponent implements OnInit, OnDestroy {

  //#region Properties

  @Input({ required: true }) coBenefitType: CoBenefitType = new CoBenefitType();
  @Input({ required: true }) mode: FormModes = FormModes.NOTSET;

  @Output() onCancel = new EventEmitter<void>();
  @Output() onSave = new EventEmitter<any>();

  form: FormGroup;

  public value: number[] = [];

  private _subs: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private logger: LoggerService,
  ) {
    this.form = this.fb.group(
      {
        id: [null, []],
        co_benefit: ['', [Validators.required]],
        co_benefit_group: [null, [Validators.required]],
        sub_co_benefit: [null, [Validators.required]],
        active: [null, []]
      }
    );
  }

  get f() {
    return this.form.controls;
  }
  get co_benefit() {
    return this.form.get('co_benefit');
  }
  get co_benefit_group() {
    return this.form.get('co_benefit_group');
  }
  get sub_co_benefit() {
    return this.form.get('sub_co_benefit');
  }
  get active() {
    return this.form.get('active');
  }

  //#endregion

  //#region Ng Events

  ngOnInit(): void {
    this.logger.debug('ngOnInit...',  this.coBenefitType);
    this.form.patchValue(this.coBenefitType);
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnInit...');
    this._subs.forEach(s => s.unsubscribe());
  }

  //#endregion

  onSubmit(): void {
    this.logger.debug(`onSubmit...`, this.form.value);
    this.onSave.emit({
      action: "save",
      data: this.form.value,
      mode: this.mode
    });
  }

  closeWindow(): void {
    this.logger.debug(`closeWindow...`);
    this.onCancel.emit();
  }

}
