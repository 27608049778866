<div class="container-fluid" style="padding-left: 0px !important; padding-right: 0px !important; margin-top: -15px;">

  <div class="row row-nomargin">

    <div class="col-12 col-lg-6" style="padding: 0;">
      <img src="assets/images/about1.png" style="width: 100%; height: 560px; object-fit: cover;">
    </div>
    <div class="col-12 col-lg-6" style="padding: 0px; position: relative;">
      <img src="assets/images/b2.png" style="width: 100%; height: 560px; object-fit: cover;">
      <div class="d-none d-md-block" style="padding: 90px 20px 20px 80px; position: absolute; top: 0;">
        <hr style="width: 50px; border-top: 5px solid #dd7f45;">
        <h2 style="line-height: 1.3; max-width: 480px; color: white; font-weight: 500 !important;">
          Environer is Australia’s
          independent knowledge hub
          informing environmental
          stewardship decisions for
          primary producers.

        </h2>
      </div>
      <div class="d-block d-md-none" style="padding: 30px 60px; position: absolute; top: 0;">
        <hr style="width: 50px; border-top: 5px solid #dd7f45;">
        <h2 style="line-height: 1.3; color: white; font-weight: 500 !important; font-size: 20pt !important;">
          Environer is Australia’s
          independent knowledge hub
          informing environmental
          stewardship decisions for
          primary producers.
        </h2>
      </div>

    </div>

  </div>

  <div style="padding: 100px 8%; background-color: #efefef;">
    <hr style="width: 50px; border-top: 5px solid #dd7f45; margin: 0 auto 30px auto;">
    <div class="row row-nomargin ">

      <div class="col-12" style="padding: 40px;">

        <p style="font-size: 15pt !important; line-height: 1.3;">
          In partnership with AgriFutures Australia, the University of New England, developed this free
          online decision support tool for farmers, fishers and foresters who want to learn more about managing natural
          capital, biodiversity, and carbon for multiple benefits. The focus is on opportunities that provide access to
          commodity markets and financial products and outcomes related to carbon and emission reduction, sustainability
          and environmental stewardship.
        </p>
      </div>

    </div>
    <div class="row row-nomargin ">
      <div class="col-12 d-block d-md-none" style="text-align: center;">
        <img src="assets/images/agrifutures_logo.png" style="height: 130px;">
        <br>
        <img src="assets/images/UNE_Logo.png" style="height: 110px; margin-top: 30px;">
      </div>
      <div class="col-md-6 d-none d-md-block" style="text-align: center; padding-right: 0; border-right: 1px solid;">
        <img src="assets/images/agrifutures_logo.png" style="height: 130px;">
      </div>
      <div class="col-md-6 d-none d-md-block" style="text-align: center; padding-left: 0; border-left: 1px solid;">
        <img src="assets/images/UNE_Logo.png" style="height: 110px;">
      </div>
    </div>
  </div>

  <div style="padding: 100px 8%; text-align: center;">
    <h2 style="margin-bottom: 40px;">University of New England</h2>
    <hr style="width: 50px; border-top: 5px solid #dd7f45; margin: 0 auto 30px auto;">
    <div class="row row-nomargin ">


        <div class="col-12 d-block d-md-none" style="padding: 40px;">
            <p style="font-size: 15pt !important; line-height: 1.3; text-align: left;">
            The University of New England has a long record of successful collaborations to address economic, social,
              production and environmental issues regionally, nationally and internationally. UNE has hosted four agricultural
              industry-specific Cooperative Research Centres (Poultry, Beef, Sheep and Cotton), delivering over $2 billion of
              benefits to Australia, with successor organisations driving the regional growth and expansion of these industries.
            </p>
            <p style="font-size: 15pt !important; line-height: 1.3; text-align: left;">
              UNE has worked in other CRCs (e.g. Weeds, Future Food Systems, Bushfire and Natural Hazards) and continues to work
              with all of the Australian RDCs and peak agricultural industry bodies in projects spanning most levied and
              emerging industries. UNE is a research provider organisation in the National Environmental Science Program (NESP)
              Resilient Landscapes Hub, and theme leader for Sustainable Native Landscapes in the NSW Government’s
              Decarbonisation Hub. UNE also leads the Armidale node of the Southern Qld and Northern NSW Drought Resilience
              Adoption and Innovation Hub.
            </p>
        </div>


        <div class="col-md-6 d-none d-md-block" style="padding: 40px;">

          <p style="font-size: 15pt !important; line-height: 1.3; text-align: left;">
            The University of New England has a long record of successful collaborations to address economic, social,
              production and environmental issues regionally, nationally and internationally. UNE has hosted four agricultural
              industry-specific Cooperative Research Centres (Poultry, Beef, Sheep and Cotton), delivering over $2 billion of
              benefits to Australia, with successor organisations driving the regional growth and expansion of these industries.
              UNE has worked in other CRCs (e.g. Weeds, Future Food Systems, Bushfire and Natural Hazards) and continues
          </p>

        </div>
        <div class="col-md-6 d-none d-md-block" style="padding: 40px;">

          <p style="font-size: 15pt !important; line-height: 1.3; text-align: left;">
            to work
            with all of the Australian RDCs and peak agricultural industry bodies in projects spanning most levied and
            emerging industries. UNE is a research provider organisation in the National Environmental Science Program (NESP)
            Resilient Landscapes Hub, and theme leader for Sustainable Native Landscapes in the NSW Government’s
            Decarbonisation Hub. UNE also leads the Armidale node of the Southern Qld and Northern NSW Drought Resilience
            Adoption and Innovation Hub.
          </p>

        </div>

    </div>
    <img src="assets/images/UNE_Logo.png" style="height: 110px;">

  </div>

  <div style="padding: 100px 8%; text-align: center; background-color: #efefef;">
    <h2 style="margin-bottom: 40px;">AgriFutures Australia</h2>
    <hr style="width: 50px; border-top: 5px solid #dd7f45; margin: 0 auto 30px auto;">
    <div class="row row-nomargin ">


        <div class="col-12 d-block d-md-none" style="padding: 40px;">
            <p style="font-size: 15pt !important; line-height: 1.3; text-align: left;">
              AgriFutures Australia is one of 15 Research and Development Corporations (RDCs) that service the Research, Development and Extension (RD&E) needs of Australian rural industries.
              We manage the research needs for 13 ruralindustries, delivering research and innovation that aims to give our farmers and producers real returns, as well as preparing them to thrive into the future.<br><br>
              AgriFutures Australia invests in research, innovation and leadership to strengthen our rural industries. Through targeted research we aim to drive the growth and development of emerging,
              high-potential agricultural rural industries such as sesame, industrial hemp, coffee, insects, hazelnuts and seaweed.<br><br>
              Through programs such as the AgriFutures Rural Women’s Award, the AgriFutures Horizon Scholarship and targeted industry activity, we support the development of those working in the
              Australian agricultural sector by responding to the specific workforce and leadership needs.
            </p>
            <p style="font-size: 15pt !important; line-height: 1.3; text-align: left;">
              We work to future-proof Australian rural industries by identifying and responding to national challenges and opportunities which impact the Australian agricultural sector, including research around
              sustainability, new revenue opportunities and farm safety.<br><br>
              Through programs such as AgriFutures evoke<sup>AG.</sup> and AgriFutures grow<sup>AG.</sup>, we engage with the global agrifood innovation system to ensure Australia is a leader in accessing,
              adopting, developing and exporting agrifood technologies and innovation.
            </p>
        </div>


        <div class="col-md-6 d-none d-md-block" style="padding: 40px;">

          <p style="font-size: 15pt !important; line-height: 1.3; text-align: left;">
            AgriFutures Australia is one of 15 Research and Development Corporations (RDCs) that service the Research, Development and Extension (RD&E) needs of Australian rural industries.
            We manage the research needs for 13 ruralindustries, delivering research and innovation that aims to give our farmers and producers real returns, as well as preparing them to thrive into the future.<br><br>
            AgriFutures Australia invests in research, innovation and leadership to strengthen our rural industries. Through targeted research we aim to drive the growth and development of emerging,
            high-potential agricultural rural industries such as sesame, industrial hemp, coffee, insects, hazelnuts and seaweed.<br><br>
            Through programs such as the AgriFutures Rural Women’s Award, the AgriFutures Horizon Scholarship and targeted industry activity, we support the development of those working in the
            Australian agricultural sector by responding to the specific workforce and leadership needs.
          </p>

        </div>
        <div class="col-md-6 d-none d-md-block" style="padding: 40px;">

          <p style="font-size: 15pt !important; line-height: 1.3; text-align: left;">
            We work to future-proof Australian rural industries by identifying and responding to national challenges and opportunities which impact the Australian agricultural sector, including research around
            sustainability, new revenue opportunities and farm safety.<br><br>
            Through programs such as AgriFutures evoke<sup>AG.</sup> and AgriFutures grow<sup>AG.</sup>, we engage with the global agrifood innovation system to ensure Australia is a leader in accessing,
            adopting, developing and exporting agrifood technologies and innovation.
          </p>

        </div>

    </div>
    <img src="assets/images/agrifutures_logo.png" style="height: 130px;">

  </div>

  <div class="row row-nomargin">

    <div class="col-12" style="padding-left: 0; padding-right: 0;">
      <img src="assets/images/b4.png" style="width: 100%; height: 500px; object-fit: cover;">
    </div>

  </div>

  <div style="padding: 100px 8%; background-color: #dd7f45; text-align: center; color: white;">
    <h2 style="margin-bottom: 40px;">How we built Environer</h2>
    <hr style="width: 50px; border-top: 5px solid white; margin: 0 auto 30px auto;">
    <div class="row row-nomargin ">

      <div class="col-12" style="padding: 40px;">

        <p style="font-size: 15pt !important; line-height: 1.3; font-weight: 500; text-align: left; color: white;">
          In consultation with Agrifutures, the UNE team reviewed relevant published and grey literature and research in
            Australia and overseas, focusing on commodity markets and financial products available to Australian primary
            producers in environmental stewardship and sustainability.
        </p>

        <p style="font-size: 14pt !important; line-height: 1.3; text-align: left; color: white; margin-top: 25px;">
          We reviewed information covering the benefits and
          costs of improving natural capital, carbon and biodiversity, including the provision of ecosystem services,
          social license and economic impacts. All this information was used to develop Environer, a discovery, opportunity
          and decision tool allowing Australian producers across all industries to make informed decisions about the
          management of carbon and natural capital in production systems.
        </p>
      </div>

    </div>

  </div>

  <div style="padding: 100px 8%;">
    <h2 style="text-align: center; margin-bottom: 40px;">Project Team</h2>
    <hr style="width: 50px; border-top: 5px solid #dd7f45; margin: 0 auto 30px auto;">
    <div class="d-none d-md-block">

      <div class="row row-nomargin " style="padding: 40px 40px 0px 40px;">
        <h3 style="padding-left: 0; margin-top: 10px;">Dr Rhiannon Smith (Team Leader)</h3>
        <hr style="width: 100%; border-top: 5px solid #dd7f45; margin-bottom: 20px;">
        <div class="col-lg-10 col-md-8" style="padding-left: 0;">
          <p class="largep">Dr Rhiannon Smith is a Senior Lecturer in Environmental Management at the University of New England. She works at the interface of agriculture
            and environment and has a strong focus on finding win-wins for producers and the environment. She has been researching biodiversity, carbon and ecosystem service
            provision on farms since 2005 and was recently a member of the Ag Biodiversity Stewardship Program Advisory Group that oversaw initial work in the development of the
            Agriculture Biodiversity Stewardship Program; the National Farmers Federation’s Agriculture Sustainability Framework, and the Commonwealth’s Ag Biodiversity Markets initiative.
            These programs aimed to develop markets for private investment in farm biodiversity and sustainability opportunities.</p>

        </div>
        <div class="col-lg-2 col-md-4" style="padding-right: 0; text-align: right;">
          <img src="assets/images/r1.png" style="width: 100%; max-width: 160px;">

        </div>

      </div>
      <div class="row row-nomargin " style="padding: 0px 40px;">
        <h3 style="padding-left: 0; margin-top: 10px;">Mr Johan Boshoff</h3>
        <hr style="width: 100%; border-top: 5px solid #dd7f45; margin-bottom: 20px;">
        <div class="col-lg-10 col-md-8" style="padding-left: 0;">
          <p class="largep">Johan leads the Computation, Analytics, Software and Informatics (CASI) group at UNE. CASI is tasked with the translation of research
            into useable solutions using Information Technology and data science. Under Johan's leadership, CASI delivered 22 research focussed applications since 2016 in a broad range of disciplines including
            <a href="https://ag360.com.au/" target="_blank">Ag360</a>,
            <a href="https://pest.une.edu.au/" target="_blank">PEST</a>,
            <a href="https://ramselect.com.au/" target="_blank">RamSelect</a>,
            <a href="https://adri.bnhcrc.com.au/" target="_blank">Australian Disaster Resilience Index</a> and <a href="https://casi.une.edu.au/#!/projects" target="_blank">more</a>. Johan has architected all the CASI software engineering projects, including Environer.</p>

        </div>
        <div class="col-lg-2 col-md-4" style="padding-right: 0; text-align: right;">
          <img src="assets/images/r2.png" style="width: 100%; max-width: 160px;">

        </div>
      </div>
      <div class="row row-nomargin " style="padding: 0px 40px;">
        <h3 style="padding-left: 0; margin-top: 10px;">Professor Lewis Kahn</h3>
        <hr style="width: 100%; border-top: 5px solid #dd7f45; margin-bottom: 20px;">
        <div class="col-lg-10 col-md-8" style="padding-left: 0;">
          <p class="largep">Lewis is a researcher and consultant and has led more than 20 projects in grazing and pasture
            management (DAFF, MLA, CMAs) and livestock systems (AWI, MLA, DAFF). He was Leader of the Wellbeing and
            Productivity Program with the Sheep CRC, has authored 85 peer-reviewed publications, and supervised 20
            postgraduate students.</p>

        </div>
        <div class="col-lg-2 col-md-4" style="padding-right: 0; text-align: right;">
          <img src="assets/images/r3.png" style="width: 100%; max-width: 160px;">

        </div>
      </div>
      <div class="row row-nomargin " style="padding: 0px 40px;">
        <h3 style="padding-left: 0; margin-top: 10px;">Dr Alex Ball</h3>
        <hr style="width: 100%; border-top: 5px solid #dd7f45; margin-bottom: 20px;">
        <div class="col-lg-10 col-md-8" style="padding-left: 0;">
          <p class="largep">Alex has contributed to the Australian Agriculture RD&E landscape for 24 years. He has widespread
            agricultural industry knowledge, stakeholder engagement awareness and networks, and logical planning methodologies.
            He has provided consultancy services to CRCs, governments, NGOs, most RDCs (including AgriFutures Australia) and
            peak industry councils.</p>

        </div>
        <div class="col-lg-2 col-md-4" style="padding-right: 0; text-align: right;">
          <!-- <img src="assets/images/r4.png" style="width: 100%; max-width: 160px;"> -->

        </div>
      </div>
      <div class="row row-nomargin " style="padding: 0px 40px;">
        <h3 style="padding-left: 0; margin-top: 10px;">Emeritus Professor Nick Reid</h3>
        <hr style="width: 100%; border-top: 5px solid #dd7f45; margin-bottom: 20px;">
        <div class="col-lg-10 col-md-8" style="padding-left: 0;">
          <p class="largep">Nick has worked in natural resource management, sustainable agriculture and environmental
            conservation for 45 years. He was a Co-Program Leader in the Cotton Catchment Communities CRC and managed
            grower-led research in Land, Water & Wool, funded by AWI and LWA. He has supervised 45 PhD and research Masters
            graduates and published over 230 books and monographs, book chapters, refereed journal papers and extension
            publications.</p>

        </div>
        <div class="col-lg-2 col-md-4" style="padding-right: 0; text-align: right;">
          <img src="assets/images/r5.png" style="width: 100%; max-width: 160px;">

        </div>
      </div>

    </div>

    <div class="d-block d-md-none">
      <div class="row row-nomargin " style="padding: 0px 40px;">
        <h3 style="padding-left: 0;">Dr Rhiannon Smith (Team Leader)</h3>
        <hr style="width: 100%; border-top: 5px solid #dd7f45; margin-bottom: 20px;">
        <div class="col-12" style="padding-left: 0; padding-right: 0;">
          <img src="assets/images/r1.png" style="width: 100%; max-width: 160px; margin-bottom: 20px;">
          <p class="largep">Dr Rhiannon Smith is a Senior Lecturer in Environmental Management at the University of New England. She works at the interface of agriculture
            and environment and has a strong focus on finding win-wins for producers and the environment. She has been researching biodiversity, carbon and ecosystem service
            provision on farms since 2005 and was recently a member of the Ag Biodiversity Stewardship Program Advisory Group that oversaw initial work in the development of the
            Agriculture Biodiversity Stewardship Program; the National Farmers Federation’s Agriculture Sustainability Framework, and the Commonwealth’s Ag Biodiversity Markets initiative.
            These programs aimed to develop markets for private investment in farm biodiversity and sustainability opportunities.</p>
        </div>
      </div>
      <div class="row row-nomargin " style="padding: 0px 40px;">
        <h3 style="padding-left: 0; margin-top: 10px;">Mr Johan Boshoff</h3>
        <hr style="width: 100%; border-top: 5px solid #dd7f45; margin-bottom: 20px;">
        <div class="col-12" style="padding-left: 0; padding-right: 0;">
          <img src="assets/images/r2.png" style="width: 100%; max-width: 160px; margin-bottom: 20px;">
          <p class="largep">Johan leads the Computation, Analytics, Software and Informatics (CASI) group at UNE. CASI is tasked with the translation of research
            into useable solutions using Information Technology and data science. Under Johan's leadership, CASI delivered 22 research focussed applications since 2016 in a broad  of including
            <a href="https://ag360.com.au/" target="_blank">Ag360</a>,
            <a href="https://pest.une.edu.au/" target="_blank">PEST</a>,
            <a href="https://ramselect.com.au/" target="_blank">RamSelect</a>,
            <a href="https://adri.bnhcrc.com.au/" target="_blank">Australian Disaster Resilience Index</a> and <a href="https://casi.une.edu.au/#!/projects" target="_blank">more</a>. Johan has architected all the CASI software engineering projects, including Environer.</p>
        </div>
      </div>
      <div class="row row-nomargin " style="padding: 0px 40px;">
        <h3 style="padding-left: 0; margin-top: 10px;">Professor Lewis Kahn</h3>
        <hr style="width: 100%; border-top: 5px solid #dd7f45; margin-bottom: 20px;">
        <div class="col-12" style="padding-left: 0; padding-right: 0;">
          <img src="assets/images/r3.png" style="width: 100%; max-width: 160px; margin-bottom: 20px;">
          <p class="largep">Lewis is a researcher and consultant and has led more than 20 projects in grazing and pasture
            management (DAFF, MLA, CMAs) and livestock systems (AWI, MLA, DAFF). He was Leader of the Wellbeing and
            Productivity Program with the Sheep CRC, has authored 85 peer-reviewed publications, and supervised 20
            postgraduate students.</p>
        </div>
      </div>
      <div class="row row-nomargin " style="padding: 0px 40px;">
        <h3 style="padding-left: 0; margin-top: 10px;">Dr Alex Ball</h3>
        <hr style="width: 100%; border-top: 5px solid #dd7f45; margin-bottom: 20px;">
        <div class="col-12" style="padding-left: 0; padding-right: 0;">
          <!-- <img src="assets/images/r4.png" style="width: 100%; max-width: 160px; margin-bottom: 20px;"> -->
          <p class="largep">Alex has contributed to the Australian Agriculture RD&E landscape for 24 years. He has widespread
            agricultural industry knowledge, stakeholder engagement awareness and networks, and logical planning methodologies.
            He has provided consultancy services to CRCs, governments, NGOs, most RDCs (including AgriFutures Australia) and
            peak industry councils.</p>
        </div>
      </div>
      <div class="row row-nomargin " style="padding: 0px 40px;">
        <h3 style="padding-left: 0; margin-top: 10px;">Emeritus Professor Nick Reid</h3>
        <hr style="width: 100%; border-top: 5px solid #dd7f45; margin-bottom: 20px;">
        <div class="col-12" style="padding-left: 0; padding-right: 0;">
          <img src="assets/images/r5.png" style="width: 100%; max-width: 160px; margin-bottom: 20px;">
          <p class="largep">Nick has worked in natural resource management, sustainable agriculture and environmental
            conservation for 45 years. He was a Co-Program Leader in the Cotton Catchment Communities CRC and managed
            grower-led research in Land, Water & Wool, funded by AWI and LWA. He has supervised 45 PhD and research Masters
            graduates and published over 230 books and monographs, book chapters, refereed journal papers and extension
            publications.</p>
        </div>
      </div>
    </div>

  </div>

  <div class="homeblock" style="padding-bottom: 80px;">

    <div class="d-none d-lg-block">
      <div class="row row-nomargin" style="font-size: medium;">
        <div class="col-lg-4" style="padding: 20px 50px; border-left: 1px solid;">

          <img src="assets/images/UNE_Logo.png" class="flogo">
          <h5 style="margin-top: 20px; font-size: 12pt; font-weight: bold;">Research by:</h5>

          <address>
            University of New England<br>
            Armidale NSW 2351 Australia</address>

          <a href="https://une.edu.au" target="_blank" style="font-weight: bold; text-decoration: none;">une.edu.au</a>
        </div>

        <div class="col-lg-4" style="padding: 20px 50px; border-left: 1px solid;">

          <img src="assets/images/agrifutures_logo.png" class="flogo" style="max-height: 100px; margin-top: -20px;">
          <h5 style="margin-top: 20px; font-size: 12pt; font-weight: bold;">Funded by:</h5>

          <address>
            AgriFutures Australia<br>
            Locked Bag 588<br>
            Wagga Wagga NSW 2650</address>

          <a href="https://agrifutures.com.au" target="_blank"
            style="font-weight: bold; text-decoration: none;">agrifutures.com.au</a>
        </div>

        <div class="col-lg-4" style="padding: 20px 50px; border-left: 1px solid;">
          <img src="assets/images/UNE_CASI_Logo.png" class="flogo">
          <h5 style="margin-top: 20px; font-size: 12pt; font-weight: bold;">Dashboard developed by:</h5>

          <address>UNE CASI (Computation, Analytics, Software, Informatics)<br>
            University of New England<br>
            Armidale NSW 2351 Australia</address>

          <a href="https://casi.une.edu.au" target="_blank"
            style="font-weight: bold; text-decoration: none;">casi.une.edu.au</a>
        </div>


      </div>
    </div>
    <div class="d-block d-lg-none">
      <div class="row row-nomargin" style="font-size: medium; ">
        <div class="col-12" style="padding: 20px;">

          <img src="assets/images/UNE_Logo.png" class="flogo">
          <h5 style="margin-top: 20px; font-size: 12pt; font-weight: bold;">Research by:</h5>

          <address>
            University of New England<br>
            Armidale NSW 2351 Australia</address>

          <a href="https://une.edu.au" target="_blank" style="font-weight: bold; text-decoration: none;">une.edu.au</a>
        </div>

        <div class="col-12" style="padding: 20px;">

          <img src="assets/images/agrifutures_logo.png" class="flogo" style="max-height: 100px;">
          <h5 style="margin-top: 20px; font-size: 12pt; font-weight: bold;">Funded by:</h5>

          <address>
            AgriFutures Australia<br>
            Locked Bag 588<br>
            Wagga Wagga NSW 2650</address>

          <a href="https://agrifutures.com.au" target="_blank"
            style="font-weight: bold; text-decoration: none;">agrifutures.com.au</a>
        </div>

        <div class="col-12" style="padding: 20px 20px 60px 20px;">
          <img src="assets/images/UNE_CASI_Logo.png" class="flogo">
          <h5 style="margin-top: 20px; font-size: 12pt; font-weight: bold;">Dashboard developed by:</h5>

          <address>UNE CASI (Computation, Analytics, Software, Informatics)<br>
            University of New England<br>
            Armidale NSW 2351 Australia</address>

          <a href="https://casi.une.edu.au" target="_blank"
            style="font-weight: bold; text-decoration: none;">casi.une.edu.au</a>
        </div>


      </div>
    </div>

  </div>





</div>
