import { Injectable } from '@angular/core';
import {ApiService} from "../api/api.service";

@Injectable({
  providedIn: 'root'
})
export class JsReportServiceService {

  constructor(
    private apiService: ApiService
  ) { }

  printOpportunity(id: any, isLoggedin: boolean): Promise<any> {
    return this.apiService.getHttpReportAsPromise(`report/opportunity/${id}/${isLoggedin}`, 'printOpportunity');
  }
}
