import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { map, tap } from "rxjs/operators";
import { Role, Enquiry } from '../models/models.lib';
import { APP_CONFIG } from '../setup/config';
import { environment } from '../../environments/environment';

const { apiUrl } = environment;

export abstract class EnvironerEnquiryService extends BehaviorSubject<Array<any>>{
  public loading: boolean = false;

  constructor(
    private http: HttpClient,
  ) {
    super([]);
  }

  public query(): void {
    this.fetch().subscribe((x) => {
      // console.log('query: x: ', x);
      return super.next(x);
    });
  }

  protected fetch(): Observable<Enquiry[]> {
    this.loading = true;

    return this.http.get(encodeURI(`${apiUrl}/findenquiries`))
      .pipe(
        map((response: any, i) => {
          // console.log('fetch:map:response: ', response, i);
          // let returning =<Role[]>response; // response.map(r =>  Role.create((r)));
          // console.log('fetch:map:response: ', returning);
          return <Enquiry[]>response;
        }),
        map(r => r.map(x => Enquiry.create(x))),
        // tap(nr => {
        //   console.log('nr: ', nr);
        // }),
        tap(() => (this.loading = false))
      )
  }
}

@Injectable({
  providedIn: 'root'
})
export class EnquiresService extends EnvironerEnquiryService {

  constructor(
    http: HttpClient,
    private apiService: ApiService
  ) {
    super(http)
  }

  get() {
    return this.apiService.getHttpCall('findenquiries', 'get');
  }

  add(p: any): Promise<any> {
    return this.apiService.postHttpCallAsPromise('enquiries', p, 'add');
  }

  update(id: number, completed: boolean): Promise<any> {
    return this.apiService.putHttpCallAsPromise(`enquiries/${id}`, { completed }, 'update');
  }

}
