import { HttpInterceptorFn } from '@angular/common/http';
import { LoggerService } from '../shared/logger/logger.service';
import { inject } from '@angular/core';
import { ApiService } from '../api/api.service';

export const casiXsrfInterceptor: HttpInterceptorFn = (req, next) => {
  const logger = inject(LoggerService);
  const apiService = inject(ApiService);
  const cookieHeaderName = 'X-CSRF-TOKEN';
  let csrfToken = apiService.csrfToken;
  // logger.debug('casiXsrfInterceptor:csrfToken: ', csrfToken);
  if (csrfToken !== null && !req.headers.has(cookieHeaderName)) {
    // logger.debug('casiXsrfInterceptor...adding header...');
    let newReq = req.clone({ headers: req.headers.set(cookieHeaderName, csrfToken) });
    return next(newReq);
  } else {
    return next(req);

  }
};
