<div class="card casi-popup" >
  <div class="card-header">
    <div class="row" style="align-items: center;">
      <div class="col-9">
        {{title}}
      </div>
      <div class="col-2">
        <button mat-button type="button" color="primary" (click)="closeMe()"> close</button>
      </div>
    </div>
  </div>
  <div class="card-body overflow-auto">
    <!-- <div class="card-title">{{title}}</div> -->
    <div class="card-text" [innerHTML]="helpContent"></div>
    <button mat-button type="button" color="primary" (click)="closeMe()"> Close</button>
  </div>
</div>
