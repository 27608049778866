<!-- <div class="k-overlay" *ngIf="show"></div> -->

<ng-template #dialogErrorContent>
  <div class="error-align">

    <h4><strong>{{content}}</strong></h4>

  </div>
</ng-template>

<ng-template #dialogActions>
  <div class="error-align">
    <a class="btn btn-warning" style="width:40%;" (click)="errorClose()">
      {{buttonText}}</a>
  </div>
</ng-template>

<div kendoDialogContainer></div>
