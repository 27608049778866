
  import * as fabIcons from './classic/brands.mjs';

  import * as falIcons from './classic/light.mjs';

  import * as farIcons from './classic/regular.mjs';

  import * as fasIcons from './classic/solid.mjs';

  import * as fatIcons from './classic/thin.mjs';

  import * as fadIcons from './duotone/solid.mjs';

  

  

  import * as faslIcons from './sharp/light.mjs';

  import * as fasrIcons from './sharp/regular.mjs';

  import * as fassIcons from './sharp/solid.mjs';

  import * as fastIcons from './sharp/thin.mjs';


var byPrefixAndName = {'fab': {},'fal': {},'far': {},'fas': {},'fat': {},'fad': {},'fasl': {},'fasr': {},'fass': {},'fast': {},};
var all = [];

function addIcons (icons) {
  Object.keys(icons).forEach(function (iconName) {
    if (iconName === 'prefix') {
      return;
    }

    var icon = icons[iconName];
    var aliases = icon.icon[2];
    aliases.forEach(function (alias) {
      if (typeof alias === 'string') {
        byPrefixAndName[icon.prefix][alias] = icon;
      }
    })
    byPrefixAndName[icon.prefix][icon.iconName] = icon;
    all.push(icon);
  });
}


  
  addIcons(fabIcons);
  var fab = fabIcons;
  

  
  addIcons(falIcons);
  var fal = falIcons;
  

  
  addIcons(farIcons);
  var far = farIcons;
  

  
  addIcons(fasIcons);
  var fas = fasIcons;
  

  
  addIcons(fatIcons);
  var fat = fatIcons;
  

  
  addIcons(fadIcons);
  var fad = fadIcons;
  

  

  

  
  addIcons(faslIcons);
  var fasl = faslIcons;
  

  
  addIcons(fasrIcons);
  var fasr = fasrIcons;
  

  
  addIcons(fassIcons);
  var fass = fassIcons;
  

  
  addIcons(fastIcons);
  var fast = fastIcons;
  


export {
  byPrefixAndName, all
  , fab, fal, far, fas, fat, fad, fasl, fasr, fass, fast
}
