import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HelpButtonComponent } from '../../admin/help-info/help-button/help-button.component';
import { AuthTokenService } from '../../auth/auth-token.service';
import { LoggerService } from '../../shared/logger/logger.service';
import { Subscription } from 'rxjs';
import { Route, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { DiscoverOpportunityComponent } from '../shared/discover-opportunity/discover-opportunity.component';
import { CommonModule } from '@angular/common';
import { SearchAustraliaLinkComponent } from '../shared/search-australia-link/search-australia-link.component';
import { IconBlockComponent } from '../shared/icon-block/icon-block.component';

@Component({
  selector: 'app-content',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink, RouterLinkActive,
    HelpButtonComponent,
    DiscoverOpportunityComponent,
    SearchAustraliaLinkComponent,
    IconBlockComponent
  ],
  templateUrl: './content.component.html',
  styleUrl: './content.component.scss'
})
export class ContentComponent implements OnInit, AfterViewInit {

  private _subs: Subscription[] = [];
  loggedIn: boolean = this.authTokenService.loggedIn;

  constructor(
    private authTokenService: AuthTokenService,
    private logger: LoggerService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.logger.debug('ngOnInit...');
    this._subs.push(this.authTokenService.isLoggedIn$.subscribe(status => {
      this.logger.debug('ngOnInit:status: ', status);
      this.loggedIn = status;
      if (this.loggedIn)
        this.router.navigate(['/logged-in']);

    }));

  }

  ngAfterViewInit(): void {
    setTimeout(() => window.scrollTo({top: 0, left: 0, behavior: "instant"}));
  }


}
