<link rel="stylesheet" href="https://unpkg.com/@progress/kendo-font-icons/dist/index.css" />
<div class="container" my-5>
  <div class="card" #appendTo>
    <div class="card-body no-border">

      <h3 style="text-align: left;">{{opportunity.title}}</h3>

      <kendo-grid
        [kendoGridBinding]="gridData" [loading]="dataService.loading" [sort]="sort" [sortable]="true" [groupable]="true" [resizable]="true"
        (sortChange)="sortChange($event)" [pageSize]="pageSize" [pageable]="true" style="height: 400px;" [width]="500">

        <ng-template kendoGridToolbarTemplate position="top">
          <button [hidden]="!canCreate" (click)="onNew()" kendoButton class="btn btn-primary"> Send for Review</button>
        </ng-template>

        <kendo-grid-column title="Integrate Review" [width]="130" [headerStyle]="{ 'vertical-align': 'top'}">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div  *ngIf="dataItem.review_complete">
              <button (click)="integrate(dataItem)" kendoButton class="btn btn-stn-grid"
                      casiClickLogger="button[reviewOpportunity:Integrate:{{dataItem.id}}]"><fa-icon icon="arrow-up"></fa-icon></button>
            </div>
          </ng-template>
        </kendo-grid-column>


        <kendo-grid-column title="Review By" field="reviewed_by_user" [style]="{'vertical-align': 'top'}" [width]="180" [headerStyle]="{ 'vertical-align': 'top'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div *ngIf="dataItem.reviewed_by_user">{{dataItem.reviewed_by_user}}<br>({{dataItem.reviewed_by_user_email}})</div>
            <div *ngIf="!dataItem.reviewed_by_user">({{dataItem.reviewed_by_user_email}})</div>
            <ng-template kendoGridFilterMenuTemplate let-column="column" let-dataItem let-filter="filter"
                         let-filterService="filterService">
              <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
                                 [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
            </ng-template>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Requested On" field="requested_on" [style]="{'vertical-align': 'top'}" [width]="120" [headerStyle]="{ 'vertical-align': 'top'}">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-dataItem let-filter="filter"
                       let-filterService="filterService">
            <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
                               [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Review Comment" field="review_comment" [style]="{'vertical-align': 'top'}" [width]="200" [headerStyle]="{ 'vertical-align': 'top'}">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-dataItem let-filter="filter"
                       let-filterService="filterService">
            <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
                               [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Review Completed" field="review_complete" [style]="{'vertical-align': 'top'}" [width]="120" [headerStyle]="{ 'vertical-align': 'top'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div *ngIf="dataItem.review_complete">Yes</div>
            <ng-template kendoGridFilterMenuTemplate let-column="column" let-dataItem let-filter="filter"
                         let-filterService="filterService">
              <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
                                 [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
            </ng-template>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Review Completed On" field="updated_on" [style]="{'vertical-align': 'top'}" [width]="120" [headerStyle]="{ 'vertical-align': 'top'}">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-dataItem let-filter="filter"
                       let-filterService="filterService">
            <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
                               [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Updated By" field="updated_by_user" [style]="{'vertical-align': 'top'}" [width]="120" [headerStyle]="{ 'vertical-align': 'top'}">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-dataItem let-filter="filter"
                       let-filterService="filterService">
            <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
                               [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Delete" [width]="70" [style]="{'vertical-align': 'top'}" [headerStyle]="{ 'vertical-align': 'top'}">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <button (click)="onConfirmDelete(dataItem)" kendoButton class="btn btn-stn-grid">
              <fa-icon icon="trash"></fa-icon>
            </button>
          </ng-template>
        </kendo-grid-column>
        <ng-template kendoPagerTemplate>
          <kendo-pager-info *ngIf="pageSize > 0"></kendo-pager-info>
        </ng-template>

      </kendo-grid>

      <div class="d-grid d-md-flex justify-content-md-end">
        <button type="button" class="btn btn-secondary" (click)="closeWindow()"> Close</button>
      </div>

    </div>
  </div>

</div>


<kendo-window *ngIf="addItemOpen" (close)="closeItem()" [width]="700" [(top)]="windowTop" [(left)]="windowLeft">
  <kendo-window-titlebar>
    <span class="k-window-title">SEND OPPORTUNITY FOR REVIEW</span>
    <button kendoWindowCloseAction></button>
  </kendo-window-titlebar>
  <casi-send-review [opportunity]="opportunity" (onSave)="onItemSave($event)" (onCancel)="closeItem()"></casi-send-review>
</kendo-window>


<kendo-dialog *ngIf="confirmDeleteItem" title="Please confirm" (close)="onConfirmedClose('cancel')" [minWidth]="250" [width]="450">
  <h4>Are you sure you would like to delete this review?</h4>

  <kendo-dialog-actions>
    <button kendoButton (click)="onConfirmedClose('delete')" themeColor="primary">Delete</button>
    <button kendoButton (click)="onConfirmedClose('cancel')">Cancel</button>
  </kendo-dialog-actions>
</kendo-dialog>

<kendo-window *ngIf="integrateOpen" (close)="closeIntegrate()" [width]="1350" [(top)]="windowTop" [(left)]="windowLeft">
  <kendo-window-titlebar>
    <span class="k-window-title">INTEGRATE REVIEWED OPPORTUNITY CONTENT</span>
    <button kendoWindowCloseAction></button>
  </kendo-window-titlebar>
  <casi-integrate-review [reviewId]="currentItem.id" (onCancel)="closeIntegrate()" (onSave)="onIntegrate($event)"></casi-integrate-review>
</kendo-window>

