<div class="container">
  <div class="row">
    <div class="col-12 col-sm-10 col-md-8 offset-sm-1 offset-md-2 jumbo" style="max-width: 765px; margin: auto;">
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="k-form-inline">

        <div class="k-form-field">
          <label for="email" class="form-label col-4 col-md-3">Email</label>
          <div class="col-7 col-md-8">
            <input type="email" id="email" formControlName="email" class="form-control">
            <div *ngIf="email?.invalid && (email?.dirty || email?.touched)" class="alert alert-danger">
              <div *ngIf="email?.errors?.['required']">Email address is required.</div>
              <div *ngIf="email?.errors && email?.errors?.['email']">Please, enter valid email address.</div>
            </div>
          </div>
        </div>
        <div class="k-form-field" #appendTo>
          <label for="password" class="form-label col-4 col-md-3">Password</label>
          <div class="col-7 col-md-8">
            <input type="password" id="password" formControlName="password" class="form-control">
            <div *ngIf="f['password'].touched && f['password'].invalid" class="alert alert-danger">
              <div *ngIf="f['password'].errors && f['password'].errors['required']">Password is required.</div>
              <div *ngIf="password?.errors?.['minlength']">
                Name must be at least 6 characters long.
              </div>
            </div>
          </div>
        </div>
        <!--        <div class="form-group">-->
        <!--          <span *ngIf="!loginForm.valid && loginForm.touched" class="alert alert-danger">Please enter valid data!</span>-->
        <!--        </div>-->
        <div class="k-form-field">
          <label class="form-label col-4 col-md-3"></label>
          <div class="col-7 col-md-8">
            <button class="btn btn-primary" type="submit" [disabled]="loginForm.invalid">Submit</button>
          </div>
        </div>

        <div class="row">
          <div class="col-7 offset-4 col-md-8 offset-md-3">
            <p>Forgot your password? <a routerLink="/reset-password" routerLinkActive="active">Reset Password</a>.</p>
            <!-- <p>Don't have an account yet? <a ui-sref="base.signup" class="ulink">Create an account</a>.</p> -->
          </div>
        </div>

      </form>
    </div>
  </div>
</div>
