import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {map, tap} from "rxjs/operators";
import {ApiService} from "../api/api.service";
import {DiscoverOpportunitySearchOutcomes} from "../models/models.lib";
import { environment } from '../../environments/environment';

const { apiUrl } = environment;

export abstract class CommonDiscoverOpportunitiesService extends BehaviorSubject<Array<any>> {
  public loading: boolean = false;

  constructor(
    private http: HttpClient,
  ) {
    super([]);
  }

  public doSearch(p: any): void {
    this.fetch(p).subscribe((x) => {
      // console.log('query: x: ', x);
      return super.next(x);
    });
  }

  protected fetch(p: any): Observable<DiscoverOpportunitySearchOutcomes[]> {
    this.loading = true;

    return this.http.post(encodeURI(`${apiUrl}/opportunity/search`), p) //to change
      .pipe(
        map((response: any, i) => {
          return <DiscoverOpportunitySearchOutcomes[]>response;
        }),
        map(r => r.map(x => DiscoverOpportunitySearchOutcomes.create(x))),
        tap(() => (this.loading = false))
      )
  }
}

@Injectable({
  providedIn: 'root'
})
export class DiscoverOpportunitiesService extends CommonDiscoverOpportunitiesService{

  private ids: number[] = [];
  private filteBy: string = ""

  setIds(data: any) {
    this.ids = data;
  }

  getIds() {
    return this.ids;
  }

  setFilterBy(text: string) {
    this.filteBy = text;
  }

  getFilterBy() {
    return this.filteBy;
  }

  constructor(
    http: HttpClient,
    private apiService: ApiService
  ) {
    super(http);
  }

  search(p: any): Promise<any> {
    return this.apiService.postHttpCallAsPromise(`opportunity/search`, p, 'search');
  }

  getOptions(){
    return this.apiService.getHttpCall('opportunity/options', 'get');
  }

  exploreOpportunity(id: number): Promise<any> {
    return this.apiService.getHttpCallAsPromise(`opportunity/explore/${id}`, 'exploreOpportunity');
  }

}
