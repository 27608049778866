import {
  Component,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  NO_ERRORS_SCHEMA
} from "@angular/core";
import {
  distinct,
  filterBy,
  FilterDescriptor,
} from "@progress/kendo-data-query";
import { FilterService } from "@progress/kendo-angular-grid";
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { LoggerService } from "../../shared/logger/logger.service";
import { CommonModule } from "@angular/common";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { LabelModule } from "@progress/kendo-angular-label";
import moment from 'moment';

@Component({
  selector: 'multicheck-filter',
  standalone: true,
  imports: [
    CommonModule,
    ButtonsModule,
    InputsModule,
    LabelModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
  templateUrl: './multicheck-filter.component.html',
  styleUrl: './multicheck-filter.component.scss'
})
export class MulticheckFilterComponent implements AfterViewInit {
  @Input() public isPrimitive: boolean = true;
  @Input() public currentFilter: CompositeFilterDescriptor = { logic: "and", filters: [] };
  @Input() public data: any[] = [];
  @Input() public textField = '';
  @Input() public valueField = '';
  @Input() public filterService: FilterService = new FilterService();
  @Input() public field: string = '';
  @Output() public valueChange = new EventEmitter<number[]>();

  public currentData: unknown[] = [];
  public showFilter = true;
  private value: unknown[] = [];

  public textAccessor = (dataItem: unknown): string => {
    if (typeof dataItem === 'string') {
      if (moment(dataItem).isValid())
        return moment(dataItem).format('DD/MM/YYYY');
      else
        return dataItem;
    }

    else
    return this.isPrimitive ? dataItem : (dataItem as any)[this.textField];
  }

  public valueAccessor = (dataItem: unknown): unknown =>
    this.isPrimitive ? dataItem : (dataItem as any)[this.valueField];


  constructor(
    private logger: LoggerService
  ) {
  }

  public ngAfterViewInit(): void {
    // this.logger.debug('ngAfterViewInit...', this.data, this.currentFilter);
    this.currentData = this.data;
    this.value = this.currentFilter.filters.map(
      f => {
        // this.logger.debug('ngAfterViewInit: f ==>>', f)
        return (f as FilterDescriptor).value;
      }
      // (f: FilterDescriptor) => <FilterDescriptor>f.value
    );

    this.showFilter =
      typeof this.textAccessor(this.currentData[0]) === "string";
  }
  public isItemSelected(item: unknown): boolean {
    return this.value.some((x) => x === this.valueAccessor(item));
  }
  public onSelectionChange(item: unknown, li: HTMLLIElement): void {
    if (this.value.some((x) => x === item)) {
      this.value = this.value.filter((x) => x !== item);
    } else {
      this.value.push(item);
    }

    this.filterService.filter({
      filters: this.value.map((value) => ({
        field: this.field,
        operator: "eq",
        value,
      })),
      logic: "or",
    });

    this.onFocus(li);
  }

  public onInput(e: Event): void {
    this.currentData = distinct(
      [
        ...this.currentData.filter((dataItem) =>
          this.value.some((val) => val === this.valueAccessor(dataItem))
        ),
        ...filterBy(this.data, {
          operator: "contains",
          field: this.textField,
          value: (e.target as HTMLInputElement).value,
        }),
      ],
      this.textField
    );
  }

  public onFocus(li: HTMLLIElement): void {
    const ul = li.parentNode as HTMLUListElement;
    const below =
      ul.scrollTop + ul.offsetHeight < li.offsetTop + li.offsetHeight;
    const above = li.offsetTop < ul.scrollTop;

    // Scroll to focused checkbox
    if (above) {
      ul.scrollTop = li.offsetTop;
    }

    if (below) {
      ul.scrollTop += li.offsetHeight;
    }
  }

}
