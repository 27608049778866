<!-- <div kendoPopoverContainer [popover]="myPopover" filter=".has-popover" #container="kendoPopoverContainer" showOn="none"> -->
<div kendoPopoverContainer [popover]="info" filter=".has-popover" #container="kendoPopoverContainer" showOn="none">
  <!-- <button class="has-popover" kendoButton>Show Popover</button> -->
  @if(!showText) {
  <!-- <button mat-icon-button aria-label="Information icon" (click)="anchor.toggle()" style="height: unset; width: unset;" #anchor>
      <mat-icon [style]="iconStyle">info_outlined_i</mat-icon>
    </button> kendoButton -->
  <!-- <button kendoButton class="btn btn-default" (click)="onToggle(anchor.element)" style="text-decoration: underline"
      #anchor>{{text}}</button> -->

  <button  mat-icon-button aria-label="Information icon" (click)="onToggle(anchor)" style="height: unset; width: unset;">
    <mat-icon [style]="iconStyle">info_outlined_i</mat-icon>
  </button>
  <!-- <button aria-label="Information icon" class="has-popover" (click)="onToggle(anchor)" style="height: unset; width: unset;">
        <mat-icon [style]="iconStyle">info_outlined_i</mat-icon>
      </button> -->
  }
  @else {
  <!-- <button class="btn btn-default" (click)="toggle()" style="text-decoration: underline">{{text}}</button> -->
  <button class="btn btn-default" style="text-decoration: underline" (click)="onToggle(anchor)">{{text}}</button>
  <!-- <button class="btn btn-default has-popover" (click)="onToggle(anchor)"
    style="text-decoration: underline">{{text}}</button> -->

  }
  <span #anchor></span>
</div>

<!-- <kendo-popover #info position="bottom" width="400px"> -->
<kendo-popover #info position="bottom" [width]="400" (show)="onShow()" (shown)="onShown()" (hide)="onHide()"
  (hidden)="onHidden()">
  <ng-template kendoPopoverBodyTemplate>
    <casi-help-card [infoId]="infoId" (onClose)="closeMe()"></casi-help-card>
  </ng-template>
</kendo-popover>
