import { Component, EventEmitter, Input, NO_ERRORS_SCHEMA, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterLink, RouterLinkActive } from "@angular/router";
import { CheckboxListComponent } from "../../common-components/checkbox-list/checkbox-list.component";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { LabelModule } from "@progress/kendo-angular-label";
import { LayoutModule } from "@progress/kendo-angular-layout";
import { LoggerService } from "../../shared/logger/logger.service";
import { OpportunityOptions, OpportunityParams } from "../../models/models.lib";
import * as _ from "lodash";
import { CasiSwitchComponent } from "../../common-components/casi-switch/casi-switch.component";
import { ClickLoggerDirective } from '../../directives/click-logger.directive';


@Component({
  selector: 'opportunity-options-accordion',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink, RouterLinkActive,
    CheckboxListComponent,
    InputsModule,
    ButtonsModule,
    LabelModule,
    LayoutModule,
    CasiSwitchComponent,
    ClickLoggerDirective
  ],
  schemas: [NO_ERRORS_SCHEMA],
  templateUrl: './options-accordion.component.html',
  styleUrl: './options-accordion.component.scss'
})
export class OptionsAccordionComponent implements OnInit, OnDestroy {

  @Input({ required: true }) items: OpportunityOptions[] = [];
  @Input({ required: true }) output: OpportunityParams;
  @Output() onChange = new EventEmitter<any>();

  @Input() filterBy: string = "";
  @Input() ids: number[] = [];

  constructor(private logger: LoggerService) {
    this.output = new OpportunityParams();
  }

  ngOnInit(): void {
    this.logger.debug('ngOnInit...', {
      ids: this.ids,
      filterBy: this.filterBy
    });
    if (this.ids || this.filterBy)
      this.check();
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnDestroy...');
  }

  toggleAccordion(index: number) {
    this.items[index].isOpen = !this.items[index].isOpen;
    let item = this.items[index];
    if (item.isOpen)
      this.output.addFilter(item);
    else {
      this.output.removeFilter(item);
      _.map(item.options, o => o.checked = false);
      item.filterAll = false;
    }
    this.onChange.emit({ params: this.output });
  }

  public onToggleAccordion(args: any, index: number): void {
    this.logger.debug('onToggleAccordion...', args);
    this.toggleAccordion(index);
  }

  public onOptionChecked(dataItem: any, fieldName: any): void {
    this.logger.debug('onOptionChecked: ', dataItem);
    dataItem.checked = !dataItem.checked;
    let filter = this.output.filters[fieldName];
    if (dataItem.checked)
      filter.ids.push(dataItem.id);
    else
      filter.ids = _.pull(filter.ids, dataItem.id);

    this.onChange.emit({ params: this.output });
  }

  public onFilterChange(dataItem: any): void {
    this.logger.debug('onFilterChange: ', dataItem);
    dataItem.filterAll = !dataItem.filterAll;
    let filter = this.output.filters[dataItem.field];
    filter.filterAll = dataItem.filterAll;
    this.logger.debug('this.output:', this.output);
    this.onChange.emit({ params: this.output });
  }

  private check() {
    this.logger.debug('check...', this.items);
    this.items.map((item, index) => {
      if (item.field && item.field === this.filterBy) {
        item.isOpen = true;
        this.output.addFilter(item);
        if (item.options)
          item.options.map(o => {
            this.ids.map(id => {
              if (o.id == id) {
                o.checked = true;
                let filter = this.output.filters[item.field as any];
                filter.ids.push(o.id);
              }
            });
          });
      }
    })
    this.onChange.emit({ params: this.output });
  }
}
