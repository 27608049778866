import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoggerService } from '../../../shared/logger/logger.service';
import { Practice } from '../../../models/models.lib';
import { FormModes } from '../../../common/constants.lib';
import {EditorComponent, EditorModule} from '@progress/kendo-angular-editor';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import {EditorImageUploadComponent} from "../../../common-components/editor-image-upload/editor-image-upload.component";

interface CbItem {
  id: number | null,
  co_benefit: string
}

@Component({
  selector: 'casi-edit-practice',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    FontAwesomeModule,
    RouterLink, RouterLinkActive,
    EditorModule,
    InputsModule,
    ButtonsModule,
    NotificationModule,
    LabelModule,
    LayoutModule,
    EditorImageUploadComponent
  ],
  templateUrl: './edit-practice.component.html',
  styleUrl: './edit-practice.component.scss'
})
export class EditPracticeComponent implements OnInit, OnDestroy {

  @Input({ required: true }) practice: Practice = new Practice();
  @Input({ required: true }) mode: FormModes = FormModes.NOTSET;

  @Output() onCancel = new EventEmitter<void>();
  @Output() onSave = new EventEmitter<any>();

  @ViewChild("uploadDialog") public dialog: EditorImageUploadComponent = <EditorImageUploadComponent>{};
  @Output() @ViewChild("practice_description_editor") private practice_description_editor: EditorComponent = <EditorComponent>{};

  public value = '';

  form: FormGroup;

  private _subs: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private logger: LoggerService,
  ) {
    this.form = this.fb.group(
      {
        id: [null, []],
        opportunity_id: [null, []],
        practice_description: [null, [Validators.required]]
      }
    );
  }

  get f() {
    return this.form.controls;
  }
  get practice_description() {
    return this.form.get('practice_description');
  }

  ngOnInit(): void {
    this.logger.debug('ngOnInit...');
    this.form.patchValue(this.practice);
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnInit...');
    this._subs.forEach(s => s.unsubscribe());
  }

  onSubmit(): void {
    this.logger.debug(`onSubmit...`, this.form.value, this.mode);
    const { name } = this.form.value;
    this.onSave.emit({
      action: "save",
      data: this.form.value,
      mode: this.mode
    });
  }

  closeWindow(): void {
    this.logger.debug(`closeWindow...`);
    this.onCancel.emit();
  }

  public openImageUpload() {
    setTimeout(() => this.dialog.open(), 200);
  }

  public onImageLoaded(args: any): void {
    this.logger.debug('onImageLoaded...', args);
    const {data } = args;
    this.form.patchValue({ id: data });
    // this.opportunity.id = data;
  }

}
