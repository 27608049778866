<form class="k-form" [formGroup]="form" class="k-form-inline">

  <fieldset>

    <div class="k-form-field">
      <label class="form-label col-3 k-label">Sort Order</label>
      <!-- <kendo-label class="k-form" text="Sort Order">
      </kendo-label> -->
      <div class="col-8">
        <kendo-numerictextbox format="##" [step]="1" [autoCorrect]="autoCorrect" [style.width.px]="100" [min]="0"
          formControlName="sort_order">
        </kendo-numerictextbox>
      </div>
    </div>


    <div class="k-form-field">
      <label for="description" class="form-label col-3 k-label">Reference Name</label>
      <div class="col-8">
        <input class="form-control" formControlName="description" id="description" />
        <div *ngIf="description?.invalid" class="alert alert-danger">

          <div *ngIf="description?.errors?.['required']">
            Reference Name is required.
          </div>
        </div>
      </div>
    </div>

    <div class="k-form-field">
      <label for="description" class="form-label col-3 k-label">Link Type</label>
      <div class="col-8">
        <kendo-formfield showHints="initial">
          <!-- <label class="k-label">Other Options</label> -->
          <ul class="k-radio-list">
            @for(item of referenceLink.radioOptions; track item){
            <li class="k-radio-item">
              <kendo-label for="opt{{item}}" text={{item}}></kendo-label>
              <input type="radio" value={{item}} id="opt{{item}}" formControlName="linkType" kendoRadioButton
                (change)="onRadioChange(item)" />
            </li>
            }
            @empty {
            <li>No options found.</li>
            }
          </ul>
        </kendo-formfield>

      </div>
    </div>

    @if(linkTypeIsDocument) {
    <div class="k-form-field">

      <div class="col-8 offset-3">
        <kendo-upload [autoUpload]="false" [saveUrl]="uploadSaveUrl" (cancel)="cancel($event)" (clear)="clear()"
          (complete)="complete()" (error)="error($event)" (blur)="blur()" (focus)="focus()" (pause)="pause($event)"
          (remove)="remove($event)" (resume)="resume($event)" (select)="select($event)" (success)="success($event)"
          (upload)="upload($event)" (uploadProgress)="uploadProgress($event)" (valueChange)="valueChange($event)"
          [restrictions]="myRestrictions">
          >
          <kendo-upload-messages dropFilesHere="or drop documents here to add (max size 5 MB)"></kendo-upload-messages>
        </kendo-upload>
        <div *ngIf="url?.invalid && !hasUploadedFile" class="alert alert-danger">
          Document is required.
        </div>
      </div>
    </div>
    }

    @if(showUrl) {
    <div class="k-form-field">
      <label for="url" class="form-label col-3 k-label">Url</label>
      <div class="col-8">
        @if(linkTypeIsDocument){
        <div style="padding-top: 0.375rem;">
          <a href="{{referenceLink.url}}" target="_blank">{{referenceLink.url}}</a>
        </div>
        <input type="hidden" class="form-control" formControlName="url" id="url" />
        }
        @else {
          <input type="text" class="form-control" formControlName="url" id="url" />
          <div *ngIf="url?.invalid" class=" alert alert-danger">

            <div *ngIf="url?.errors?.['required']">
              Url is required.
            </div>
          </div>
        }

      </div>
    </div>
    }

    <!-- Buttons -->

    <div class="d-grid d-md-flex justify-content-md-end">
      <button type="button" class="btn btn-primary me-md-2" (click)="onSubmit()" [disabled]="form.invalid">
        Save</button>
      <!-- <button type="button" class="btn btn-primary me-md-2" (click)="onSubmit()">
        Save</button> -->
      <button type="button" class="btn btn-secondary" (click)="closeWindow()"> Cancel</button>
    </div>

  </fieldset>
</form>
