<nav id="thefooter" class="navbar navbar-custom navbar-expand-lg">
  <div >
    <div>&copy; University of New England</div>
  </div>

  <ul class="navbar-nav ml-auto footer-nav">
    <li class="nav-item"><a  routerLinkActive="active" routerLink="/terms-conditions">Customer Licence Agreement</a> | </li>
    <li class="nav-item"><a routerLinkActive="active" routerLink="/privacy">Privacy Policy</a> | </li>
    <li class="nav-item"><a routerLinkActive="active" routerLink="/disclaimer">Terms of Use</a></li>
  </ul>
</nav>
