import { Inject, Injectable, OnDestroy, OnInit } from '@angular/core';
import { StorageService } from './storage.service';
import { LoggerService } from '../shared/logger/logger.service';
import {
  storageTokenName, storageTokenAccess,
  IClaimsUser, IClaimSub, IResponseToken, IClaimSubAccess, IClaimsAccess, UserClaim, User, CasiInvalidTokenError
} from './me.lib';
import moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AppConfig, CONFIG_TOKEN } from '../setup/config';
import _ from 'lodash';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthTokenService implements OnDestroy {

  private previousStatus: boolean = this.isLoggedIn;
  private _loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.isLoggedIn);
  get isLoggedIn$() {
    return this._loggedIn.asObservable();
  }
  get loggedIn() {
    return this._loggedIn.getValue();
  }
  set loggedIn(val: boolean) {
    this._loggedIn.next(val);
  }

  private _user: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(this.getClaims());
  get user$() {
    return this._user.asObservable();
  }
  get user() {
    return this._user.getValue();
  }
  set user(val: User | null) {
    this._user.next(val);
  }

  private _subs: Subscription[] = [];

  intervalId: any;

  constructor(
    private storage: StorageService,
    private logger: LoggerService,
    private cookieService: CookieService,
    private router: Router,
    @Inject(CONFIG_TOKEN) private config: AppConfig,
  ) {
    this.intervalId = setInterval(() => {
      // this.logger.debug('Call Function Every Five Seconds', this.expiresIn());
      this.tokenCheck();
      // this.loggedIn = this.isLoggedIn;
    }, this.config.pollInterval);
    this.tokenCheck();

    this._subs.push(this._loggedIn.subscribe(status => {
      this.logger.debug('status changed: **>> ', status, this.previousStatus);
      if (this.previousStatus !== status && !status) {
        this.router.navigate(['/signed-out']);
      }
      this.previousStatus = status;
    }));
  }
  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnDestroy...');
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    this._subs.forEach(s => s.unsubscribe());
  }

  /**
   *This function taken from the jwtDecode lib.
   * @param str
   * @returns
   */
  // private b64DecodeUnicode(str: string) {
  //   return decodeURIComponent(
  //     atob(str).replace(/(.)/g, (m, p) => {
  //       let code = (p as string).charCodeAt(0).toString(16).toUpperCase();
  //       if (code.length < 2) {
  //         code = "0" + code;
  //       }
  //       return "%" + code;
  //     }),
  //   );
  // }

  // private urlBase64Decode(str: string): string {
  //   let output = str.replace(/-/g, '+').replace(/_/g, '/');
  //   switch (output.length % 4) {
  //     case 0:
  //       break;
  //     case 2:
  //       output += '==';
  //       break;
  //     case 3:
  //       output += '=';
  //       break;
  //     default:
  //       throw 'Illegal base64url string!';
  //   }
  //   try {
  //     return this.b64DecodeUnicode(output);
  //   } catch (err) {
  //     return atob(output);
  //   }
  //   //  return window.atob(output);
  // }

  private cachedTokenUser: string | null = null;
  private cachedTokenAccess: string | null = null;

  //#region Login Claims

  getToken(): string | null {
    if (!this.cachedTokenUser) {
      this.cachedTokenUser = this.storage.get(storageTokenName);
    }
    return this.cachedTokenUser;
  }

  getTokenPayload<T = UserClaim>(token: string): T | null {
    try {
      // this.logger.log('getTokenPayload:token: ', token, token === 'undefined');
      if (!token || token === 'undefined') {
        return null;
      }
      let decodedToken = jwtDecode(token);
      return <T>decodedToken;
    } catch (error) {
      this.logger.error('getTokenPayload:error: ', error);
      return null;
    }
  }

  getClaimsFromToken(): UserClaim | null {
    let token = this.getToken();
    let claims: UserClaim | null = null; //  <UserClaim>{};
    if (token && token !== 'undefined') {
      try {
        claims = UserClaim.create(this.getTokenPayload<any>(token));
      } catch (e) {
        this.logger.error('getClaimsFromToken:error: ', e);
        return null;
      }
    }
    // this.logger.debug('getClaimsFromToken: claims: ', claims, claims?.isExpired);
    if (claims) { // && !claims.isExpired) {
      return claims;
    }
    // this.removeToken();
    return null;
  }

  getClaims(): User | null {
    let claims = this.getClaimsFromToken();
    if (claims && claims.sub) {
      return User.createFromSub(claims.sub);
    }
    return null;
  }

  //#endregion

  //#region Permissions Token

  getAccess(): string | null {
    if (!this.cachedTokenAccess) {
      this.cachedTokenAccess = this.storage.get(storageTokenAccess);
    }
    return this.cachedTokenAccess;
  }

  getAccessClaims(): IClaimSubAccess | undefined {
    let claims = this.getClaimsFromAccess();
    if (claims && claims.sub) {
      return claims.sub;
    }
    return undefined;
  }

  getClaimsFromAccess(): IClaimsAccess | null {
    let token = this.getAccess();
    let claims: IClaimsAccess = <IClaimsAccess>{};
    if (token) {
      try {
        claims = this.getTokenPayload<any>(token);
      } catch (e) {
        return null;
      }
    }
    return claims;
  }

  canIAccess(entity?: string): boolean {
    // this.logger.debug('canIAccess:entity: ', entity);
    if (!entity)
      return true;
    let result = this.isLoggedIn
      && _.indexOf(this.getAccessClaims()?.menuAccess, entity) !== -1;
    // this.logger.debug('canIAccess:result: ', entity, result);
    return result;
  };

  //#endregion

  //#region General Functions

  parseToken(token?: string): any {
    if (!token)
      throw 'expected token';
    let decodedToken = jwtDecode(token);
    this.logger.debug('parseToken:decodedToken: ', decodedToken);
    const isExpired = decodedToken.exp ? decodedToken.exp < Date.now() / 1000 : true;
    if (!isExpired && decodedToken.sub) {
      return decodedToken.sub;
    }
    return null;
  }

  isClaimsExpired(claims: any): boolean {
    if (!claims)
      throw 'expected token';
    if (!claims || Object.keys(claims).length === 0)
      return true;
    return moment().unix() > claims.exp;
    // return Date.now() > claims.exp;
  }

  hasClaims(): boolean {
    let claims = this.getClaimsFromToken();
    // this.logger.debug('hasClaims: ', claims)
    if (claims)
      return true; //  Object.keys(claims).length === 0;
    return false;
  }

  isExpired(): boolean {
    let claims = this.getClaimsFromToken();
    if (claims) {
      // this.logger.debug('isExpired: ', claims.isExpired, moment().unix() > claims.exp);
      return claims.isExpired;
    }
    return true;
  }

  hasExpired(token: string): boolean {
    let decodedToken = jwtDecode(token);
    let returning = decodedToken.exp ? decodedToken.exp < Date.now() / 1000 : true;
    // this.logger.debug('hasExpired:decodedToken: ', decodedToken, decodedToken.exp ? decodedToken.exp - Date.now() / 1000 : 0);
    return returning;
  }

  expiresIn(): number {
    let claims = this.getClaimsFromToken();
    if (!claims)
      return -1;
    return claims.expiresIn;
  }

  setToken(token: IResponseToken): void {
    if (!token)
      return;

    this.loggedIn = true;
    const { meToken, accessToken } = token;
    this.cachedTokenUser = meToken;
    this.cachedTokenAccess = accessToken;
    this.storage.set(storageTokenName, meToken);
    this.storage.set(storageTokenAccess, accessToken);
    this.user = this.getClaims();
    let claims = this.getClaimsFromToken();
    if (!claims)
      return;
    const { exp } = claims;
    let expiresIn: Date = moment.unix(exp).toDate(); //.toISOString(); //  moment().add(1, 'd').toISOString(); //.format('YYYY-MM-DDTHH:MM:SS');
    this.cookieService.set(storageTokenName, meToken, { expires: expiresIn })
  }

  setAccess(accessToken: string): void {
    if (!accessToken)
      return;
    this.cachedTokenAccess = accessToken;
    this.storage.set(storageTokenAccess, accessToken);

    let claims = this.getClaimsFromToken();
    if (!claims)
      return;

    const { exp } = claims;
    let expiresIn = moment.unix(exp).toDate(); //.toISOString(); //  moment().add(1, 'd').toISOString(); //.format('YYYY-MM-DDTHH:MM:SS');
    this.cookieService.set(storageTokenAccess, accessToken, { expires: expiresIn })
  }

  isAuthenticated(): boolean {
    // this.logger.debug('isAuthenticated? ', !!this.getToken(), this.getToken());
    return !!this.getToken();
  }

  removeToken(): void {
    this.logger.debug('removeToken...');
    this.loggedIn = false;
    this.cachedTokenAccess = null;
    this.cachedTokenUser = null;
    this.storage.remove(storageTokenName);
    this.storage.remove(storageTokenAccess);
    this.cookieService.delete(storageTokenName);
    this.cookieService.delete(storageTokenAccess);
    this.user = null;
  }

  tokenCheck(): void {
    this.logger.debug('tokenCheck: ', this.hasClaims(), this.isExpired(), this.expiresIn());
    if (this.hasClaims() && this.isExpired()) {
      // if (!this.hasClaims() || (this.hasClaims() && this.isExpired())) {
      // this.logger.debug('tokenCheck: ', this.hasClaims());
      this.removeToken();

      this.router.navigate(['/signed-out']);
      this.loggedIn = false;
    }
  }

  get isLoggedIn(): boolean {
    // this.logger.debug('isLoggedIn: ', this.hasClaims(), this.isExpired(), this.isAuthenticated());
    if (!this.hasClaims() || this.isExpired()) {
      return false;
    }
    return this.isAuthenticated();
  }

  //#endregion

}
