import { Inject, Injectable, InjectionToken } from '@angular/core';
import { LoggerService } from '../shared/logger/logger.service';

export const BROWSER_STORAGE = new InjectionToken<Storage>('BROWSER_STORAGE', {
  providedIn: 'root',
  factory: () => localStorage
});

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
    @Inject(BROWSER_STORAGE) public storage: Storage,
    private logger: LoggerService
  ) { }

  get(key: string): string | null {
    return this.storage.getItem(key);
  }

  getJson(key: string): any {
    try {
      let tmp = this.storage.getItem(key);
      if (!tmp) {
        return null;
      }
      let obj = JSON.parse(tmp);
      return obj;
    } catch (error) {
      this.logger.error('getJson:error: ', error);
      return null;
    }
  }

  set(key: string, value: string) {
    this.storage.setItem(key, value);
  }
  setJson(key: string, value: any) {
    this.storage.setItem(key, JSON.stringify(value));
  }

  remove(key: string) {
    this.storage.removeItem(key);
  }
}
