import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { LoggerService } from '../../shared/logger/logger.service';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CasiCloseOptions } from '../../common/constants.lib';
import { DialogRef, DialogContentBase, DialogModule } from "@progress/kendo-angular-dialog";
import { EditorComponent, EditorModule } from '@progress/kendo-angular-editor';
import { CheckboxListComponent } from '../../common-components/checkbox-list/checkbox-list.component';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { LabelModule } from '@progress/kendo-angular-label';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { HelpButtonComponent } from '../../admin/help-info/help-button/help-button.component';
import { HelpPopoverComponent } from '../../admin/help-info/help-popover/help-popover.component';
import { EditorImageUploadComponent } from '../../common-components/editor-image-upload/editor-image-upload.component';
import { ReviewOpportunity } from '../../models/models.lib';

@Component({
  selector: 'casi-edit-review-modal',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    FontAwesomeModule,
    RouterLink, RouterLinkActive,
    DialogModule,
    EditorModule,
    CheckboxListComponent,
    InputsModule,
    ButtonsModule,
    NotificationModule,
    LabelModule,
    DropDownsModule,
    DateInputsModule,
    LayoutModule,
    HelpButtonComponent,
    HelpPopoverComponent,
    EditorImageUploadComponent

  ],
  templateUrl: './edit-review-modal.component.html',
  styleUrl: './edit-review-modal.component.scss'
})
export class EditReviewModalComponent extends DialogContentBase implements OnInit {

  private _review: ReviewOpportunity | undefined;

  @Input({ required: true })
  set review(val: ReviewOpportunity) {
    this._review = val;
    this.form.patchValue(this._review);
  }
  get review(): ReviewOpportunity | undefined {
    return this._review;
  };

  @ViewChild("uploadDialog") public dialogUploader: EditorImageUploadComponent = <EditorImageUploadComponent>{};

  @Output() @ViewChild("opportunity_review_full_description_editor") private opportunity_review_full_description_editor: EditorComponent = <EditorComponent>{};
  @Output() @ViewChild("opportunity_review_service_providers_editor") private opportunity_review_service_providers_editor: EditorComponent = <EditorComponent>{};
  @Output() @ViewChild("opportunity_review_elegibility_text_editor") private opportunity_review_elegibility_text_editor: EditorComponent = <EditorComponent>{};
  @Output() @ViewChild("opportunity_review_benefits_editor") private opportunity_review_benefits_editor: EditorComponent = <EditorComponent>{};
  @Output() @ViewChild("opportunity_review_negatives_editor") private opportunity_review_negatives_editor: EditorComponent = <EditorComponent>{};
  @Output() @ViewChild("opportunity_review_risks_editor") private opportunity_review_risks_editor: EditorComponent = <EditorComponent>{};
  @Output() @ViewChild("opportunity_review_costs_editor") private opportunity_review_costs_editor: EditorComponent = <EditorComponent>{};
  @Output() @ViewChild("opportunity_review_attribution_editor") private opportunity_review_attribution_editor: EditorComponent = <EditorComponent>{};
  @Output() @ViewChild("opportunity_review_potential_financial_benefits_editor") private opportunity_review_potential_financial_benefits_editor: EditorComponent = <EditorComponent>{};

  public editor: EditorComponent = <EditorComponent>{};
  public fieldName: string = null as any;

  form: FormGroup;

  constructor(
    public dialog: DialogRef,
    private fb: FormBuilder,
    private logger: LoggerService,
  ) {
    super(dialog);
    this.form = this.fb.group(
      {
        id: [null, []],
        opportunity_review_title: [null, [Validators.required]],
        opportunity_review_short_description: [null, [Validators.required]],
        opportunity_review_full_description: [null, []],
        opportunity_review_organisation_name: [null, []],
        opportunity_review_organisation_contact_email: [null, [Validators.email]],
        opportunity_review_organisation_url: [null, []],
        opportunity_review_service_providers: [null, []],
        opportunity_review_elegibility_text: [null, []],
        opportunity_review_benefits: [null, []],
        opportunity_review_negatives: [null, []],
        opportunity_review_risks: [null, []],
        opportunity_review_costs: [null, []],
        opportunity_review_attribution: [null, []],
        opportunity_review_potential_financial_benefits: [null, []],
      }
    );
  }

  //#region Form

  get f() {
    return this.form.controls;
  }
  get opportunity_review_title() {
    return this.form.get('opportunity_review_title');
  }
  get opportunity_review_short_description() {
    return this.form.get('opportunity_review_short_description');
  }
  get opportunity_review_organisation_name() {
    return this.form.get('opportunity_review_organisation_name');
  }
  get opportunity_review_organisation_contact_email() {
    return this.form.get('opportunity_review_organisation_contact_email');
  }
  get opportunity_review_organisation_url() {
    return this.form.get('opportunity_review_organisation_url');
  }
  get opportunity_review_service_providers() {
    return this.form.get('opportunity_review_service_providers');
  }
  get opportunity_review_elegibility_text() {
    return this.form.get('opportunity_review_elegibility_text');
  }
  get opportunity_review_benefits() {
    return this.form.get('opportunity_review_benefits');
  }
  get opportunity_review_negatives() {
    return this.form.get('opportunity_review_negatives');
  }
  get opportunity_review_risks() {
    return this.form.get('opportunity_review_risks');
  }
  get opportunity_review_costs() {
    return this.form.get('opportunity_review_costs');
  }
  get attribution() {
    return this.form.get('opportunity_review_attribution');
  }
  get opportunity_review_potential_financial_benefits() {
    return this.form.get('opportunity_review_potential_financial_benefits');
  }

  //#endregion

  ngOnInit(): void {
    this.logger.debug('ngOnInit...');
  }

  public openImageUpload(fieldName: string) {
    this.fieldName = fieldName;
    this.editor = (this as any)[`${fieldName}_editor`];
    setTimeout(() => this.dialogUploader.open(), 200);
  }

  public onImageLoaded(args: any): void {
    this.logger.debug('onImageLoaded...', args);
    const { data } = args;
    this.form.patchValue({ id: data });
    if (this.review) {
      this.review.opportunity_review_id = data;
    }
  }

  public onCancelAction(): void {
    this.logger.debug(`onCancelAction...`);
    this.dialog.close({ text: CasiCloseOptions.Cancelled });
  }

  public onSaveAction(): void {
    this.logger.debug(`onSaveAction...`, this.form.value);
    let toSave = { ...this.review, ...this.form.value };

    this.dialog.close({ text: CasiCloseOptions.Saved, data: toSave });
  }
}
