<casi-editor-image-upload [editor]="editor" [fieldName]="fieldName" [recordId]="review.opportunity_review_id" tableName="opportunity_review"
                          (onLoad)="onImageLoaded($event)" #uploadDialog></casi-editor-image-upload>
<div class="row" style="margin-bottom: 30px;">
  <div class="col-6">
    <h4 style="text-decoration: underline">Suggested Content</h4>
  </div>
  <div  class="col-6">
    <h4 style="text-decoration: underline">Current Content</h4>
  </div>
</div>
<form [formGroup]="form"  style="height: calc(100vh - 220px); overflow-y: scroll; padding: 0 20px">
  <fieldset>


    <div class="row" style="margin-bottom: 30px; border-bottom: 1px solid #dee2e6; padding-bottom: 20px">
      <div class="col-6">
        <label for="title" class="form-label ps-0" style="margin-bottom: 15px">Title</label>
        <textarea class="form-control" formControlName="opportunity_review_title" id="title" style="height: 70%"></textarea>
<!--        <input type="text" id="title" formControlName="opportunity_review_title" class="form-control">-->
        <div *ngIf="opportunity_review_title?.invalid" class="alert alert-danger">
          <div *ngIf="opportunity_review_title?.errors?.['required']">Title is required.</div>
        </div>
      </div>
      <div  class="col-6">
        <label for="title" class="form-label" style="margin-bottom: 20px"> </label>
        <div style="padding-top: 20px">{{review.title}}</div>
      </div>
    </div>

    <div class="row" style="margin-bottom: 30px; border-bottom: 1px solid #dee2e6; padding-bottom: 20px">
      <div class="col-6">
        <label class="form-label ps-0" style="margin-bottom: 15px">Short Description</label>
        <textarea class="form-control" formControlName="opportunity_review_short_description" required style="height: 82%"></textarea>
        <div *ngIf="opportunity_review_short_description?.invalid" class="alert alert-danger">
          <div *ngIf="opportunity_review_short_description?.errors?.['required']">Short Description is required.</div>
        </div>
      </div>
      <div  class="col-6">
        <label class="form-label" style="margin-bottom: 20px"></label>
        <div style="padding-top: 20px">{{review.short_description}}</div>

      </div>
    </div>

    <div class="row" style="margin-bottom: 30px; border-bottom: 1px solid #dee2e6; padding-bottom: 20px">
      <div class="col-6">
        <label class="form-label ps-0" style="margin-bottom: 15px">Full Description</label>
        <kendo-editor formControlName="opportunity_review_full_description" style="min-height: 300px; height: 90%" #opportunity_review_full_description_editor>
          <kendo-toolbar>
<!--            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>

            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
              <kendo-toolbar-button text="Upload Image" (click)="openImageUpload('opportunity_review_full_description')"></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
            <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
<!--            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
<!--            <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>-->
<!--            <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>-->
          </kendo-toolbar>
        </kendo-editor>

      </div>
      <div  class="col-6">
        <label class="form-label" style="margin-bottom: 20px"></label>
        <div [innerHTML]="review.fullDescription | safeHtml" style="padding-top: 20px"></div>
      </div>
    </div>

    <div class="row" style="margin-bottom: 30px; border-bottom: 1px solid #dee2e6; padding-bottom: 20px">
      <div class="col-6">
        <label class="form-label ps-0" style="margin-bottom: 15px">Organisation Name</label>
        <input type="text" formControlName="opportunity_review_organisation_name" class="form-control">
      </div>
      <div  class="col-6">
        <label class="form-label" style="margin-bottom: 20px"></label>
        <div style="padding-top: 20px">{{review.organisation_name}} </div>
      </div>
    </div>

    <div class="row" style="margin-bottom: 30px; border-bottom: 1px solid #dee2e6; padding-bottom: 20px">
      <div class="col-6">
        <label class="form-label ps-0" style="margin-bottom: 15px">Organisation Contact Email</label>
        <input type="text"  formControlName="opportunity_review_organisation_contact_email"
               class="form-control">
        <div *ngIf="opportunity_review_organisation_contact_email?.invalid" class="alert alert-danger">
          <div *ngIf="opportunity_review_organisation_contact_email?.errors && opportunity_review_organisation_contact_email?.errors?.['email']">Please, enter valid email address.</div>
        </div>
      </div>
      <div  class="col-6">
        <label class="form-label" style="margin-bottom: 20px"></label>
        <div style="padding-top: 20px">{{review.organisation_contact_email}} </div>

      </div>
    </div>

    <div class="row" style="margin-bottom: 30px; border-bottom: 1px solid #dee2e6; padding-bottom: 20px">
      <div class="col-6">
        <label class="form-label ps-0" style="margin-bottom: 15px">Organisation URL</label>
        <textarea  formControlName="opportunity_review_organisation_url" class="form-control"></textarea>

      </div>
      <div  class="col-6">
        <label class="form-label" style="margin-bottom: 20px"></label>
        <div style="padding-top: 20px">{{review.organisation_url}} </div>
      </div>
    </div>


    <div class="row" style="margin-bottom: 30px; border-bottom: 1px solid #dee2e6; padding-bottom: 20px">
      <div class="col-6">
        <label class="form-label ps-0" style="margin-bottom: 15px">Service Providers</label>
        <kendo-editor formControlName="opportunity_review_service_providers" style="min-height: 300px; height: 90%" #opportunity_review_service_providers_editor>
          <kendo-toolbar>
<!--            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>

            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
              <kendo-toolbar-button text="Upload Image" (click)="openImageUpload('opportunity_review_service_providers')"></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
            <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
<!--            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
<!--            <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>-->
<!--            <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>-->
          </kendo-toolbar>
        </kendo-editor>

      </div>
      <div  class="col-6">
        <label class="form-label" style="margin-bottom: 20px"></label>
        <div [innerHTML]="review.serviceProviders | safeHtml" style="padding-top: 20px"></div>
      </div>
    </div>

    <div class="row" style="margin-bottom: 30px; border-bottom: 1px solid #dee2e6; padding-bottom: 20px">
      <div class="col-6">
        <label class="form-label ps-0" style="margin-bottom: 15px">Eligibility Description</label>
        <kendo-editor formControlName="opportunity_review_elegibility_text" style="min-height: 300px; height: 90%" #opportunity_review_elegibility_text_editor>
          <kendo-toolbar>
<!--            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>

            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
              <kendo-toolbar-button text="Upload Image" (click)="openImageUpload('opportunity_review_elegibility_text')"></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
            <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
<!--            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
<!--            <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>-->
<!--            <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>-->
          </kendo-toolbar>
        </kendo-editor>

      </div>
      <div  class="col-6">
        <label class="form-label" style="margin-bottom: 20px"></label>
        <div [innerHTML]="review.elegibility | safeHtml" style="padding-top: 20px"></div>
      </div>
    </div>

    <div class="row" style="margin-bottom: 30px; border-bottom: 1px solid #dee2e6; padding-bottom: 20px">
      <div class="col-6">
        <label class="form-label ps-0" style="margin-bottom: 15px">Benefits</label>
        <kendo-editor formControlName="opportunity_review_benefits" style="min-height: 300px; height: 90%" #opportunity_review_benefits_editor>
          <kendo-toolbar>
<!--            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>

            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
              <kendo-toolbar-button text="Upload Image" (click)="openImageUpload('opportunity_review_benefits')"></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
            <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
<!--            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
<!--            <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>-->
<!--            <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>-->
          </kendo-toolbar>
        </kendo-editor>

      </div>
      <div  class="col-6">
        <label class="form-label" style="margin-bottom: 20px"></label>
        <div [innerHTML]="review.benefitEsc | safeHtml" style="padding-top: 20px"></div>

      </div>
    </div>

    <div class="row" style="margin-bottom: 30px; border-bottom: 1px solid #dee2e6; padding-bottom: 20px">
      <div class="col-6">
        <label class="form-label ps-0" style="margin-bottom: 15px">Negatives</label>
        <kendo-editor formControlName="opportunity_review_negatives" style="min-height: 300px; height: 90%" #opportunity_review_negatives_editor>
          <kendo-toolbar>
<!--            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>

            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
              <kendo-toolbar-button text="Upload Image" (click)="openImageUpload('opportunity_review_negatives')"></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
            <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
<!--            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
<!--            <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>-->
<!--            <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>-->
          </kendo-toolbar>
        </kendo-editor>

      </div>
      <div  class="col-6">
        <label class="form-label" style="margin-bottom: 20px"></label>
        <div [innerHTML]="review.negativesEsc | safeHtml" style="padding-top: 20px"></div>
      </div>
    </div>

    <div class="row" style="margin-bottom: 30px; border-bottom: 1px solid #dee2e6; padding-bottom: 20px">
      <div class="col-6">
        <label class="form-label ps-0" style="margin-bottom: 15px">Risks</label>
        <kendo-editor formControlName="opportunity_review_risks" style="min-height: 300px; height: 90%" #opportunity_review_risks_editor>
          <kendo-toolbar>
<!--            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>

            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
              <kendo-toolbar-button text="Upload Image" (click)="openImageUpload('opportunity_review_risks')"></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
            <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
<!--            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
<!--            <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>-->
<!--            <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>-->
          </kendo-toolbar>
        </kendo-editor>

      </div>
      <div  class="col-6">
        <label class="form-label" style="margin-bottom: 20px"></label>
        <div [innerHTML]="review.risksEsc | safeHtml" style="padding-top: 20px"></div>
      </div>
    </div>

    <div class="row" style="margin-bottom: 30px; border-bottom: 1px solid #dee2e6; padding-bottom: 20px">
      <div class="col-6">
        <label class="form-label ps-0" style="margin-bottom: 15px">Costs</label>
        <kendo-editor formControlName="opportunity_review_costs" style="min-height: 300px; height: 90%" #opportunity_review_costs_editor>
          <kendo-toolbar>
<!--            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>

            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
              <kendo-toolbar-button text="Upload Image" (click)="openImageUpload('opportunity_review_costs')"></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
            <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
<!--            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
<!--            <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>-->
<!--            <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>-->
          </kendo-toolbar>
        </kendo-editor>

      </div>
      <div  class="col-6">
        <label class="form-label" style="margin-bottom: 20px"></label>
        <div [innerHTML]="review.costsEsc | safeHtml" style="padding-top: 20px"></div>
      </div>
    </div>

    <div class="row" style="margin-bottom: 30px; border-bottom: 1px solid #dee2e6; padding-bottom: 20px">
      <div class="col-6">
        <label class="form-label ps-0" style="margin-bottom: 15px">Attribution</label>
        <kendo-editor formControlName="opportunity_review_attribution" style="min-height: 300px; height: 90%" #opportunity_review_attribution_editor>
          <kendo-toolbar>
<!--            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>

            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
              <kendo-toolbar-button text="Upload Image" (click)="openImageUpload('opportunity_review_attribution')"></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
            <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
<!--            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
<!--            <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>-->
<!--            <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>-->
          </kendo-toolbar>
        </kendo-editor>

      </div>
      <div  class="col-6">
        <label class="form-label" style="margin-bottom: 20px"></label>
        <div [innerHTML]="review.attributionEsc | safeHtml" style="padding-top: 20px"></div>
      </div>
    </div>

    <div class="row" style="margin-bottom: 30px; border-bottom: 1px solid #dee2e6; padding-bottom: 20px">
      <div class="col-6">
        <label class="form-label ps-0" style="margin-bottom: 15px">Potential Financial Benefits</label>
        <kendo-editor formControlName="opportunity_review_potential_financial_benefits" style="min-height: 300px; height: 90%" #opportunity_review_potential_financial_benefits_editor>
          <kendo-toolbar>
<!--            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>

            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
              <kendo-toolbar-button text="Upload Image" (click)="openImageUpload('opportunity_review_potential_financial_benefits')"></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
            <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-buttongroup>
              <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
              <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
            </kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
<!--            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>-->
<!--            <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>-->
<!--            <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>-->
          </kendo-toolbar>
        </kendo-editor>

      </div>
      <div  class="col-6">
        <label class="form-label" style="margin-bottom: 20px"></label>
        <div [innerHTML]="review.potentialFinancialBenefits | safeHtml" style="padding-top: 20px"></div>
      </div>
    </div>

    <div class="d-grid d-md-flex justify-content-md-end mt-3">
      <button type="button" class="btn btn-primary me-md-2" (click)="onSubmit()" [disabled]="form.invalid">Save</button>

      <button type="button" class="btn btn-secondary" (click)="closeWindow()"> Cancel</button>
    </div>

  </fieldset>
</form>




