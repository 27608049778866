import { Component, EventEmitter, Input, NO_ERRORS_SCHEMA, OnDestroy, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { LoggerService } from '../../shared/logger/logger.service';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogAction, DialogCloseResult, DialogRef, DialogService, DialogsModule, WindowCloseActionDirective, WindowCloseResult, WindowModule, WindowRef, WindowService } from '@progress/kendo-angular-dialog';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { SVGIcon, trashIcon } from "@progress/kendo-svg-icons";

@Component({
  selector: 'casi-error-dialog',
  standalone: true,
  imports: [CommonModule,
    FontAwesomeModule,
    InputsModule,
    ButtonsModule,
    DialogsModule,
    WindowModule,
    NotificationModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
  templateUrl: './error-dialog.component.html',
  styleUrl: './error-dialog.component.scss'
})
export class ErrorDialogComponent implements OnInit, OnDestroy {

  private _title: string | undefined;
  private _content: string = 'Content not set';

  @Input({ required: true })
  set title(value: string) {
    this._title = value;
  }
  get title(): string | undefined {
    return this._title
  };

  @Input({ required: true })
  set content(val: string) {
    this._content = val;
  }
  get content() {
    return this._content;
  }

  private _show: boolean = false;
  @Input({ required: true })
  set show(val: boolean) {
    this._show = val;
    if (this._show) {
      this.showDialog();
    } else {
      this.closeWindow();
    }
  }
  get show() {
    return this._show;
  }

  @Input() buttonText: string = 'Close';
  @Input() minWidth: number = 320;
  @Input() width: number = 700;

  @Output() closeForm: EventEmitter<void> = new EventEmitter();

  @ViewChild('dialogErrorContent', { static: true }) public errorContent: TemplateRef<any> | undefined;
  @ViewChild('dialogActions', { static: true }) public dialogActions: TemplateRef<any> | undefined;

  public trashIcon: SVGIcon = trashIcon;

  private _dialogRef: DialogRef | undefined;

  constructor(
    private logger: LoggerService,
    private dialogService: DialogService
  ) { }

  //#region Ng Events

  ngOnInit(): void {
    this.logger.debug('ngOnInit...');
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnDestroy...');
  }

  //#endregion

  public showDialog(): void {
    this.logger.debug("showDialog...");
    this._dialogRef = this.dialogService.open({
      title: this.title,
      content: this.errorContent,
      actions: this.dialogActions, // [{text: this.buttonText, themeColor: "secondary"}],
      width: this.width,
      minWidth: this.minWidth,
      cssClass: "custom-css-class"
    });

    this._dialogRef.result.subscribe(results => {
      this.logger.debug("showDialog:result:sub: ", results);
      if (results instanceof DialogCloseResult) {
        this.logger.debug("showDialog:result:DialogCloseResult...");
        this.closeForm.emit();
      }
    });
  }

  public errorClose(): void {
    this.logger.debug('errorClose... ');
    // this.closeWindow();
    this.closeForm.emit();
  }

  private closeWindow() {
    if (this._dialogRef) {
      this._dialogRef.close();
      this._dialogRef = undefined;
    }
  }

}
