import { Component, EventEmitter, NO_ERRORS_SCHEMA, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { LoggerService } from '../../shared/logger/logger.service';
import { DialogsModule } from "@progress/kendo-angular-dialog";
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterLink, RouterLinkActive } from '@angular/router';
import {
  faPlusCircle, faAddressCard,
  faEdit, faTrash, faBan
} from '@fortawesome/free-solid-svg-icons';

import { Observable, Subscription } from 'rxjs';
import { ButtonsModule } from "@progress/kendo-angular-buttons";
// import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { WindowModule } from "@progress/kendo-angular-dialog";
import { NotificationService, NotificationModule } from "@progress/kendo-angular-notification";
import { MeService } from '../../auth/me.service';
import { EnquiryAddress } from '../../models/models.lib';
import { CompositeFilterDescriptor, SortDescriptor, filterBy, distinct, orderBy } from "@progress/kendo-data-query";
import { GridModule, PageChangeEvent, PagerModule, PagerPosition, PagerType } from '@progress/kendo-angular-grid';
import { MulticheckFilterComponent } from '../../filters/multicheck-filter/multicheck-filter.component';
import { findNearestNumber } from '../../common/utils.lib';
import { EnquiryRecipientsService } from '../enquiry-recipients.service';
import { EnquiryRecipientComponent } from '../enquiry-recipient/enquiry-recipient.component';

@Component({
  selector: 'casi-enquiry-recipients',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    FontAwesomeModule,
    RouterLink, RouterLinkActive,
    InputsModule,
    ButtonsModule,
    DialogsModule,
    WindowModule,
    NotificationModule,
    PagerModule,
    GridModule,
    MulticheckFilterComponent,
    EnquiryRecipientComponent
  ],
  schemas: [NO_ERRORS_SCHEMA],
  templateUrl: './enquiry-recipients.component.html',
  styleUrl: './enquiry-recipients.component.scss'
})
export class EnquiryRecipientsComponent implements OnInit, OnDestroy {

  @Output() onClose = new EventEmitter<void>();

  public view: Observable<EnquiryAddress[]> = new Observable<EnquiryAddress[]>();
  public rawData: EnquiryAddress[] = [];
  public filter: CompositeFilterDescriptor = { logic: "and", filters: [] };
  public gridData: EnquiryAddress[] = filterBy(this.rawData, this.filter);

  public currentItem: EnquiryAddress | null = null;

  public pagerTypes = ["numeric", "input"];
  public type: PagerType = "numeric";
  public buttonCount = 5;
  public info = true;
  public pageSizes = true;
  public previousNext = true;
  public position: PagerPosition = "bottom";

  public pageSize = 10;
  public skip = 0;
  public sizes = [5, 10, 20, 50];

  public editRecipients: boolean = true;
  public errorMsgOpen: boolean = false;
  public addAddresOpen: boolean = false;

  public confirmDeleteItem: boolean = false;
  public windowTop = 100;
  public windowLeft = 50;
  @ViewChild("appendTo", { read: ViewContainerRef }) appendTo: ViewContainerRef | undefined;

  private _subs: Subscription[] = [];

  constructor(
    private logger: LoggerService,
    private notificationService: NotificationService,
    private me: MeService,
    public dataService: EnquiryRecipientsService,
    public faIcons: FaIconLibrary
  ) {
    faIcons.addIcons(
      faPlusCircle, faAddressCard, faEdit,
      faTrash,
      faBan
    );
  }

  public sort: SortDescriptor[] = [
    {
      field: "completed",
      dir: "asc",
    },
  ];

  ngOnInit(): void {
    this.logger.debug('ngOnInit...');

    this.dataService.query();
    this._subs.push(this.dataService.subscribe(e => {
      this.logger.debug('ngOnInit:v: ', e);
      this.rawData = e;
      this.loadData();
    }))
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnDestroy...');
    this._subs.forEach(s => s.unsubscribe());
  }

  onClearFilters(gridName: string) {
    this.logger.debug("onClearFilters: ", gridName);
    this.filter = { logic: "and", filters: [] };
    this.loadData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.logger.debug("sortChange: ", sort);
    this.sort = sort;
    this.loadData();
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    // this.logger.debug('filterChange: filter: ', filter);
    this.filter = filter;
    this.loadData();
  }

  public distinctPrimitive(fieldName: string): any[] {
    // this.logger.debug('distinctPrimitive: fieldName: ', fieldName);
    return distinct(this.gridData, fieldName).map((item: any) => item[fieldName]);
  }

  public pageChange({ skip, take }: PageChangeEvent): void {
    // this.logger.debug('pageChange:args: ', skip, take);
    this.skip = skip;
    this.pageSize = take;
    this.loadData();
  }

  public addNewAddress() {
    this.currentItem = null;
    // $('#newAddress').data('kendoWindow').center().open();
  };

  public onNew(): void {
    this.logger.debug("onNew...");
    this.currentItem = new EnquiryAddress();
    // this.formMode = FormModes.NEW;
    this.logger.debug("onNew:currentItem ", this.currentItem);
    // this.showTitle = setTitle(this.formMode, 'ROLE');
    this.addAddresOpen = true;
  }

  onConfirmDelete(dataItem: any): void {
    this.logger.debug("onConfirmDelete: ", dataItem);
    this.currentItem = dataItem;
    this.confirmDeleteItem = true;
  }

  onConfirmedClose(status: string): void {
    this.logger.debug("onConfirmedClose: ", status, this.currentItem);
    this.confirmDeleteItem = false;
    if (status === 'delete') {
      this.handleRemove(this.currentItem);
    }
  }

  public onErrorDialogClose(): void {
    this.logger.debug('onErrorDialogClose...');
    this.errorMsgOpen = false;
  }

  closeWindow(): void {
    this.logger.debug(`closeWindow...`);
    this.onClose.emit();
  }

  public close(args: any): void {
    this.logger.debug('close...', args);
    this.addAddresOpen = false;
  }

  public onAddressSave(args: any): void {
    this.logger.debug('onAddressSave...', args);
    this.handleAddAddressClose(args);
  }

  public closeAddress(): void {
    this.logger.debug('closeAddress...');
    this.addAddresOpen = false;
  }

  private loadData(): void {
    // this.logger.debug('loadData...');
    this.gridData = orderBy(filterBy(this.rawData, this.filter), this.sort);
    this.pageSize = findNearestNumber(this.sizes, this.gridData.length);
  }

  private handleRemove(args: any): void {
    if (args && !args.id) {
      this.logger.error('handlRemove: No id given');
    }

    this.dataService.delete(args.id)
      .then(resp => {
        this.logger.debug('handleRemove:resp: ', resp);
        this.dataService.query();
      })
      .catch(err => {
        this.logger.error('handleRemove:err: ', err);
        this.errorMsgOpen = true;
      });
  }

  private handleAddAddressClose(args: any): void {
    this.logger.debug('handleAddAddressClose...', args);
    const { data } = args;
    this.dataService.add(data)
      .then(resp => {
        this.dataService.query();
      })
      .catch(err => {
        this.logger.error('handleAddAddressClose:err: ', err);
      })
      .finally(() => this.addAddresOpen = false);
    // this.addAddresOpen = false;
  }

}
