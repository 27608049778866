import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CommonModule} from "@angular/common";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {DropDownsModule} from "@progress/kendo-angular-dropdowns";
import {Opportunity, ResourceOpportunity} from "../../../models/models.lib";
import {Subscription} from "rxjs";
import {LoggerService} from "../../../shared/logger/logger.service";
import {OpportunitiesService} from "../../../opportunities/opportunities.service";
import _ from "lodash";

interface Item {
  id: number | null,
  title: string
}

@Component({
  selector: 'casi-add-opportunity',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    DropDownsModule
  ],
  templateUrl: './add-opportunity.component.html',
  styleUrl: './add-opportunity.component.scss'
})
export class AddOpportunityComponent implements OnInit, OnDestroy {
  @Input({ required: true }) resourceOpportunity: ResourceOpportunity = new ResourceOpportunity();

  @Output() onCancel = new EventEmitter<void>();
  @Output() onSave = new EventEmitter<any>();

  public value = '';
  form: FormGroup;
  private _subs: Subscription[] = [];
  public opportunityList: Opportunity[] = [];

  public defaultItem: Item = {
    id: null,
    title: "Select item..."
  };

  constructor(
    private fb: FormBuilder,
    private logger: LoggerService,
    public dataService: OpportunitiesService
  ) {
    this.form = this.fb.group(
      {
        id: [null, []],
        opportunity_id: [null, [Validators.required]],
        resource_id: [null, []]
      }
    );
  }

  get f() {
    return this.form.controls;
  }
  get opportunity_id() {
    return this.form.get('opportunity_id');
  }

  ngOnInit(): void {
    this.logger.debug('ngOnInit...');
    this.form.patchValue(this.resourceOpportunity);

    this.dataService.query();
    this._subs.push(this.dataService.subscribe(items => {
      this.logger.debug('ngOnInit:items: ', items);
      let sortedList = _.orderBy(items, ['title'], ['asc']);
      this.opportunityList = sortedList;
    }));
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnInit...');
    this._subs.forEach(s => s.unsubscribe());
  }

  onSubmit(): void {
    this.logger.debug(`onSubmit...`, this.form.value);
    const { name } = this.form.value;
    this.onSave.emit({
      action: "save",
      data: this.form.value
    });
  }

  closeWindow(): void {
    this.logger.debug(`closeWindow...`);
    this.onCancel.emit();
  }

}
