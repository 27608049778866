import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { map, tap } from "rxjs/operators";
import { RolePermission } from '../models/models.lib';
import { APP_CONFIG } from '../setup/config';
import { environment } from '../../environments/environment';

const { apiUrl } = environment;

export abstract class EnvironerRolePermissionService extends BehaviorSubject<Array<any>>{
  public loading: boolean = false;

  constructor(
    private http: HttpClient,
  ) {
    super([]);
  }

  public query(roleId: number): void {
    this.fetch(roleId)
      .subscribe((x) => {
        console.log('query: x: ', x);
        return super.next(x);
      });
  }

  protected fetch(id: number): Observable<RolePermission[]> {
    this.loading = true;

    return this.http.get(encodeURI(`${apiUrl}/admin/rolespermissions/${id}`))
      .pipe(
        map((response: any, i) => <RolePermission[]>response),
        map(r => r.map(x => RolePermission.create(x))),
        // tap(nr => {
        //   console.log('nr: ', nr);
        // }),
        tap(() => (this.loading = false))
      )
  }
}

@Injectable({
  providedIn: 'root'
})
export class RolePermissionService extends EnvironerRolePermissionService {

  constructor(
    http: HttpClient,
    private apiService: ApiService
  ) {
    super(http);
  }

  get() {
    return this.apiService.getHttpCall('admin/rolespermissions', 'get');
  }

  getById(id: number) {
    return this.apiService.getHttpCall(`admin/rolespermissions/${id}`, 'getById');
  }

  add(p: any): Promise<any> {
    return this.apiService.postHttpCallAsPromise('admin/rolespermissions', p, 'add');
  }

  update(id:number, p: any): Promise<any> {
    return this.apiService.putHttpCallAsPromise(`admin/rolespermissions/${id}`, p, 'update');
  }

  destroy(roleId: number, permissionId: number): Promise<any> {
    return this.apiService.deleteHttpCallAsPromise(`admin/rolespermissions/${roleId}/${permissionId}`, 'destroy');
  }


}
