import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { LoggerService } from '../../shared/logger/logger.service';
import { Subscription } from 'rxjs';
import { confirmedPasswordValidator } from '../../directives/casi.validators';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UserDataService } from '../user-data.service';
import { CasiCloseOptions } from '../../common/constants.lib';

@Component({
  selector: 'casi-change-password',
  standalone: true,
  imports: [CommonModule,
    FormsModule, ReactiveFormsModule,
    FontAwesomeModule,
    RouterLink, RouterLinkActive
  ],
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss'
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

  @Input({ required: true }) userId = -99;

  @Output() panelClosed = new EventEmitter<CasiCloseOptions>();

  lForm: FormGroup;

  private _subs: Subscription[] = [];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private logger: LoggerService,
    private dataService: UserDataService
  ) {
    this.lForm = this.fb.group(
      {
        password: [null, [Validators.required, Validators.minLength(6)]],
        confirmedPassword: [null, [Validators.required]],
      },
      {
        validators: confirmedPasswordValidator
      }
    );
  }

  get f() {
    return this.lForm.controls;
  }
  get password() {
    return this.lForm.get('password');
  }
  get confirmedPassword() {
    return this.lForm.get('confirmedPassword');
  }

  ngOnInit(): void {
    this.logger.debug('ngOnInit...');
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnInit...');
    this._subs.forEach(s => s.unsubscribe());
  }

  changePassword() {
    this.logger.debug(`changePassword...`, this.lForm.value, this.userId);
    const { password, confirmedPassword } = this.lForm.value;
    return this.dataService.updatePassword({
      id: this.userId,
      password
    })
      .then(done => {
        this.logger.debug('changePassword: done: ', done);
        this.panelClosed.emit(CasiCloseOptions.Saved);
      })
      .catch(err => {
        this.logger.error('changePassword:error: ', err );
      });
    // let password = this.form.get('password').value;
    // this.reset();
    // this.dataProvider.handleStringOutcome(password);
    // this.onGoBack();
    // this.panelClosed.emit();
  }

  cancelPassword() {
    this.logger.debug(`cancelPassword...`);
    // this.form.reset();
    // this.reset();
    // this.dataProvider.handleStringOutcome();
    // this.onGoBack();
    this.panelClosed.emit(CasiCloseOptions.Closed);
  }

  reset() {
    this.logger.debug(`reset...`);
    // this.passwordControlIsValid = true;
    // this.passwordConfirmedCtrlIsValid = true;
    // this.formIsValid = true;
  }

  togglePV(args: any) {
    this.logger.debug(`togglePV...`);
    // this.hidePassword = !this.hidePassword;
  }

  closePasswordDialog() {
    this.logger.debug(`closePasswordDialog...`);
  }
}
