<div class="container">
  <div class="row">
    <div class="col-12 col-sm-10 col-md-8 offset-sm-1 offset-md-2 jumbo" style="max-width: 765px; margin: auto;">
      <form [formGroup]="form" (ngSubmit)="onSubmit()" class="k-form-inline">
        @if(messageState === MessageStates.Before){
        <fieldset>
          <div class="k-form-field">
            <div class="col-md-8 offset-md-3 col-12">
              <p style="margin-bottom: 0;">Enter your email address to reset your passsword.<br>
                You will be sent an email shortly with instructions.</p>
            </div>
          </div>

          <div class="k-form-field">
            <label for="email" class="form-label col-4 col-md-3 ">Email Address</label>
            <div class="col-7 col-md-8">
              <input type="email" id="email" formControlName="email" class="form-control">
              <div *ngIf="email?.invalid" class="alert alert-danger">

                <div *ngIf="email?.errors?.['required']">
                  Email is required.
                </div>
                <div *ngIf="email?.errors && email?.errors?.['email']">Please, enter valid email address.</div>

              </div>
            </div>
          </div>

          <div class="k-form-field">
            <label class="form-label col-4 col-md-3"></label>
            <div class="col-7 col-md-8">
              <button class="btn btn-primary" type="submit" [disabled]="form.invalid">Submit</button>
            </div>
          </div>

          <div class="k-form-field">
            <div class="col-md-8 offset-md-3 col-7 offset-4">
              <a routerLink="/login" routerLinkActive="active" class="btn btn-link" style="padding-left: 0;">Back to Sign in</a>.
              <!-- <p>Don't have an account yet? <a ui-sref="base.signup" class="ulink">Create an account</a>.</p> -->
            </div>
          </div>

        </fieldset>
        }
      </form>



      @if(messageState === MessageStates.After){
      <div>
        <div class="message-sent">
          <img src="assets/images/envelope.png" style="margin-bottom: 30px;" />
          <h2>Message Sent!</h2>
          <br><br>
          <h4>Please check your Inbox in a few minutes.</h4>
          <br><br>
          <button type="button" class="btn btn-link" (click)="setForm()"> Send another message.</button><br>
          <a routerLink="/login" routerLinkActive="active" class="btn btn-link">Back to Sign in</a>.
        </div>
      </div>
      }

      @if(messageState === MessageStates.InProgress){
      <div>
        <div class="message-sent">
          <!-- <i class="fa fa-spinner fa-pulse fa-4x" aria-hidden="true"></i> -->
          <fa-icon icon="spinner" animation="spin" size="4x"></fa-icon>
          <h2>Sending Message...</h2>
        </div>
      </div>
      }

      @if(messageState === MessageStates.Error){
      <div>
        <div class="message-sent">
          <h2 style="margin-bottom: 30px;">No matching email address found</h2>
          <button class="btn btn-default" (click)="setForm()">Try again</button>
          <a routerLink="/login" routerLinkActive="active">Back to Sign in</a>.
        </div>
      </div>
      }

    </div>
  </div>
</div>
