import { InjectionToken, isDevMode } from '@angular/core';
import { CasiLogLevel } from '../common/logger.lib';

export enum WebApiEndpoints {
  WebApiUrlLocal = 'http://localhost:1338',
  WebApiUrlDev = "https://environer-dev.une.edu.au/web-api",
  WebApiUrlProd = "https://environer-prod.une.edu.au/web-api",
  WebUrlDev = "https://environer-dev.une.edu.au/#!/out/",
  WebUrlProd = "https://environer-prod.une.edu.au/#!/out/"
};

export const pollingInterval: number = 300000;
export const baseIconDir: string = "assets/images/";
export const iconEnding: string = '.png';
export interface AppConfig {
  isDev: boolean;
  logLevel: CasiLogLevel;
  pollInterval: number;
  iconBaseDir: string;
  iconEnding: string;
};

export const APP_CONFIG: AppConfig = {
  isDev: isDevMode(),
  logLevel: CasiLogLevel.Debug,
  pollInterval: pollingInterval,
  iconBaseDir: baseIconDir,
  iconEnding: iconEnding
};

export const CONFIG_TOKEN = new InjectionToken<AppConfig>('CONFIG_TOKEN', {
  providedIn: 'root',
  factory: () => APP_CONFIG
});

