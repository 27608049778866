import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { map, tap } from "rxjs/operators";
import { ControlHost } from '../models/models.lib';
import { APP_CONFIG } from '../setup/config';
import { environment } from '../../environments/environment';

const { apiUrl } = environment;

export abstract class CommonControlHostService extends BehaviorSubject<any>{
  public loading: boolean = false;

  constructor(
    private http: HttpClient,
  ) {
    super({});
  }

  public query(): void {
    this.fetch().subscribe((x) => {
      // console.log('query: x: ', x);
      return super.next(x);
    });
  }

  protected fetch(): Observable<ControlHost> {
    this.loading = true;

    return this.http.get(encodeURI(`${apiUrl}/hostinfo`))
      .pipe(
        map((response: any, i) => {
          // console.log('fetch:map:response: ', response, i);
          // let returning =<ControlHost[]>response; // response.map(r =>  ControlHost.create((r)));
          // console.log('fetch:map:response: ', returning);
          return <ControlHost[]>response;
        }),
        map(r => ControlHost.create(r)), // r.map(x => ControlHost.create(x))),
        // tap(nr => {
        //   console.log('nr: ', nr);
        // }),
        tap(() => (this.loading = false))
      )
  }
}

@Injectable({
  providedIn: 'root'
})
export class ControlHostService extends CommonControlHostService {

  constructor(
    http: HttpClient,
    private apiService: ApiService
  ) {
    super(http);
  }

  get() {
    return this.apiService.getHttpCall('hostinfo', 'get');
  }

}

/**
 * {
  "db_major_version": 1,
  "db_minor_version": 0,
  "app_base_url": "https://environer-dev.une.edu.au",
  "param_company_name": "AgriFutures Australia",
  "param_entity_name": "AgriFutures Australia",
  "param_company_address": "Building 007, Tooma Way, Charles Sturt University, Locked Bag 588, Wagga Wagga NSW 2650",
  "param_abn": "25 203 754 319",
  "param_acn": null,
  "param_primary_email": "no-reply@environer-dev.une.edu.au",
  "param_additional_email": null,
  "param_website_url": "https://agrifutures.com.au",
  "param_primary_contact_number": null,
  "param_additional_contact_number": null,
  "param_product_name": "Environer"
}
 */
