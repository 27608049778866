import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';


@Injectable({
  providedIn: 'root'
})
export class ImageUploadService {

  constructor(
    private apiService: ApiService
  ) {}


  deleteImage(id: any, dirName: string, subDir: string, fileName: string): Promise<any> {
    return this.apiService.deleteHttpCallAsPromise(`image/${id}/${dirName}/${subDir}/${fileName}`);
  }

}
