<link rel="stylesheet" href="https://unpkg.com/@progress/kendo-font-icons/dist/index.css" />
<div class="container" my-5>
  <div class="card" #appendTo>
    <div class="card-body no-border">
      <div class="card-title">
        <h3 style="text-align: left;" class="page-header">Edit Documents</h3>
      </div>
        <div class="col-xs-12 col-sm-6 example-col">
          <h2>Edit
            <kendo-dropdownlist [data]="docTypeList" [(value)]="currentDocType"
                                textField="text" valueField="value" (valueChange)="valueChange($event)" style="width: 250px; margin-left: 20px"></kendo-dropdownlist>
          </h2>

        </div>

      <casi-editor-image-upload
        [editor]="editor"
        fieldName="html"
        [recordId]="recordId"
        tableName="terms_conditions"
        (onLoad)="onImageLoaded($event)" #uploadDialog></casi-editor-image-upload>

        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <fieldset>

            <div class="form-group">
              <kendo-editor [value]="value" style="height: 400px;" (valueChange)="editorChange($event)" #editor>
                <kendo-toolbar>
                  <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>
                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>

                  <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                    <kendo-toolbar-button text="Upload Image" (click)="openImageUpload()"></kendo-toolbar-button>
                  </kendo-toolbar-buttongroup>
                  <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
                </kendo-toolbar>
              </kendo-editor>
            </div>

            <div class="d-grid d-md-flex justify-content-md-end">
              <button [disabled]="approved?.value" type="button" class="btn btn-primary me-md-2" (click)="approvePage()"> Approve/Publish</button>
              <button type="button" class="btn btn-primary me-md-2" (click)="onSubmit()"> Save Changes</button>
            </div>

          </fieldset>
        </form>

    </div>
  </div>
</div>
