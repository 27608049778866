import {Component, OnInit} from '@angular/core';
import {LoggerService} from "../../shared/logger/logger.service";
import {TermsConditionsService} from "../terms-conditions.service";
import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';

@Component({
  selector: 'privacy',
  standalone: true,
  imports: [
    SafeHtmlPipe
  ],
  templateUrl: './privacy.component.html',
  styleUrl: './privacy.component.scss'
})
export class PrivacyComponent implements OnInit{

  docContent: string = "";
  private docVersion: number = 0;

  constructor(
    private logger: LoggerService,
    private dataService: TermsConditionsService
  ) {
  }


  ngOnInit(): void {
    this.dataService.get("privacy")
      .then(response =>  {
        this.logger.debug('ngOnInit: ', response);
        this.docContent = response.html;
        this.docVersion = response.version_number;
      }).catch(err => {
      this.logger.error('ngOnInit: ', err);
    })
  }
}
