<link rel="stylesheet" href="https://unpkg.com/@progress/kendo-font-icons/dist/index.css" />
<div class="container" my-5>
  <div class="card" #appendTo>
    <div class="card-body no-border">

      <kendo-grid [kendoGridBinding]="gridData" [loading]="dataService.loading" [groupable]="true" [resizable]="true"
        style="max-height: 450px;">

        <ng-template kendoGridToolbarTemplate position="top">
          <button (click)="toggleAll()" kendoButton kendoButton class="btn btn-primary">
            <span *ngIf="toggleFlag">Un</span>Select All
          </button>
        </ng-template>

        <kendo-grid-column title="Select" field="selected" [width]="80">
          <ng-template kendoGridCellTemplate let-dataItem>
            <input type="checkbox" kendoCheckBox size="large" [checked]="dataItem.selected"
              (click)="setSelection(dataItem)" />
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Entity" field="entity">
        </kendo-grid-column>

        <kendo-grid-column title="Action" field="display_action">
        </kendo-grid-column>

      </kendo-grid>

      <div class="d-grid d-md-flex justify-content-md-end">
        <button type="button" class="btn btn-primary me-md-2" (click)="closeAndSaveWindow()"> Save</button>
        <button type="button" class="btn btn-secondary" (click)="closeWindow()"> Cancel</button>
      </div>

    </div>
  </div>

</div>
