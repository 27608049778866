<link rel="stylesheet" href="https://unpkg.com/@progress/kendo-font-icons/dist/index.css" />
<div class="container" my-5>
  <div class="card" #appendTo>
    <div class="card-body no-border">
        <h3 style="text-align: left;"><b style="margin-right: 15px;">Resource Title</b> <span>{{title}}</span></h3>

      <kendo-grid
        [kendoGridBinding]="gridData" [loading]="dataService.loading" [sort]="sort" [sortable]="true" [groupable]="true" [resizable]="true"
        (sortChange)="sortChange($event)" [pageSize]="pageSize" [pageable]="true" style="height: 400px;">

        <ng-template kendoGridToolbarTemplate position="top">
          <button [hidden]="!canCreate" (click)="onNew()" kendoButton class="btn btn-primary"><fa-icon icon="circle-plus"></fa-icon> Add Opportunity</button>
        </ng-template>

        <kendo-grid-column title="Opportunity Title" field="title" [style]="{'vertical-align': 'top'}"></kendo-grid-column>
        <kendo-grid-column title="Description" field="short_description" [style]="{'vertical-align': 'top'}"></kendo-grid-column>

        <kendo-grid-column [hidden]="!canDestroy" title="Delete" [width]="70" [style]="{'vertical-align': 'top'}">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <button (click)="onConfirmDelete(dataItem)" kendoButton class="btn btn-stn-grid">
              <fa-icon icon="trash"></fa-icon>
            </button>
          </ng-template>
        </kendo-grid-column>

        <ng-template kendoPagerTemplate>
          <kendo-pager-info *ngIf="pageSize > 0"></kendo-pager-info>
        </ng-template>

      </kendo-grid>

      <div class="d-grid d-md-flex justify-content-md-end">
        <button type="button" class="btn btn-secondary" (click)="closeWindow()"> Close</button>
      </div>

    </div>
  </div>

</div>

<kendo-dialog *ngIf="confirmDeleteItem" title="Please confirm" (close)="onConfirmedClose('cancel')" [minWidth]="250" [width]="450">
  <h4>Delete Opportunity - <b>{{currentItem.title}}</b>?</h4>

  <kendo-dialog-actions>
    <button kendoButton (click)="onConfirmedClose('delete')" themeColor="primary">Delete</button>
    <button kendoButton (click)="onConfirmedClose('cancel')">Cancel</button>
  </kendo-dialog-actions>
</kendo-dialog>


<kendo-window *ngIf="addItemOpen" (close)="closeItem()" [width]="900" [(top)]="windowTop" [(left)]="windowLeft">
  <kendo-window-titlebar>
    <span class="k-window-title">ADD OPPORTUNITY</span>
    <button kendoWindowCloseAction></button>
  </kendo-window-titlebar>
  <casi-add-opportunity [resourceOpportunity]="currentItem"  (onSave)="onItemSave($event)" (onCancel)="closeItem()"></casi-add-opportunity>
</kendo-window>


<kendo-dialog *ngIf="errorMsgOpen" (close)="onErrorDialogClose()">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;"><span class="k-icon k-font-icon k-i-exclamation-circle"></span> Duplicate Opportunity</div>
  </kendo-dialog-titlebar>
  <h4>The Opportunity has already been linked to the Resource Record!</h4>
  <kendo-dialog-actions>
    <button kendoButton (click)="onErrorDialogClose()">Close and Retry</button>
  </kendo-dialog-actions>
</kendo-dialog>

