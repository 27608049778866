import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef} from '@angular/core';
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FaIconLibrary, FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {DialogsModule, WindowModule} from "@progress/kendo-angular-dialog";
import {NotificationModule, NotificationService} from "@progress/kendo-angular-notification";
import {GridModule, PagerModule, PagerPosition, PagerType} from "@progress/kendo-angular-grid";
import {InputsModule} from "@progress/kendo-angular-inputs";
import {ButtonsModule} from "@progress/kendo-angular-buttons";
import {LabelModule} from "@progress/kendo-angular-label";
import {LayoutModule} from "@progress/kendo-angular-layout";
import {AddOpportunityComponent} from "./add-opportunity/add-opportunity.component";
import {Observable, Subscription} from "rxjs";
import {ResourceOpportunity} from "../../models/models.lib";
import {CompositeFilterDescriptor, distinct, filterBy, orderBy, SortDescriptor} from "@progress/kendo-data-query";
import {LoggerService} from "../../shared/logger/logger.service";
import {ResourceOpportunityService} from "../../commonServices/resource-opportunity.service";
import {MeService} from "../../auth/me.service";
import {faPlusCircle, faTrash} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'casi-opportunities',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    FontAwesomeModule,
    RouterLink, RouterLinkActive,
    DialogsModule,
    WindowModule,
    NotificationModule,
    PagerModule,
    GridModule,
    InputsModule,
    ButtonsModule,
    LabelModule,
    LayoutModule,
    AddOpportunityComponent
  ],
  templateUrl: './opportunities.component.html',
  styleUrl: './opportunities.component.scss'
})
export class OpportunitiesComponent  implements OnInit, OnDestroy  {

  @Input({ required: true }) resourceId: number = 0;
  @Input() title: string = "";

  @Output() onClose = new EventEmitter<void>();
  @Output() onAdd = new EventEmitter<any>();

  public view: Observable<ResourceOpportunity[]> = new Observable<ResourceOpportunity[]>();
  public rawData: ResourceOpportunity[] = [];
  public filter: CompositeFilterDescriptor = { logic: "and", filters: [] };
  public gridData: ResourceOpportunity[] = filterBy(this.rawData, this.filter);

  public currentItem: ResourceOpportunity = new ResourceOpportunity();

  public pagerTypes = ["numeric", "input"];
  public type: PagerType = "numeric";
  public info = true;
  public pageSizes = true;
  public previousNext = true;
  public position: PagerPosition = "bottom";
  public pageSize = 10;

  public addItemOpen: boolean = false;
  public errorMsgOpen: boolean = false;
  public confirmDeleteItem: boolean = false;

  public windowTop = 100;
  public windowLeft = 50;

  public canCreate: boolean = true;
  public canUpdate: boolean = true;
  public canDestroy: boolean = true;

  @ViewChild("appendTo", { read: ViewContainerRef }) appendTo: ViewContainerRef | undefined;

  private _subs: Subscription[] = [];

  constructor(
    private logger: LoggerService,
    public dataService: ResourceOpportunityService,
    public faIcons: FaIconLibrary,
    private me: MeService,
    private notificationService: NotificationService
  ) {
    faIcons.addIcons(faPlusCircle, faTrash);
  }

  public sort: SortDescriptor[] = [
    {
      field: "title",
      dir: "asc",
    },
  ];

  //#region Ng Handlers
  ngOnInit(): void {
    this.logger.debug('ngOnInit...',);

    this.dataService.query(this.resourceId, false);
    this._subs.push(this.dataService.subscribe(e => {
      this.logger.debug('ngOnInit:v: ', e);
      this.rawData = e;
      this.loadData();
    }))
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnInit...');
    this._subs.forEach(s => s.unsubscribe());
  }

  //#endregion

  //#region Grid handlers

  onClearFilters(gridName: string) {
    this.logger.debug("onClearFilters: ", gridName);
    this.filter = { logic: "and", filters: [] };
    this.loadData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.logger.debug("sortChange: ", sort);
    this.sort = sort;
    this.loadData();
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.logger.debug('filterChange: filter: ', filter);
    this.filter = filter;
    this.loadData();
  }

  public distinctPrimitive(fieldName: string): any[] {
    this.logger.debug('distinctPrimitive: fieldName: ', fieldName);
    return distinct(this.gridData, fieldName).map((item: any) => item[fieldName]);
  }

  //#endregion

  //#region Window handlers

  public onNew(): void {
    this.logger.debug("onNew...");
    this.currentItem = ResourceOpportunity.createWithResId(this.resourceId);
    this.logger.debug("onNew:currentItem ", this.currentItem);
    this.addItemOpen = true;
  }

  public onItemSave(args: any): void {
    this.logger.debug('onItemSave...', args);
    this.handleSaveAndClose(args);
  }

  onConfirmDelete(dataItem: any): void {
    this.logger.debug("onConfirmDelete: ", dataItem);
    this.currentItem = dataItem;
    this.confirmDeleteItem = true;
  }

  onConfirmedClose(status: string): void {
    this.logger.debug("onConfirmedClose: ", status, this.currentItem);
    this.confirmDeleteItem = false;
    if (status === 'delete') {
      this.handleRemove(this.currentItem);
    }
  }

  public onErrorDialogClose(): void {
    this.logger.debug('onErrorDialogClose...');
    this.errorMsgOpen = false;
  }

  closeWindow(): void {
    this.logger.debug(`closeWindow...`);
    this.onClose.emit();
  }

  public close(args: any): void {
    this.logger.debug('close...', args);
    this.addItemOpen = false;
  }

  public closeItem(): void {
    this.logger.debug('closeItem...');
    this.addItemOpen = false;
  }

  //#endregion

  //#region Private functions

  private loadData(): void {
    this.logger.debug('loadData...');
    this.gridData = orderBy(filterBy(this.rawData, this.filter), this.sort);
    this.pageSize = this.gridData.length;
  }

  private handleRemove(args: any): void {
    if (args && !args.id) {
      this.logger.error('handlRemove: No id given');
    }

    this.dataService.destroy(args.id)
      .then(resp => {
        this.logger.debug('handleRemove:resp: ', resp);
        this.notify("Opportunity Deleted");
        this.dataService.query(this.resourceId, false);
      })
      .catch(err => {
        this.logger.error('handleRemove:err: ', err);
        this.notify("Error Deleting Opportunity", true);
      });
  }

  private handleSaveAndClose(args: any): void {
    this.logger.debug('handleSaveAndClose...', args);
    const { action, data, mode } = args;
    this.dataService.add(data)
      .then(resp => {
        this.notify('Opportunity Added')
        this.dataService.query(this.resourceId, false);
      })
      .catch(err => {
        this.logger.error('handleSaveAndClose:error: ', err);
        let duplicate = err.status == 409;
        if(duplicate)
          this.errorMsgOpen = true;
        else
          this.notify("Error Adding Opportunity", true);
      })
      .finally(() => this.addItemOpen = false);
  }

  private notify(msg: string, error: boolean = false): void {
    this.logger.debug("notify", msg);
    this.notificationService.show({
      appendTo: this.appendTo,
      content: msg,
      animation: { type: "fade", duration: 300 },
      type: { style: !error ? "success" : "error", icon: true },
      position: {
        horizontal: "right",
        vertical: "top"
      },
      height: 50,
      // width: 150,
      hideAfter: 2000
    });
  }

  //#endregion
}
