import {Component, OnInit, AfterViewInit} from '@angular/core';
import {LoggerService} from "../../shared/logger/logger.service";

@Component({
  selector: 'about',
  standalone: true,
  templateUrl: './about.component.html',
  styleUrl: './about.component.scss'
})
export class AboutComponent implements OnInit, AfterViewInit{


  constructor(
    private logger: LoggerService
  ) {
  }


  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    setTimeout(() => window.scrollTo({top: 0, left: 0, behavior: "instant"}));
  }
}
