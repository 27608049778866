<link rel="stylesheet" href="https://unpkg.com/@progress/kendo-font-icons/dist/index.css" />
<div class="container-fluid gx-5">
  <div class="card" #appendTo>
    <div class="card-body no-border">
      <div class="card-title">
      </div>

      <h4>Review Opportunites:</h4>
      <div class="add-footer-margin">

        <kendo-grid [kendoGridBinding]="gridData" [loading]="dataService.loading" [groupable]="false" [resizable]="true"
                    [filter]="filter" filterable="menu" (filterChange)="filterChange($event)" [pageSize]="pageSize"
                    [pageable]="true" [style.maxHeight.px]="2000" [width]="500"  style="height: calc(100vh - 300px); min-height: 250px;">

          <ng-template kendoGridToolbarTemplate position="top">
            <button kendoGridExcelCommand class="btn btn-primary"><fa-icon icon="download"></fa-icon> Export Data</button>
          </ng-template>

          <kendo-grid-column title="Edit" [width]="80" [headerStyle]="{ 'vertical-align': 'middle'}" [sticky]="stickyCols" [hidden]="!canUpdate">
<!--            <ng-template kendoGridHeaderTemplate><span style="margin-right: 10px">Edit</span>-->
<!--              <casi-help-popover [infoId]="14"></casi-help-popover>-->
<!--            </ng-template>-->
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <div *ngIf="!dataItem.review_complete">
                <button (click)="edit(dataItem)" kendoButton class="btn btn-stn-grid"
                        casiClickLogger="button[reviewOpportunity:Edit:{{dataItem.id}}]"><fa-icon icon="edit"></fa-icon></button>
              </div>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Complete" [width]="130" [headerStyle]="{ 'vertical-align': 'middle'}" [sticky]="stickyCols" [hidden]="!canUpdate">
            <ng-template kendoGridHeaderTemplate><span style="margin-right: 10px">Complete</span>
              <casi-help-popover [infoId]="26"></casi-help-popover>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <div  *ngIf="!dataItem.review_complete">
                <button (click)="complete(dataItem)" kendoButton class="btn btn-stn-grid"
                        casiClickLogger="button[reviewOpportunity:Complete:{{dataItem.id}}]"><fa-icon icon="check"></fa-icon></button>
              </div>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="title" title="Organisation & Title" [width]="220" [headerStyle]="{ 'vertical-align': 'middle'}" [sticky]="stickyCols">
            <ng-template kendoGridHeaderTemplate><span style="margin-right: 10px">Title & Organisation</span>
              <casi-help-popover [infoId]="15" (click)="disableStickyCols()" [winStyle]="'position: fixed;'" (onClose)="enableStickyCols($event)"></casi-help-popover>
            </ng-template>

            <ng-template kendoGridCellTemplate let-dataItem>
              <div style="margin-bottom: 15px">
                <a [href]="dataItem.organisation_url" style="cursor: pointer; text-decoration: underline"
                   target=”_blank” *ngIf="dataItem.organisation_url">{{dataItem.title}}</a>
                <span *ngIf="!dataItem.organisation_url">{{dataItem.title}}</span>
              </div>
              <div *ngIf="dataItem.organisation_name">{{dataItem.organisation_name}}</div>

              <ng-template kendoGridFilterMenuTemplate let-column="column" let-dataItem let-filter="filter"
                           let-filterService="filterService">
                <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
                                   [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
              </ng-template>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="short_description" title="Summary" [width]="270" [headerStyle]="{ 'vertical-align': 'middle'}" [sticky]="stickyCols">
            <ng-template kendoGridHeaderTemplate><span style="margin-right: 10px">Summary</span>
              <casi-help-popover [infoId]="16"></casi-help-popover>
            </ng-template>
            <ng-template kendoGridFilterMenuTemplate let-column="column" let-dataItem let-filter="filter"
                         let-filterService="filterService">
              <div [innerHTML]="dataItem.short_description"></div>
              <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
                                 [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="eligibilityText" title="Eligibility" [width]="300"
                             [headerStyle]="{ 'vertical-align': 'middle'}">
            <ng-template kendoGridHeaderTemplate><span style="margin-right: 10px">Eligibility</span>
              <casi-help-popover [infoId]="24" [winStyle]="'position: fixed;'"></casi-help-popover></ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <div [innerHTML]="dataItem.elegibility | safeHtml" *ngIf="dataItem.elegibility_text"></div>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="industryList" title="Industries" [width]="150"
                             [headerStyle]="{ 'vertical-align': 'middle'}" [filterable]="false">
            <ng-template kendoGridHeaderTemplate><span style="margin-right: 10px">Industries</span>
              <casi-help-popover [infoId]="17" [iconStyle]="'color: #d9b35b;'"></casi-help-popover>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <div [ngStyle]="{'text-align':'center'}">
                <div *ngFor="let industry of dataItem.industries">
                  <kendo-avatar [size]="iconSize" [rounded]="iconRoundness"
                                [imageSrc]="buildImageLink(industry.icon_name)" *ngIf="industry.icon_name">˝</kendo-avatar>
                  <div>{{industry.description}}</div>
                </div>
              </div>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="classification_description" title="Market Opportunities" [width]="150"
                             [headerStyle]="{ 'vertical-align': 'middle'}" [filterable]="false">
            <ng-template kendoGridHeaderTemplate><span style="margin-right: 10px">Market
                opportunities</span>
              <casi-help-popover [infoId]="18" [iconStyle]="'color: #7ba89e;'"
                                 [winStyle]="'position: fixed;'"></casi-help-popover>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <div [ngStyle]="{'text-align':'center'}">
                <kendo-avatar [size]="iconSize" [rounded]="iconRoundness"
                              [imageSrc]="buildImageLink(dataItem.classification_icon_name)">˝</kendo-avatar>
                <div>{{dataItem.classification_description}}</div>
              </div>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="goalList" title="Project Goals" [width]="150"
                             [headerStyle]="{ 'vertical-align': 'middle'}" [filterable]="false">
            <ng-template kendoGridHeaderTemplate><span style="margin-right: 10px">Project Goals</span>
              <casi-help-popover [infoId]="19" [iconStyle]="'color: #5d654d;'">
              </casi-help-popover></ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <div [ngStyle]="{'text-align':'center'}">
                <div *ngFor="let item of dataItem.opportunity_goals">
                  <kendo-avatar [size]="iconSize" [rounded]="iconRoundness" [imageSrc]="buildImageLink(item.icon_name)">˝</kendo-avatar>
                  <div>{{item.goal}}</div>
                </div>
              </div>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="coBenefitList" title="Project Outcomes" [width]="150"
                             [headerStyle]="{ 'vertical-align': 'middle'}" [filterable]="false">
            <ng-template kendoGridHeaderTemplate><span style="margin-right: 10px">Project Outcomes</span>
              <casi-help-popover [infoId]="20" [iconStyle]="'color: #dd7f45;'"></casi-help-popover>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <div [ngStyle]="{'text-align':'center'}">
                <div *ngFor="let item of dataItem.co_benefits">
                  <kendo-avatar [size]="iconSize" [rounded]="iconRoundness"
                                [imageSrc]="buildImageLink(item.icon_name)">˝</kendo-avatar>
                  <div>{{item.co_benefit}}</div>
                </div>
              </div>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="stateList" title="Location" [width]="150"
                             [headerStyle]="{ 'vertical-align': 'middle'}">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div>
                <div>{{dataItem.stateList}}</div>
              </div>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="resourceList" title="Resources" [width]="200" [headerStyle]="{ 'vertical-align': 'middle'}">
            <ng-template kendoGridHeaderTemplate><span style="margin-right: 10px">Resources</span>
              <casi-help-popover [infoId]="21"></casi-help-popover>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <div>
                <div *ngFor="let item of dataItem.opportunity_resources" style="margin-bottom: 20px">
                  <a [href]="item.url" target="_blank"
                     style="cursor: pointer; text-decoration: underline;">{{item.description}}</a>
                </div>
              </div>
            </ng-template>
          </kendo-grid-column>

          <ng-template kendoPagerTemplate>
            <kendo-pager-info *ngIf="pageSize > 0"></kendo-pager-info>
          </ng-template>

          <kendo-grid-excel fileName="Opportunity Review.xlsx" [fetchData]="allData"></kendo-grid-excel>
        </kendo-grid>

      </div>

    </div>
  </div>
</div>


<kendo-window *ngIf="editReviewOpen" (close)="closeEditReview()" [width]="1350" autoFocusedElement="#title">
  <kendo-window-titlebar>
    <span class="k-window-title">EDIT OPPORTUNITY REVIEW</span>
    <button kendoWindowCloseAction></button>
  </kendo-window-titlebar>
  <casi-edit-review [review]="currentItem" (onCancel)="closeEditReview()" (onSave)="onItemSave($event)"></casi-edit-review>
</kendo-window>

<kendo-window *ngIf="completeReviewOpen" (close)="closeCompleteReview('cancel')" [width]="700" autoFocusedElement="#title">
  <kendo-window-titlebar>
    <span class="k-window-title">CONFIRM COMPLETE REVIEW</span>
    <button kendoWindowCloseAction></button>
  </kendo-window-titlebar>
  <h4>Are you sure you would like to complete this review?</h4>
  <form class="k-form"  [formGroup]="form">
    <fieldset>
      <div class="row">
        <label  class="col-4 form-label k-label">Comment</label>
        <div class=" col-8">
          <textarea class="form-control" formControlName="review_comment" rows="5"></textarea>
        </div>
      </div>
      <div class="d-grid d-md-flex justify-content-md-end mt-3">
        <button type="button" class="btn btn-primary me-md-2" (click)="closeCompleteReview('complete')" >Confirm</button>
        <button type="button" class="btn btn-secondary" (click)="closeCompleteReview('cancel')"> Cancel</button>
      </div>
    </fieldset>
  </form>
</kendo-window>

<!--<kendo-dialog *ngIf="completeReviewOpen" title="Please confirm" (close)="closeCompleteReview('cancel')" [minWidth]="250" [width]="450">-->
<!--  <h4>Are you sure you would like to complete this review?</h4>-->

<!--  <kendo-dialog-actions>-->
<!--    <button kendoButton (click)="closeCompleteReview('complete')" themeColor="primary">Complete</button>-->
<!--    <button kendoButton (click)="closeCompleteReview('cancel')">Cancel</button>-->
<!--  </kendo-dialog-actions>-->
<!--</kendo-dialog>-->

<div kendoDialogContainer></div>
