import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from "rxjs/operators";

import { HttpClient } from '@angular/common/http';
import { APP_CONFIG } from '../setup/config';
import { User } from '../models/models.lib';
import { environment } from '../../environments/environment';

const { apiUrl } = environment;

export abstract class EnvironerUserService extends BehaviorSubject<Array<any>>{
  public loading: boolean = false;

  constructor(
    private http: HttpClient,
  ) {
    super([]);
  }

  public query(): void {
    this.fetch()
      .subscribe((x) => {
        console.log('query: x: ', x);
        return super.next(x);
      });
  }

  protected fetch(): Observable<User[]> {
    this.loading = true;

    return this.http.get(encodeURI(`${apiUrl}/users`))
      .pipe(
        map((response: any, i) => <User[]>response),
        map(r => r.map(x => User.create(x))),
        // tap(nr => {
        //   console.log('nr: ', nr);
        // }),
        tap(() => (this.loading = false))
      )
  }
}

@Injectable({
  providedIn: 'root'
})
export class UserDataService extends EnvironerUserService {

  constructor(
    http: HttpClient,
    private apiService: ApiService

  ) {
    super(http);
  }

  loginSSO(): Promise<any> {
    return this.apiService.getHttpCallAsPromise('login_sso', 'loginSSO');
  }

  fetchToken(email: string): Promise<any> {
    return this.apiService.postHttpCallAsPromise('user/fetchtoken', { email }, 'fetchToken');
  }

  getForCodeUsers(): Promise<any> {
    return this.apiService.getHttpCallAsPromise('forcode/users/', 'getForCodeUsers');
  }

  refreshAccessToken(body: any): Promise<any> {
    return this.apiService.postHttpCallAsPromise('access/refresh/token', body, 'refreshAccessToken');
  }

  getRefreshToken(userId: number): Promise<any> {
    return this.apiService.postHttpCallAsPromise(`access/refresh/get`, { userId }, 'getRefreshToken');
  }

  //#region Private Methods

  setActive(id: number, active: boolean): Promise<any> {
    active = !active;
    return this.apiService.postHttpCallAsPromise('useractive', { id: id, active: active }, 'setActive');
  }

  deleteUser(id: number): Promise<any> {
    return this.apiService.deleteHttpCallAsPromise(`users/${id}`, 'deleteUser');
  }

  getUsers(): Promise<any> {
    return this.apiService.getHttpCallAsPromise('users', 'getUsers');
  }

  getRoles(): Promise<any> {
    return this.apiService.getHttpCallAsPromise('findroles', 'getRoles');
  }

  getUserRoles(userId: number): Promise<any> {
    return this.apiService.getHttpCallAsPromise(`findanyuserroles/${userId}`, 'getUserRoles');
  }

  updateUser(user: any, userRoles: any, userLocations?: any, supervisorId?: number): Promise<any> {
    return this.apiService.postHttpCallAsPromise('updateuser', {
      user,
      userRoles,
      userLocations,
      supervisorId
    }, 'updateUser');
  }

  createUser(user: any, userRoles: any, userLocations?: any, supervisorId?: number): Promise<any> {
    return this.apiService.postHttpCallAsPromise('user', {
      user,
      userRoles,
      userLocations,
      supervisorId
    }, 'createUser');
  }

  getUserByEmail(email: string): Promise<any> {
    return this.apiService.postHttpCallAsPromise('finduserbyemail', { email }, 'getUserByEmail');
  }

  updateUserProfile(user: any): Promise<any> {
    return this.apiService.putHttpCallAsPromise(`userprofile/${user.id}`, user, 'updateUserProfile');
  }

  updatePassword(user: any): Promise<any> {
    return this.apiService.postHttpCallAsPromise('updatepassword', user, 'updatePassword');
  }

  checkPassword(email: string, resettoken: string) {
    return this.apiService.postHttpCallAsPromise('checkpassword', {
      email,
      resettoken
    }, 'checkPassword');
  }

  signUp(user: any): Promise<any> {
    return this.apiService.postHttpCallAsPromise('signup/', user, 'signUp');
  }

  resetPassword(email: string, password: string): Promise<any> {
    return this.apiService.postHttpCallAsPromise('passwordreset/', {
      email,
      password
    }, 'resetPassword');
  }

  sendResetRequest(email: string): Promise<any> {
    return this.apiService.postHttpCallAsPromise('email/sendresetlink/', {
      email
    }, 'sendResetRequest');
  }

  checkTerms(): Promise<any> {
    return this.apiService.getHttpCallAsPromise('termscheck', 'checkTerms');
  }

  getUserForCodes(userId: number): Promise<any> {
    return this.apiService.getHttpCallAsPromise(`forcodes/${userId}`, 'getUserForCodes');
  }

  addUserForCode(userId: number, forCodeId: number): Promise<any> {
    return this.apiService.postHttpCallAsPromise('userforcode', { userId, forCodeId }, 'addUserForCode');
  }

  deleteUserForCode(id: number): Promise<any> {
    return this.apiService.deleteHttpCallAsPromise(`userforcode/${id}`, 'deleteUserForCode');
  }

  //#endregion

}
