import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoggerService } from '../shared/logger/logger.service';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { AuthTokenService } from '../auth/auth-token.service';
import { CommonModule } from '@angular/common';
import { MenuItem } from '../common/obj.lib';
import { AppEnities } from '../common/constants.lib';


@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink, RouterLinkActive
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent implements OnInit, OnDestroy {
  private _subs: Subscription[] = [];


  constructor(
    public authTokenService: AuthTokenService,
    private logger: LoggerService,
  ) {}

  ngOnInit(): void {
    this.logger.debug('ngOnInit...');
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnDestroy...');
    if(this._subs)
      this._subs.forEach(s => s.unsubscribe());
  }



}
