import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { map, tap } from "rxjs/operators";
import { ReferenceLink } from '../models/models.lib';
import { APP_CONFIG } from '../setup/config';
import { FormModes } from '../common/constants.lib';
import { environment } from '../../environments/environment';

const { apiUrl } = environment;

export abstract class EnvironerReferenceLinksService extends BehaviorSubject<Array<any>>{
  public loading: boolean = false;

  constructor(
    private http: HttpClient,
  ) {
    super([]);
  }

  public query(id: number): void {
    this.fetch(id).subscribe((x) => {
      // console.log('query: x: ', x);
      return super.next(x);
    });
  }

  protected fetch(id: number): Observable<ReferenceLink[]> {
    this.loading = true;

    return this.http.get(encodeURI(`${apiUrl}/reference/link/all/${id}`))
      .pipe(
        map((response: any, i) => {
          // console.log('fetch:map:response: ', response, i);
          // let returning =<ReferenceLink[]>response; // response.map(r =>  ReferenceLink.create((r)));
          // console.log('fetch:map:response: ', returning);
          return <ReferenceLink[]>response;
        }),
        map(r => r.map(x => ReferenceLink.create(x))),
        // tap(nr => {
        //   console.log('nr: ', nr);
        // }),
        tap(() => (this.loading = false))
      )
  }
}

@Injectable({
  providedIn: 'root'
})
export class ReferenceLinksService extends EnvironerReferenceLinksService {

  constructor(
    http: HttpClient,
    private apiService: ApiService
  ) {
    super(http);
  }

  get(id: number): Promise<any> {
    return this.apiService.getHttpCallAsPromise(`reference/link/all/${id}`, 'get');
  }

  getById(id: number): Promise<any> {
    return this.apiService.getHttpCallAsPromise(`reference/link/${id}`, 'getById');
  }

  add(p: any): Promise<any> {
    return this.apiService.postHttpCallAsPromise('reference/link', p, 'add');
  }

  update(p: any): Promise<any> {
    return this.apiService.putHttpCallAsPromise(`reference/link/${p.id}`, p, 'update');
  }

  destroy(id: number): Promise<any> {
    return this.apiService.deleteHttpCallAsPromise(`reference/link/${id}`, 'destroy');
  }

  deleteFile(id: number, dirName: string): Promise<any> {
    return this.apiService.deleteHttpCallAsPromise(`file/${id}/${dirName}`, 'deleteFile');
  }

  addEdit(p: any, formMode: FormModes): Promise<any> {
    if (formMode === FormModes.NEW) {
      return this.add(p);
    } else {
      return this.update(p);
    }
  }
}

/**
 *  {
    "id": 12,
    "opportunity_id": 3,
    "description": "Test URL",
    "url": "https://une.edu.au",
    "document_file_name": null,
    "sort_order": 11,
    "deleted": false
  },
 */
