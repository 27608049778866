<link rel="stylesheet" href="https://unpkg.com/@progress/kendo-font-icons/dist/index.css" />
<div class="container" my-5>
  <div class="card" #appendTo>
    <div class="card-body no-border">
      <!-- <div class="card-title">
        <h3 style="text-align: left;">MANAGE CO-BENEFITS</h3>
      </div> -->

      <kendo-grid [kendoGridBinding]="gridData" [loading]="dataService.loading" [sort]="sort" [sortable]="true"
        [groupable]="true" [resizable]="true" [filter]="filter" filterable="menu" (filterChange)="filterChange($event)"
        (sortChange)="sortChange($event)" [pageSize]="pageSize" [pageable]="true" style="height: 400px;">

        <ng-template kendoGridToolbarTemplate position="top">
          <button [hidden]="!canCreate" (click)="onNew()" kendoButton class="btn btn-primary">
            <fa-icon icon="circle-plus"></fa-icon> Add Co-benefit
          </button>
        </ng-template>

        <kendo-grid-column [hidden]="!canUpdate" title="Edit" [width]="70" [style]="{'vertical-align': 'top'}">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <button (click)="onEditClick(dataItem)" kendoButton class="btn btn-stn-grid">
              <fa-icon icon="edit"></fa-icon>
            </button>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Visible" field="visible" [width]="100" [style]="{'vertical-align': 'top'}">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                       let-filterService="filterService">
            <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
                               [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Co-benefit" field="co_benefit" [width]="180" [style]="{'vertical-align': 'top'}">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                       let-filterService="filterService">
            <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
                               [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Co-benefit Group" field="co_benefit_group" [width]="220" [style]="{'vertical-align': 'top'}">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                       let-filterService="filterService">
            <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
                               [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Sub Co-benefit" field="sub_co_benefit" [width]="280" [style]="{'vertical-align': 'top'}">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                       let-filterService="filterService">
            <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
                               [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Positive" [width]="100" [style]="{'vertical-align': 'top'}">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">

            <input type="radio" disabled [checked]="dataItem.optionSelected === 'positive'" kendoRadioButton />
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Negative" [width]="100" [style]="{'vertical-align': 'top'}">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">

            <input type="radio" disabled [checked]="dataItem.optionSelected === 'negative'" kendoRadioButton />
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Neutral" [width]="100">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <input type="radio" disabled [checked]="dataItem.optionSelected === 'neutral'" kendoRadioButton />
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column [hidden]="!canDestroy" title="Delete" [width]="70" [style]="{'vertical-align': 'top'}">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <button (click)="onConfirmDelete(dataItem)" kendoButton class="btn btn-stn-grid">
              <fa-icon icon="trash"></fa-icon>
            </button>
          </ng-template>
        </kendo-grid-column>

        <ng-template kendoPagerTemplate>
          <kendo-pager-info *ngIf="pageSize > 0"></kendo-pager-info>
        </ng-template>

      </kendo-grid>

      <div class="d-grid d-md-flex justify-content-md-end">
        <button type="button" class="btn btn-secondary" (click)="closeWindow()"> Close</button>
      </div>

    </div>
  </div>

</div>

<kendo-dialog *ngIf="confirmDeleteItem" title="Please confirm" (close)="onConfirmedClose('cancel')" [minWidth]="250"
  [width]="450">

  <h4>Delete Co-benefit -<b>{{currentItem.co_benefit}} - {{currentItem.co_benefit_group}} -
      {{currentItem.sub_co_benefit}}</b>?</h4>

  <kendo-dialog-actions>
    <button kendoButton (click)="onConfirmedClose('delete')" themeColor="primary">Delete</button>
    <button kendoButton (click)="onConfirmedClose('cancel')">
      Cancel
    </button>
  </kendo-dialog-actions>
</kendo-dialog>

<!-- <kendo-dialog *ngIf="errorMsgOpen" (close)="onErrorDialogClose()">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      <span class="k-icon k-font-icon k-i-exclamation-circle"></span> Update Issue
    </div>
  </kendo-dialog-titlebar>

  <h5>The current address: {{this.currentItem?.address}} had a problem being removed.</h5>

  <kendo-dialog-actions>
    <button kendoButton (click)="onErrorDialogClose()" themeColor="primary">Close</button>
  </kendo-dialog-actions>
</kendo-dialog> -->
<!-- <div class="k-overlay" *ngIf="editItemOpen"></div> -->

<kendo-window *ngIf="editItemOpen" (close)="closeItem()" [width]="900" [(top)]="windowTop" [(left)]="windowLeft"
  autoFocusedElement="CobenefitType">
  <kendo-window-titlebar>
    <span class="k-window-title">{{showTitle}}</span>
    <button kendoWindowCloseAction></button>
  </kendo-window-titlebar>
  <casi-edit-co-benefit [coBenefit]="currentItem" [ddTreeData]="ddTreeData" [mode]="formMode" (onSave)="onItemSave($event)"
    (onCancel)="closeItem()"></casi-edit-co-benefit>
</kendo-window>
