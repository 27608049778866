<form class="k-form" [formGroup]="form" class="k-form-inline">

  <fieldset>

    <div class="k-form-field">
      <kendo-label [for]="CobenefitType" text="Co-benefit Type" class="col-3 col-form-label" style="padding-top: 0;">
      </kendo-label>
      <!-- <br /> -->
      <div class="col-9" style="padding-top: 0.375rem;">
        <kendo-dropdowntree #CobenefitType formControlName="coBenefitTree"
          [kendoDropDownTreeHierarchyBinding]="ddTreeData" childrenField="items" textField="text"
          valueField="id" [filterable]="true" (valueChange)="setCoId($event)" kendoDropDownTreeExpandable>
          <!-- [valuePrimitive]="true" -->
          <!-- [dataItem]="fthis" -->
          <ng-template kendoDropDownTreeValueTemplate let-dataItem>
            <span class="k-state-default" style="font-weight: bold;">
              {{ dataItem.co_benefit ? dataItem.co_benefit + ' | ' : ''}}
            </span>
            <span>{{ dataItem.co_benefit_group ? dataItem.co_benefit_group + ' | ': ''}} {{dataItem.text ? dataItem.text : ''}}</span>
            <!-- <span>{{dataItem?.text}}</span> -->
          </ng-template>
        </kendo-dropdowntree>
        <!-- <kendo-dropdownlist #CobenefitType formControlName="co_benefit_type_id" [data]="ddData"
        [defaultItem]="defaultItem" textField="co_benefit" valueField="id" [valuePrimitive]="true" required>
        <ng-template kendoDropDownListItemTemplate let-dataItem class="form-control">
          <span class="k-state-default">
            <h5 class="mb-0" style="font-size: 1rem">{{ dataItem.co_benefit }}</h5><br>
            <h6 class="mb-1">{{ dataItem.co_benefit_group }}</h6><br>
            <p>{{dataItem.sub_co_benefit}}</p>
          </span>
        </ng-template>
        <ng-template kendoDropDownListValueTemplate let-dataItem>
          <span class="k-state-default" style="font-weight: bold;">
            {{ dataItem.co_benefit }}
          </span>
          <span>{{ dataItem.co_benefit_group }}</span><br>
          <span>{{dataItem.sub_co_benefit}}</span>
        </ng-template>
      </kendo-dropdownlist> -->

        <!-- <br> -->
        <div *ngIf="co_benefit_type_id?.invalid" class="alert alert-danger">

          <div *ngIf="co_benefit_type_id?.errors?.['required'] || co_benefit_type_id?.errors?.['min']">
            Co-benefit Type is required.
          </div>
        </div>
      </div>

    </div>

    @for(item of coBenefit.radioOptions; track item.value){
      <div class="k-form-field">
      <kendo-label for="opt{{item.value}}" text={{item.text}} class="col-3 form-label k-label rlabel"></kendo-label>
      <div class=" col-6 rcol">
        <input type="radio" value={{item.value}} id="opt{{item.value}}" formControlName="optionSelected" kendoRadioButton />
      </div>
    </div>
    }

    <!-- <kendo-formfield showHints="initial">

      <ul class="k-radio-list">
        @for(item of coBenefit.radioOptions; track item.value){
        <li class="k-radio-item row">
          <kendo-label class="col-3 col-form-label" for="opt{{item.value}}" text={{item.text}}></kendo-label>
          <div class="col-8">
            <input type="radio" value={{item.value}} id="opt{{item.value}}" formControlName="optionSelected"
              kendoRadioButton />
          </div>

        </li>
        }
        @empty {
        <li>No options found.</li>
        }
      </ul>
      <kendo-formhint class="offset-3 col-8" style="padding-left: 10px; margin-bottom: 10px;">Hint: Choose an
        option</kendo-formhint>
      <kendo-formerror class="offset-3 col-8" style="padding-left: 10px; margin-bottom: 10px;">Error: Option is
        required</kendo-formerror>
    </kendo-formfield> -->

    <div class="k-form-field">
      <kendo-label for="summary" class="col-3 form-label k-label" style="padding-top: 0;">Summary</kendo-label>
      <div class=" col-9">
        <textarea class="form-control" formControlName="summary" id="summary" rows="4"></textarea>
        <div *ngIf="summary?.invalid" class="alert alert-danger">

          <div *ngIf="summary?.errors?.['required']">
            Summary is required.
          </div>
        </div>
      </div>

    </div>

    <div class="k-form-field">
      <kendo-label for="visible" text="Visible" class="col-3 form-label k-label rlabel"></kendo-label>
      <div class="col-9 rcol">
        <input type="checkbox" size="large" kendoCheckBox class="form-check-input" formControlName="visible"
          id="visible" />

      </div>
    </div>


    <!-- <p>
      <br>
      coBenefit: {{coBenefit | json }}<br>
    </p> -->

    <!-- Buttons -->

    <div class="d-grid d-md-flex justify-content-md-end">
      <button type="button" class="btn btn-primary me-md-2" (click)="onSubmit()" [disabled]="form.invalid">
        Save</button>
      <!-- <button type="button" class="btn btn-primary me-md-2" (click)="onSubmit()">
        Save</button> -->
      <button type="button" class="btn btn-secondary" (click)="closeWindow()"> Cancel</button>
    </div>

  </fieldset>
</form>
