import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormModes} from "../../../common/constants.lib";
import {Resource, ResourceOpportunity} from "../../../models/models.lib";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {LoggerService} from "../../../shared/logger/logger.service";
import {DropDownsModule} from "@progress/kendo-angular-dropdowns";
import {ResourceService} from "../../../resources/resource.service";
import {CommonModule} from "@angular/common";

interface Item {
  id: number | null,
  title: string
}

@Component({
  selector: 'casi-add-resource',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    DropDownsModule
  ],
  templateUrl: './add-resource.component.html',
  styleUrl: './add-resource.component.scss'
})
export class AddResourceComponent implements OnInit, OnDestroy {

  @Input({ required: true }) resourceOpportunity: ResourceOpportunity = new ResourceOpportunity();

  @Output() onCancel = new EventEmitter<void>();
  @Output() onSave = new EventEmitter<any>();

  public value = '';
  form: FormGroup;
  private _subs: Subscription[] = [];
  public resourceList: Resource[] = [];

  public defaultItem: Item = {
    id: null,
    title: "Select item..."
  };

  constructor(
    private fb: FormBuilder,
    private logger: LoggerService,
    public dataService: ResourceService
  ) {
    this.form = this.fb.group(
      {
        id: [null, []],
        opportunity_id: [null, []],
        resource_id: [null, [Validators.required]]
      }
    );
  }

  get f() {
    return this.form.controls;
  }
  get resource_id() {
    return this.form.get('resource_id');
  }

  ngOnInit(): void {
    this.logger.debug('ngOnInit...');
    this.form.patchValue(this.resourceOpportunity);

    this.dataService.query();
    this._subs.push(this.dataService.subscribe(items => {
      this.logger.debug('ngOnInit:items: ', items);
      this.resourceList = items;
    }));
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnInit...');
    this._subs.forEach(s => s.unsubscribe());
  }

  onSubmit(): void {
    this.logger.debug(`onSubmit...`, this.form.value);
    const { name } = this.form.value;
    this.onSave.emit({
      action: "save",
      data: this.form.value
    });
  }

  closeWindow(): void {
    this.logger.debug(`closeWindow...`);
    this.onCancel.emit();
  }

}
