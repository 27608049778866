import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { CoBenefitTypesService } from '../coBenefits/co-benefit-types.service';
import { map } from 'rxjs';
import { CoBenefitType } from '../models/models.lib';
import { AuthTokenService } from '../auth/auth-token.service';
import { LoggerService } from '../shared/logger/logger.service';
import { buildLevel3DropDownTree } from '../common/utils.lib';

export const coBenDdTreeResolver: ResolveFn<any> = (route, state) => {
  let logger = inject(LoggerService);
  return inject(CoBenefitTypesService).get()
    .pipe(map((response: any, i) => {
      // console.log('fetch:map:response: ', response, i);
      // let returning =<CoBenefitType[]>response; // response.map(r =>  CoBenefitType.create((r)));
      // console.log('fetch:map:response: ', returning);
      return <CoBenefitType[]>response;
    }),
      map(r => r.map(x => CoBenefitType.create(x))),
      map(r => {
        logger.debug('coBenDdTreeResolver:r', r);
        return buildLevel3DropDownTree(r);
      })
  );
};
