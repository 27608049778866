<form [formGroup]="form" (ngSubmit)="onSubmit()" class="k-form-inline">
  <fieldset>

    <div class="k-form-field" >
      <label class="form-label col-3 k-label" for="name">Name</label>
      <div class="col-8">
        <input type="name" id="name" formControlName="name" class="form-control">
        <div *ngIf="name?.invalid" class="alert alert-danger">
          <div *ngIf="name?.errors?.['required']">Name is required.</div>
        </div>
      </div>
    </div>

    <div class="d-grid d-md-flex justify-content-md-end">
      <button type="button" class="btn btn-primary me-md-2" type="submit" [disabled]="form.invalid"> Save</button>
      <button type="button" class="btn btn-secondary" (click)="closeWindow()"> Cancel</button>
    </div>

  </fieldset>
</form>
