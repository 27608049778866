import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {map, tap} from "rxjs/operators";
import {environment} from "../../environments/environment";
import {ApiService} from "../api/api.service";
import {OpportunityReview} from "../models/models.lib";

const { apiUrl } = environment;

export abstract class EnvironerOpportunityReviewService extends BehaviorSubject<Array<any>>{
  public loading: boolean = false;

  constructor(
    private http: HttpClient,
  ) {
    super([]);
  }

  public query(id: number | undefined): void {
    this.fetch(id).subscribe((x) => {
      return super.next(x);
    });
  }

  protected fetch(id: number | undefined): Observable<OpportunityReview[]> {
    this.loading = true;

    return this.http.get(encodeURI(`${apiUrl}/opportunity/review/all/${id}`))
      .pipe(
        map((response: any, i) => {
          return <OpportunityReview[]>response;
        }),
        map(r => r.map(x => OpportunityReview.create(x))),
        tap(() => (this.loading = false))
      )
  }
}

@Injectable({
  providedIn: 'root'
})
export class OpportunityReviewsService extends EnvironerOpportunityReviewService{

  constructor(
    http: HttpClient,
    private apiService: ApiService
  ) {
    super(http);
  }

  get(id: number) {
    return this.apiService.getHttpCall(`opportunity/review/all/${id}`, 'get');
  }

  getById(id: number): Promise<any> {
    return this.apiService.getHttpCallAsPromise(`opportunity/review/${id}`, 'getById');
  }

  add(p: any): Promise<any> {
    return this.apiService.postHttpCallAsPromise('opportunity/review', p, 'add');
  }

  update(p: any): Promise<any> {
    return this.apiService.putHttpCallAsPromise(`opportunity/review/${p.id}`, p, 'update');
  }

  destroy(id: number): Promise<any> {
    return this.apiService.deleteHttpCallAsPromise(`opportunity/review/${id}`, 'destroy');
  }

  integrate(p: any): Promise<any> {
    return this.apiService.putHttpCallAsPromise(`opportunity/review/integrate/${p.id}`, p, 'integrate');
  }
}
