import { Component, OnInit } from '@angular/core';
import { LoggerService } from "../../shared/logger/logger.service";
import { TermsConditionsService } from "../terms-conditions.service";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { ApiService } from '../../api/api.service';
import { AuthTokenService } from '../../auth/auth-token.service';
//import { AuthService } from '../auth.service';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'terms-check',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, FontAwesomeModule],
  templateUrl: './terms-check.component.html',
  styleUrl: './terms-check.component.scss'
})
export class TermsCheckComponent implements OnInit {

  docContent: string = "";
  private docVersion: number = 0;
  // agreeToTerms: boolean = false;
  form: FormGroup;

  constructor(
    private logger: LoggerService,
    private dataService: TermsConditionsService,
    private fb: FormBuilder,
    public faIcons: FaIconLibrary,
    private apiService: ApiService,
    private authTokenService: AuthTokenService,
    private router: Router
  ) {
    this.form = this.fb.group(
      {
        agreeToTerms: [null, [Validators.required]]
      }
    );
    faIcons.addIcons(faInfoCircle);
  }


  ngOnInit(): void {
    this.dataService.get("terms")
      .then(response => {
        this.logger.debug('ngOnInit: ', response);
        this.docContent = response.html;
        this.docVersion = response.version_number;
      }).catch(err => {
        this.logger.error('ngOnInit: ', err);
      })
  }


  updateUser(): void {
    let termsinfo = {
      version_number: this.docVersion
    };
    this.dataService.updateUserTerms(termsinfo)
      .then((resData) => {
        this.logger.debug('onSubmit:resData: ', resData);
        this.authTokenService.setToken(resData);
        // this.apiService.getCsrfAsPromise();
        this.router.navigate(['/logged-in']);
      }).catch((err) => {

      });
  }


}
