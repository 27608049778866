import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from "rxjs/operators";

import { HttpClient } from '@angular/common/http';
import { Role, UserRole } from '../models/models.lib';
import { environment } from '../../environments/environment';

const { apiUrl } = environment;

export abstract class EnvironerCasiRoleService extends BehaviorSubject<Array<any>> {
  public loading: boolean = false;

  constructor(
    private http: HttpClient,
  ) {
    super([]);
  }

  public query(): void {
    this.fetch()
      .subscribe((x) => {
        console.log('query: x: ', x);
        return super.next(x);
      });
  }

  public queryUserRoles(userId: number): void {
    this.fetchUserRoles(userId)
      .subscribe((x) => {
        console.log('queryUserRoles: x: ', x);
        return super.next(x);
      })
  }

  protected fetch(): Observable<Role[]> {
    this.loading = true;

    return this.http.get(encodeURI(`${apiUrl}/findroles`))
      .pipe(
        map((response: any, i) => <Role[]>response),
        map(r => r.map(x => Role.create(x))),
        // tap(nr => {
        //   console.log('nr: ', nr);
        // }),
        tap(() => (this.loading = false))
      )
  }

  protected fetchUserRoles(userId: number): Observable<UserRole[]> {
    this.loading = true;

    return this.http.get(encodeURI(`${apiUrl}/findanyuserroles/${userId}`))
      .pipe(
        map((response: any, i) => <UserRole[]>response),
        map(r => r.map(x => UserRole.create(x))),
        // tap(nr => {
        //   console.log('nr: ', nr);
        // }),
        tap(() => (this.loading = false))
      )
  }

}

@Injectable({
  providedIn: 'root'
})
export class CasiRolesService extends EnvironerCasiRoleService {

  constructor(
    http: HttpClient,
    private apiService: ApiService
  ) {
    super(http);
  }

  getUserRoles(id: number): Promise<UserRole[]> {
    return new Promise((resolve, reject) => {
      return this.fetchUserRoles(id)
        .subscribe({
          next: resData => resolve(resData),
          error: err => reject(err)
        });
    });
  }

}
