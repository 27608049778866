import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { map, tap } from "rxjs/operators";
import { CommonBenefitType } from '../models/models.lib';
import { APP_CONFIG } from '../setup/config';
import { FormModes } from '../common/constants.lib';
import { environment } from '../../environments/environment';

const { apiUrl } = environment;

export abstract class CommonBenefitService extends BehaviorSubject<Array<any>>{
  public loading: boolean = false;

  constructor(
    private http: HttpClient,
  ) {
    super([]);
  }

  public query(): void {
    this.fetch().subscribe((x) => {
      // console.log('query: x: ', x);
      return super.next(x);
    });
  }

  protected fetch(): Observable<CommonBenefitType[]> {
    this.loading = true;

    return this.http.get(encodeURI(`${apiUrl}/benefit/type/all`))
      .pipe(
        map((response: any, i) => {
          // console.log('fetch:map:response: ', response, i);
          // let returning =<CommonBenefitType[]>response; // response.map(r =>  CommonBenefitType.create((r)));
          // console.log('fetch:map:response: ', returning);
          return <CommonBenefitType[]>response;
        }),
        map(r => r.map(x => CommonBenefitType.create(x))),
        // tap(nr => {
        //   console.log('nr: ', nr);
        // }),
        tap(() => (this.loading = false))
      )
  }
}

@Injectable({
  providedIn: 'root'
})
export class BenefitsService extends CommonBenefitService {

  constructor(
    http: HttpClient,
    private apiService: ApiService
  ) {
    super(http);
  }

  get() {
    return this.apiService.getHttpCall('benefit/type/all', 'get');
  }

  getById(id: number): Promise<any> {
    return this.apiService.getHttpCallAsPromise(`benefit/type/${id}`, 'getById');
  }

  add(p: any): Promise<any> {
    return this.apiService.postHttpCallAsPromise('benefit/type', p, 'add');
  }

  update(p: any): Promise<any> {
    return this.apiService.putHttpCallAsPromise(`benefit/type/${p.id}`, p, 'update');
  }

  destroy(id: number): Promise<any> {
    return this.apiService.deleteHttpCallAsPromise(`benefit/type/${id}`, 'destroy');
  }

  addEdit(p: any, formMode: FormModes): Promise<any> {
    if (formMode === FormModes.NEW) {
      return this.add(p);
    } else {
      return this.update(p);
    }
  }

}

