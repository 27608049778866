import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { map, tap } from "rxjs/operators";
import { Permission } from '../models/models.lib';
import { APP_CONFIG } from '../setup/config';
import { environment } from '../../environments/environment';

const { apiUrl } = environment;

export abstract class EnvironerPermissionService extends BehaviorSubject<Array<any>>{
  public loading: boolean = false;

  constructor(
    private http: HttpClient,
  ) {
    super([]);
  }

  public query(): void {
    this.fetch().subscribe((x) => {
      // console.log('query: x: ', x);
      return super.next(x);
    });
  }

  protected fetch(): Observable<Permission[]> {
    this.loading = true;

    return this.http.get(encodeURI(`${apiUrl}/admin/permissions`))
      .pipe(
        map((response: any, i) => <Permission[]>response),
        map(r => r.map(x => Permission.create(x))),
        // tap(nr => {
        //   console.log('nr: ', nr);
        // }),
        tap(() => (this.loading = false))
      )
  }
}

@Injectable({
  providedIn: 'root'
})
export class PermissionService extends EnvironerPermissionService {


  constructor(
    http: HttpClient,
    private apiService: ApiService
  ) {
    super(http);
  }

  get() {
    return this.apiService.getHttpCall('admin/permissions', 'get');
  }

  getById(id: number): Promise<any> {
    return this.apiService.getHttpCallAsPromise(`admin/permissions/${id}`, 'getById');
  }

  add(p: any): Promise<any> {
    return this.apiService.postHttpCallAsPromise('admin/permissions', p, 'add');
  }

  update(p: any): Promise<any> {
    return this.apiService.putHttpCallAsPromise(`admin/permissions/${p.id}`, p, 'update');
  }

  destroy(id: number): Promise<any> {
    return this.apiService.deleteHttpCallAsPromise(`admin/permissions/${id}`, 'destroy');
  }
}
