import {Component, NO_ERRORS_SCHEMA, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import { LoggerService } from '../../shared/logger/logger.service';
import { RolesService } from '../roles.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import {
  PageChangeEvent,
  PagerPosition,
  PagerType, DataBindingDirective,
  GridModule
} from "@progress/kendo-angular-grid";
import { CompositeFilterDescriptor, SortDescriptor, filterBy, distinct, orderBy } from "@progress/kendo-data-query";
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogCloseResult, DialogService, DialogsModule, WindowModule } from '@progress/kendo-angular-dialog';
import {NotificationModule, NotificationService} from '@progress/kendo-angular-notification';
import { PagerModule } from "@progress/kendo-angular-pager";

import { Role, Permission } from '../../models/models.lib';
import {
  faPlusCircle, faAddressCard,
  faEdit, faTrash
} from '@fortawesome/free-solid-svg-icons';
import { MulticheckFilterComponent } from '../../filters/multicheck-filter/multicheck-filter.component';
import { CasiCloseOptions, FormModes } from '../../common/constants.lib';
import { EditRolesComponent } from './edit-roles/edit-roles.component';
import { findNearestNumber, setTitle } from '../../common/utils.lib';
import { PermissionService } from '../permission.service';
import { RolePermissionComponent } from './role-permission/role-permission.component';
import { EditRoleModalComponent } from './edit-role-modal/edit-role-modal.component';

@Component({
  selector: 'casi-roles',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    FontAwesomeModule,
    InputsModule,
    ButtonsModule,
    DialogsModule,
    WindowModule,
    PagerModule,
    GridModule,
    NotificationModule,
    MulticheckFilterComponent,
    EditRolesComponent,
    RolePermissionComponent,
    EditRoleModalComponent
  ],
  schemas: [NO_ERRORS_SCHEMA],
  templateUrl: './roles.component.html',
  styleUrl: './roles.component.scss'
})
export class RolesComponent implements OnInit, OnDestroy {

  @ViewChild("appendTo", { read: ViewContainerRef }) appendTo: ViewContainerRef | undefined;

  public view: Observable<Role[]> = new Observable<Role[]>();
  public rawData: Role[] = [];
  public filter: CompositeFilterDescriptor = { logic: "and", filters: [] };
  public gridData: Role[] = filterBy(this.rawData, this.filter);

  public permissionSet: Permission[] = []

  public currentRole: Role = new Role();

  public formMode: FormModes = FormModes.NOTSET;

  public editWinOpen: boolean = false;
  public errorWinOpen: boolean = false;
  public confirmDeleteRole: boolean = false;
  public accessRightsWinOpen: boolean = false;
  public errorMsgOpen: boolean = false;

  public pagerTypes = ["numeric", "input"];
  public type: PagerType = "numeric";
  public info = true;
  public pageSizes = true;
  public previousNext = true;
  public position: PagerPosition = "bottom";

  public pageSize = 10;

  private _showTitle = new BehaviorSubject<string>('');
  get showTitle() {
    return this._showTitle.getValue();
  }
  set showTitle(val: string) {
    this._showTitle.next(val);
  }

  private _subs: Subscription[] = [];
  constructor(
    private logger: LoggerService,
    public dataService: RolesService,
    public faIcons: FaIconLibrary,
    private permissionService: PermissionService,
    private notificationService: NotificationService,
    private dialogService: DialogService
  ) {
    faIcons.addIcons(
      faPlusCircle, faAddressCard, faEdit,
      faTrash
    );
  }

  public sort: SortDescriptor[] = [
    {
      field: "name",
      dir: "asc",
    },
  ];

  ngOnInit(): void {
    this.logger.debug('ngOnInit...');
    // this.view = this.dataService;
    this.dataService.query();
    this.permissionService.query();
    this._subs.push(this.dataService.subscribe(v => {
      this.logger.debug('ngOnInit:v: ', v);
      this.rawData = v;
      this.loadData();
    }));
    this._subs.push(this.permissionService.subscribe(p => {
      this.logger.debug('ngOnInit:p: ', p);
      this.permissionSet = p;
    }))
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnDestroy...');
    this._subs.forEach(s => s.unsubscribe());
  }

  public onAddRole(): void {
    this.logger.debug("onAddRole...");
    this.currentRole = new Role();
    this.formMode = FormModes.NEW;
    this.logger.debug("onAddRole:currentRole ", this.currentRole);
    this.openAddEditDialog(this.formMode, this.currentRole);
    // this.showTitle = setTitle(this.formMode, 'ROLE');
    // this.editWinOpen = true;
  }

  onEditClick(dataItem: any): void {
    this.logger.debug("onEditClick: ", dataItem);
    this.currentRole = Role.create(dataItem);
    this.formMode = FormModes.EDIT;
    this.logger.debug("onEditClick:currentRole ", this.currentRole);
    this.openAddEditDialog(this.formMode, this.currentRole);
    // this.showTitle = setTitle(this.formMode, 'ROLE');
    // this.editWinOpen = true;
  }

  openAddEditDialog(mode: FormModes, dataItem: Role): void {
    this.logger.debug("openAddEditDialog...");
    const dialogRef = this.dialogService.open({
      content: EditRoleModalComponent,
      minWidth: 320,
      width: 450,
      // cssClass: "custom-css-class"
    });

    const contentRef = dialogRef.content.instance as EditRoleModalComponent;
    contentRef.title = setTitle(mode, 'ROLE');
    contentRef.role = dataItem;
    contentRef.mode = mode;

    dialogRef.result.subscribe((r: any) => {
      this.logger.debug('openAddEditDialog:result:', r);
      if (r instanceof DialogCloseResult) {
        return;
      }
      if (r.text === CasiCloseOptions.Saved) {
        this.logger.debug("openAddEditDialog:saved... ", contentRef.form.value);
        this.saveRole(contentRef.form.value, mode);
      }
    });
  }

  onEditPermisions(dataItem: any): void {
    this.logger.debug("onEditPermisions: ", dataItem, this.permissionSet);
    this.currentRole = dataItem;
    this.accessRightsWinOpen = true;
  }

  onConfirmDelete(dataItem: any): void {
    this.logger.debug("onConfirmDelete: ", dataItem);
    this.currentRole = dataItem;
    this.confirmDeleteRole = true;
  }

  onConfirmedClose(status: string): void {
    this.logger.debug("onConfirmedClose: ", status, this.currentRole);
    this.confirmDeleteRole = false;
    if (status === 'delete') {
      this.handleRemove(this.currentRole);
    }
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.logger.debug("sortChange: ", sort);
    this.sort = sort;
    this.loadData();
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    // this.logger.debug('filterChange: filter: ', filter);
    this.filter = filter;
    this.loadData();
    // this.gridData = filterBy(this.rawData, filter);
  }

  public distinctPrimitive(fieldName: string): any[] {
    // this.logger.debug('distinctPrimitive: fieldName: ', fieldName);
    return distinct(this.gridData, fieldName).map((item: any) => item[fieldName]);
  }

  public close(args: any): void {
    this.logger.debug('close...', args);
    this.editWinOpen = false;
    this.handleClose(args);
  }

  public closeAccessRights(): void {
    this.logger.debug('closeAccessRights...');
    this.accessRightsWinOpen = false;
  }

  public onDialogClose(): void {
    this.logger.debug('onDialogClose...');
    this.errorWinOpen = false;
  }

  public onErrorDialogClose(): void {
    this.logger.debug('onErrorDialogClose...');
    this.errorMsgOpen = false;
  }

  public onRolesPermissionSave(): void {
    this.logger.debug('onRolesPermissionSave...');
    this.handleAccessRightClose();
  }

  private loadData(): void {
    this.logger.debug('loadData...');
    this.gridData = orderBy(filterBy(this.rawData, this.filter), this.sort);
    this.pageSize = this.gridData.length;
  }

  private handleClose(args: any): void {
    this.logger.debug('handleClose...', args.data, args.mode);
    let dup: boolean = false;
    if (args && args.data) {
      let inUse = this.rawData.find(r => r.name === args.data.name);
      this.logger.debug('handleClose...inUse', inUse);
      if (inUse) {
        if (args.mode === FormModes.NEW) {
          dup = true;
        } else {
          dup = inUse.id !== args.data.id;
        }
      }
      if (dup) {
        this.logger.warn(`handleClose: role: ${args.data.name} already in use `);
        this.errorWinOpen = true;

      } else
        this.saveRole(args.data, args.mode);
    }
  }

  private handleAccessRightClose(): void {
    this.logger.debug('handleAccessRightClose...');
    this.dataService.query();
    this.accessRightsWinOpen = false;
  }

  private handleRemove(args: any): void {
    if (args && !args.id) {
      this.logger.error('handlRemove: No id given');
    }

    this.dataService.destroy(args.id)
      .then(resp => {
        this.logger.debug('handleRemove:resp: ', resp);
        this.notify("Role Deleted");
        this.dataService.query();
      })
      .catch(err => {
        this.logger.error('handleRemove:err: ', err);
        this.notify("Error Deleting Role", true);
        this.errorMsgOpen = true;
      });
  }

  private saveRole(role: any, mode: FormModes): void {
    this.logger.debug('saveRole...', role, mode);
    if (mode === FormModes.EDIT) {
      this.dataService.update(role)
        .then(resp => {
          this.logger.debug('saveRole:update:resp: ', resp);
          this.notify("Role Updated");
          this.dataService.query();
        })
        .catch(err => {
          this.logger.error('saveRole:update:err: ', err);
          this.notify("Error Updating Role", true);
        });
    } else {
      this.dataService.add(role)
        .then(resp => {
          this.logger.debug('saveRole:add:resp: ', resp);
          this.notify("Role Created");
          this.dataService.query();
        })
        .catch(err => {
          this.logger.error('saveRole:add:err: ', err);
          this.notify("Error Creating Role", true);
        });
    }
  }

  private notify(msg: string, error: boolean = false): void {
    this.logger.debug("notify", msg);
    this.notificationService.show({
      appendTo: this.appendTo,
      content: msg,
      animation: { type: "fade", duration: 300 },
      type: { style: !error ? "success" : "error", icon: true },
      position: {
        horizontal: "right",
        vertical: "top"
      },
      height: 50,
      // width: 150,
      hideAfter: 2000
    });
  }

}
