import { Component, OnInit } from '@angular/core';
import { LoggerService } from '../../shared/logger/logger.service';
import { Route, Router } from '@angular/router';
import { StorageService } from '../../auth/storage.service';

@Component({
  selector: 'app-signed-out',
  standalone: true,
  imports: [],
  templateUrl: './signed-out.component.html',
  styleUrl: './signed-out.component.scss'
})
export class SignedOutComponent implements OnInit {

  constructor(
    private logger: LoggerService,
    private router: Router,
    private storage: StorageService
  ) { }

  signIn() {
    this.logger.debug('signIn...');
    this.router.navigate(['/login']);
  };

  ngOnInit(): void {
    this.logger.debug('ngOnInit...');
    // if (this.storage.get('redirectState'))
    //   this.storage.remove('redirectState');
    // else
    //   this.storage.set('redirectState', 'signed-out');
  }

}
