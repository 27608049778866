import { Component, NO_ERRORS_SCHEMA, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { LoggerService } from '../../shared/logger/logger.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { GridModule, PageChangeEvent, PagerPosition, PagerType } from "@progress/kendo-angular-grid";
import { CompositeFilterDescriptor, SortDescriptor, filterBy, distinct, orderBy } from "@progress/kendo-data-query";
import { CommonModule } from '@angular/common';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogsModule, WindowCloseResult, WindowModule, WindowRef, WindowService } from '@progress/kendo-angular-dialog';
import { NotificationModule, NotificationService } from '@progress/kendo-angular-notification';
import { PagerModule } from "@progress/kendo-angular-pager";
import { faPlusCircle, faAddressCard, faEdit, faTrash, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { MulticheckFilterComponent } from '../../filters/multicheck-filter/multicheck-filter.component';
import { FormModes } from '../../common/constants.lib';
import { findNearestNumber, setTitle } from '../../common/utils.lib';
import { UserDataService } from '../user-data.service';
import { User, Role } from '../../models/models.lib';
// import { EditUserComponent } from '../edit-user/edit-user.component';
import { CasiRolesService } from '../casi-roles.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorDialogComponent } from '../../common-components/error-dialog/error-dialog.component';
import { EditUserModalComponent } from '../edit-user-modal/edit-user-modal.component';

@Component({
  selector: 'casi-user-list',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    FontAwesomeModule,
    InputsModule,
    ButtonsModule,
    DialogsModule,
    WindowModule,
    PagerModule,
    GridModule,
    NotificationModule,
    MulticheckFilterComponent,
    // EditUserComponent,
    EditUserModalComponent,
    ErrorDialogComponent
  ],
  schemas: [NO_ERRORS_SCHEMA],
  templateUrl: './user-list.component.html',
  styleUrl: './user-list.component.scss'
})
export class UserListComponent implements OnInit, OnDestroy {
  public view: Observable<User[]> = new Observable<User[]>();
  public rawData: User[] = [];
  public rolesData: Role[] = [];
  public filter: CompositeFilterDescriptor = { logic: "and", filters: [] };
  public gridData: User[] = filterBy(this.rawData, this.filter);

  public currentUser: User = new User();
  public selectedRoles: any = {};
  public selectedRoleIds: Role[] = [];

  public formMode: FormModes = FormModes.NOTSET;
  public editWinOpen: boolean = false;
  public errorOpened: boolean = false;
  public showErrorMessage: boolean = false;
  public confirmDeleteUser: boolean = false;

  public pagerTypes = ["numeric", "input"];
  public type: PagerType = "numeric";
  public info = true;
  public pageSizes = true;
  public previousNext = true;
  public position: PagerPosition = "bottom";

  public pageSize = 10;

  @ViewChild("appendTo", { read: ViewContainerRef }) appendTo: ViewContainerRef | undefined;

  private _showTitle = new BehaviorSubject<string>('');
  get showTitle() {
    return this._showTitle.getValue();
  }
  set showTitle(val: string) {
    this._showTitle.next(val);
  }

  private showEmailInUse: boolean = false;

  private _windowRef: WindowRef | undefined;

  private _subs: Subscription[] = [];
  constructor(
    private logger: LoggerService,
    public dataService: UserDataService,
    public rolesService: CasiRolesService,
    // public userRolesService: CasiUserRolesService,
    public faIcons: FaIconLibrary,
    private notificationService: NotificationService,
    private toastr: ToastrService,
    private windowService: WindowService
  ) {
    faIcons.addIcons(
      faPlusCircle, faAddressCard, faEdit,
      faTrash, faTriangleExclamation
    );
  }

  public sort: SortDescriptor[] = [
    {
      field: "name",
      dir: "asc",
    },
  ];

  //#region Ng functions

  ngOnInit(): void {
    this.logger.debug('ngOnInit...');
    this.dataService.query();
    this.rolesService.query();
    this._subs.push(this.dataService.subscribe(v => {
      this.logger.debug('ngOnInit:v: ', v);
      this.rawData = v;
      this.loadData();
    }));
    this._subs.push(this.rolesService.subscribe(roles => {
      this.logger.debug('ngOnInit:roles: ', roles);
      this.rolesData = roles;
    }));

  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnDestroy...');
    this._subs.forEach(s => s.unsubscribe());
  }

  //#endregion

  //#region Grid function

  public sortChange(sort: SortDescriptor[]): void {
    this.logger.debug("sortChange: ", sort);
    this.sort = sort;
    this.loadData();
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    // this.logger.debug('filterChange: filter: ', filter);
    this.filter = filter;
    this.loadData();
    // this.gridData = filterBy(this.rawData, filter);
  }

  public distinctPrimitive(fieldName: string): any[] {
    // this.logger.debug('distinctPrimitive: fieldName: ', fieldName);
    return distinct(this.gridData, fieldName).map((item: any) => item[fieldName]);
  }

  //#endregion

  public showAddWindow(titleBar: TemplateRef<unknown>): void {
    this.logger.debug("showAddWindow...");
    this.closeWindowRef();
    this.currentUser = new User();
    this.formMode = FormModes.NEW;
    this.selectedRoleIds = [];
    this.logger.debug("showAddWindow:currentUser ", this.currentUser);
    this.showTitle = setTitle(this.formMode, 'USER DETAILS');
    this._windowRef = this.windowService.open({
      // title: setTitle(this.formMode, 'USER DETAILS'),
      titleBarContent: titleBar,
      content: EditUserModalComponent,
      width: 700,
      minWidth: 320
    });
    this.setAddEditInstanceInputs();

    this._windowRef.content.instance.submitForm.subscribe((formData: FormGroup) => {
      this.logger.debug('showAddWindow:formData: ', formData);
      this.handleWinRefClose(formData.value, this.formMode);
    });

    this._windowRef.result.subscribe(results => {
      this.logger.debug('showAddWindow:results: ', results);
      if(results instanceof WindowCloseResult){
        this.closeWindowRef();
      }
    });

    this._windowRef.content.instance.closeForm.subscribe(() => {
      this.logger.debug('showAddWindow:closeForm');
      this.closeWindowRef();
    });
    this.editWinOpen = true;

  }

  public showEditWindow(dataItem: any, titleBar: TemplateRef<unknown>): void {
    this.logger.debug("showEditWindow...");
    this.closeWindowRef();
    this.currentUser = User.create(dataItem);
    this.formMode = FormModes.EDIT;
    this.logger.debug("showEditWindow:currentUser ", this.currentUser);
    this.showTitle = setTitle(this.formMode, 'USER DETAILS');
    this._windowRef = this.windowService.open({
      // title: setTitle(this.formMode, 'USER DETAILS'),
      titleBarContent: titleBar,
      content: EditUserModalComponent,
      width: 700,
      minWidth: 320
    });
    this.getUserDetails();

    this._windowRef.content.instance.submitForm.subscribe((formData: FormGroup) => {
      this.logger.debug('showEditWindow:formData: ', formData);
      this.handleWinRefClose(formData.value, this.formMode);
    });

    this._windowRef.result.subscribe(results => {
      this.logger.debug('showEditWindow:results: ', results);
      if(results instanceof WindowCloseResult){
        this.closeWindowRef();
      }
    });

    this._windowRef.content.instance.closeForm.subscribe(() => {
      this.logger.debug('showEditWindow:closeForm');
      this.closeWindowRef();
    });
    this.editWinOpen = true;
  }

  public onAddUser(): void {
    this.logger.debug("onAddUser...");
    this.currentUser = new User();
    this.formMode = FormModes.NEW;
    this.logger.debug("onAddRole:currentUser ", this.currentUser);
    this.showTitle = setTitle(this.formMode, 'USER DETAILS');
    this.selectedRoleIds = [];
    this.editWinOpen = true;
  }

  onEditClick(dataItem: any): void {
    this.logger.debug("onEditClick: ", dataItem);
    this.currentUser = User.create(dataItem);
    this.formMode = FormModes.EDIT;
    this.logger.debug("onEditClick:currentUser ", this.currentUser);
    this.showTitle = setTitle(this.formMode, 'USER DETAILS');
    this.selectedRoleIds = [];
    // this.userRolesService.query((this.currentUser.id as number));

    this.rolesService.getUserRoles((this.currentUser.id as number))
      .then(userRoles => {
        this.logger.debug('onEditClick:userRoles: ', userRoles);
        if (userRoles && userRoles.length > 0) {
          this.selectedRoleIds = userRoles.map(ur => {
            return (this.rolesData.find(rd => rd.id === ur.role_id) as Role);
          });
          // this.selectedRoleIds = userRoles.map(ur => {
          //   let found = this.rolesData.find(rd => rd.id === ur.role_id);
          //   return (found as Role);
          // });
        }
        this.currentUser.addRoles(this.selectedRoleIds);
        this.logger.debug('onEditClick:userRoles: selectedRoleIds: ', this.selectedRoleIds);
        this.editWinOpen = true;
      })
      .catch(err => {
        this.logger.error('onEditClick:getUserRoles:error: ', err);
      })
  }

  onConfirmDelete(dataItem: any): void {
    this.logger.debug("onConfirmDelete: ", dataItem);
    this.currentUser = dataItem;
    this.confirmDeleteUser = true;
  }

  toggleActive(dataItem: any) {
    this.logger.debug('toggleActive:dataItem: ', dataItem);
    this.currentUser = dataItem;
    this.dataService.setActive(dataItem.id, dataItem.active)
      .then(() => {
        this.toastr.success(!this.currentUser.active ? "User Activated" : "User Deactivated", "Success");
        // this.notificationService.show({
        //   appendTo: this.appendTo,
        //   content: !this.currentUser.active ? "User Activated" : "User Deactivated",
        //   animation: { type: "fade", duration: 300 },
        //   type: { style: "success", icon: true },
        //   position: {
        //     horizontal: "right",
        //     vertical: "top"
        //   },
        //   // closable: true,
        //   height: 50,
        //   width: 150,
        //   hideAfter: 2000
        // });
        // logger.logActivity("Updating User Status", vm.editUser, { active: !vm.editUser.active });
      })
      .catch(e => {
        this.logger.error(`toggleActive:error: `, e);
        this.toastr.error('Error updating user', 'Error');
        // this.notificationService.show({
        //   appendTo: this.appendTo,
        //   content: `Error updating user`,
        //   animation: { type: "fade", duration: 300 },
        //   type: { style: "error", icon: true },
        //   position: {
        //     horizontal: "right",
        //     vertical: "top"
        //   },
        //   // closable: true,
        //   height: 50,
        //   width: 150,
        //   hideAfter: 2000
        // });
      })
      .finally(() => this.dataService.query());
  }

  public close(args: any): void {
    this.logger.debug('close...', args);
    if (!args || args.action == "cancel")
      this.editWinOpen = false;
    if (args && args.action == "save")
      this.handleClose(args);
  }


  public errorClose(status: string): void {
    this.logger.debug('errorClose:status: ', status);
    this.errorOpened = false;
  }

  public closeErrorMessage(): void {
    this.logger.debug('closeErrorMessage...');
    this.showErrorMessage = false;
  }

  onConfirmedClose(status: string): void {
    this.logger.debug("onConfirmedClose: ", status, this.currentUser);
    this.confirmDeleteUser = false;
    if (status === 'delete') {
      this.handleRemove(this.currentUser);
    }
  }

  //#region Private function

  private setAddEditInstanceInputs(): void {
    if (this._windowRef) {
      const inputs = this._windowRef.content.instance;
      inputs.user = this.currentUser;
      inputs.roles = this.rolesData;
      inputs.selectedRoleIds = this.selectedRoleIds;
      inputs.mode = this.formMode;
      this.logger.debug('setAddEditInstanceInputsinputs: ', inputs);
    }
  }

  private getUserDetails(): void {
    this.selectedRoleIds = [];
    this.rolesService.getUserRoles((this.currentUser.id as number))
      .then(userRoles => {
        this.logger.debug('getUserDetails:userRoles: ', userRoles);
        if (userRoles && userRoles.length > 0) {
          this.selectedRoleIds = userRoles.map(ur => {
            return (this.rolesData.find(rd => rd.id === ur.role_id) as Role);
          });
        }
        this.currentUser.addRoles(this.selectedRoleIds);
        this.logger.debug('getUserDetails:userRoles:selectedRoleIds: ', this.selectedRoleIds);
        this.setAddEditInstanceInputs();
      })
      .catch(err => {
        this.logger.error('getUserDetails:getUserRoles:error: ', err);
      })
  }

  private closeWindowRef(): void {
    if (this._windowRef) {
      this._windowRef.close();
      this._windowRef = undefined;
    }
    this.editWinOpen = false;
  }

  private handleWinRefClose(formData: any, mode: FormModes): void {
    this.logger.debug('handleWinRefClose...', formData);
    let toSubmit = formData;
    let uRoles = (formData.userRoles as Role[]).map(ur => ur.id);

    if (mode === FormModes.EDIT) {
      this.dataService.updateUser(toSubmit, uRoles)
        .then(resp => {
          this.showEmailInUse = false;
          this.toastr.success('User Updated', 'Success');
          this.closeWindowRef();
          // windowRef.close();
          this.dataService.query();
        })
        .catch(err => {
          this.logger.error('handleClose:', err);
          this.showEmailInUse = err.status === 409;
          if (this.showEmailInUse) {
            this.showErrorMessage = true;
            // this.errorOpened = true;
          } else {
            this.toastr.error('Error saving user', 'Error');
          }
        });
    } else {
      this.dataService.createUser(toSubmit, uRoles)
        .then(resp => {
          this.showEmailInUse = false;
          this.toastr.success('User Created', 'Success');
          this.toastr.info(`Invitation Sent to ${this.currentUser.email}`);
          this.closeWindowRef();
          this.dataService.query();
        })
        .catch(err => {
          this.logger.error('handleClose:', err);
          this.showEmailInUse = err.status === 409;
          if (this.showEmailInUse) {
            this.showErrorMessage = true;
            // this.errorOpened = true;
          } else {
            this.toastr.error('Error saving user', 'Error');
          }
        });

    }
  }

  private handleClose(args: any): void {
    this.logger.debug('handleClose...', args);
    let toSubmit = args.data;
    let uRoles = (args.data.userRoles as Role[]).map(ur => ur.id);

    if (args.mode === FormModes.EDIT) {
      this.dataService.updateUser(toSubmit, uRoles)
        .then(resp => {
          this.showEmailInUse = false;
          this.toastr.success('User Updated', 'Success');
          // this.notificationService.show({
          //   appendTo: this.appendTo,
          //   content: 'User Updated',
          //   animation: { type: "fade", duration: 300 },
          //   type: { style: "success", icon: true },
          //   position: {
          //     horizontal: "right",
          //     vertical: "top"
          //   },
          //   // closable: true,
          //   height: 50,
          //   width: 150,
          //   hideAfter: 2000
          // });
          this.editWinOpen = false;
          this.dataService.query();
        })
        .catch(err => {
          this.logger.error('handleClose:', err);
          this.showEmailInUse = err.status === 409;
          if (this.showEmailInUse) {
            // toSubmit.email = this.currentUser.email;
            this.errorOpened = true;
          } else {
            this.toastr.error('Error saving user', 'Error');
            //   this.notificationService.show({
            //     appendTo: this.appendTo,
            //     content: `Error saving user`,
            //     animation: { type: "fade", duration: 300 },
            //     type: { style: "error", icon: true },
            //     position: {
            //       horizontal: "right",
            //       vertical: "top"
            //     },
            //     // closable: true,
            //     height: 50,
            //     width: 150,
            //     hideAfter: 2000
            //   });
          }
        });
    } else {
      this.dataService.createUser(toSubmit, uRoles)
        .then(resp => {
          this.showEmailInUse = false;
          this.toastr.success('User Created', 'Success');
          // this.notificationService.show({
          //   appendTo: this.appendTo,
          //   content: 'User Created',
          //   animation: { type: "fade", duration: 300 },
          //   type: { style: "success", icon: true },
          //   position: {
          //     horizontal: "right",
          //     vertical: "top"
          //   },
          //   // closable: true,
          //   height: 50,
          //   width: 150,
          //   hideAfter: 2000
          // });
          this.toastr.info(`Invitation Sent to ${this.currentUser.email}`);
          // this.notificationService.show({
          //   appendTo: this.appendTo,
          //   content: `Invitation Sent to ${this.currentUser.email}`,
          //   animation: { type: "fade", duration: 300 },
          //   type: { style: "success", icon: true },
          //   position: {
          //     horizontal: "right",
          //     vertical: "top"
          //   },
          //   // closable: true,
          //   height: 50,
          //   width: 150,
          //   hideAfter: 2000
          // });
          this.editWinOpen = false;
          this.dataService.query();
        })
        .catch(err => {
          this.logger.error('handleClose:', err);
          this.showEmailInUse = err.status === 409;
          if (this.showEmailInUse) {
            //toSubmit.email = this.currentUser.email;
            this.errorOpened = true;
          } else {
            this.toastr.error('Error saving user', 'Error');
            // this.notificationService.show({
            //   appendTo: this.appendTo,
            //   content: `Error saving user`,
            //   animation: { type: "fade", duration: 300 },
            //   type: { style: "error", icon: true },
            //   position: {
            //     horizontal: "right",
            //     vertical: "top"
            //   },
            //   // closable: true,
            //   height: 50,
            //   width: 150,
            //   hideAfter: 2000
            // });
          }
        });

    }
  }

  private loadData(): void {
    this.logger.debug('loadData...');
    this.gridData = orderBy(filterBy(this.rawData, this.filter), this.sort);
    this.pageSize = this.gridData.length;//findNearestNumber(this.sizes, this.gridData.length);
  }

  private handleRemove(args: any): void {
    if (args && !args.id) {
      this.logger.error('handlRemove: No id given');
    }

    this.dataService.deleteUser(args.id)
      .then(resp => {
        this.logger.debug('handleRemove:resp: ', resp);
        this.notify("User Deleted");
        this.dataService.query();
      })
      .catch(err => {
        this.notify("Error Deleting User", true);
        this.logger.error('handleRemove:err: ', err);
      });
  }

  private notify(msg: string, error: boolean = false): void {
    this.logger.debug("notify", msg);
    if (error)
      this.toastr.error(msg);
    else
      this.toastr.success(msg);
    // this.notificationService.show({
    //   appendTo: this.appendTo,
    //   content: msg,
    //   animation: { type: "fade", duration: 300 },
    //   type: { style: !error ? "success" : "error", icon: true },
    //   position: {
    //     horizontal: "right",
    //     vertical: "top"
    //   },
    //   height: 50,
    //   // width: 150,
    //   hideAfter: 2000
    // });
  }

  //#endregion

}
