import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonModule, NgClass } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoggerService } from '../../shared/logger/logger.service';
import { UserDataService } from '../../users/user-data.service';
import { EnquiryAddress } from '../../models/models.lib';
import { FormModes } from '../../common/constants.lib';

@Component({
  selector: 'casi-enquiry-recipient',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    FontAwesomeModule,
    RouterLink, RouterLinkActive,
    NgClass
  ],
  templateUrl: './enquiry-recipient.component.html',
  styleUrl: './enquiry-recipient.component.scss'
})
export class EnquiryRecipientComponent implements OnInit, OnDestroy {

  public enquiryAddress: EnquiryAddress = new EnquiryAddress();

  @Output() onCancel = new EventEmitter<void>();
  @Output() onSave = new EventEmitter<any>();

  form: FormGroup;

  private _subs: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private logger: LoggerService,
  ) {
    this.form = this.fb.group(
      {
        id: [null, []],
        address: [null, [Validators.required, Validators.email]],
      }
    );
  }

  get f() {
    return this.form.controls;
  }
  get address() {
    return this.form.get('address');
  }

  ngOnInit(): void {
    this.logger.debug('ngOnInit...');
    this.form.patchValue(this.enquiryAddress);
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnInit...');
    this._subs.forEach(s => s.unsubscribe());
  }

  onSubmit(): void {
    this.logger.debug(`onSubmit...`, this.form.value);
    const { address } = this.form.value;
    this.onSave.emit({
      action: "save",
      data: this.form.value
    });
  }

  closeWindow(): void {
    this.logger.debug(`closeWindow...`);
    this.onCancel.emit();
  }

  private handleSave() {

    this.logger.debug(`handleSave... `);
    // return this.dataService
    //   .update((this.role.id as number), this._selectedPermissions)
    //   .then((done) => {
    //     this.notificationService.show({
    //       appendTo: this.appendTo,
    //       content: 'Role Permissions Updated.',
    //       animation: { type: "fade", duration: 300 },
    //       type: { style: "success", icon: true },
    //       position: {
    //         horizontal: "right",
    //         vertical: "top"
    //       },
    //       // closable: true,
    //       height: 50,
    //       width: 150,
    //       hideAfter: 2000
    //     });
    //     this.onSave.emit();
    //   })
    //   .catch(err => {
    //     this.notificationService.show({
    //       appendTo: this.appendTo,
    //       content: 'Problem updating permissions.',
    //       animation: { type: "fade", duration: 300 },
    //       type: { style: "error", icon: true },
    //       position: {
    //         horizontal: "right",
    //         vertical: "top"
    //       },
    //       // closable: true,
    //       height: 50,
    //       width: 150,
    //       hideAfter: 2000
    //     });
    //     this.logger.error(`handleSave: ** ERROR **`, err);
    //   });
  }

}
